import React, { useEffect, useState } from "react";
import {useLocation } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {  Form,Col, Row, Spinner } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { fetchDelievryFeedBackExport } from "./actions/action";
import "flatpickr/dist/themes/material_blue.css";
import toast, { Toaster } from "react-hot-toast";


import { DateRangePicker } from "rsuite";
import moment from "moment";

function DeliveryFeedbackExportReport() {
  const {  afterToday, combine,allowedMaxDays} =DateRangePicker;
  const [windowSize, setWindowSize] = useState(getWindowSize());
  let isMobileView = 0;
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  if (windowSize.innerWidth <= 1279 && isMobileView === 0) {
    isMobileView++;
  }
  const [startDate, setStartDate] = useState(
    new moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    new moment(new Date()).format("YYYY-MM-DD")
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const [isTypeError, setisTypeError] = useState(false);
  const [isDateError, setisDateError] = useState(false);
  const [excelExportReport, setexcelExportReport] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const optionGroup = [
    {
      label: "Country",
      options: [
        { label: "All", value: "all" },
        { label: "UAE", value: "ae" },
        { label: "OMAN ", value: "om" },
        { label: "BAHRAIN ", value: "bh" },
        { label: "QATAR ", value: "qa" },
        { label: "KUWAIT", value: "kw" },
      ],
    },
  ];
  const handleSubmit = (event) => {
    setexcelExportReport(true);
    event.preventDefault();
    event.stopPropagation();
    setisTypeError(selectedCountry === null ? true : false);

    if (selectedCountry !== null) {
      fetchDelievryFeedBackExport({
        params: {
          date_from: startDate,
          date_to:
            endDate === undefined || endDate === "" ? startDate : endDate,
          country: selectedCountry,
        },
        dispatch,
      });

      // setisExportEnable(true);
    }
  };
  const { deliveryFeedBackExport = [], isExportLoading } = useSelector(
    (state) => state.review
  );
  useEffect(() => {
    setexcelExportReport(false);
    if (deliveryFeedBackExport?.code === 100) {
      toast.success(deliveryFeedBackExport?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
    } else {
      if (deliveryFeedBackExport?.message) {
        toast.error(deliveryFeedBackExport?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [deliveryFeedBackExport]);

  const handleSelectCountry = (selectedGroup) => {
    setSelectedCountry(selectedGroup?.value);
  };
  return (
    <>
      <Row>
        <Form onSubmit={handleSubmit}>
          <Col xl={12}>
            <div className="DashbordFliter1 ">
              <div className="InputBg">
                <Select
                  onChange={handleSelectCountry}
                  options={optionGroup}
                  classNamePrefix="select2-selection"
                />
              </div>

              {isTypeError && (
                <span
                  style={{
                    position: "absolute",
                    top: 40,
                    left: 10,
                    color: "#f00",
                  }}
                >
                  Please Select Country.
                </span>
              )}
              <div className="InputBg">
                {isMobileView !== 0 ? (
                  <>
                    <DateRangePicker
                      placement="auto"
                      placeholder="Select Date Range"
                      showOneCalendar
                      // disabledDate={combine(allowedMaxDays(30), afterToday())}
                      onChange={(selectedDates, instance) => {
                        const dateStr = selectedDates?.[0];
                        const first_Date = new Date(dateStr)?.toString();
                        const firstD = moment(new Date(first_Date))?.format(
                          "YYYY/MM/DD"
                        );
                        const firstDateReplace = firstD?.replaceAll("/", "-");
                        const dateStr1 = selectedDates?.[1];
                        const second_Date = new Date(dateStr1)?.toString();
                        const secondD = moment(new Date(second_Date))?.format(
                          "YYYY/MM/DD"
                        );
                        const secondDateReplace = secondD?.replaceAll("/", "-");
                        setStartDate(firstDateReplace);
                        setEndDate(secondDateReplace);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <DateRangePicker
                      placement="rightStart"
                      placeholder="Select Date Range"
                      onClean={() => {
                        setStartDate(
                          new moment(new Date()).format("YYYY-MM-DD")
                        );
                        setEndDate(new moment(new Date()).format("YYYY-MM-DD"));
                      }}
                      // disabledDate={combine(allowedMaxDays(30), afterToday())}
                      onChange={(selectedDates, instance) => {
                        const dateStr = selectedDates?.[0];
                        const first_Date = new Date(dateStr)?.toString();
                        const firstD = moment(new Date(first_Date))?.format(
                          "YYYY/MM/DD"
                        );
                        const firstDateReplace = firstD?.replaceAll("/", "-");
                        const dateStr1 = selectedDates?.[1];
                        const second_Date = new Date(dateStr1)?.toString();
                        const secondD = moment(new Date(second_Date))?.format(
                          "YYYY/MM/DD"
                        );
                        const secondDateReplace = secondD?.replaceAll("/", "-");
                        setStartDate(firstDateReplace);
                        setEndDate(secondDateReplace);
                      }}
                    />{" "}
                  </>
                )}
                {isDateError && (
                  <span
                    style={{
                      position: "absolute",
                      top: 40,
                      left: 0,
                      color: "#f00",
                    }}
                  >
                    Please Select Export Date.
                  </span>
                )}
              </div>

              <button
                type="submit"
                className="btn btn-primary"
                style={{ display: "flex" }}
              >
                {" "}
                {excelExportReport === true && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}{" "}
                Export{" "}
                <i className="bx bx-download" style={{ padding: "3px" }}></i>{" "}
              </button>
            </div>
          </Col>
        </Form>
      </Row>

      {}
    </>
  );
}
export default DeliveryFeedbackExportReport;
