import React from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
//import OrderList from "./components/OrderList";
import "react-datepicker/dist/react-datepicker.css"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
  } from "reactstrap";

const Returns = () => {
  const title = "Returns";
  const description = "Manage Returns";

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mange Returns</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Returns" breadcrumbItem="Manage Returns" />
            </Col>
            <Col xl={6}>
              <Row className="align-items-end">
                <Col xl={12} className="textAlignRigh">
                  <div className="DashbordFliter ">
                  <div className="InputBg"><select className="form-select">
                    <option>Choose Export Report Type</option>
                    <option>Large select</option>
                    <option>Small select</option>
                  </select></div>
                    <div className="InputBg">
                    <i className="bx bx-calendar"></i>
                    <FormGroup>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    </div>
                    <button type="submit" className="btn btn-primary">Export</button>
                  </div>
                </Col>
        
              </Row>
            </Col>
          </Row>
          
            {/* <OrderList /> */}
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Returns;