import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useUrlSearchParams } from "use-url-search-params";
import Select from "react-select";
import {  Form} from "react-bootstrap";
import { Badge } from "reactstrap";
import { useParams } from "react-router-dom";
import CsLineIcons from "../../cs-line-icons/CsLineIcons";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import { SORTING_ORDER } from "../../constants";
import { fetchReviewList } from "./actions/action";
import moment from "moment";

import {
  Table,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  NavLink,
  Nav,
  NavItem,
} from "reactstrap";

const ReviewList = () => {
  const location = useLocation();
  const [isApiCall, setApiCall] = useState(0);
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [ActiveFilter, setActiveFilter] = useState(false);

  const dispatch = useDispatch();
  const [sortingName, setSortingName] = useState({ id: "sort.date_asc" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: "_date",
    order: SORTING_ORDER.ASC,
  });

  useEffect(() => {
    setDataLoading(true);
    if (isApiCall === 0) {
      fetchReviewList({
        params: {
          start: Number(paginationValue.page * limitValue.limit),
          limit: Number(limitValue.limit),
          search_qry: searchValue.search,
          order_by: "desc",
        },
        dispatch,
      });
    }

    setDataLoading(false);
  }, [
    paginationValue.page,
    limitValue.limit,
    sortingValue.sort,
    searchValue.search,
  ]);
  const { reviewList = [], isdataLoading } = useSelector(
    (state) => state.review
  );
  const { listRating } = reviewList;
  const reviewData = listRating?.reviewList;
  const limitByValues = [5, 10, 20, 50];
  const [isDataLoading, setDataLoading] = useState(false);

  const sortByValues = [
    { key: "date", order: SORTING_ORDER.ASC, label: { id: "sort.date_asc" } },
    { key: "date", order: SORTING_ORDER.DESC, label: { id: "sort.date_desc" } },
    {
      key: "status",
      order: SORTING_ORDER.ASC,
      label: { id: "sort.status_asc" },
    },
    {
      key: "status",
      order: SORTING_ORDER.DESC,
      label: { id: "sort.status_desc" },
    },
  ];

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
    setApiCall(0);
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
    setApiCall(0);
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
    setApiCall(0);
  };
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
    setApiCall(0);
  };

  let pageIndex = Number(listRating?.start);

  return (
    <>

         <Row className=" dh-TabRow textAlignRight ">
      
        <Col className="MobileView textAlignRight ">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
        </Col>
      </Row>

      <div className="table-responsive dt-table">
        {isdataLoading || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {reviewData?.length > 0 ? (
          <>
            <Table className="OrderListTable table mb-0 ">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Rating</th>
                  <th>Review</th>
                  <th>Order ID</th>
                  <th>Order Date</th>
                  <th>Delivery Date</th>
                  <th></th>
                </tr>
              </thead>
              {(reviewData || []).map((order, index) => {
                pageIndex++;

                let cdate = order.created_at,
                  orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                  orderTime = moment(new Date(cdate)).format("h:mm:ss a");
                let orderdate = order.order_created_at,
                  orderCreatedDt = moment(new Date(orderdate)).format(
                    "Do MMM, YYYY"
                  ),
                  orderCreatedTime = moment(new Date(orderdate)).format(
                    "h:mm:ss a"
                  );

                let stsBtn = "bg-lightgrey";
                let reviewArr = order?.review?.split(",");
                let STsReview = "";
                if (order.rating === "1") {
                  stsBtn = "bg-danger";
                  STsReview = reviewArr;
                } else if (order.rating === "2") {
                  stsBtn = "bg-primary";
                  STsReview = reviewArr;
                } else if (order.rating === "3") {
                  stsBtn = "bg-secondary";
                  STsReview = reviewArr;
                } else if (order.rating === "4") {
                  stsBtn = "bg-success";
                  STsReview = reviewArr;
                } else {
                  stsBtn = "bg-success";
                  STsReview = reviewArr;
                }

                return (
                  <tbody key={order.id}>
                    <tr>
                      <td>
                        {orderDt} <br></br>
                        {orderTime}
                      </td>
                      <td>
                        <span className="reviewSpan">
                          {order.rating}{" "}
                          <span
                            className="star"
                            star1
                            style={{ color: "#FBAB32" }}
                          >
                            &#9733;
                          </span>
                        </span>
                      </td>
                      <td>
                       
                          {/* {reviewArr} */}
                          {reviewArr?.map((item, index) => {
                            return  <Badge className={` ${stsBtn} me-2`}><span key={index}>{item}</span> </Badge>
                          })}
                       
                      </td>

                      <td>{order.shipment_reference}</td>

                      <td>
                        {orderCreatedDt} <br></br>
                        {orderCreatedTime}
                      </td>
                      <td>{order.delivered_at}</td>
                      <td>
 <Link
                              to={`/deliveryreviewsdetail/${order.shipment_reference}`}
                              className="btn btn-sm btn-outline-warning waves-effect waves-light mr-2"
                            >
                              <i className="mdi mdi-eye-outline"></i>{" "}
                            </Link>
                       
                          </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>

            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listRating?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0 ">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Rating</th>
                  <th>Review</th>
                  <th>Order ID</th>
                  <th>Order Date</th>
                  <th>Delivery Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
};

export default ReviewList;
