import './DeliveryAlert.scss';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Row, Col, CardBody, CardHeader, Button } from 'reactstrap';

const DeliveryAlert = (props) => {
  const { deliveryDt, status } = props || {};
  let orderSts = status;
  orderSts = orderSts.toLowerCase();
  if (
    deliveryDt &&
    orderSts !== 'delivered' &&
    orderSts !== 'canceled' &&
    orderSts !== 'pending payment'
  ) {
    // Convert strings to Date objects
    const delDate = new Date(deliveryDt);
    const endDateDate = new Date();
    // Calculate the difference in milliseconds
    const dateDifferenceInMillis = endDateDate - delDate;
    // Convert milliseconds to days (1 day = 24 hours = 86400000 milliseconds)
    const dateDifferenceInDays = Math.floor(
      dateDifferenceInMillis / (24 * 60 * 60 * 1000)
    );
    if (dateDifferenceInDays > 0) {
      return (
        <div className="deliveryAlertBx">
          <span>
            <b>{dateDifferenceInDays}</b> Day Delay <br></br> in Delivery{' '}
          </span>
        </div>
      );
    }
  }
  return <></>;
};
export default DeliveryAlert;
