import { gql } from '@apollo/client';
export const CREATE_CUSTOMER = gql`
query (
  $firstName: String!
  $lastName: String! 
  $email: String! 
  $mobile: String!
  $otp: String!
  $password: String!
  $isSocial: Boolean!
  $source: String!){
    createCustomerAdmin(
    input: {
      firstName: $firstName
      lastName: $lastName
      email: $email
      mobile: $mobile
      otp: $otp
      password: $password
      isSocial:$isSocial
      source:$source
    }
  ) 
  {
    _id
    createdAt
    updatedAt
    firstName
    lastName
    email
    country {
      code
    }
    address {
      _id
      countryCode
      firstName
      lastName
      telephone
      alternateTelephone
      address1
      address2
      city
      region
      country
      mapData
      latitude
      longitude
      addressType
      isDefault
    }
  }
}`;

