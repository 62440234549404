import { gql } from '@apollo/client';
export const UPDATE_CUSTOMER_ADMIN= gql`
mutation(
   $input:updateCustomerProfileInput!
  ){
    updateCustomerProfileAdmin(input: $input) {
    _id
    createdAt
    updatedAt
    firstName
    lastName
    email
    country {
      code
    }
    address {
      _id
      countryCode
      firstName
      lastName
      telephone
      alternateTelephone
      address1
      address2
      flatNo
      city
      region
      country
      mapData
      latitude
      longitude
      addressType
      isDefault
      plusCode
    }
  }
    }`;