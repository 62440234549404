import React ,{useEffect} from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../../components/Common/Breadcrumb";
import UserRolleLists from "./UserRoleLists";





import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
  } from "reactstrap";

const UserRolle = () => {
  
  return (
    <React.Fragment>
    
          
         <UserRolleLists/>
         
      
    </React.Fragment>
  );
};

export default UserRolle;
