import React, { useEffect,useState } from "react";
import './Listing.scss' 
import { useDispatch, useSelector } from "react-redux"; 
import {DateRangePicker } from "rsuite";
import "react-drawer/lib/react-drawer.css";
import { useUrlSearchParams } from "use-url-search-params";
import { fetchQaList } from "../../action/action";
import moment from "moment";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import { Form } from "react-bootstrap";
import { Link, BrowserRouter} from "react-router-dom";
import ViewQuestionDetail from "../../../components/ViewQuestionDetail/ViewQuestionDetail";
import {
  Table,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  
} from "reactstrap";


const ListingComponent = () => {
  
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
 const [openPopup, setOpenPopup] = useState(false)
;  const [orderMode, setOrderMode] = useState("All");
  const { country = [] } = useSelector((state) => state.country);
  let isMobileView = 0;

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  
    useEffect(() => {
      fetchQaList({
          params: {
            start: Number(paginationValue.page * limitValue.limit),
            limit: Number(limitValue.limit),
            search_qry: searchValue.search,
            country:'ae',
            date_from: startDate,
             date_to:
            endDate === undefined || endDate === "" ? startDate : endDate,
            
            orderBy: "id",
          },
          dispatch,
        })
        
      },[startDate,paginationValue.page,
        limitValue]);
    const limitByValues = [5, 10, 20, 50];
    const { QaList = [] } = useSelector((state) => state.order);
    const { listOrders } = QaList;
    const orderData = listOrders?.orderList;

    console.log("QaList",QaList);
    let pageIndex = Number(listOrders?.start);
    const handlePagination = (e) => {
      setPaginationValue({ page: e.target.value });
    };
    const handleSearch = (e) => {
      setSearchValue({ search: e.target.value });
    };
    const handleLimit = (e) => {
      setLimitValue({ limit: Number(e) });
    };
    const [btnprimary1, setBtnprimary1] = useState(false);
   
  const handleSubmit = (event) => {
      event.preventDefault();
      event.stopPropagation();
  }
    
   
return (
    <div className="table-responsive dt-table"> *
   <Row className=" dh-TabRow textAlignRight "  >
        
        <Col className="MobileView textAlignRight ">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
            <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
       </Col>
      </Row>

      
   
    {orderData?.length > 0 ? (
      <>
        <Table className="OrderListTable table mb-0">
          <thead>
            <tr>
              <th>SI.No</th>
              <th>Created At</th>
              <th nowrap="nowrap">Reference NO</th>
              <th>Question</th>
              <th>Is Replied</th>
              <th></th>
           
            </tr>
          </thead>
          {(orderData || []).map((order, index) => {
            pageIndex++;
            let order_id = order.entity_id;
            console.log(order_id,"order_id")
            let split = "All";
            let orderCn = order.country.toLowerCase();
            const orderSplits = order.reference_no?.slice(0, 2);
            if (
              (orderMode === "split" && country === "ae") ||
              orderSplits === "WH" ||
              orderSplits === "CR" ||
              orderSplits === "ST"
            ) {
              split = "split";
              order_id = order.order_id;
              orderCn = "ae";
            } else if (
              (orderMode === "b2b" && country === "ae") ||
              orderSplits === "BB"
            ) {
              split = "b2b";
              order_id = order.order_id;
              orderCn = "ae";
            } else if (
              orderMode === "store" &&
              (country === "ae" || orderCn === "ae")
            ) {
              split = "store";
              order_id = order.entity_id;
              orderCn = "ae";
            } else if (
              orderMode === "store" &&
              country !== "ae" &&
              orderCn !== "ae"
            ) {
              split = "All";
              order_id = order.entity_id;
            }
            let cdate = order.created_at,
            orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
            orderTime = moment(new Date(cdate)).format("h:mm:ss a");
            let reply = '';
            if(order?.created_by === "customer"){
              reply = "Ask by customer"

            }
            else if(order?.created_by === "seller"){
              reply= "Ask by seller"
            }
            else {
              reply = "Ask by admin"
            }
          return (
              <tbody key={order?.qa_id}>
                <tr >
                  <th scope="row">{pageIndex}</th>
                  <td> {orderDt}<br/>{ orderTime}</td>
                  <td>{order?.reference_no}
               </td>
                <td  className="QData"><p className="qDetail">{order?.description}</p>
               
                <span className="createdBy">{reply}</span>
                </td> 
                  <td>{order?.is_replied === "0"? "No":"Yes"}</td>
                 <td>
                 <ViewQuestionDetail shipment={order?.reference_no} openPopup = {openPopup} orderCn={orderCn} split={split} order_id ={order_id}/>
             
              
                  </td>
                </tr>
              </tbody>
            );
          })}
        </Table>
        <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
        
      </>
    ) : (
      <>
        <Table className="OrderListTable table mb-0">
          <thead>
          <tr>
          <th>SI.No</th>
              <th nowrap="nowrap">Reference NO</th>
              <th>Question</th>
              <th>Is Replied</th>
           <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={7}>No Records found</td>
            </tr>
          </tbody>
        </Table>
      </>
    )}
  </div>

  
)

};
export default ListingComponent;