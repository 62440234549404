import React, {
  useEffect,
  useState,
} from "react";
import {  useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useUrlSearchParams } from "use-url-search-params";
import {

  Form,
} from "react-bootstrap";
import "flatpickr/dist/themes/material_blue.css";
import { Badge } from "reactstrap";
import "react-drawer/lib/react-drawer.css";
import { Link, BrowserRouter } from "react-router-dom";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import { fetchAllDeleteOrders } from "../actions/orderActions";
import { SORTING_ORDER } from "../../../constants/index";
import android from "../../../assets/images/android.png";
import apple from "../../../assets/images/apple.png";
import moment from "moment";
import "../style.scss";
import undoIcon from "../../../assets/images/undo.png";
import toast, { Toaster } from "react-hot-toast";
import { getRevertOrderData } from "../actions/orderActions";
import {
  Table,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner
} from "reactstrap";
const DeleteOrdersList = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  let isMobileView = 0;
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  if (windowSize.innerWidth <= 1279 && isMobileView === 0) {
    isMobileView++;
  }

  const location = useLocation();
  const search = useLocation().search;
  const paramsCountry = new URLSearchParams(search).get('cn');
  const [btnprimary1, setBtnprimary1] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: "_date",
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });

  const { country = [] } = useSelector((state) => state.country);
  const [isDataLoading, setDataLoading] = useState(false);

  const [orderMode, setOrderMode] = useState("All");
  const [isApiCall, setApiCall] = useState(0);

  let currentCountry = localStorage.getItem("WEBSITE_COUNTRY");
 
  let cntry = currentCountry;
const getAllOrderListData = () =>{
  
    setDataLoading(true);
  
    if (isApiCall === 0) {
      
      fetchAllDeleteOrders({
        params: {
          country: paramsCountry ? paramsCountry : cntry,
          start: Number(paginationValue.page * limitValue.limit),
          limit: Number(limitValue.limit),
       
          sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
          search_qry: searchValue.search,
          status: "",
          date_from: "",
          date_to:""
        },
        dispatch,
      });
      setTimeout(() => setApiCall(0), 8000);
    }
    setDataLoading(false);
 
}
useEffect(() => {
  getAllOrderListData()
}, [
  paginationValue.page,
  limitValue.limit,
  sortingValue.sort,
  searchValue.search,
  

]);
  const limitByValues = [5, 10, 20, 50];
  const {orderDeleteList = [], isOrderDeleteListLoading } = useSelector((state) => state.order);


  const { listOrders } = orderDeleteList;
  const orderData = listOrders?.orderList; 
  let LsSts = "New";

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
    localStorage.setItem("PageNumber",e.target.value);

    setApiCall(0);
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
    setApiCall(0);
  };

  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
    setPaginationValue({ page: 0 });
    setApiCall(0);
  };
  let userID = '';
  if (localStorage.getItem("authUser")) {
    const userObj = JSON.parse(localStorage.getItem("authUser"));
    if (userObj?.role_id !== undefined) {
        
          userID = userObj?.user_id
    }
  }



  const [isActive, setIsActive] = useState(true);

  const handleClick = () => {

    setIsActive(current => !current);
  };
  const renderPaymentMethods = (paymentMode) => {
    var PayMode = "";
    if (paymentMode === "payfort") {
      PayMode = "Payfort";
    } else if (paymentMode === "payfort_fort_cc") {
      PayMode = "Payfort";
    } else if (paymentMode === "postpay") {
      PayMode = "Postpay";
    } else if (paymentMode === "payfort_fort_installments") {
      PayMode = "Payfort Installments";
    } else if (paymentMode === "tamara") {
      PayMode = "Tamara";
    } else if (paymentMode === "tap") {
      PayMode = "Tap";
    } else if (paymentMode === "tap_careem_pay") {
      PayMode = "Tap Careem Pay";
    } else if (paymentMode === "tap_apple_pay") {
      PayMode = "Tap Apple Pay";
    } else if (paymentMode === "checkout") {
      PayMode = "Checkout";
    } else if (paymentMode === "spotiipay") {
      PayMode = "Spotii";
    } else if (paymentMode === "tap_knet") {
      PayMode = "Tap Knet";
    } else if (paymentMode === "apple_pay") {
      PayMode = "Apple Pay";
    } else if (paymentMode === "payfort_omannet") {
      PayMode = "Payfort Omannet";
    } else if (paymentMode === "cashew") {
      PayMode = "Cashew";
    } else if (paymentMode === "oman_net") {
      PayMode = "Oman Net";
    }
    if (paymentMode !== "cashondelivery") {
      return <Badge className="badge-soft-dark me-1">{PayMode}</Badge>;
    } else {
      return <></>;
    }
  };

  const revertOrderData = async (entityId) => {
    let data = {
      entity_id: entityId,
      user_id: userID
    };

    let result = await getRevertOrderData(data);

    if (result?.code === 100) {
      toast.success(result?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
      getAllOrderListData()
    }
    else{
      toast.error(result?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#f44336",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#f44336",
        },
      });
    }
  };
  let pageIndex = Number(listOrders?.start);

  return (
    <>
      <Row className="justify-content-between align-items-right textAlignRight dh-TabRow">
       
        <Col  className="MobileView ">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              
              />
              <i className="bx bx-search-alt search-icon" 
        onClick={handleClick}/>
            </div>
          </div>

      
        </Col>
      </Row>
   

      <div className=" dt-table">
        {isOrderDeleteListLoading || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {orderData?.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="OrderListTable table mb-0">
                <thead>
                  <tr>
                    <th>SI.No</th>
                    <th>Reference No</th>
                    <th>Name</th>
                    <th>Grand Total</th>
                    <th>Status</th>
                    <th>Device</th>
                    <th></th>
                  </tr>
                </thead>
                {(orderData || []).map((order, index) => {
        
                  const viewMoreBtn = `itv-${index}`;
                  pageIndex++;
                  let order_id = order.entity_id;
                  let split = "All";
                  let orderCn = order.country.toLowerCase();
                  const orderSplits = order.reference_no.slice(0, 2);
                  if (
                    (orderMode === "split" && country === "ae") ||
                    orderSplits === "WH" ||
                    orderSplits === "CR" ||
                    orderSplits === "ST"
                  ) {
                    split = "split";
                    order_id = order.order_id;
                    orderCn = "ae";
                  } else if (
                    (orderMode === "b2b" && country === "ae") ||
                    orderSplits === "BB"
                  ) {
                    split = "b2b";
                    order_id = order.order_id;
                    orderCn = "ae";
                  } else if (
                    (orderMode === "store" && (country === "ae" || orderCn === "ae"))
                  ) {
                    split = "store";
                    order_id = order.entity_id;
                    orderCn = "ae";
                  } else if (
                    orderMode === "store" &&
                    country !== "ae" &&
                    orderCn !== "ae"
                  ) {
                    split = "All";
                    order_id = order.entity_id;
                  }
                  let cdate = order.created_at,
                  orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                  orderTime = moment(new Date(cdate)).format("h:mm:ss a");
                  let paymentMode = "",
                    paymentIcon = "";
                  if (order.payment_mode !== "cashondelivery") {
                    paymentIcon = "fa-credit-card";
                    paymentMode = order.payment_mode;
                  }
                  let device_data = "";
                  let deviceArray = "";
                  let deviceVersion = "";
                  let osData = "";
                  let device_Version = "";
                  if (order.device !== null || order.device !== undefined) {
                    let deviceData = order.device_tracking;

                    if (order.os === "android") {
                      deviceArray = deviceData.split("||");

                      deviceVersion = deviceArray[9].split(":");
                      device_data = "android";
                      device_Version = deviceVersion?.[1];
                    } else if (order.os === "ios") {
                      deviceArray = deviceData.split("||");

                      deviceVersion = deviceArray[9].split(":");
                      device_Version = deviceVersion?.[1];
                      device_data = "ios";
                    } else {
                      if (
                        deviceData !== "" &&
                        deviceData !== null &&
                        deviceData !== undefined
                      ) {
                        if (deviceData !== undefined) {
                          deviceArray = deviceData.split("||");
                          if (
                            deviceArray !== undefined &&
                            deviceArray !== null &&
                            deviceArray !== ""
                          ) {
                            if (
                              deviceArray[9] !== undefined &&
                              deviceArray[9] !== null &&
                              deviceArray[9] !== ""
                            ) {
                              deviceVersion = deviceArray[9].split(":");
                              if (
                                deviceVersion !== undefined &&
                                deviceVersion !== null &&
                                deviceVersion !== ""
                              ) {
                                device_Version = deviceVersion?.[1];
                              }
                            }
                          }
                        }
                      }
                      device_data = "";
                    }
                  }
                  let isWeb = "";
                  var installdata = "";
                  let deData = "";
                  if (order.device === "phone") {
                    let appData = order.device_tracking;
                    const appArray = appData.split("||");
                    if (typeof appArray[12] !== "undefined") {
                      const appVersion = appArray[12]?.slice(
                        appArray[12].indexOf(":") + 1
                      ); 
                      if (
                        typeof appVersion !== "undefined" &&
                        appVersion != ""
                      ) {
                        const objInstall = JSON.parse(appVersion);
                        if (
                          typeof objInstall?.number_of_installment !==
                          "undefined"
                        ) {
                          const percentInstall =
                            objInstall.fee_display_value / 100;
                          installdata =
                            objInstall.number_of_installment +
                            "Month EMI" + " " + 
                            objInstall.amountPerMonth +
                            "/Month";
                        }
                      }
                    }
                  } else {
                    if (
                      order.device === "" ||
                      order.device === "web" ||
                      order.device === "Desktop" ||
                      order.device === "Mobile"
                    ) {
                      if (deviceVersion === "web") {
                        deData = "mdi mdi-laptop-windows";
                      }
                      if (deviceVersion === "Desktop") {
                        deData = "mdi mdi-laptop-windows";
                      }
                      if (deviceVersion === "Mobile") {
                        deData = "mdi mdi-laptop-windows";
                      }

                      if (order.os === "Windows") {
                        osData = "mdi mdi-microsoft-windows";
                      } else if (order.os === "Mac OS") {
                        osData = "mdi mdi-apple";
                      } else if (order.os === "Linux") {
                        osData = "mdi mdi-linux";
                      } else {
                        osData = "mdi mdi-web";
                      }

                      isWeb = "Web";
                      if (typeof order.device_tracking !== "undefined") {
                        const appVersion = order.device_tracking.slice(
                          order.device_tracking.indexOf(":") + 1
                        );
                        if (
                          typeof appVersion !== "undefined" &&
                          appVersion != ""
                        ) {
                          try {
                            const objInstall = JSON.parse(appVersion);
                            if (
                              typeof objInstall.number_of_installment !==
                              "undefined"
                            ) {
                              installdata =
                                objInstall.number_of_installment +
                                " Month Installment";
                            }
                          } catch (e) {
                            installdata = "";
                          }
                        }
                      }
                    }
                  }

                  if (device_Version === "web") {
                    deData = "mdi mdi-laptop-windows";
                  } else if (device_Version === "Desktop") {
                    deData = "mdi mdi-laptop-windows";
                  } else if (device_Version === "Mobile") {
                    if (order.os.toLowerCase() === "android") {
                      deData = "mdi mdi-cellphone";
                      osData = "mdi mdi-android-debug-bridge";
                    } else if (order.os.toLowerCase() === "ios") {
                      deData = "mdi mdi-cellphone";
                      osData = "mdi mdi-apple";
                    } else {
                      deData = "mdi mdi-laptop-windows";
                    }
                  }
                  let installemntPayment= "";
                  let appDataPayment = "";
                  let installemnt_months="";
                  if(order?.is_installment === "1"){
                    appDataPayment = order?.payment_info;
                    installemntPayment=JSON.parse(appDataPayment)
                    const installment_details = installemntPayment?.installment_details;
                    if(installment_details !== undefined){
                    if(Object.keys(installment_details).length > 0){
                     
                      installemnt_months =
                   installemntPayment?.installment_details?.number_of_installment +
                   " Month EMI "  + " " + 
                  installemntPayment?.installment_details?.amountPerMonth +
                  "/Month";
                    }
                  }
                  else if(installemntPayment?.additionalInfo) {
                   installemnt_months =
                   installemntPayment?.additionalInfo?.number_of_installment +
                   " Month EMI "  + " " + 
                  installemntPayment?.additionalInfo?.amountPerMonth +
                  "/Month";
                  }
                  }

                  return (
                    
                    <tbody key={order?.order_id}>
                      
                      <tr>
                        <th scope="row">{pageIndex}</th>

                        <td>
                          <strong>{order?.reference_no} </strong>
                          <span style={{ textTransform: "capitalize" }}>
                            {" "}
                            {order?.payment_mode === "cashondelivery" ? (
                              <Badge className="badge-soft-dark me-1">
                                COD
                              </Badge>
                            ) : (
                              <Badge className="badge-soft-dark me-1">
                                {" "}
                                CC{" "}
                                <i
                                  style={{ fontSize: "10px" }}
                                  className={`badge-soft-dark fas ${paymentIcon}`}
                                ></i>
                              </Badge>
                            )}
                            {renderPaymentMethods(paymentMode)}
                          </span>
                      
                          {installdata ? (
                            <>
                              {" "}
                            <div>
                              <span className="installPlan">{installdata}</span></div>
                            </>
                          ) : (
                            <></>
                          )}
                        {installemnt_months? (<>
                            <div>
                              <span className="installPlan">{installemnt_months}  </span></div>

                              </>):(null)}
                     
                           <div>
                          <span className="highlightInfo2 ">
                            {orderDt} {orderTime}
                          </span>
                           

                          </div>
                        
                      
                        </td>

                        <td>
                          {order?.full_name}
                          <span className="noTxtWrap" title={order?.region}>
                            {order?.country}-{order?.region}
                          </span>
                        </td>

                        <td>
                          {order?.currency} {order?.grand_total}
                          {order?.coupon_code !== "" ? (
                           <><div className="highlightInfo1">
                              Coupon: <b>{order?.coupon_code} ({order?.discount_amt})</b>
                            </div>

                            </>
                          ) : null}
                           {order?.shipping_amt> "0.00" ? (
                           <>
                          <div className="highlightInfo2">
                             Shipping Fee: {order?.shipping_amt}
                           </div>     
                            </>
                          ) : null}
                           
                        </td>
                    <td> <Badge className="bg-danger me-2 badge ">
                              {order?.status === "pending_payment" ? "Pending Payment" : order?.status}
                              </Badge></td>
                        <td>
                          {isWeb !== "" ? (
                            <>
                              <div>
                                <Badge className="badge-soft-dark me-1">
                                  Web
                                </Badge>

                                <i className={osData}></i>
                                <i className={deData}></i>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {device_data === "android" ? (
                            <>
                              <Badge className="badge-soft-dark me-1">
                                App <span className="highlightInfo1"></span>
                              </Badge>{" "}
                              <img src={android} alt="" height="15" />
                            </>
                          ) : (
                            <></>
                          )}
                          {device_data === "ios" ? (
                            <>
                              <Badge className="badge-soft-dark me-1">
                                App
                              </Badge>{" "}
                              <img src={apple} alt="" height="15" />
                            </>
                          ) : (
                            <></>
                          )}
                          <div className="version">
                            <Badge className="badge-soft-dark me-1">
                              {deviceVersion?.[0] === "AppVersion" ? (
                                <>
                                  {" "}
                                  V- {deviceVersion?.[1]} |{" "}
                                  {order?.order_source === "0"
                                    ? "W-1.0"
                                    : "W-2.0"}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {order.order_source === "0"
                                    ? "W-1.0"
                                    : "W-2.0"}
                                </>
                              )}
                            </Badge>
                          </div>
                        </td>
                        <td>
                          <BrowserRouter forceRefresh={true}>
                            <Link
                              to={`/DeleteOrderDetails/${order?.entity_id}/?order_mode=${split}&orderCn=${orderCn}`}
                              className="btn btn-sm btn-outline-warning waves-effect waves-light mr-2"
                            >
                              <i className="mdi mdi-eye-outline"></i>{" "}
                            </Link>
                          </BrowserRouter>

                           <span onClick={()=>{
                            revertOrderData(order?.entity_id)
                           }}  className="btn btn-sm btn-outline-dark waves-effect waves-light mr-2 "><img src={undoIcon} height="14px"/></span>

                        </td>
                        
                      </tr>

                 
                    </tbody>
                  );
                })}
              
              </Table>
            </div>
            <div className="dataTblFooter" nowrap="nowrap">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                  style={{ width: "max-content" }}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn btn-primary"
                    nowrap="nowrap"
                    sytyle={{ width: "30px" }}
                  >
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          className="DropdownItem1"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th>Date</th>
                  <th>Reference No</th>
                  <th>Name</th>
                  <th>Country</th>
                  <th>Device</th>
                  <th>Grand Total</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>

      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};

export default DeleteOrdersList;