import {
  Col,
  Row,
  Badge,
  Card,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  CardHeader,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  getDeliveryData,
  getDeliveryList,
  getReviewsSummary,
  getOrderSummaryByMode,
} from '../actions';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import { Calendar, Progress } from 'rsuite';
import { useUrlSearchParams } from 'use-url-search-params';
import deliveryStatusList from '../../../constants/delievryStatusList';
import { Form } from 'react-bootstrap';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import { Link } from 'react-router-dom';
import DountChart from '../../AllCharts/dountchart';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';

const Calender = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingReviews, setIsLoadingReviews] = useState(false);
  const [isLoadingSummaryMode, setIsLoadingSummaryMode] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [reviewSummary, setReviewSUmmary] = useState(null);
  const [orderSummaryMode, setOrderSummaryMode] = useState([]);
  const [deliveryCalender, setDeliveryCalendar] = useState([]);
  const [delievreyDate, setDeliveryDate] = useState(null);
  const [currentDeliveriesList, setCurrentDeliveriesList] = useState('');
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState();
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const { country = [] } = useSelector((state) => state.country);
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: '' });
  const [activeValue, setActiveValue] = useState('');
  var today = new Date();
  const [value, setValue] = useState([
    new Date(new Date().setDate(today.getDate() - 30)),
    today,
  ]);
  const getDeliveryDataFunc = async () => {
    setIsLoading(true);
    let isSuccess = await getDeliveryData({
      country: country === 'all' ? 'ae' : country,
      deliverBy: activeValue,
    });

    if (isSuccess) {
      const deliveries = {};
      isSuccess?.forEach((d) => {
        deliveries[
          format(
            new Date(new Date(d?.delivery_dt).getTime() + 14600000),
            'dd/MM/yyyy'
          )
        ] = d;
      });

      setDeliveryCalendar(deliveries);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (country !== null) {
      getDeliveryDataFunc();
    }
  }, [country, activeValue]);
  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
    setPaginationValue({ page: 0 });
  };
  const getDeliveryListFunc = async () => {
    setIsListLoading(true);
    let isSuccess = await getDeliveryList({
      params: {
        country: country === 'all' ? 'ae' : country,
        delivery_dt: delievreyDate,
        search_qry: searchValue.search,
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        orderMode: activeValue,
      },
    });

    if (isSuccess) {
      setCurrentDeliveriesList(isSuccess);
      setIsListLoading(false);
    } else {
      setIsListLoading(false);
    }
  };
  let pageIndex = Number(currentDeliveriesList?.start);

  useEffect(() => {
    if (country !== null && delievreyDate !== null) {
      getDeliveryListFunc();
    }
  }, [
    paginationValue.page,
    limitValue.limit,
    delievreyDate,
    searchValue.search,
  ]);
  const onDrawerClose = () => {
    setOpen(false);
  };
  const toggleRightDrawer = () => {
    setPosition('right');
    setOpen(true);
  };

  const getReviewSummaryDataFunc = async () => {
    setIsLoadingReviews(true);
    let isSuccess = await getReviewsSummary({
      country: country === 'all' ? 'ae' : country,
    });

    if (isSuccess) {
      setIsLoadingReviews(false);
      setReviewSUmmary(isSuccess);
    } else {
      setIsLoadingReviews(false);
    }
  };
  useEffect(() => {
    if (country !== null) {
      getReviewSummaryDataFunc();
    }
  }, [country]);

  const getOrderSummaryByModeFunc = async () => {
    setIsLoadingSummaryMode(true);
    let isSuccess = await getOrderSummaryByMode({
      country: country === 'all' ? 'ae' : country,
      date_from:
        value?.[0] !== ''
          ? new moment(new Date(value?.[0])).format('YYYY-MM-DD')
          : '',
      date_to:
        value?.[1] !== ''
          ? new moment(new Date(value?.[1])).format('YYYY-MM-DD')
          : '',
    });

    if (isSuccess?.length > 0) {
      setIsLoadingSummaryMode(false);
      setOrderSummaryMode(isSuccess);
    } else {
      setIsLoadingSummaryMode(false);
    }
  };
  useEffect(() => {
    if (country !== null) {
      getOrderSummaryByModeFunc();
    }
  }, [country, value]);
  const renderCell = (date) => {
    const cellDate = format(new Date(date), 'dd/MM/yyyy');

    if (deliveryCalender?.[cellDate]?.numberOfDelivery) {
      const percentage = Math.floor(
        (deliveryCalender[cellDate]?.completed /
          deliveryCalender[cellDate].numberOfDelivery) *
          100
      );
      const strokeColors =
        percentage === 100
          ? '#4caf50'
          : percentage < 100 && percentage >= 90
          ? '#ffc107'
          : '#fd7676';

      return (
        <div
          onClick={() => {
            setDeliveryDate(deliveryCalender[cellDate]?.delivery_dt);
            setOpen(true);
            toggleRightDrawer();
            setPaginationValue({ page: 0 });
          }}
          className="calenderDates"
        >
          <div
            style={{
              width: 30,
              display: 'inline-block',
              marginRight: 0,
            }}
          >
            <Progress.Circle
              percent={percentage}
              strokeColor={strokeColors}
              className="progressCircle"
            />
          </div>
          &nbsp; &nbsp;
          <Badge
            className={
              percentage === 100
                ? 'badge bg-success'
                : percentage < 100 && percentage >= 90
                ? 'badge bg-warning'
                : 'badge bg-danger'
            }
          >
            {deliveryCalender[cellDate]?.completed || '0'}/
            {deliveryCalender[cellDate]?.numberOfDelivery || '0'}
          </Badge>
        </div>
      );
    }

    return null;
  };
  return (
    <>
      <div className="calenderContainer">
        <Row>
          <Col md={8}>
            {isLoading ? (
              <Spinner className="ms-2 sploader" color="primary" />
            ) : null}
            {!open && (country === 'ae' || country === 'all') ? (
              <div className="selectDeliveryDetailOption">
                <select
                  className="form-control form-select"
                  name="is_active"
                  id="choices-single-no-search"
                  onChange={(e) => setActiveValue(e.target.value)}
                >
                  <option value="all">Select </option>
                  <option value="danube">DanubeHome Online </option>
                  <option value="b2b">Seller </option>
                </select>
              </div>
            ) : null}
            <Calendar
              bordered
              className="dashboard-calendar"
              renderCell={renderCell}
              data={deliveryCalender}
            />
          </Col>
          <Col md={4}>
            {isLoadingReviews ? (
              <Spinner className="ms-2 sploader" color="primary" />
            ) : null}
            <Card className="deliveryCalenderChart">
              <div dir="ltr">
                <div className="p-3">
                  <h6 className="text-muted mb-3 text-uppercase fw-semibold text-center">
                    Reviews Summary
                  </h6>
                  <div className="text-center">
                    <div className="text-muted">
                      Total{' '}
                      <span className="fw-medium">
                        {reviewSummary?.totalCount}
                      </span>{' '}
                      reviews
                    </div>
                  </div>

                  <div className="mt-3 progressBar">
                    {reviewSummary?.reviewInfo?.map((info, index) => {
                      let progressBarColor = '';
                      if (info?.rating === '5' || info?.rating === '4') {
                        progressBarColor = 'bg-success-rating';
                      } else if (info?.rating === '3') {
                        progressBarColor = 'bg-primary-rating';
                      } else if (info?.rating === '2') {
                        progressBarColor = 'bg-warning-rating';
                      } else {
                        progressBarColor = 'bg-danger-rating';
                      }
                      return (
                        <Row className="align-items-center g-2" key={index}>
                          <div className="col-auto">
                            <div className="p-1">
                              <h6 className="mb-0">{info?.rating} Star</h6>
                            </div>
                          </div>
                          <div className="col">
                            <div className="p-1">
                              <div className="progress animated-progess progress-sm">
                                <div
                                  className={`progress-bar ${progressBarColor}`}
                                  role="progressbar"
                                  style={{ width: `${info?.percentage}%` }}
                                  aria-valuenow={info?.percentage}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="p-1">
                              <h6 className="mb-0 text-muted">
                                {`${info?.percentage}%`} ({info?.ratingCount})
                              </h6>
                            </div>
                          </div>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Card>
            <Card>
              <div dir="ltr">
                <div className="p-3">
                  <div className="CalenderHeader">
                    <h6 className="text-muted text-uppercase fw-semibold ">
                      Orders Summary
                    </h6>
                    <DateRangePicker
                      showOneCalendar
                      value={value}
                      onChange={setValue}
                      showMeridian
                      format="yyyy-MM-dd"
                      onClean={() => {
                        setValue(['', '']);
                      }}
                    />
                  </div>
                  {isLoadingSummaryMode ? (
                    <Spinner className="ms-2 sploader" color="primary" />
                  ) : null}
                  <DountChart orderSummaryMode={orderSummaryMode} />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
          {isListLoading ? (
            <Spinner className="ms-2 sploader" color="primary" />
          ) : null}
          <Card className="card-h-100 OrderDetailsBox">
            <CardTitle
              style={{
                paddingTop: '10px',
                paddingLeft: '15px',
              }}
            >
              Orders Delivery Details{' '}
              <button
                type="button"
                onClick={() => {
                  setOpen(false);
                }}
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </CardTitle>
            <div className="searchField">
              <div className="search-box me-2  d-inline-block">
                <div className="position-relative">
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    value={searchValue.search}
                    id="listSearch"
                    onChange={handleSearch}
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
            </div>

            <CardBody className="table-responsive">
              <Table className="OrderListTable table mb-0">
                <thead>
                  <tr>
                    <th>SI.No</th>
                    <th>Reference No</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Delivery Date</th>
                    <th>Grand Total</th>
                  </tr>
                </thead>

                {currentDeliveriesList &&
                  currentDeliveriesList?.orderList?.map((delivery, index) => {
                    pageIndex++;
                    let LsSts = '';
                    let stsBtn = '';
                    const Delivery_status = delivery?.ls_status.toLowerCase();

                    LsSts =
                      deliveryStatusList?.[Delivery_status?.replace(/\s+/g, '')]
                        ?.lsSts;
                    stsBtn =
                      deliveryStatusList?.[Delivery_status?.replace(/\s+/g, '')]
                        ?.stsBtn;

                    return (
                      <tbody key={index}>
                        <tr>
                          <td>{pageIndex}</td>
                          <td>
                            <Link
                              to={`/orders/${
                                country === 'ae'
                                  ? delivery?.order_id
                                  : delivery?.entity_id
                              }/?order_mode=${
                                country === 'ae' ? 'split' : 'All'
                              }&orderCn=${country}&showPopup=false`}
                              className="refLink"
                            >
                              {delivery?.reference_no}
                            </Link>

                            <br />
                            <span className="highlightInfo1">
                              {delivery?.created_at}
                            </span>
                          </td>
                          <td>{delivery?.full_name}</td>
                          <td>
                            {' '}
                            <Badge className={` ${stsBtn} me-2`}>{LsSts}</Badge>
                          </td>
                          <td>{delivery?.delivery_dt}</td>
                          <td>
                            {delivery?.currency} {delivery?.grand_total}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </Table>
              <div className="dataTblFooter">
                <div className="d-flex justify-content-center">
                  <Pagination
                    id="listPage"
                    value={paginationValue.page}
                    totalPages={currentDeliveriesList?.recordsTotal}
                    onChange={(e) => handlePagination(e)}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </ReactDrawer>
      </div>

      <div>
        <Row>
          <Col className="p-3">
            <Row>
              <Col md={8}>
                <Row>
                  <Col>
                    <h5>title</h5>
                  </Col>
                  <Col>
                    <div className="d-flex justify-content-end">dropdown</div>
                  </Col>
                </Row>
                <Table className="OrderListTable table mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Express Fee</th>
                      <th>Shipment Fee</th>
                      <th>No.of Orders w SF</th>
                      <th>Total No.of Orders</th>
                      <th>Revenue</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Zone A</strong>
                      </td>
                      <td>AED 1000.00</td>
                      <td>AED 1309.00</td>
                      <td>123</td>
                      <td>33</td>
                      <td>97</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Zone B</strong>
                      </td>
                      <td>AED 1000.00</td>
                      <td>AED 1309.00</td>
                      <td>123</td>
                      <td>33</td>
                      <td>97</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>
                        <strong>AED 80000.00</strong>
                      </td>
                      <td>
                        <strong>AED 122309.00</strong>
                      </td>
                      <td>
                        <strong>1233</strong>
                      </td>
                      <td>
                        <strong>3244</strong>
                      </td>
                      <td>
                        <strong>3516</strong>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </Col>
              <Col md={4}>
                <Card className="deliveryCalenderChart p-3">sdasdc</Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Calender;
