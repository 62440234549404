import React from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
//import OrderList from './components/orderList';
import OrderInstallationList from "./components/OrderInstallationList";
import "react-datepicker/dist/react-datepicker.css"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
  } from "reactstrap";

const installationOrders = () => {
  const  users = JSON.parse(localStorage?.getItem('authUser'));
  const title = "Orders";
  const description = "Manage Installation Orders";

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mange Installation Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={8}>
              <DashBreadcrumb title="Orders" breadcrumbItem="Manage Installation Orders" />
            </Col>
          </Row>
          
            <OrderInstallationList />
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default installationOrders ;
