import React from "react";
import ResizeForm from "./components/resizeForm";
import MetaTags from "react-meta-tags";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const ImageTools = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Image Resize Tool - Danube Home</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Tools" breadcrumbItem="Image Resize" />

          <Row>
            <ResizeForm />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ImageTools;
