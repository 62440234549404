
let deliveryStatusList={
    "material_received":{
      "lsSts":"Material Received",
      "stsBtn" :"bg-warning"
    },
    "open":{
      "lsSts":"Pending",
      "stsBtn" :"bg-dark"
    },
    "open":{
      "lsSts":"Pending",
      "stsBtn" :"bg-dark"
    },
    "confirmed":{
      "lsSts":"Confirmed",
      "stsBtn" :"bg-dark"
    },
    "posted":{
      "lsSts":"Posted",
      "stsBtn" :"bg-primary"
    },
    "delivered":{
      "lsSts":"Delivered",
      "stsBtn" :"bg-success"
    },
    "picked":{
      "lsSts":"Picked",
      "stsBtn" :"bg-warning"
    },
    "delivery_scheduled":{
      "lsSts":"Delivery Scheduled",
      "stsBtn" :"bg-soft-success"
    },
    "rescheduled":{
      "lsSts":"Rescheduled",
      "stsBtn" :"bg-soft-success"
    },
    "partially_delivered":{
      "lsSts":"bg-warning",
      "stsBtn" :"Partially Delivered"
    },
    "out_for_delivery":{
      "lsSts":"Out For Delivery",
      "stsBtn" :"bg-soft-success"
    },
    "packed":{
      "lsSts":"Packed",
      "stsBtn" :"bg-dark"
    },
    "new":{
      "lsSts":"New",
      "stsBtn" :"bg-soft-dark"
    },
    "materialn/a":{
      "lsSts":"Material N/A",
      "stsBtn" :"bg-warning"
    },
    "assigned":{
      "lsSts":"Ready for Delivery",
      "stsBtn" :"bg-warning"
    },

   
  }
  export default deliveryStatusList;  