import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts"
import { useDispatch, useSelector } from "react-redux";
const Spinearea = ({splineGraph}) => {
  const { country = [] } = useSelector((state) => state.country);
  let cntry = country;

  if(cntry !== 'all' && cntry !== null)
  {
      cntry = cntry.toUpperCase();
  }
  const dispatch = useDispatch();
  if(cntry === 'all' && cntry !== 'AE'){
    const series = [
      {
        name: "Total AE Order",
        data:  splineGraph?.total_AE_orders,
      },
      {
        name: "Total OM Order",
        data: splineGraph?.total_OM_orders,
      },
      {
        name: "Total KW Order",
        data:  splineGraph?.total_KW_orders,
      },
      {
        name: "Total QA Order",
        data: splineGraph?.total_QA_orders,
      },
      {
          name: "Total BH Order",
          data: splineGraph?.total_BH_orders,
        },
    ]
  
    const options = {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
  
      colors: ["#556ee6", "#34c38f","#f1be65","#f47f7f","#800080"],
      xaxis: {
        type: "date",

        categories: splineGraph?.dates
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd-MM-yy",
        },
      },
    }
  
    return (
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height="350"
      />
    )

  }
  else if(country === 'All' || country ==='AE' || country === 'ae'){
   
 
  const series = [
    {
      name: "Total Order",
      data:  splineGraph?.no_orders,
    },
    {
      name: "B2B Orders",
      data: splineGraph?.b2b_no_orders,
    },
    {
      name: "Click & Collect",
      data:  splineGraph?.click_order_no,
    },
    {
      name: "Payment Failure",
      data: splineGraph?.failure_order_no,
    },
  ]
    
  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#556ee6", "#34c38f","#f1be65","#f47f7f"],
    xaxis: {
      type: "date",
      categories: splineGraph?.dates
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd-MM-yy",
      },
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
    />
  )
}
else{
  const series = [
    {
      name: "Total Order",
      data:  splineGraph?.no_orders,
    },
    {
      name: "Click & Collect",
      data:  splineGraph?.click_order_no,
    },
    {
      name: "Payment Failure",
      data: splineGraph?.failure_order_no,
    },
  ]
  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#556ee6", "#34c38f","#f1be65","#f47f7f"],
    xaxis: {
      type: "date",
      categories: splineGraph?.dates
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd-MM-yy",
      },
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
    />
  )
}
}


export default Spinearea