import { gql } from '@apollo/client';
export const GET_ADDRESS_ADMIN= gql`
mutation(
  $uuid:String!
  $_id:String!
  
 
  ){
    getAddressAdmin(
      input: { uuid:$uuid,
      _id:$_id
     
       }

    ) {
    address {
    _id
    countryCode
    firstName
    lastName
    telephone
    alternateTelephone
    address1
    address2
    flatNo
    city
    region
    country
    mapData
    latitude
    longitude
    addressType
    isDefault
    plusCode
    }
  }
    }`;