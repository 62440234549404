import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useUrlSearchParams } from "use-url-search-params";
import { Card, Button, OverlayTrigger, Form, Tooltip } from "react-bootstrap";

import Pagination from "@vlsergey/react-bootstrap-pagination";
import { fetchSkuDataList } from "../action/action";
import { SORTING_ORDER } from "../../../constants/index";

import "react-drawer/lib/react-drawer.css";

import moment from "moment";

import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  NavLink,
  Nav,
  NavItem,
} from "reactstrap";


const LsSkuList = () => {
  const location = useLocation();

  const [btnprimary1, setBtnprimary1] = useState(false);

  const dispatch = useDispatch();

  const [sortingName, setSortingName] = useState({ id: "sort.date_asc" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });

  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });

  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: "_date",
    order: SORTING_ORDER.ASC,
  });

  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });

  let is_split = 0;
  const { country = [] } = useSelector((state) => state.country);
  const [RequestTypeFilter, setRequestTypeFilter] = useUrlSearchParams({ TypeFilter: "All" });
  const splitVal = location?.pathname.split("/");
  const [isDataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    setDataLoading(true);
    fetchSkuDataList({
      params: {
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
        search_qry: searchValue.search,
        orderBy: "created_at"
 
      },
      dispatch,
    })
  
    //setDataLoading(false);
  }, [
    paginationValue.page,
    limitValue,
    sortingValue.sort,
    searchValue.search,
  ]);

  const limitByValues = [5, 10, 20, 50];
  const { skuList = [], isLoading } = useSelector((state) => state.importexport);
  const { statusCounts = [], isStsCntsLoading } = useSelector(
    (state) => state.order
  );



  const listSkus  = skuList?.listStockSku;
  const skuData = skuList?.listStockSku?.skuList;

   useEffect(() => {
    if(skuData !== undefined)
    {
      setDataLoading(false);
    }
   }, [skuData]);


 

  const sortByValues = [
    { key: "date", order: SORTING_ORDER.ASC, label: { id: "sort.date_asc" } },
    { key: "date", order: SORTING_ORDER.DESC, label: { id: "sort.date_desc" } },
    {
      key: "status",
      order: SORTING_ORDER.ASC,
      label: { id: "sort.status_asc" },
    },
  
  ];

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
    setDataLoading(true);
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };





  let pageIndex = Number(listSkus?.start);

  return (
    <>

<Row className=" dh-TabRow textAlignRight "  >
        
        <Col className="MobileView textAlignRight ">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
 
                
                </Col>
      </Row>


      <div className="table-responsive dt-table">
        {isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {skuData?.length > 0 ? (
         
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th  nowrap="nowrap">Date</th>
                  <th nowrap="nowrap">Sku</th>
                
                </tr>
              </thead>
              {(skuData || []).map((order, index) => {
                pageIndex++;
                
                //  console.log("order_id----------",order_id);
                let cdate = order?.Date,
                  orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                  orderTime = moment(new Date(cdate)).format("h:mm:ss a");

          
             
            
                return (
                  <tbody key={order?.sku}>
                    <tr>
                      <th scope="row">{pageIndex}</th>
                      <td  nowrap="nowrap">
                        {orderDt} {orderTime}
                      </td>
                      <td nowrap="nowrap">{order?.sku}</td>
                      <td  nowrap="nowrap">{order?.date}</td>
                    
                    </tr>
                  </tbody>
                );
              })}
            </Table>

            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listSkus?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
              <tr>
                  <th>SI.No</th>
                  <th>Date</th>
                  <th>Sku</th>
                 
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
};
export default LsSkuList;
