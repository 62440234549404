import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { DEFAULT_LANGUAGE, DEFAULT_COUNTRY ,AUTH_TOKEN, CURRENT_LANGUAGE, CURRENT_COUNTRY } from '../src/constants';
const endpoint = process.env.REACT_APP_SMILES_SERVICE_GRAPHQL_ENDPOINT;
const httpLink = createHttpLink({
  uri: endpoint,
});

const authLink = setContext((_, { headers }) => {
   // get the authentication token from local storage if it exists
   let token = localStorage?.getItem(AUTH_TOKEN);
   let language = localStorage?.getItem(CURRENT_LANGUAGE) || DEFAULT_LANGUAGE;
   let country = localStorage?.getItem(CURRENT_COUNTRY) || DEFAULT_COUNTRY;
   // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      language: language,
      country: country,
    },
  };
});

const ApolloClientSmileService = new ApolloClient({
  // ssrMode: true,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
export default ApolloClientSmileService;
