import React from "react";
import axios from "axios";
import ReturnProgressbar from "./Returnprogressbar";
import ReturnOrderDetailsBlock from "./ReturnOrderDetailsBlock";
import ReturnNotebyAdmin from "./ReturnNoteByAdmin";
import OrderItemDetails from "./ReturnOrderItemDetails";
import { useDispatch, useSelector } from "react-redux";
import ReturnOrderUpdateDetailsForm from "./ReturnOrderUpdateDetailsForm";
import { useState, useEffect, button } from "react";
import { fetchHistoryList } from "../../../Queuess/action/action";
import ReactDrawer from 'react-drawer';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  
} from "reactstrap";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import StatusBarReturnCustomer from '../../../Queuess/components/StatusBarReturnCustomer'; 


function ReturnOrderDetails({ orderData, itemData }) {
  let stsBtn = "bg-lightgrey";
  let LsSts = "";
  if (orderData?.Status === "Pending" || orderData?.Status === "pending" || orderData?.Status === "Cancel Pending") {
    stsBtn = "bg-dark";
    LsSts = "Pending";
  } else {
    LsSts = orderData?.Status;
  }
  if (orderData?.Status === "Authorized") {
    stsBtn = "bg-primary";
  } else if (
    orderData?.Status === "Cancel" ||
    orderData?.Status === "cancel" ||
    orderData?.Status === "pending_payment"
  ) {
    stsBtn = "bg-danger";
  } else if (orderData?.Status === "Approved") {
    stsBtn = "bg-success";
  }

  if (
    orderData?.Status === "Rejected" ||
    orderData?.Status === "rejected" ||
    orderData?.Status === "Canceled" ||
    orderData?.Status === "cancel"
  ) {
    LsSts = "Canceled";
    stsBtn = "bg-danger";
  }

  const [skudetail, setSkudetail] = useState("");
  const [orderValue, setorderValue] = useState("");
  const [detail,setDetail]=useState([]);
  const [buttonDetail,setButtonDetail] = useState(""); 
  const [open, setOpen] = useState(false);

  const toggleRightDrawer3 = async ( historyData) => {
   

    let result = await fetchHistoryList( 
      {
        params: {
          queue_id: historyData.queue_id,
          
        }
      });
     
     
      
    //setOpen(true);
    if(historyData.queue_id!==''){

      
    setorderValue(historyData);
    setDetail(result);
    //setCheck(result);
    setButtonDetail("3")
    setOpen(!open);
    }
    
 

  };

  return (
    <>
      <Card className="card-dh card-h-100">
        <CardBody className="card-body-dh">
          <Row>
            <Col className="ReturnInfo">
             Return Order Details :
             </Col>
             <Col >
             Ref# <strong>{orderData?.ReturnNo}</strong>
             <span className="highlightInfo1">
                              Return Date : {orderData?.ReturnDate}
                            </span> 
                            </Col>
                            <Col>Order Ref:{" "}
                            <Link to={`/orders/${orderData?.EntityId}/?order_mode=All&orderCn=${orderData?.ShippingDetails?.[0]?.Country.toLowerCase()}`}
              type="button"
              
             className="refColor"
            >  <b> {orderData?.OrderId}</b></Link>
              <span className="highlightInfo1">
                            Order Date : {orderData?.OrderDate}
                            </span> 
                            </Col>
                            <Col>
                            Shipment Ref:{" "}
                            <Link to={`/orders/${orderData?.EntityId}/?order_mode=All&orderCn=${orderData?.ShippingDetails?.[0]?.Country.toLowerCase()}`}
              type="button"
              
              className="refColor"
            > <b>{orderData?.ShipmentInfo}</b></Link>
            
             {orderData?.QueueId !=='' ?(    <i title ="Track Return Request" class="mdi mdi-eight-track btnTracking"  onClick={() => {
                             
                             toggleRightDrawer3({"reference_no":orderData?.OrderNo,"date":'2024-06-24',"created_by":'customer',"queue_id":orderData?.QueueId,"resolution_type":orderData?.QueueResolution});
                             
                           }} style={{
                            marginLeft:"15px"
                           }}
                          ></i>):''}
            {orderData?.DeliveryDate !== null ? (<>
              <span className="highlightInfo1">
                              Delivery Date  : {orderData?.DeliveryDate}
                            </span> 
                            </>):(<></>)}
            </Col>
            {orderData?.FareyeReturnNo !== null ? (<>
            <Col>
            Fareye Return No:{" "} {orderData?.FareyeReturnNo}
            </Col>
            </>):(<></>)}
           <Col> <span className="highlightInfo1">
                              Push to CRM : {orderData?.FlowCrm === 1 ?<i title ="Yes" class="mdi mdi-check-outline"   style={{
                            marginLeft:"15px"
                           }}
                          ></i>:'No'}
                            </span> <span className="highlightInfo1">
                            CST No : {orderData?.CstNo}
                            </span>  </Col>
          </Row>
        
          <Row className="mt-3">
            <Col xl={9} md={7}>
              <ReturnOrderDetailsBlock orderData={orderData} />
              </Col>
              <Col xl={3} md={5}>
             {/*  <OrderInvoice orderData={orderData} /> */}
             {/*  <OrderUpdateDetailsForm orderData={orderData} /> */}
              <ReturnOrderUpdateDetailsForm orderData={orderData} />
       
            </Col>
            <Col>  <OrderItemDetails itemData={itemData} orderData={orderData}/></Col>
            
          </Row>

          <Row>
            <Col>
           <ReturnNotebyAdmin  orderData={orderData}/>
            </Col>
     
          </Row>
        </CardBody>
      </Card>
      <ReactDrawer open={open} >
            <Card className="card-h-100 OrderDetailsBox">
              <CardHeader>
              <Row>
                 
                  <Col className="textAlignRight">
                    {" "}
                    <button
                      type="button"
                      className="btn-close "
                      
                      aria-label="Close"
                    ></button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                
              {buttonDetail === "3" && detail.length > 0 ? (<> 
                <p><b>Return status Tracking</b></p> 
                <StatusBarReturnCustomer detail={detail} orderValue={orderValue}/>  {/* Add StatusBar component */}

              </>):''}
              </CardBody>
            </Card>
          </ReactDrawer>
    </>
  );
}

export default ReturnOrderDetails;
