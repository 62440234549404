import { useState, useEffect, button } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Notification, Placeholder } from "rsuite";
import { orderBy } from "lodash";
import React from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
} from "reactstrap";
import Places from "../../../components/maps/GoogleMaps";
import { nodeName } from "rsuite/esm/DOMHelper";
import toast, { Toaster } from "react-hot-toast";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function ProjectDetailsBlock({ orderData }) {
  return (
    <>
      <Row className="row-fluid-scroll">
        <Col xl={4} md={6}>
         
            <CardHeader>
              <CardTitle>CUSTOMER DETAILS</CardTitle>
            </CardHeader>
            {orderData !== undefined ? (
               
              <>
            <CardBody>
                <strong>{orderData?.[0].name}</strong>
                <br />
               <strong>Mobile: </strong> {orderData?.[0].mobile}
                <br />
                <strong>Email: </strong> {orderData?.[0].email}
                <br />
                <strong>Region: </strong> {orderData?.[0].region}
                <br />
                <strong>Address: </strong> {orderData?.[0].address}
                <br />
         
            </CardBody>
            <CardHeader>
              <CardTitle>Request DETAILS</CardTitle>
            </CardHeader>
            <CardBody>
            {orderData !== undefined ? (
              <>
                <strong>Customer Remarks: </strong> {orderData?.[0].remarks}
                <br/>
                <strong>Request Date: </strong> {orderData?.[0].created_at}
                <br/>
                <strong>Request Scope: </strong> {orderData?.[0].scope}
                <br/>
                <strong>Call Date Time: </strong> {orderData?.[0].called_date_time}
                <br/>
                <strong>Status: </strong> {orderData?.[0].status}
                <br/>

               </>
               ) : (<>
               <Skeleton count={5} />
                </>
              )}   
            </CardBody>
            </>
            ) : (<>
              <CardBody>
               <Skeleton count={8} />
               </CardBody>
                </>
              )}
              
         
        </Col>
    
       
      </Row>
      </>
    )
}
export default ProjectDetailsBlock;