import React, { useState, useEffect } from "react";
import ReactDrawer from "react-drawer";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Label,
} from "reactstrap";
import "react-drawer/lib/react-drawer.css";
//Import Breadcrumb

const WalletStatus = () => {
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);

  const toggleRightDrawer = () => {
    setPosition("right");
    setOpen(!open);
  };
  const onDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  return (
    <React.Fragment>
      <Button
        onClick={toggleRightDrawer}
        disabled={open}
        className="btn SecondaryBtn2"
      >
        Check Wallet Status
      </Button>

      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <Card className="WalletDetails">
          <CardHeader>
            <CardTitle>Wallet Details</CardTitle>
            <p>Available Balance</p>
            <h3>AED 1,999</h3>
            <button type="button" className="close" onClick={onDrawerClose}>
              <span aria-hidden="true">×</span>
            </button>
          </CardHeader>
          <CardBody>
            <Col xl={12} md={12} className="WalletDetailsBlock">
              <h5>
                <span>
                  <i className="bx bx-plus"></i> Refunded
                </span>
                <span>+1,999</span>
              </h5>
              <p>
                <span>Feb 3, 2022</span>
                <span>Balance AED 1,999</span>
              </p>
              <p className="CreditNote">
                credit note AED 1999 added to wallet #000020606
              </p>
            </Col>

            <Col xl={12} md={12} className="WalletDetailsBlock">
              <h5>
                <span>
                  <i className="bx bx-minus"></i> Used
                </span>
                <span>- 1,999</span>
              </h5>
              <p>
                <span>Feb 3, 2022</span>
                <span>Balance AED 1,999</span>
              </p>
              <p className="CreditNote">order #000020606</p>
            </Col>

            <Col xl={12} md={12} className="WalletDetailsBlock">
              <h5>
                <span>
                  <i className="bx bx-plus"></i> Refunded
                </span>
                <span>+1,999</span>
              </h5>
              <p>
                <span>Feb 3, 2022</span>
                <span>Balance AED 1,999</span>
              </p>
              <p className="CreditNote">
                credit note AED 1999 added to wallet #000020606
              </p>
            </Col>

            <Col xl={12} md={12} className="WalletDetailsBlock">
              <h5>
                <span>
                  <i className="bx bx-minus"></i> Used
                </span>
                <span>- 1,999</span>
              </h5>
              <p>
                <span>Feb 3, 2022</span>
                <span>Balance AED 1,999</span>
              </p>
              <p className="CreditNote">order #000020606</p>
            </Col>

            <Col xl={12} md={12} className="WalletDetailsBlock">
              <h5>
                <span>
                  <i className="bx bx-plus"></i> Refunded
                </span>
                <span>+1,999</span>
              </h5>
              <p>
                <span>Feb 3, 2022</span>
                <span>Balance AED 1,999</span>
              </p>
              <p className="CreditNote">
                credit note AED 1999 added to wallet #000020606
              </p>
            </Col>

            <Col xl={12} md={12} className="WalletDetailsBlock">
              <h5>
                <span>
                  <i className="bx bx-minus"></i> Used
                </span>
                <span>- 1,999</span>
              </h5>
              <p>
                <span>Feb 3, 2022</span>
                <span>Balance AED 1,999</span>
              </p>
              <p className="CreditNote">order #000020606</p>
            </Col>

            <Col xl={12} md={12} className="WalletDetailsBlock">
              <h5>
                <span>
                  <i className="bx bx-plus"></i> Refunded
                </span>
                <span>+1,999</span>
              </h5>
              <p>
                <span>Feb 3, 2022</span>
                <span>Balance AED 1,999</span>
              </p>
              <p className="CreditNote">
                credit note AED 1999 added to wallet #000020606
              </p>
            </Col>

            <Col xl={12} md={12} className="WalletDetailsBlock">
              <h5>
                <span>
                  <i className="bx bx-minus"></i> Used
                </span>
                <span>- 1,999</span>
              </h5>
              <p>
                <span>Feb 3, 2022</span>
                <span>Balance AED 1,999</span>
              </p>
              <p className="CreditNote">order #000020606</p>
            </Col>

            <Col xl={12} md={12} className="WalletDetailsBlock">
              <h5>
                <span>
                  <i className="bx bx-plus"></i> Refunded
                </span>
                <span>+1,999</span>
              </h5>
              <p>
                <span>Feb 3, 2022</span>
                <span>Balance AED 1,999</span>
              </p>
              <p className="CreditNote">
                credit note AED 1999 added to wallet #000020606
              </p>
            </Col>

            <Col xl={12} md={12} className="WalletDetailsBlock">
              <h5>
                <span>
                  <i className="bx bx-minus"></i> Used
                </span>
                <span>- 1,999</span>
              </h5>
              <p>
                <span>Feb 3, 2022</span>
                <span>Balance AED 1,999</span>
              </p>
              <p className="CreditNote">order #000020606</p>
            </Col>

            <Col xl={12} md={12} className="WalletDetailsBlock">
              <h5>
                <span>
                  <i className="bx bx-plus"></i> Refunded
                </span>
                <span>+1,999</span>
              </h5>
              <p>
                <span>Feb 3, 2022</span>
                <span>Balance AED 1,999</span>
              </p>
              <p className="CreditNote">
                credit note AED 1999 added to wallet #000020606
              </p>
            </Col>

            <Col xl={12} md={12} className="WalletDetailsBlock">
              <h5>
                <span>
                  <i className="bx bx-minus"></i> Used
                </span>
                <span>- 1,999</span>
              </h5>
              <p>
                <span>Feb 3, 2022</span>
                <span>Balance AED 1,999</span>
              </p>
              <p className="CreditNote">order #000020606</p>
            </Col>
          </CardBody>
        </Card>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default WalletStatus;
