import { useState, useEffect, button, useSearchParams } from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../components/Common/Breadcrumb";
import { fetchOrderDetail } from "./actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";
import OrderDetails from "./components/orderDetails";
import OrderUpdateDetailsForm from "./components/OrderUpdateDetailsForm";
import "react-datepicker/dist/react-datepicker.css";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import ChangepaymentMethod from "./components/ChangePaymentMethod";
import StatusBar from '.././../pages/Queuess/components/StatusBar';

import {
  Col,
  Container,
  Row,
  FormGroup,
  InputGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { Link } from "react-router-dom";
const itemSku = localStorage.getItem("skuItem");

const Details = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_mode = params.get("order_mode");
  const orderCountry = params.get("orderCn");
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [apiCounts, setApiCounts] = useState(0);
  const  currentCountry = localStorage.getItem("WEBSITE_COUNTRY");
  const [buttonDetail,setButtonDetail] = useState("");
  const [orderValue, setorderValue] = useState(""); 
  useEffect(() => {
   let ocountry = currentCountry;
    if(orderCountry !== '')
    {
      ocountry = orderCountry;
    }
    fetchOrderDetail({
      params: {
        country: ocountry,
        order_id: id,
        order_mode: order_mode,
      },
      dispatch,
      
    });
  }, [currentCountry,orderCountry]);
  const { orderDetail = [], isOrderLoading, apiTriggerCount } = useSelector(
    (state) => state.order
  );
  const reLoadOrderDetail=()=>{
    let ocountry = currentCountry;
    if(orderCountry !== '')
    {
      ocountry = orderCountry;
    }
  
       fetchOrderDetail({
      params: {
        country: ocountry,
        order_id: id,
        order_mode: order_mode,
      },
       dispatch,
     });
   }
 
  useEffect(() => {
    setApiCounts(apiTriggerCount);
  },[apiTriggerCount]);
 
 
  const toggleRightDrawer = () => {
    setPosition("right");
    setOpen(!open);
  };
  const onDrawerClose = () => {
    setOpen(false);
  };
  let orderData = [];
   orderData = orderDetail.OrderDetails?.[0];  
   let itemData = [];
   itemData = orderDetail.OrderDetails?.[0]?.ShipmentDetails;



  
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Mange Order View</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Manage Orders"
                breadcrumbItem="Order View"
              />
            </Col>
            <Col xl={6} className="textAlignRight">
            
             
                 
                  {orderData?.FinancialStatus=== "0"?(<>
                    <Button
                      color="primary"
                      className="mr-2"
                      onClick={() => {toggleRightDrawer()
                        setButtonDetail("1")
                      }}
                    
                      disabled={open}
                    >
                      Change Payment Method
                    </Button>  
                    </>):null}
                  
                    <Button
                      color="primary"
                      className=""
                      onClick={() => {toggleRightDrawer()
                        setButtonDetail("4")
                        localStorage.setItem("skuItem", '')
                      }}
                      disabled={open}
                    >
                      Update Detail
                    </Button>  
       
        
            </Col> 
          
          </Row>
          <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
            <Card className="card-h-100 OrderDetailsBox">
              <CardHeader>
              <Row>
                  <Col>
                    <CardTitle>{buttonDetail === "4" ? <>Update Detail</>
                    :buttonDetail === "1" ? <>Manage Payment Method</>:
                     ''}</CardTitle>
                  </Col>
                  <Col className="textAlignRight">
                    {" "}
                    <button
                      type="button"
                      className="btn-close "
                      onClick={() => {
                        onDrawerClose()
                      }}
                      aria-label="Close"
                    ></button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {buttonDetail === "4" ? (<>
              <OrderUpdateDetailsForm orderData={orderData} reLoadOrderDetail={reLoadOrderDetail} onDrawerClose={onDrawerClose} />
              </>):(<>  <ChangepaymentMethod orderData={orderData} reLoadOrderDetail={reLoadOrderDetail} onDrawerClose={onDrawerClose} /></>)}
              
              </CardBody>
            </Card>
          </ReactDrawer>
          <OrderDetails orderData={orderData} itemData={itemData} reLoadOrderDetail= {reLoadOrderDetail}/>
        </Container>
      </div>
    </>
  );
};

export default Details;