import React ,{useEffect} from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
//import OrderList from './components/orderList';
import ReportList from "./reportList";
import OrderExportReport from "../Orders/components/OrderExportReport";


import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
  } from "reactstrap";

const Reports = () => {
  
  const title = "Orders";
  const description = "Manage Orders";
 // const countryAll = "All";

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mange Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Orders" breadcrumbItem="Manage Orders" />
            </Col>
           <OrderExportReport />
           
          </Row>
          
            <ReportList  />
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default  Reports;
