export const OMS_URL = {
  BASE_URL: 'https://omsapi.danubehome.com/',
};
export const HOME_URL = {
  BASE_URL: 'https://danubehome.com/',
};

export const LIVE_URL = {
  BASE_URL: 'https://betaoms.danubehome.com/',
};
export const SORTING_ORDER = {
  ASC: 1,
  DESC: -1,
};
export const SORTING_CUSTOMER = {
  ASC: 1,
  DESC: -1,
};
export const SORTING_CATEGORY_LIST = {
  ASC: 1,
  DESC: -1,
};
export const STORE_LIST = [
  {
    country: 'UAE',
    code: 'AE',
    language: [
      { title: 'English', code: 'en' },
      { title: 'Arabic', code: 'ar' },
    ],
  },
  {
    country: 'BAHRAIN',
    code: 'BH',
    language: [
      { title: 'English', code: 'en' },
      { title: 'Arabic', code: 'ar' },
    ],
  },
  {
    country: 'OMAN',
    code: 'OM',
    language: [
      { title: 'English', code: 'en' },
      { title: 'Arabic', code: 'ar' },
    ],
  },
  {
    country: 'QATAR',
    code: 'QA',
    language: [
      { title: 'English', code: 'en' },
      { title: 'Arabic', code: 'ar' },
    ],
  },
];
export const GUEST_USER_CART_TOKEN_KEY = '_gct';
export const AUTH_TOKEN = '_ut';
export const CURRENT_COUNTRY = 'ae';
export const CURRENT_LANGUAGE = 'en';
export const DEFAULT_CURRENCY = 'AED';
export const DEFAULT_COUNTRY = 'ae';
export const DEFAULT_LANGUAGE = 'en';
export const ARABIC_LANGUAGE = 'ar';
export const HOME_DELIVERY_METHOD = 'bestway';

export const EXPORT_ALLOWED_USER_ROLES = ['1', '6', '7', '10', '20', '13'];
export const SELLER_DETAILS_ALLOWED_USER_ROLES = ['38', '1'];
export const RETURN_DETAILS_ALLOWED_USER_ROLES = ['2', '1','38'];

export const DEFAULT_IS_FREE_COD = false;
export const DEFAULT_IS_FREE_SHIPPING = false;
export const DEFAULT_IS_REMOVE_PROMO_PRICES = false;
