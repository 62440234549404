import axios from "axios";

import { setQueueList,setReturnQueueList,setRefundList,updateOrderDetails,setQueueDetail} from "../../../Redux/queue/queueSlice";
import UserAuthKey from "../../../components/Common/UserAuthKey";
import { setUserData } from "../../../Redux/user/userSlice";

const apiUrl = process.env.REACT_APP_OMS_API;

export const fetchQueueList= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/get-queue-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setQueueList(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
    // this loader added because in case order not returned the success message
 
  
  }
};

export const fetchReturnQueueList= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/get-queue-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setReturnQueueList(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
    // this loader added because in case order not returned the success message
 
  
  }
};

export const fetchApprovalList= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/get-refund-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setRefundList(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
    // this loader added because in case order not returned the success message
 
  
  }
};


export const updateOrderStatus = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/oms-service/approve-refund`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(updateOrderDetails(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const getQueueOrderRemarks = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/orders/cancel-reprocess`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(updateOrderDetails(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const fetchHistoryList = ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();

  return axios({
    method: 'post',
    url: `${apiUrl}v1/orders/queue-detail`,
    data: {
      ...params,
    },
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (response) => {
      if (response?.data?.code === 100) {
        return response?.data?.data;
      } else {
      }
    })
    .catch((error) => {

    });
};


  

