
    import { gql } from '@apollo/client';
    export const GET_ARTICLES_NAME_LIST= gql`
    query GET_ARTICLES_NAME_LIST(
      $input:CategoryArticlesNameListInput!
    ){
        getCategoryArticlesNameList(input: $input)
        {
    articlesNameData {
      _id
      title{en ar}
      position

      
    }
        }
        }`;