import { gql } from '@apollo/client';
export const GET_CUSTOMER_DATA_ADMIN= gql`
mutation(
   $_id: String! 
  ){
    getCustomerDataAdmin(input: { _id:$_id }) {
    _id
    createdAt
    updatedAt
    firstName
    lastName
    email
    country {
      code
      data{
        basicInfo
        {
          mobile
          defaultLanguage
        }
      }
    }
    address {
      _id
      countryCode
      firstName
      lastName
      telephone
      alternateTelephone
      address1
      address2
      city
      region
      country
      mapData
      latitude
      longitude
      addressType
      isDefault
      plusCode
    }
  }
    }`;