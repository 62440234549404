import { gql } from '@apollo/client';
export const GET_CATEGORY_DATA_DETAIL= gql`
query GET_CATEGORY_DATA_DETAIL(
  $input:dataReqInput!
)
{
    getCategoryDetails(input: $input) {
    _id
    name {
      en
      ar
    }
    slug
    icon
    shortDescription {
      en
      ar
    }
    metaTitle {
      en
      ar
    }
    metaDescription {
      en
      ar
    }
    metaKeywords {
      en
      ar
    }
    status
    noOfArticles
    createdBy
    updatedBy
    createdAt
    updatedAt
    departmentId
    departmentName
    categoryType
    position
  }
    }`;