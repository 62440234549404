import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    storeandlang:'AE-en',
    cartDetails: {},
    pageLoader:false
 };

const createorderSlice = createSlice({
  name: "createorder",
  initialState,
  reducers: {
    changeStoreAndLang: (state, actions) => {
        state.storeandlang = actions.payload;
    },
    setCartDetails(state, action) {
      state.cartDetails = action.payload;
    },
    setPageLoader(state, action) {
      state.pageLoader = action.payload;
    }    
  },
});  


export const { setCartDetails,changeStoreAndLang,setPageLoader }= createorderSlice.actions;

const createorderReducer = createorderSlice.reducer;

export default createorderReducer;