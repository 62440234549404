import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    invoiceList: [],
    isLoading: true,
    isDetailLoading:true,
    isExportLoading:true
  };
  
  const invoiceSlice = createSlice({
    name: "Invoice",
    initialState,
    reducers: {
      setInvoiceList(state, actions) {
        state.invoiceList = actions.payload;
        state.isLoading = false;
      },
      setInvoiceDetails(state, actions) {
        state.invoiceDetail = actions.payload;
        state.isDetailLoading = false;
      },
      setInvoiceCreate(state, actions) {
        state.invoiceCreate = actions.payload;
        state.isLoading = false;
      },
      setInvoiceExport(state, actions) {
        state.invoiceExport = actions.payload;
        state.isExportLoading = false;
      },
    },
  });
  
  export const { setInvoiceList, setInvoiceDetails, setInvoiceCreate,setInvoiceExport }= invoiceSlice.actions;
  
  const invoiceReducer = invoiceSlice.reducer;
  
  export default invoiceReducer;
  