import React from "react";
import axios from "axios";
import PhotoShootProgressbar from "./components/PhotoShootProgressbar";
import PhotoShootItemDetails from "./components/PhotoshootItemDetail";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PhotoShootUpdateDetailForm from "./components/PhotoShootUpdateDetailForm";
import { useState, useEffect, button } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
} from "reactstrap";

function PhotoShootOrderDetails({ orderData }) {
    //console.log(orderData,"orderDataorderDataorderDataorderData")
  let cdate = orderData?.OrderCreatedDate,
                  orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                  orderTime = moment(new Date(cdate)).format("h:mm:ss a");
  return (
    <>
      <Card className="card-dh card-h-100">
        <CardBody className="card-body-dh">
          <Row>
            <Col>
              Photo Shoot Detail : Transfer No# <strong>{orderData?.OrderNo}</strong> &nbsp; / &nbsp; Transfer Date 
              : {orderDt} &nbsp; {orderTime} &nbsp;  Status: &nbsp; {orderData?.[0].Status}
             
            </Col>
            <PhotoShootProgressbar orderData={orderData} />
          </Row>

          <Row>
            <Col xl={9} md={7}>
             

              <PhotoShootItemDetails orderData={orderData} />
            </Col>
            <Col xl={3} md={5}>
              
              <PhotoShootUpdateDetailForm orderData={orderData} />
            
            </Col>
          </Row>

          {/* <Row>
            <Col xl={9} md={7}>
              <OrderNotebyAdmin  orderData={orderData}/>
            </Col>
            <Col xl={3} md={5}></Col>
          </Row> */}
        </CardBody>
      </Card>
    </>
  );
}

export default PhotoShootOrderDetails;
