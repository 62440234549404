// import { iteratorSymbol } from "immer/dist/internal";
import React, { useState } from "react";
import {
  Table,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Modal,
} from "reactstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


function RiverOrderItemDetails({ itemData, orderData }) {
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_large, setmodal_large] = useState(false);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [modal_small, setmodal_small] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [modal_scroll, setmodal_scroll] = useState(false);
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  function tog_small() {
    setmodal_small(!modal_small);
    removeBodyCss();
  }

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }


  return (
    <>
      <Card className="OrderItemDetails">
        <CardHeader>
          <CardTitle>ORDER ITEM DETAILS</CardTitle>
        </CardHeader>
        {itemData !== undefined ? (
          <>
        <CardBody>
          <div className="table-responsive dt-table">
            <Table className=" table mb-0">
              <thead>
                <tr>
                  <th style={{ maxWidth: 400, minWidth: 400 }}>Details</th>
                  <th style={{ maxWidth: 70, minWidth: 70 }}>Qty</th>
                  <th style={{ maxWidth: 110, minWidth: 110 }}>SubTotal</th>
                  <th style={{ maxWidth: 110, minWidth: 110 }}>Total</th>
                </tr>
              </thead>
              <tbody>
                {itemData?.map((item,itemInx) => {
                  return (
                    <tr key={itemInx}>
                      <td>
                        <div className="ProductDetails">
                          <span>
                            <img
                              alt=""
                              width="120"
                              height="120"
                              className="img-thumbnail"
                              src={item?.Image}
                            />
                          </span>
                          <p>
                            Name : {item?.Name}
                            <br />
                            Sku : {item?.Sku}
                            <br />
                            Item Condition :{" "}
                            <span className="status pending">
                              {item?.ItemCondition}
                            </span>
                            <br />
                            Resolution :{" "}
                            <span className="status" style={{color:"red", fontWeight:"bold"}}>
                              {item?.Resolution}
                            </span>
                            <br />
                            CustomerComments :{" "}
                            <span className="status">
                              {item?.CustomerComments}
                            </span>
                          </p>
                        </div>
                      </td>
                     
                      <td>{item?.Qty}</td>
                    
                      <td>
                        {orderData?.Currency} {Number(item?.TotalPrice).toFixed(2)}
                      </td>
                      <td>
                        {orderData?.Currency} {Number(item?.TotalPrice).toFixed(0)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
          </>
          ) : (<>
            <CardBody>
             <Skeleton count={6} />
             </CardBody>
              </>
            )}
      </Card>
    </>
  );
}
export default RiverOrderItemDetails;
