import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  settingList: [],
  isLoading: true,
  isupdateLoading:true
  
 
};

const settingSlice = createSlice({
  name: "MarketPlaceOrder",
  initialState,
  reducers: {
    setSettingsData(state, actions) {
      state.settingList = actions.payload;
      state.isLoading = false;
    },
    getUpdateSettings(state, actions) {
      state.updateList = actions.payload;
      state.isupdateLoading = false;
    },

    dmsExpressDate(state, actions) {
      state.ExpressList = actions.payload;
      state.isExpressLoading = false;
    },
    getdmsExpressDate(state, actions) {
      state.expressDate = actions.payload;
      state.isExpressLoading = false;
    },
    updatedmsExpressDate(state, actions) {
      state.updateExpressList = actions.payload;
      state.isExpressUpdateLoading = false;
    },
    


  },
});

export const { setSettingsData, getUpdateSettings, dmsExpressDate,getdmsExpressDate, updatedmsExpressDate }= settingSlice.actions;

const settingReducer = settingSlice.reducer;

export default settingReducer;
