import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags"
import {
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    FormGroup,
    Label,
    Button,
    Input,
    Form,
    Table,
    UncontrolledDropdown,
    Spinner
} from "reactstrap";
import { DashBreadcrumb } from "../../../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { NonServiceAreaView,NonServiceAreaUpdate } from "../../actions/orderActions"

import { AvForm, AvField } from "availity-reactstrap-validation"

import { Link } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast";



import classnames from "classnames"

const NonServicesAreaForm  = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [updatenonViewArea, setUpdateNonViewArea] = useState(false);
    const [updateNonAreaLoad, setUpdateNonAreaLoad] = useState(false);
    const [status, setSatus] = useState("1");
      
    useEffect(() => {
        NonServiceAreaView({
            params: {
              area_id:id
                
            },
            dispatch,
          });
       
      }, []);   
        
      
    
      const { NonServiceView = [], isNonServiceViewLoading } = useSelector(
        (state) => state.order
      );
      const handleBulkStatus = (events, values) => {
        setUpdateNonAreaLoad(true);
        const area = values?.area ? values?.area : "";
        const emirate = values?.emirate ? values?.emirate : "";
        const country = values?.country ? values?.country : "";
        const is_active = values?.is_active ? values?.is_active : "";

        NonServiceAreaUpdate({
          params: {
            area_id:id,
            area:area,
            emirate:emirate,
            country:country,
            is_active:status
            


            
          },
          dispatch,
        });
      };
      const { NonServiceUpdate = [], isNonServiceUpdateLoading } = useSelector(
        (state) => state.order
      );
      useEffect(() => {
        if (NonServiceUpdate?.code === 100) {
          setUpdateNonAreaLoad(false);
          toast.success(NonServiceUpdate?.message, {
            style: {
              border: "1px solid #fff",
              padding: "20px",
              color: "#ffffff",
              background: "#4CAF50",
            },
            iconTheme: {
              primary: "#FFFAEE",
              secondary: "#4CAF50",
            },
          });
        } else {
          if (NonServiceUpdate?.message) {
            toast.error(NonServiceUpdate?.message, {
              style: {
                border: "1px solid #fff",
                padding: "20px",
                color: "#ffffff",
                background: "#f44336",
              },
              iconTheme: {
                primary: "#FFFAEE",
                secondary: "#f44336",
              },
            });
          }
        }
      }, [NonServiceUpdate]);
  
      


    

   
    const [verticalActiveTab, setverticalActiveTab] = useState("1")
  
    const [headerTab, setHeaderTab] = useState("1")
    const [headerPillsTab, setHeaderPillsTab] = useState("1")
  

    const toggleHeader = tab => {
        if (headerTab !== tab) setHeaderTab(tab);
    }
    const toggleHeaderPills = tab => {
        if (headerPillsTab !== tab) setHeaderPillsTab(tab);
    }

    // 
 

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab)
        }
    }
   
    return (
        <React.Fragment>
            <div className="page-content">
               
                <Container fluid>
              
                <Row>
            <Col xl={6}>
              <DashBreadcrumb title="NonServiceArea" breadcrumbItem="NonServiceArea" />
            </Col>
       
          </Row>
                  
                    <Row>
                        <Col >
                         
                                    <Row>
                                        
                                     
                                        <Col md="9">
                                          
                                                   
                                                <React.Fragment>
             <Col xl={9}>
                <Card>
                   
                   <CardBody>
                        <AvForm className="needs-validation" 
                         onValidSubmit={(e, v) => {
                          handleBulkStatus(e, v);
                        }}   
                        >
                            <Row>
                            
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01">Sub Area</Label>
                                        <AvField
                                            name="area"
                                            placeholder="Find a area"
                                            type="text"
                                        
                                            className="form-control"
                                            value= {NonServiceView?.data?.[0]?.sub_area}
                                           
                                        />
                                    </FormGroup>
                                </Col>
                                </Row>
                                <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="">Country</Label>
                                        <AvField
                                type="select"
                                name="country"
                                className="form-select"
                                required
                              >
                                <option value="UAE" selected ={NonServiceView?.data?.[0]?.country === 'UAE'? 'selected':''}>
                                UAE
                                </option>
                                <option value="Oman" selected ={NonServiceView?.data?.[0]?.country === 'Oman'? 'selected':''}>
                                Oman
                                </option>
                                <option value="Baharain" selected ={NonServiceView?.data?.[0]?.country === 'Baharain'? 'selected':''}>
                                Bahrian
                                </option>
                                <option value="Qatar" selected ={NonServiceView?.data?.[0]?.country === 'Qatar'? 'selected':''}>
                                Qatar
                                </option>
                                <option value="Kuwait" selected ={NonServiceView?.data?.[0]?.country === 'Kuwait'? 'selected':''}>
                                Kuwait
                                </option>
                               
                              </AvField>
                                     
                                    </FormGroup>
                                </Col>
                              
                              
                            </Row>
                            <Row>
                          
                          <Col lg="12">
                            <FormGroup className="mb-3">
                            <Label htmlFor="">Region</Label>
                              <AvField
                                type="select"
                                name="emirate"
                                value= {NonServiceView?.data?.[0]?.emirate}
                                required
                                className="form-select"
                              >
                                <option value="Abu Dhabi" selected ={NonServiceView?.data?.[0]?.emirate === 'Abu Dhabi'? 'selected':''}>
                                Abu Dhabi
                                </option>
                                <option value="Dubai" selected ={NonServiceView?.data?.[0]?.emirate === 'Dubai'? 'selected':''}>
                                Dubai
                                </option>
                                <option value="Sharjah" selected ={NonServiceView?.data?.[0]?.emirate === 'Sharjah'? 'selected':''}>
                                Sharjah
                                </option>
                                <option value="Ajman" selected ={NonServiceView?.data?.[0]?.emirate === 'Ajman'? 'selected':''}>
                                Ajman
                                </option>
                                <option value="Umm Al-Quwain" selected ={NonServiceView?.data?.[0]?.emirate === 'Umm Al-Quwain'? 'selected':''}>
                               Umm Al-Quwain
                                </option>
                                <option value="Fujairah" selected ={NonServiceView?.data?.[0]?.emirate === 'Fujairah'? 'selected':''}>
                                Fujairah
                                </option>
                                <option value="Ras Al Khaimah" selected ={NonServiceView?.data?.[0]?.emirate === 'Ras Al Khaimah'? 'selected':''}>
                                Ras Al Khaimah
                                </option>
                              </AvField>
                            </FormGroup>
                          </Col>
                        
                    
                        </Row>
                           
                          
                            <Row>
                          
                                <Col lg="12
                                ">
                                <FormGroup className="mb-3">
                                    <label>Status</label>
              <select className="form-control form-select" name="is_active" 
              onChange={(e) => setSatus(e.target.value)}
               >
              <option selected={NonServiceView?.data?.[0]?.is_active === '1' ? 'selected':''} value = "1">Active</option>
               <option selected={NonServiceView?.data?.[0]?.is_active === '0'? 'selected':''} value ="0">InActive</option>
 </select>
                                    </FormGroup>   
                                </Col>
                               
                            </Row>
                    
                            <Button type="submit" color="primary" className="">
                                        {updatenonViewArea === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "}
                                            Save
                                        </Button>{" "}
                        </AvForm>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
                                               
                                        
                                                 
                                          
                                        </Col>
                                    </Row>
                             
                        </Col>
                        
                    </Row>
                  
                </Container>
            </div>
            <Toaster position="top-right" reverseOrder={false} />
        </React.Fragment>
    );
}


export default NonServicesAreaForm  ;

