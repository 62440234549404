import React from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
//import OrderList from './components/orderList';
import BulkOrderList from "./components/bulkorders/BulkOrderList";
import "react-datepicker/dist/react-datepicker.css"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import OrderExportReport from "./components/OrderExportReport";
import DesignYourhomeLeadsList from "../bccwOrders/DesignYourHomeLeadsList";
import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
  } from "reactstrap";

const DesignYourHomeLead = () => {
  const title = "Orders";
  const description = "BCCW Orders";

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>BCCW Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Orders" breadcrumbItem="BCCW Orders" />
            </Col>
             <OrderExportReport />
          </Row>
          
           <DesignYourhomeLeadsList/>
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DesignYourHomeLead;
