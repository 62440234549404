import { useState, useEffect, button, useSearchParams } from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../components/Common/Breadcrumb";
import { fetchProjectTrackDetail } from "./action/action";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";
import CustomerTrackingDetails from "./CustomerTrackingDetail";
import "react-datepicker/dist/react-datepicker.css";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import WalletStatus from "../Orders/components/WalletStatus";
import {
  Col,
  Container,
  Row,
  FormGroup,
  InputGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { Link } from "react-router-dom";

const TrackingDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_mode = params.get("order_mode");
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [apiCounts, setApiCounts] = useState(0);
  useEffect(() => {
    fetchProjectTrackDetail({
      params: {
        track_id: id,
      },
      dispatch,
      
    });
  }, [apiCounts]);
  const { projectDetail = [], isProjectDetailLoading, apiTriggerCount } = useSelector(
    (state) => state.bccw
  );

 
  useEffect(() => {
    setApiCounts(apiTriggerCount);
  },[apiTriggerCount]);
 
  
  const orderData = projectDetail.OrderDetails?.[0];
 
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Project Tracker Details</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Project Tracker"
                breadcrumbItem="Project Track - Lead"
              />
            </Col>
    
          </Row>
          <CustomerTrackingDetails orderData={orderData} />
        </Container>
      </div>
    </>
  );
};

export default TrackingDetails;
