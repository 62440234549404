import { useState, useEffect, useSearchParams, useRef } from "react";
import { MetaTags } from "react-meta-tags";
import { DashBreadcrumb } from "../../../components/Common/Breadcrumb";
import "react-drawer/lib/react-drawer.css";
import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";

import {
  Col,
  Container,
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Row,
  Modal,
  Spinner,
} from "reactstrap";
import { GET_DEPARTMENT_NAME_LIST } from "../../Customers/query/DepartmentListName";
import ApolloClientKnowledgeBaseService from "../../../ApolloClientKBService";
import { CREATE_CATEGORY } from "../../Customers/query/CreateCategory";

import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import { Badge } from "reactstrap";
const AddNewCategoryForm = () => {
  const history = useHistory();
  const [drp_success1, setDrp_success1] = useState(0);
  const [saveCatLoad, setCatLoad] = useState(false);
  const [articleType, setArticleType] = useState("");
  const [drp_secondary_lg, setDrp_secondary_lg] = useState(false);
  const [kbCreateCatData, setCreateCatData] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [modal_center, setmodal_center] = useState(false);
  const [value, setValue] = useState("");
  const [toggleValue, setToggleValue] = useState("Select Status");
  const [getDepName, setDepNameList] = useState();
  const [selectedGroup, setselectedGroup] = useState(null);
  const [depValue, setDepValue] = useState("");
  const [kabCatError, setKbCatError] = useState("");

  const buttonRef = useRef(null);
  useEffect(() => {
    if (value === 0) {
      setToggleValue("Unpublished");
    } else if (value === 1) {
      setToggleValue("Published");
    } else if (value === 2) {
      setToggleValue("Draft");
    } else {
      setToggleValue("Select Status");
    }
  }, [value]);

  function tog_center() {
    setmodal_center(!modal_center);
  }
  const handleSelect = (e) => {
    setValue(e);
  };
  const handleArticleType = (e) => {
    setArticleType(e.target.value);
  };
  let userName = "";
  if (localStorage.getItem("authUser")) {
    const userObj = JSON.parse(localStorage.getItem("authUser"));
    if (userObj?.role_id !== undefined) {
      userName = userObj?.user_name;
    }
  }
  const createCategoryData = async (events, values) => {
    try {
      if (value !== "") {
        setCatLoad(true);
        const { data, errors } = await ApolloClientKnowledgeBaseService.mutate({
          mutation: CREATE_CATEGORY,
          variables: {
            input: {
              name: { en: values?.name_e, ar: values?.name_a },
              slug: values?.slug,
              shortDescription: {
                en: values?.description_e,
                ar: values?.description_a,
              },
              metaTitle: { en: values?.metaT_e, ar: values?.metaT_a },
              metaDescription: { en: values?.metaD_e, ar: values?.metaD_a },
              metaKeywords: { en: values?.metak_w, ar: "" },
              status: value,
              createdBy: userName,
              updatedBy: userName,
              icon: values?.icon,
              categoryType: articleType,
              departmentId: selectedGroup?.value,
              departmentName: selectedGroup?.label,
              position:Number(values?.position)
            },
          },
          fetchPolicy: "network-only",
        });

        setKbCatError(errors);
        setCreateCatData(data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (kbCreateCatData?.createCategory?._id !== undefined) {
      setCatLoad(false);
      toast.success("Category Successfully Created", {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });

      setCreateCatData("");
    } else {
      if (kbCreateCatData?.createCategory?._id === "") {
        setCatLoad(false);
        toast.error("Category Not Created", {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [kbCreateCatData]);
  let stsBtn = "";
  if (value === 0) {
    stsBtn = "bg-primary";
  } else if (value === 1) {
    stsBtn = "bg-success";
  } else if (value === 2) {
    stsBtn = "bg-warning";
  } else {
    stsBtn = "bg-soft-dark";
  }

  const getDepNameList = async (variables) => {
    try {
      const { data } = await ApolloClientKnowledgeBaseService.mutate({
        mutation: GET_DEPARTMENT_NAME_LIST,
        variables: { input: {} },
        fetchPolicy: "network-only",
      });
      setDepNameList(data);
    } catch (error) {}
  };
  useEffect(() => {
    getDepNameList();
  }, []);
  const depNameListFromApi = getDepName?.getDepartmentsList?.departmentsData;
  useEffect(() => {
    const optionGroup = depNameListFromApi?.map((item, itemInx) => ({
      label: item?.name,
      value: item?._id,
      slug: item?.slug,
    }));
    setDepValue(optionGroup);
  }, [depNameListFromApi]);
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Categories</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col>
              <DashBreadcrumb
                title="Categories"
                breadcrumbItem="Add New Category"
              />

              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Col lg={8} md={8}>
                  <Card>
                    <CardBody>
                      <AvForm
                        ref={buttonRef}
                        onValidSubmit={(e, v) => {
                          createCategoryData(e, v);
                        }}
                      >
                        <div className="mb-3">
                          <FormGroup className="mb-3">
                            <AvField
                              type="select"
                              name="status"
                              label="Associate to Website"
                              className="form-select"
                              onChange={handleArticleType}
                              value=""
                              required
                            >
                              <option value="">Select</option>
                              <option value="intrenal">internal</option>
                              <option value="external">external</option>
                            </AvField>
                          </FormGroup>
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="name_e"
                            label="Name - English "
                            placeholder="Type Something"
                            type="text"
                            value=""
                            required
                            onChange={(e) => {
                              setCategoryName(e?.target?.value);
                            }}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="name_a"
                            label="Name - Arabic"
                            placeholder="Type Something"
                            type="text"
                            value=""
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="slug"
                            label="Slug*"
                            type="text"
                            value={categoryName
                              ?.replace(/[^A-Z0-9]+/gi, "-")
                              ?.toLowerCase()}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="description_e"
                            label="Short Description - English"
                            type="textarea"
                            value=""
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="description_a"
                            label="Short Description - Arabic"
                            type="textarea"
                            value=""
                            required
                          />
                        </div>
                        <h5>Search Engine Optimization</h5>

                        <div className="mb-3">
                          <AvField
                            name="metaT_e"
                            label="Meta Title - English"
                            type="text"
                            value=""
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="metaT_a"
                            label="Meta Title - Arabic"
                            type="text"
                            value=""
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="metaD_e"
                            label="Meta Description - English"
                            type="textarea"
                            value=""
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="metaD_a"
                            label="Meta Description - Arabic"
                            type="textarea"
                            value=""
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="metak_w"
                            label="Meta Keywords - English"
                            type="text"
                            value=""
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="icon"
                            label="Category Icon"
                            type="text"
                            value=""
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label>Departments*</label>
                          <Select
                            value={selectedGroup}
                            onChange={handleSelectGroup}
                            options={depValue}
                            classNamePrefix="select2-selection"
                            required
                          />
                        </div>

                        <div className="mb-3">
                                        <AvField
                            name="position"
                            label="Position*"
                            type="text"
                            value=""
                            required
                         
                          />
                        </div>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={4}>
              <Card className="statusDetail">
                <CardHeader className="card_header">
                  <label>Status</label>
                </CardHeader>
                <hr></hr>
                <CardBody>
                  <Row>
                    <Col>
                      <label>Current</label>
                    </Col>

                    <Col className="textAlignRight">
                      {" "}
                      <Badge className={` ${stsBtn} me-2`}>
                        {toggleValue}{" "}
                      </Badge>
                    </Col>
                  </Row>
                  <ButtonDropdown
                    isOpen={drp_secondary_lg}
                    toggle={() => setDrp_secondary_lg(!drp_secondary_lg)}
                    className="mt-2 mb-2 catbuttonDropdown"
                  >
                    <DropdownToggle
                      caret
                      color="success"
                      className="btn btn-success btn-lg "
                    >
                      {saveCatLoad && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{" "}
                      {toggleValue} &nbsp;
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu
                      className="dropdownmenu-secondary dropdown-menu-end"
                      onClick={() => {
                        buttonRef?.current?.submit();
                      }}
                    >
                      <DropdownItem onClick={() => handleSelect(0)}>
                        Unpublished
                      </DropdownItem>

                      <DropdownItem onClick={() => handleSelect(1)}>
                        Published
                      </DropdownItem>

                      <DropdownItem onClick={() => handleSelect(2)}>
                        Draft
                      </DropdownItem>

                      <DropdownItem
                        onClick={() => {
                          tog_center();
                        }}
                      >
                        Set Schedule
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>

                  <Modal
                    isOpen={modal_center}
                    toggle={() => {
                      tog_center();
                    }}
                    centered={true}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Set Schedule</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_center(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Row>
                        <label>Schedule (required)</label>
                        <Col>
                          <Row>
                            <Col>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value="exchange"
                                  name="payment_mode"
                                  defaultChecked
                                  id="exchangeRadios"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exchangeRadios"
                                >
                                  Exchange
                                </label>
                              </div>
                            </Col>
                            <Col>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value="Refund"
                                  name="payment_mode"
                                  id="refundRadios"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="refundRadios"
                                >
                                  Refund
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <label
                              htmlFor="example-datetime-local-input"
                              className="form-Label"
                            >
                              Publish on(required)
                            </label>
                            <input
                              className="form-control"
                              type="datetime-local"
                              defaultValue="2019-08-19T13:45:00"
                              id="example-datetime-local-input"
                            />
                          </Row>
                          <Row className="mt-3">
                            <Col>
                              <button
                                type="submit"
                                className="btn btn-danger mr-2"
                                data-toggle="modal"
                              >
                                Cancel
                              </button>

                              <button
                                type="submit"
                                className="btn btn-success"
                                data-toggle="modal"
                              >
                                Submit
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </>
  );
};

export default AddNewCategoryForm;
