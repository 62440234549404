import { gql } from '@apollo/client';
export const GET_ARTICLES_DATA_LIST= gql`
query GET_ARTICLES_DATA_LIST(
  $input:ArticlesAllInput!
)
{
    getAllArticlesList(input: $input)
{
    articlesData {
     _id
      title {
        en
        ar
      }
      slug
      categoryId
      categoryName
      articleType
      content {
        en
        ar
      }
      shortDescription {
        en
        ar
      }
      metaTitle {
        en
        ar
      }
      metaDescription {
        en
        ar
      }
      metaKeywords {
        en
        ar
      }
      like
      dislike
      status
      createdBy
      createdAt
      updatedAt
      updatedBy
    }
    totalRecord
  }
    }`;