import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  country: null
};
const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        setCountryCode: (state, action) => {
        
            state.country = action.payload;
          },
          sideBarMenuStatus(state, actions) {
            state.sideMenuActive= actions.payload;
          },
    },
});


export const {setCountryCode,sideBarMenuStatus} = countrySlice.actions;
const countryReducer = countrySlice.reducer;

export default countryReducer;