import { useState, useEffect, button } from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../components/Common/Breadcrumb";
import {  getReturnOrderDetails} from "./actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { Badge } from "reactstrap";
import ReturnOrderDetails from "./components/return/ReturnOrderDetails";

import "react-datepicker/dist/react-datepicker.css";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import Flatpickr from "react-flatpickr";

import WalletStatus from "./components/WalletStatus";
import { Col, Container, Row, FormGroup, InputGroup, Button } from "reactstrap";

const ReturnDetails = () => {
  const { id } = useParams();
  const { order } = useParams();
  const dispatch = useDispatch();
  const [apiCounts, setApiCounts] = useState(0);

  useEffect(() => {
    getReturnOrderDetails({
      params: {
        return_id:id,
        reference_no: order
        
      },
      
      dispatch,
     
    });
  }, [apiCounts]);
  const { returnDetails = [], isOrderLoading ,apiReturnTriggerCount} = useSelector((state) => state.order);

  useEffect(() => {
    setApiCounts(apiReturnTriggerCount);
  },[apiReturnTriggerCount]);
  const orderData = returnDetails.OrderDetails?.[0];
  const itemData = returnDetails.OrderDetails?.[0]?.ItemDetails;
  
  return (
    <>

      <div className="page-content">
        <MetaTags>
          <title> Return Order Detail</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
         
          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Return Order Detail"
                breadcrumbItem=" Return Order Detail View"
              />
            </Col>
           
            
          </Row>

          <ReturnOrderDetails  orderData={orderData} itemData={itemData}/> 
    
        </Container>
    
      </div>
   </>
  );
};

export default ReturnDetails;
