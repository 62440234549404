import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { Col, Row, Progress } from "reactstrap";
import "./OrderShipmentProgress.scss";
import { format, compareAsc } from "date-fns";

function ShipmentProgressbar(ShipmentData) {
  const shipmentInfo = ShipmentData;
  const currentCountry = localStorage.getItem("WEBSITE_COUNTRY");
  let shipData = "";

 // if(currentCountry === "ae"){
   shipData = shipmentInfo?.ShipmentData?.status_details;   
 /*  }
  else {
    shipData = shipmentInfo?.ShipmentData?.[0]?.status_details;  
  } */
 
  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word?.slice(1);
      })
      .join(' ');
  
    return titleCase;
  }

  if (shipData?.length > 0) { 

    return shipData.map((shipments,itemInx) => {
      return (
        <div  key={shipments?.id}>
        
        <div className="trackView">
          <div className="trackingIcon">
            <span className="Active">
              <svg xmlns="http://www.w3.org/2000/svg" width="15.004" height="12.549" viewBox="0 0 15.004 12.549">
                <path
                  id="Path_663"
                  data-name="Path 663"
                  d="M13.639.75,4.433,11.491.75,7.195"
                  transform="translate(0.308 0.308)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </svg>
            </span>
          </div>
          <div className="trackingStatus">
            <p>
              <strong>{ toTitleCase(shipments?.status)}</strong>
              <span>
               <em>Created on {format(new Date(shipments?.created_at), 'MMMM d, yyyy h:mma')}</em>

              </span>
              <strong>
              {shipments?.delivery_at !== null ? (<>
               
               {shipments?.ls_status.toLowerCase() === 'delivery_scheduled' ? (<>
              
              <em>Delivery Scheduled Date : </em></>
              ):(null)}
               {shipments?.ls_status.toLowerCase() === 'rescheduled' ? (<>
              
              <em>Rescheduled Date : </em></>
              ):(null)}
              
               {shipments?.ls_status.toLowerCase() === 'delivered' ? (<>
              
              <em>Delivered Date : </em></>
              ):(null)}
                <em> {format(new Date(shipments?.delivery_at), 'MMMM d, yyyy')}</em></>
                ):(null)}
              </strong>
              {shipments?.time_slot !== null && shipments?.time_slot !=='' ? (<>
              
              <em>Time Slot : {shipments.time_slot}</em></>
              ):(null)}
            </p>
          </div>
        </div>
       
      
        </div>
      )
    });

  } else {
    return (<>
      <Row className="mt-5">
        <Col>Item details not available</Col>
      </Row>
    </>);
  }


}
export default ShipmentProgressbar;
