import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "./slider.scss";
SwiperCore.use([Navigation]);
const ProductSliderComponent = () => {
  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={2}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
      modules={[Navigation]}
      navigation
      breakpoints={{
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
        1191: {
          slidesPerView: 6,
        },
      }}
    >
      {[1, 2, 3, 4, 5, 5, 5, 1, 2, 3, 4, 5, 5, 5]?.map(() => {
        return (
          <SwiperSlide>
            <div className="productCard">
              <div className="productImage">
                <img
                  src="https://cdn.danubehome.com/media/dh-seller/p/231202706786/231202706786-1.jpg"
                  alt="images"
                  width="100%"
                />
              </div>
              <div className="productDetails">
                <p className="productName">
                  Anacletus Fabric Sofa Set - Cool Grey - With 2-Year Warranty
                </p>
                <p className="productQty">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.828"
                    height="11.756"
                    viewBox="0 0 17.828 11.756"
                  >
                    <path
                      id="Path_85318"
                      data-name="Path 85318"
                      d="M7.2,5.076,4.49,2.36c.293-.238.638-.5.957-.784.384-.345.315-.72-.184-.808C3.676.489,2.082.246.489.007.141-.045-.029.184,0,.515c.154,1.527.3,3.054.491,4.577.066.538.4.631.822.263.338-.3.656-.618,1.116-1.055.423.48.8.952,1.228,1.383C4.433,6.469,5.228,7.233,6.019,8A1.362,1.362,0,0,0,8.147,8c.659-.649,1.3-1.32,1.972-2.012.191.178.346.314.492.46,1.617,1.614,3.225,3.238,4.852,4.843a1.343,1.343,0,0,0,2.346-.591,1.655,1.655,0,0,0-.357-1.272c-2.079-2.155-4.207-4.262-6.335-6.368a1.334,1.334,0,0,0-1.957.034C8.483,3.749,7.832,4.431,7.2,5.076"
                      transform="translate(0)"
                      fill="#20cb81"
                    />
                  </svg>
                  <span>
                    Avg.selling - AED 12000 <em>(320 Items sold)</em>
                  </span>
                </p>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
export default ProductSliderComponent;
