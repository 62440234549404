import "./Assembly.scss";
import React, { useState, useEffect } from "react";
import { Modal, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { setSupplierIdsForAssemblyCharges } from "../../actions/action";
import { SuccessToast, ErrorToast } from "../../../../../utils/Toaster";
const AssemblyComponent = (props, totalInstallmentCost) => {
  const {
    cart = {},
    showAssembly,
    totalAssemblyCharge,
    setTotalAssemblyCharge,
  } = props || {};
  const { countryInfo = {} } = cart || {};
  const [modal_center, setmodal_center] = useState(false);
  const { cartMeta, items } = cart || {};
  const { sellers = [], sellerAssemblyInfo } = cartMeta || {};
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [orderItemsExist, setOrderItemsExist] = useState(false);
  const [sellerAllInfo, setSellerAllInfo] = useState([]);
  const [selectedTotalAssemblyCharge, setSelectedTotalAssemblyCharge] =
    useState(0);
  const [
    selectedTotalAssemblyChargeCount,
    setSelectedTotalAssemblyChargeCount,
  ] = useState(0);
  const dispatch = useDispatch();
  //const [totalAssemblyCharge, setTotalAssemblyCharge] = useState(0);

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  useEffect(() => {
    setSelectedSuppliers(sellerAssemblyInfo || []);
  }, [cartMeta]);

  useEffect(() => {
    const hasOrderItems = sellerAllInfo?.some(
      (info) => (info?.orderItems || [])?.length > 0
    );
    setOrderItemsExist(hasOrderItems);
  }, [sellerAllInfo]);
  /**
   *
   */
  useEffect(() => {
    try {
      let totalInstallmentCost = 0;
      let selectedTotalInstallmentCost = 0;
      let selectedTotalInstallmentCostCount = 0;

      const data = (cartMeta?.sellers || []).map(
        ({ sellerId, sellerInfo = {}, installationCharge = 0 }) => {
          const sellerItems = items.filter(
            (item) => item?.product?.coreFields?.supplierInfo?.code === sellerId
          );

          const sellerItemsModified = sellerItems
            .map((item) => {
              if (!item?.product?.coreFields.assembly_required) {
                return;
              }

              return {
                name: item?.product?.name || "",
                assemblyProvided:
                  item?.product?.coreFields.assembly_provided || false,
              };
            })
            .filter(Boolean);

          const isChecked = selectedSuppliers?.includes(sellerId) || false;
          totalInstallmentCost += installationCharge;
          if (isChecked) {
            selectedTotalInstallmentCost += installationCharge;
            if (installationCharge > 0) {
              selectedTotalInstallmentCostCount += 1;
            }
          }

          return {
            installationCharge,
            name: sellerInfo?.name || "",
            orderItems: sellerItemsModified,
            sellerId,
            isSelected: isChecked,
          };
        }
      );
      setTotalAssemblyCharge(totalInstallmentCost);
      setSelectedTotalAssemblyCharge(selectedTotalInstallmentCost);
      setSelectedTotalAssemblyChargeCount(selectedTotalInstallmentCostCount);
      setSellerAllInfo(data);
    } catch (e) { }
  }, [cartMeta, selectedSuppliers]);

  /**
   * Select And Unselect Assembly charges
   * @param {*} selectedSellerId
   */
  const selectSupplierAssembly = (selectedSellerId, installationCharge) => {
    if (selectedSuppliers.includes(selectedSellerId)) {
      if (!installationCharge > 0) {
        return;
      }
      const updatedArray = selectedSuppliers.filter(
        (item) => item !== selectedSellerId
      );
      setSelectedSuppliers(updatedArray);
    } else {
      const updatedArray = [...selectedSuppliers, selectedSellerId];
      setSelectedSuppliers(updatedArray);
    }
  };

  const updateSelectedSupplier = () => {
    const setIds = async () => {
      const updateAssemblyCharg = await setSupplierIdsForAssemblyCharges(
        { ids: selectedSuppliers },
        dispatch
      );
      if (updateAssemblyCharg) {
        tog_center()
        SuccessToast("Assembly charge updated");
      } else {
        ErrorToast("Assembly charge update error");
      }
    };
    setIds();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  /**
   * Seller Item listing
   */
  const SellerAllInfo = () => {
    return (sellerAllInfo || []).map((seller) => {
      const {
        name = "",
        installationCharge = 0,
        orderItems = [],
        sellerId,
        isSelected,
      } = seller || {};

      if (!orderItems?.length > 0) {
        return;
      }

      return (
        <div
          className="assemblyListContain"
          key={sellerId}
          onClick={() => selectSupplierAssembly(sellerId, installationCharge)}
        >
          <div className="assemblyList">
            <p className="assemblyType">
              <div className="shipped">
                <div
                  className={`checkbox ${isSelected ? "selected" : ""} `}
                ></div>
                <strong>Shipped and delivered by {name}</strong>
              </div>
              <span className="feeAmount">
                {installationCharge > 0
                  ? countryInfo.currency + " " + installationCharge
                  : "Free"}
              </span>
            </p>
            <ol>
              {orderItems.map((item) => (
                <li key={item?.name}>
                  {item?.name} {item?.assemblyProvided ? "( Free )" : null}
                </li>
              ))}
            </ol>
          </div>
        </div>
      );
    });
  };

  const ShowAssemblyTotal = ({ amount }) => {
    let totalCost = amount;
    return totalCost;
  };

  return showAssembly ? (
    <>
      {orderItemsExist && (
        <>
          <div className="assembly">
            <div className="contain p-4">
              <div className="form-check form-check-inline">
                {selectedTotalAssemblyCharge > 0 && (
                  <>
                    <label
                      className="form-check-label"
                      htmlFor="assemblyInstallationFee"
                    >
                      Assembly/Installation Fee for{" "}
                      {selectedTotalAssemblyChargeCount} product @ AED{" "}
                      {selectedTotalAssemblyCharge} added to your cart
                    </label>
                    <p className="mb-0 mt-1">
                      Would you like to{" "}
                      <span
                        onClick={() => {
                          tog_center();
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        change assembly charges?
                      </span>{" "}
                      some of the items are required assembly Installation or
                      Assembly will be done with in 48 Hours of delivery
                    </p>
                  </>
                )}
                {/* Assembly charge not selected */}
                {selectedTotalAssemblyCharge <= 0 &&
                  totalAssemblyCharge > 0 && (
                    <>
                      <label
                        className="form-check-label"
                        htmlFor="assemblyInstallationFee"
                      >
                        Assembly/Installation Fee @
                        <ShowAssemblyTotal amount={totalAssemblyCharge} />{" "}
                        Available
                      </label>
                      <p className="mb-0 mt-1">
                        Would you like to{" "}
                        <span
                          onClick={() => {
                            tog_center();
                          }}
                          data-toggle="modal"
                          data-target=".bs-example-modal-center"
                        >
                          add assembly charges?
                        </span>{" "}
                        Installation or assembly will be done with in 48 hours
                        of delivery
                      </p>
                    </>
                  )}
                {/* Seller Danube */}
                {totalAssemblyCharge === 0 &&
                  sellers?.length === 1 &&
                  sellers.find(
                    ({ sellerId }) => sellerId === "DANUBEHOMEONLINE"
                  ) && (
                    <>
                      <label
                        className="form-check-label"
                        htmlFor="assemblyInstallationFee"
                      >
                        Shiping fee
                      </label>
                      <p className="mb-0 mt-1">
                        <span
                          onClick={() => {
                            tog_center();
                          }}
                          data-toggle="modal"
                          data-target=".bs-example-modal-center"
                        >
                          Details
                        </span>
                      </p>
                    </>
                  )}
              </div>
            </div>
          </div>
          <Modal
            isOpen={modal_center}
            toggle={() => {
              tog_center();
            }}
            centered={true}
            className="assemblyChargesPopup"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">How Assembly Charges applies</h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_center(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Assembly charges may apply if the items require assembly. Some
                Sellers offer free assembly as part of their services, while
                others may charge a fee for assembling the products at the
                customer's location.
              </p>
              <p>
                The assembly charges can vary depending on the size and
                complexity of the item.
              </p>

              <div className="assemblyChargesContain">
                <p className="assemblyChargesContainNotice m-0">
                  <strong>
                    Assembly Charges may vary according to different
                    sellers/products
                  </strong>
                </p>
                <SellerAllInfo />
                <div className="assemblyTotal">
                  <strong className="assemblyTotalTitle">Total</strong>
                  <strong className="assemblyTotalAmount">
                    {countryInfo?.currency} {selectedTotalAssemblyCharge}
                  </strong>
                </div>
                {totalAssemblyCharge > 0 && (
                  <div
                    className="assemblyAction"
                    onClick={() => updateSelectedSupplier()}
                  >
                    <Button type="submit" className="btn btn-primary m-3 mt-0">
                      UPDATE ASSEMBLY CHARGES
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  ) : (
    ""
  );
};

export default AssemblyComponent;
