import { gql } from '@apollo/client';
export const CREATE_ARTICLES= gql`
mutation(
   $input:ArticlesInput!
  ){
    createArticles(input: $input) {
      _id
      title {
      en
      ar
    }
    slug
    icon
    shortDescription {
      en
      ar
    }
    metaTitle {
      en
      ar
    }
    metaDescription {
      en
      ar
    }
    metaKeywords {
      en
      ar
    }
    status
    createdBy
    createdAt
    updatedAt
    position
  }
  }`;
