import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  Location: [],
  isLoading: true,
  

 
};

const customerSlice = createSlice({
  name: "Customer",
  initialState,
  reducers: {
    EditCustomer(state, actions) {
        state.editCustomer = actions.payload;
        state.isLoading = false;
      },
      CustomerCountryHeader(state, actions) {
        state.customerCuntry= actions.payload;
      },
      setOrderList(state, actions) {
        state.orderListDetail = actions.payload;
        state.isListLoading = false;
      },
      setReturnList(state, actions) {
        state.orderReturnDetail = actions.payload;
        state.isReturnListLoading = false;
      },
     
  
      


  },
});

export const { EditCustomer, CustomerCountryHeader,setOrderList,setReturnList}= customerSlice .actions;

const CustomerReducer = customerSlice .reducer;

export default CustomerReducer;
