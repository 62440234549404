import React from "react";
import { Table, Card, CardHeader, CardTitle, CardBody } from "reactstrap";

function OrderNotebyAdmin({ orderData }) {
  const AdminRemarksDetails = orderData?.AdminRemarksDetails;
  if (AdminRemarksDetails?.[0]?.length > 0) {
   
  
  return (
    <>
      <Card className="OrderNotebyAdmin">
        <CardHeader>
          <CardTitle>ORDER NOTES BY ADMIN:</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="table-responsive dt-table">
            <Table className=" table mb-0">
              <thead>
                <tr>
                  <th className="text-center">Date</th>
                  <th className="text-center">CreatedBy</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Remarks</th>
                </tr>
              </thead>
              <tbody>
              {AdminRemarksDetails?.[0]?.map((remark,inRem) => {
                  return ( <tr key={inRem}>
                    <td className="table-bordered align-middle text-center">{remark?.AdminRemarksDate}</td>
                    <td className="table-bordered align-middle text-center">{remark?.AdminUser}</td>
                    <td className="table-bordered align-middle failed text-center ">
                      {remark?.AdminStatus}
                    </td>
                    <td className="table-bordered align-middle text-center noWrapText">
                      {remark?.AdminCancelReason } 
              <p>{remark?.AdminRemarks}</p></td>
                  </tr> )
              })}
             
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
else{
  return(<></>)
}
}
export default OrderNotebyAdmin;
