
import React, { useEffect, useState } from "react";
//import Breadcrumbs

import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import deliveryFile from '../../../../../assets/files/delivery.csv';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  
  Spinner,
} from "reactstrap";
import { AvForm} from "availity-reactstrap-validation";
import { CardHeader } from "semantic-ui-react";
import { fetchCheckData,deliveryImportSummary } from "../../action/action";



const ImportFileComponent = () => {
    const dispatch = useDispatch();

    const [selectedFile, setSelectedFile] = useState();
    const [errorMsg, setErrorMsg] = useState(false);
    const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleValidSubmit = (events, values) => {

        const formData = new FormData();
        if(selectedFile === undefined){
          setErrorMsg("Please select the file")
        }
        else{
        setUpdateDetailsLoad(true);
        formData.append("marketplace_file", selectedFile);
    
        fetchCheckData({
          formData,
          dispatch,
        });
      }
      };
      const { deliveryCheckdata = [], isCkeckdataLoading } = useSelector(
        (state) => state.deliveryReport
      );
      
     
      useEffect(() => {
        if (deliveryCheckdata?.code === 100) {
          setSuccessMessage(true);

          setUpdateDetailsLoad(false);
          deliveryImportSummary({
            params: {
              file_name: deliveryCheckdata?.file_name,
              user_id: '1',
            },
            dispatch,
          });
    
          
        } else {
          if (deliveryCheckdata?.message) {
            setUpdateDetailsLoad(false);
            toast.error(deliveryCheckdata?.message, {
              style: {
                border: "1px solid #fff",
                padding: "20px",
                color: "#ffffff",
                background: "#f44336",
              },
              iconTheme: {
                primary: "#FFFAEE",
                secondary: "#f44336",
              },
            });
          }
        }
      }, [deliveryCheckdata]);
      const { importSummary = [], isImportSummaryLoading } = useSelector(
        (state) => state.deliveryReport
      );
      useEffect(() => {
        if (importSummary?.code === 100) {
          setUpdateDetailsLoad(false);
          setSelectedFile(false);

          toast.success(importSummary?.message, {
            style: {
              border: "1px solid #fff",
              padding: "20px",
              color: "#ffffff",
              background: "#4CAF50",
            },
            iconTheme: {
              primary: "#FFFAEE",
              secondary: "#4CAF50",
            },
          });
         window.location.reload(false);
        } else {
          if (importSummary?.message) {
            setUpdateDetailsLoad(false);
    
            toast.error(importSummary?.message, {
              style: {
                border: "1px solid #fff",
                padding: "20px",
                color: "#ffffff",
                background: "#f44336",
              },
              iconTheme: {
                primary: "#FFFAEE",
                secondary: "#f44336",
              },
            });
          }
        }
      }, [importSummary]);

    return (
        <React.Fragment>
          <div className="page-content">
            
            <Container fluid>
              {/* Render Breadcrumbs */}
             
              <Row className="rowCenter">
                <Col lg={5}>
                  <Card>
                    <CardHeader className="headPadding">
                      <CardTitle>Upload Delivery Report</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <AvForm onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
               
                    }}
                    
                      >
                        <Row>
                        <Col>
                          <input
                            type="file"
                            name="file"
                            required
                            onChange={(e) => {
                              setSelectedFile(e.target.files[0]);
                            }}
                          />
                     
                        {setSelectedFile && (
                          <div
                           className="importError"
                          >
                            {errorMsg}
                          </div>
                        )}
                           </Col>
                        <Col className="mb-3">
                          <button
                            className="btn btn-primary"
                           
                          >
                            {updateStsLoad === true && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}{" "}
                            Submit
                          </button>
                        </Col>
                        </Row>
                      </AvForm>
                      <Row>
                      <Col lg={12} className="">
                   <a href={deliveryFile} download={deliveryFile}> Sample File To Download</a>   </Col>
                      </Row>
                    </CardBody>
                    {successMessage === true && (
                    <Row>
                      <Col lg={12} className="bg-success me-2 badge badge badge-secondary">
                      Delivery status updation will be reflected after some time</Col>
                      </Row>)}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <Toaster position="top-right" reverseOrder={false} />
        </React.Fragment>
      );
};
export default ImportFileComponent;