import React, { useEffect, useState } from 'react';
import { MetaTags } from 'react-meta-tags';
import { DashBreadcrumb } from '../../components/Common/Breadcrumb';
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  CardBody,
  CardTitle,
  CardHeader,
  Spinner,
  Collapse,
  Label,
} from 'reactstrap';
import CustomRichTextEditor from './CustomRichTextEditor';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useParams } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { storeDetailView, CCStoreDetailUpdate } from './action/action';
import classnames from 'classnames';

const ClickandCollectForm = () => {
  const history = useHistory();
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [storeStatus, setStoreStatus] = useState(false);
  const [isClickAndCollect, setIsClickAndCollect] = useState(false);
  const [showSalesBanner, setShowSalesBanner] = useState(false);
  const [bookAnAppointment, setBookAnAppointment] = useState(false);
  const [desContentEn, setDescriptionContentEn] = useState('');
  const [desContentAr, setDescriptionContentAr] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const updateArticleContentEn = (e) => {
    setDescriptionContentEn(e);
  };
  const updateArticleContentAr = (e) => {
    setDescriptionContentAr(e);
  };
  const dispatch = useDispatch();

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
  };
  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
  };

  const { id } = useParams();
  useEffect(() => {
    storeDetailView({
      params: {
        store_id: id,
      },
      dispatch,
    });
  }, []);
  const { ccStoreDetail = {}, isCCStoreDetailLoading } = useSelector(
    (state) => state.permissions
  );
  const ccStoredata = ccStoreDetail?.data?.[0];

  const updateNewStoreData = (events, values) => {
    setIsLoading(true);
    const StoreName_en = values?.stName_en
      ? values?.stName_en
      : ccStoredata?.storeName?.en;
    const StoreName_ar = values?.stName_ar
      ? values?.stName_ar
      : ccStoredata?.storeName?.ar;
    const address_en = values?.address_en
      ? values?.address_en
      : ccStoredata?.address?.en;
    const address_ar = values?.address_ar
      ? values?.address_ar
      : ccStoredata?.address?.ar;
    const shortDescription_en = values?.shortDec_en
      ? values?.shortDec_en
      : ccStoredata?.shortDescription?.en;
    const shortDescription_ar = values?.storeDec_ar
      ? values?.storeDec_ar
      : ccStoredata?.shortDescription?.ar;
    const image = values?.image ? values?.image : ccStoredata?.image;
    const region = values?.region ? values?.region : ccStoredata?.region;
    const city = values?.city ? values?.city : ccStoredata?.city;
    const longitude = values?.longitude
      ? values?.longitude
      : ccStoredata?.longitude;
    const latitude = values?.latitude
      ? values?.latitude
      : ccStoredata?.latitude;
    const sortOrder = values?.sortOrder ? values?.sortOrder : '';
    const fullfillmentDays = values?.fulfillmentDay
      ? values?.fulfillmentDay
      : ccStoredata?.fullfillmentDays;
    const storeEmailId = values?.email
      ? values?.email
      : ccStoredata?.storeEmailId;
    const telephone1 = values?.telephone1
      ? values?.telephone1
      : ccStoredata?.telephone1;
    const telephone2 = values?.telephone2
      ? values?.telephone2
      : ccStoredata?.telephone2;
    const placeID = values?.placeID ? values?.placeID : ccStoredata?.PlaceID;
    const appointmentURL = values?.appointmentURL
      ? values?.appointmentURL
      : ccStoredata?.appointmentURL;
    const storeCode = values?.storcode
      ? values?.storcode
      : ccStoredata?.storeCode;
    const storeUrlKey = values?.storeUrlKey
      ? values?.storeUrlKey
      : ccStoredata?.storeUrlKey;
    const timing_en = values?.storeTiming_en
      ? values?.storeTiming_en
      : ccStoredata?.timing?.en;
    const timing_ar = values?.storeTiming_ar
      ? values?.storeTiming_ar
      : ccStoredata?.timing?.ar;
    const metaTitle_en = values?.metaTitle_en
      ? values?.metaTitle_en
      : ccStoredata?.metaTitle?.en;
    const metaTitle_ar = values?.metaTitle_ar
      ? values?.metaTitle_ar
      : ccStoredata?.metaTitle?.ar;
    const metaDescription_en = values?.metaDescription_en
      ? values?.metaDescription_en
      : ccStoredata?.metaDescription?.en;
    const metaDescription_ar = values?.metaDescription_ar
      ? values?.metaDescription_ar
      : ccStoredata?.metaDescription?.ar;
    const data = {
      country: selectedCountry ? selectedCountry : ccStoredata?.country,
      storeName: { en: StoreName_en, ar: StoreName_ar },
      address: { en: address_en, ar: address_ar },
      shortDescription: { en: shortDescription_en, ar: shortDescription_ar },
      description: { en: desContentEn, ar: desContentAr },
      image: image,
      region: region,
      city: city,
      longitude: longitude,
      latitude: latitude,
      sortOrder: sortOrder,
      fullfillmentDays: fullfillmentDays,
      storeEmailId: storeEmailId,
      telephone1: telephone1,
      telephone2: telephone2,
      PlaceID: placeID,
      storeCode: storeCode,
      storeUrlKey: storeUrlKey,
      isClickCollect: isClickAndCollect
        ? Boolean(isClickAndCollect)
        : ccStoredata?.isClickCollect,
      isDisplayInForm: storeStatus
        ? Boolean(storeStatus)
        : ccStoredata?.isDisplayInForm,
      showSaleBanner: showSalesBanner
        ? Boolean(showSalesBanner)
        : ccStoredata?.showSaleBanner,
      bookAnAppointment: bookAnAppointment
        ? Boolean(bookAnAppointment)
        : ccStoredata?.bookAnAppointment,
      appointmentURL: appointmentURL,
      timing1: { en: timing_en, ar: timing_ar },
      metaTitle: { en: metaTitle_en, ar: metaTitle_ar },
      metaDescription: { en: metaDescription_en, ar: metaDescription_ar },
    };

    CCStoreDetailUpdate({ id, data, dispatch });
  };
  const { ccStoreUpdate = {}, isCCStoreUpdateLoading } = useSelector(
    (state) => state.permissions
  );
  console.log(ccStoreUpdate, 'ccStoreUpdate');
  useEffect(() => {
    if (ccStoreUpdate?.code === 200) {
      setIsLoading(false);
      toast.success(ccStoreUpdate?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      setTimeout(() => {
        history.push('/ClickandCollectList');
      }, 10);
    } else {
      if (ccStoreUpdate?.code === 400) {
        setIsLoading(false);
        toast.error('Create store error', {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [ccStoreUpdate]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Click & Collect </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={11}>
              <DashBreadcrumb
                title="Click & Collect Store"
                breadcrumbItem="Click & Collect"
              />
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <AvForm
                className="needs-validation"
                onValidSubmit={(e, v) => {
                  updateNewStoreData(e, v);
                }}
              >
                <CardHeader>
                  <Row>
                    <Col xl={11}>
                      <CardTitle>Danubehome - Dubai Barsha Store</CardTitle>
                    </Col>
                    <Col>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        data-toggle="modal"
                      >
                        {isLoading === true && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}{' '}
                        Submit
                      </button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="accordion" id="accordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className={classnames(
                            'accordion-button',
                            'fw-medium',

                            { collapsed: !col1 }
                          )}
                          type="button"
                          onClick={t_col1}
                          style={{ cursor: 'pointer' }}
                        >
                          General Information
                        </button>
                      </h2>

                      <Collapse isOpen={col1} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="text-muted">
                            <Row
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                              }}
                            >
                              <Col lg={9}>
                                <Card>
                                  <CardBody>
                                    <Row>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Store Name - English
                                          </Label>
                                          <AvField
                                            name="stName_en"
                                            type="text"
                                            errorMessage="Enter store Name"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={ccStoredata?.storeName?.en}
                                            id="validationCustom01"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Store Name - Arabic
                                          </Label>
                                          <AvField
                                            name="stName_ar"
                                            type="text"
                                            errorMessage="Enter store Name"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={ccStoredata?.storeName?.ar}
                                            id="validationCustom01"
                                          />
                                        </FormGroup>
                                      </Col>

                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Store Code
                                          </Label>
                                          <AvField
                                            name="storcode"
                                            placeholder="Type Something"
                                            type="text"
                                            errorMessage="Enter Store Code"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={ccStoredata?.storeCode}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Country
                                          </Label>
                                          <select
                                            className="form-control"
                                            name="website_id"
                                            id="choices-single-no-search"
                                            onChange={(e) => {
                                              setSelectedCountry(
                                                e.target.value
                                              );
                                            }}
                                            value={
                                              selectedCountry
                                                ? selectedCountry
                                                : ccStoredata?.country
                                            }
                                          >
                                            <option value="">Select</option>
                                            <option value="ae">UAE</option>
                                            <option value="om">Oman</option>
                                            <option value="bh">Bahrain</option>
                                            <option value="qa">Qatar</option>
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Email
                                          </Label>
                                          <AvField
                                            name="email"
                                            type="email"
                                            errorMessage="Enter Email"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={ccStoredata?.storeEmailId}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Telephone1
                                          </Label>
                                          <AvField
                                            name="telephone1"
                                            type="text"
                                            errorMessage="Enter Store Phone No"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={ccStoredata?.telephone1}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Telephone2
                                          </Label>
                                          <AvField
                                            name="telephone2"
                                            type="text"
                                            errorMessage="Enter Store Phone No"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={ccStoredata?.telephone2}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Latitude
                                          </Label>
                                          <AvField
                                            name="latitude"
                                            type="text"
                                            errorMessage="Please Enter the Latitude"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={ccStoredata?.latitude}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Longitude
                                          </Label>
                                          <AvField
                                            name="longitude"
                                            type="text"
                                            errorMessage="Please Enter the Longitude"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={ccStoredata?.longitude}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Place ID
                                          </Label>
                                          <AvField
                                            name="placeID"
                                            type="text"
                                            errorMessage=" Enter the Place ID"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={ccStoredata?.PlaceID}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Sort Order
                                          </Label>
                                          <AvField
                                            name="sortOrder"
                                            type="Number"
                                            value={ccStoredata?.sortOrder}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Store Status
                                          </Label>
                                          <select
                                            className="form-control"
                                            name="storeStatus"
                                            id="choices-single-no-search"
                                            onChange={(e) => {
                                              setStoreStatus(e.target.value);
                                            }}
                                            value={
                                              storeStatus
                                                ? storeStatus
                                                : ccStoredata?.isDisplayInForm
                                            }
                                          >
                                            <option value="">Select</option>
                                            <option value={true}>Active</option>
                                            <option value={false}>
                                              InActive
                                            </option>
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Fullfillment Days
                                          </Label>
                                          <AvField
                                            name="fulfillmentDay"
                                            placeholder="Type Something"
                                            type="text"
                                            errorMessage="Please write the fullfillment Days"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              ccStoredata?.fullfillmentDays
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Click & Collect
                                          </Label>
                                          <select
                                            className="form-control"
                                            name="isClickAndCollect"
                                            id="choices-single-no-search"
                                            onChange={(e) => {
                                              setIsClickAndCollect(
                                                e.target.value
                                              );
                                            }}
                                            value={
                                              isClickAndCollect
                                                ? isClickAndCollect
                                                : ccStoredata?.isClickCollect
                                            }
                                          >
                                            <option value="ae">Select</option>
                                            <option value={true}>Active</option>
                                            <option value={false}>
                                              InActive
                                            </option>
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Image
                                          </Label>
                                          <AvField
                                            name="image"
                                            placeholder="Type Something"
                                            type="text"
                                            value={ccStoredata?.image}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Region
                                          </Label>
                                          <AvField
                                            name="region"
                                            placeholder="Type Something"
                                            type="text"
                                            value={ccStoredata?.region}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            City
                                          </Label>
                                          <AvField
                                            name="city"
                                            placeholder="Type Something"
                                            type="text"
                                            value={ccStoredata?.city}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Store Url
                                          </Label>
                                          <AvField
                                            name="storeUrlKey"
                                            placeholder="Type Something"
                                            type="text"
                                            value={ccStoredata?.storeUrlKey}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Show Sales Banner
                                          </Label>
                                          <select
                                            className="form-control"
                                            name="showSalesBanner"
                                            id="choices-single-no-search"
                                            onChange={(e) => {
                                              setShowSalesBanner(
                                                e.target.value
                                              );
                                            }}
                                            value={
                                              showSalesBanner
                                                ? showSalesBanner
                                                : ccStoredata?.showSaleBanner
                                            }
                                          >
                                            <option value="">Select</option>
                                            <option value={true}>Active</option>
                                            <option value={false}>
                                              InActive
                                            </option>
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Book An Appointment
                                          </Label>
                                          <select
                                            className="form-control"
                                            name="showSalesBanner"
                                            id="choices-single-no-search"
                                            onChange={(e) => {
                                              setBookAnAppointment(
                                                e.target.value
                                              );
                                            }}
                                            value={
                                              bookAnAppointment
                                                ? bookAnAppointment
                                                : ccStoredata?.bookAnAppointment
                                            }
                                          >
                                            <option value="">Select</option>
                                            <option value={true}>Active</option>
                                            <option value={false}>
                                              InActive
                                            </option>
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Appointment URL
                                          </Label>
                                          <AvField
                                            name="appointmentURL"
                                            placeholder="Type Something"
                                            type="text"
                                            value={ccStoredata?.appointmentURL}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Meta Title(English)
                                          </Label>
                                          <AvField
                                            name="metaTitle_en"
                                            placeholder="Type Something"
                                            type="text"
                                            value={ccStoredata?.metaTitle?.en}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Meta Title(Arabic)
                                          </Label>
                                          <AvField
                                            name="metaTitle_ar"
                                            placeholder="Type Something"
                                            type="text"
                                            value={ccStoredata?.metaTitle?.ar}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Meta Description(English)
                                          </Label>
                                          <AvField
                                            name="metaDescription_en"
                                            placeholder="Type Something"
                                            type="text"
                                            value={
                                              ccStoredata?.metaDescription?.en
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Meta Description(Arabic)
                                          </Label>
                                          <AvField
                                            name="metaDescription_ar"
                                            placeholder="Type Something"
                                            type="text"
                                            value={
                                              ccStoredata?.metaDescription?.ar
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className={classnames(
                            'accordion-button',
                            'fw-medium',
                            { collapsed: !col2 }
                          )}
                          type="button"
                          onClick={t_col2}
                          style={{ cursor: 'pointer' }}
                        >
                          Aditional Information
                        </button>
                      </h2>

                      <Collapse isOpen={col2} className="accordion-collapse">
                        <div className="accordion-body">
                          <div className="text-muted">
                            <Row
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                              }}
                            >
                              <Col lg={9}>
                                <Card>
                                  <CardBody>
                                    <Row>
                                      <Col lg="6">
                                        <FormGroup className="mb-3">
                                          <label
                                            htmlFor="choices-single-no-search"
                                            className="form-label font-size-13 text-muted"
                                          >
                                            Address - English
                                          </label>
                                          <AvField
                                            name="address_en"
                                            placeholder="Type Something"
                                            type="text"
                                            errorMessage="Please write the Store Address"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={ccStoredata?.address?.en}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup className="mb-3">
                                          <label
                                            htmlFor="choices-single-no-search"
                                            className="form-label font-size-13 text-muted"
                                          >
                                            Address - Arabic
                                          </label>
                                          <AvField
                                            name="address_ar"
                                            placeholder="Type Something"
                                            type="text"
                                            value={ccStoredata?.address?.ar}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Store Timing - English
                                          </Label>
                                          <AvField
                                            name="storeTiming_en"
                                            placeholder="Type Something"
                                            type="text"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={ccStoredata?.timing1?.en}
                                          />
                                        </FormGroup>
                                      </Col>

                                      <Col lg="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Store Timing - Arabic
                                          </Label>
                                          <AvField
                                            name="storeTiming_ar"
                                            placeholder="Type Something"
                                            type="text"
                                            value={ccStoredata?.timing1?.ar}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Short Description - English
                                          </Label>
                                          <AvField
                                            name="shortDec_en"
                                            placeholder="Type Something"
                                            type="textarea"
                                            errorMessage="Write Short Description"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              ccStoredata?.shortDescription?.en
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">
                                            Short Description - Arabic
                                          </Label>

                                          <AvField
                                            name="storeDec_ar"
                                            placeholder="Type Something"
                                            type="textarea"
                                            value={
                                              ccStoredata?.shortDescription?.ar
                                            }
                                          />
                                        </FormGroup>
                                      </Col>

                                      <div className="mb-3">
                                        <label>Body* – English</label>
                                        <CustomRichTextEditor
                                          updateDescriptionContent={
                                            updateArticleContentEn
                                          }
                                          data_rtl={false}
                                          content={ccStoredata?.description?.en}
                                        />
                                      </div>

                                      <div className="mb-3">
                                        <label>Body* – Arabic</label>
                                        <CustomRichTextEditor
                                          updateDescriptionContent={
                                            updateArticleContentAr
                                          }
                                          data_rtl={false}
                                          content={ccStoredata?.description?.ar}
                                        />
                                      </div>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </CardBody>
              </AvForm>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default ClickandCollectForm;
