import axios from "axios";

import { setCallBackList, setCallbackDetails, setCallbackUpdate } from "../../../Redux/callback/callbackSlice";
import UserAuthKey from "../../../components/Common/UserAuthKey";
import { setUserData } from "../../../Redux/user/userSlice";
const apiUrl = process.env.REACT_APP_OMS_API;

export const fetchCallBackDataList = async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/callback/callback-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setCallBackList(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
};
export const CallBackDataUpdate = async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/callback/callback-update`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setCallbackUpdate(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
 
};
export const CallBackDataDetail = async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/callback/callback-details`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setCallbackDetails(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))

      window.location.replace('/logout');
   }
   else{

   }
  }
 
};
