import { useState, useEffect, button } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Notification, Placeholder } from "rsuite";
import { orderBy } from "lodash";
import React from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
} from "reactstrap";
import Places from "../../../components/maps/GoogleMaps";
import { nodeName } from "rsuite/esm/DOMHelper";
import toast, { Toaster } from "react-hot-toast";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ManageHomeLeadDetailsForm from "./ManageHomeLeadUpdateForm";

function ManageHomeLeadBlock({ orderData }) {
 
  return (
    <>
      <Row className="row-fluid-scroll">
        <Col xl={4} md={6}>
          <Card className="card-h-100 OrderDetailsBox">
            <CardHeader>
              <CardTitle>CUSTOMER DETAILS</CardTitle>
            </CardHeader>
            {orderData !== undefined ? (
              <>
            <CardBody>
              <p>
                <strong>{orderData?.name}</strong>
                <br />
                Mobile: {orderData?.mobile}
                <br />
               Email: {orderData?.email}
                <br />
                Request Date: {orderData?.created_at}
                <br />
                Visiting Date: {orderData?.visiting_date}
                <br />
                SO Number: {orderData?.so_number}
                <br />
                Current Status: {orderData?.status}
                <br />
            
              </p>
            
            </CardBody>

            </>
            ) : (<>
              <CardBody>
               <Skeleton count={8} />
               </CardBody>
                </>
              )}
              
          </Card>
        </Col>

        <Col xl={4} md={6}>
          <Card className="card-h-100 OrderDetailsBox">
            <CardHeader>
              <CardTitle>Follow Up</CardTitle>
            </CardHeader>
            
              <CardBody>
              <ManageHomeLeadDetailsForm orderData ={orderData}/>
               </CardBody>
               
          </Card>
        </Col>
     
     
      </Row>
     
    </>
  );
}
export default ManageHomeLeadBlock;
