import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  marketPlaceList: [],
  isLoading: true,
  isDetailLoading:true,
  isCkeckdataLoading:true,
  isStatusLoading:true,
  isDasboardLoading:true,
  getDashboardSummary:true

 
};

const marketPlaceSlice = createSlice({
  name: "MarketPlaceOrder",
  initialState,
  reducers: {
    setMarketPlaceOrderList(state, actions) {
      state.marketPlaceList = actions.payload;
      state.isLoading = false;
    },
    setMarketPlaceSplitOrderList(state, actions) {
      state.marketPlaceList = actions.payload;
      state.isLoading = false;
    },
    getMarketPlaceOrderDetail(state, actions) {
        state.marketPlaceDetail = actions.payload;
        state.isDetailLoading = false;
      },
    getMarketPlaceSplitOrderDetail(state, actions) {
        state.marketPlaceDetail = actions.payload;
        state.isDetailLoading = false;
      },
    getCheckData(state, actions) {
        state.marketPlaceCheckdata = actions.payload;
        state.isCkeckdataLoading = false;
      },
      getStatusCount(state, actions) {
        state.marketStatus = actions.payload;
        state.isStatusLoading = false;
      },
      getDashboardCount(state, actions) {
        state.dasboardStatus = actions.payload;
        state.isDasboardLoading = false;
      },
      getDashboardSummary(state, actions) {
        state.dasboardSummary = actions.payload;
        state.isSummaryLoading = false;
      },
      marketPlaceImportOrders(state, actions) {
        state.importSummary = actions.payload;
        state.isImportSummaryLoading = false;
      },

  },
});

 
    export const { setMarketPlaceOrderList,setMarketPlaceSplitOrderList, getMarketPlaceOrderDetail,getMarketPlaceSplitOrderDetail,getCheckData,getStatusCount,getDashboardCount,marketPlaceImportOrders, getDashboardSummary}= marketPlaceSlice.actions;

const marketplaceReducer = marketPlaceSlice.reducer;

export default marketplaceReducer;
