import axios from 'axios';
import {
  getUserRoleLists,
  getUserRoleMaster,
  getUserEdit,
  roleStatusUpdate,
  getUserView,
  getUserDelete,
  getUserMenuLists,
  CCStoreUpdate,
  getUpdateRole,
  CCStoreDetail,
  getUserRoleView,
  getAllUsers,
  createUsers,
  ClickandCOllectList,
  CCStoreInsert,
  createStore,
  getStoresList,
} from '../../../Redux/permissions/permissionsSlice';
import UserAuthKey from '../../../components/Common/UserAuthKey';
import { setUserData } from '../../../Redux/user/userSlice';
const apiUrl = process.env.REACT_APP_OMS_API;
const dhStoreLocatorUrl = process.env.REACT_APP_DH_STORElOCATOR_API;

export const fetchUserRoleLists = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/role/role-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getUserRoleLists(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const fetchUserRolemaster = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/role/role-list-master`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getUserRoleMaster(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const fetchUserView = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/user/user-view`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getUserView(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const removeUser = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/user/user-delete`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getUserDelete(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const updateRoleStatus = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/role/update-role-status`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(roleStatusUpdate(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const fetchUserMenuLists = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/role/user-menus`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getUserMenuLists(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const UpdateRoleData = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/role/role-resources`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getUpdateRole(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const UpdateRoleView = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/role/role-view`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getUserRoleView(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const allUsersList = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/user/allusers`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getAllUsers(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const CreateNewUser = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/user/user-create`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(createUsers(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const ClickandCollectListDetail = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/stores/allstoredata`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(ClickandCOllectList(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const getEditDetailList = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/user/user-edit`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getUserEdit(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const CCDataInsertaion = async ({ formData, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/stores/store-insert`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'multipart/form-data',
    },

    data: formData,
  };
  try {
    const response = await axios(config);
    dispatch(CCStoreInsert(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const storeDetailView = async ({ params, dispatch }) => {
  const config = {
    method: 'GET',
    url: `${dhStoreLocatorUrl}/stores/getstoredatabyid?_id=${params?.store_id}`,
  };
  try {
    const response = await axios(config);
    dispatch(CCStoreDetail(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const CCStoreDetailUpdate = async ({ id, data, dispatch }) => {
  const config = {
    method: 'POST',
    url: `${dhStoreLocatorUrl}/stores/${id}`,

    data: data,
  };
  console.log(config, 'Configf');
  try {
    const response = await axios(config);
    dispatch(CCStoreUpdate(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const CreateNewStore = async ({ data, dispatch }) => {
  const config = {
    method: 'POST',
    url: `${dhStoreLocatorUrl}/stores/create`,
    data, // Use data instead of params
  };

  try {
    const response = await axios(config);
    dispatch(createStore(response?.data));
  } catch (error) {
    console.error('Create store error:', error); // Add error handling here
  }
};

export const fetchStoresLists = async ({ params, dispatch }) => {
  const { country, page, limit, sortBy, sortOrder, searchQuery } = params;
  const queryParams = new URLSearchParams({
    country: country,
    page: page?.toString() || '1',
    limit: limit?.toString() || '20',
    sortBy: sortBy || '',
    sortOrder: sortOrder || 'asc',
    searchQuery: searchQuery || '',
  }).toString();

  const config = {
    method: 'POST',
    url: `${dhStoreLocatorUrl}/stores/getallstores?${queryParams}`,
    data: {
      // If there are any additional data to be sent in the body, include them here
    },
  };

  try {
    const response = await axios(config);
    dispatch(getStoresList(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
      console.error('Failed to fetch store lists', error);
    }
  }
};
