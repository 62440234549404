import { useState, useEffect, button, useSearchParams } from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../components/Common/Breadcrumb";
import { marketPlaceOrderDetail } from "./action/action";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";
import MarketplaceDetails from "./components/MarketplaceDetail";
import "react-datepicker/dist/react-datepicker.css";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import WalletStatus from "../Orders/components/WalletStatus";
import {
  Col,
  Container,
  Row,
  FormGroup,
  InputGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { Link } from "react-router-dom";

const MarketDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_mode = params.get("order_mode");
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [apiCounts, setApiCounts] = useState(0);
  useEffect(() => {
    marketPlaceOrderDetail({
      params: {
        country: "AE",
        order_id: id,
        order_mode: order_mode,
      },
      dispatch,
      
    });
  }, [apiCounts]);
  const { marketPlaceDetail = [], isDetailLoading, apiTriggerCount } = useSelector(
    (state) => state.marketPlace
  );
 
  useEffect(() => {
    setApiCounts(apiTriggerCount);
  },[apiTriggerCount]);
 
 
  const toggleRightDrawer = () => {
    setPosition("right");
    setOpen(!open);
  };
  const onDrawerClose = () => {
    setOpen(false);
  };
  const orderData = marketPlaceDetail.OrderDetails?.[0];
 
  const itemData = marketPlaceDetail.OrderDetails?.[0]?.orderitems;
  const splitDeviceTrack = orderData?.DeviceTracking?.split("||");
  
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Market Place Order View</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="market Place Orders"
                breadcrumbItem="Order View"
              />
            </Col>
            <Col xl={6}>
              <Row className="align-items-end">
                <Col xl={12} className="textAlignRigh">
                  <div className="DashbordFliter ">
                  {splitDeviceTrack !== undefined ? (
                    <Button
                      color="primary"
                      className=""
                      onClick={toggleRightDrawer}
                      disabled={open}
                    >
                      Device Tracking
                    </Button>  ) : (
            ""
          )}
                    {/* <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <button type="button" className="btn SecondaryBtn2">
                        Send Sms
                      </button>
                      <span>
                        <WalletStatus />
                      </span>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
            <Card className="card-h-100 OrderDetailsBox">
              <CardHeader>
                <CardTitle>DEVICE TRACKING</CardTitle>
              </CardHeader>
              <CardBody>
                <p>
                  {splitDeviceTrack?.map((device, deviceInx) => {
                    return (
                      <>
                        <span key={deviceInx} className="status">
                          {device}{" "}
                        </span>
                        <br />
                      </>
                    );
                  })}
                </p>
              </CardBody>
            </Card>
          </ReactDrawer>
          <MarketplaceDetails orderData={orderData} itemData={itemData} />
        </Container>
      </div>
    </>
  );
};

export default MarketDetail;
