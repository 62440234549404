import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  callbackList: [],
  isLoading: true,
  isDesignLoading: true,
  isFormLeadLoading: true,
  isProjectDetailLoading: true,
  isbccwLoading:true
 
 
};

const bccwSlice = createSlice({
  name: "BCCW",
  initialState,
  reducers: {
    setBccwList(state, actions) {
      state.bccwList = actions.payload;
      state.isLoading = false;
    },
    setBccwDetail(state, actions) {
        state.bccwDetail = actions.payload;
        state.isbccwLoading = false;
      },
    setDesignHomeList(state, actions) {
        state.designHomeList = actions.payload;
        state.isDesignLoading = false;
      },
    setDesignHomeDetail(state, actions) {
        state.designHomeDetail= actions.payload;
        state.isHomeDesignLoading = false;
      },
    setDesignHomeFormUpdate(state, actions) {
        state.designHomeUpdate= actions.payload;
        state.isUpdateLoading = false;
      },
      getFormLeadData(state, actions) {
        state.formLeadUpdate= actions.payload;
        state.isFormLeadLoading = false;
      },
      getProjecttrackList(state, actions) {
        state.projectList= actions.payload;
        state.isProjectLoading = false;
      },
      getProjecttrackDetail(state, actions) {
        state.projectDetail= actions.payload;
        state.isProjectDetailLoading = false;
      },
      getProjecttrackUpdate(state, actions) {
        state.projectUpdate= actions.payload;
        state.isProjectUpdateLoading = false;
      },
   
   
   
   
   

  },
});

export const {setBccwList, setBccwDetail, setDesignHomeList, setDesignHomeDetail, setDesignHomeFormUpdate, getFormLeadData, getProjecttrackList,getProjecttrackDetail,getProjecttrackUpdate}= bccwSlice.actions;

const bccwReducer = bccwSlice.reducer;

export default bccwReducer;
