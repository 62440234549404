import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useUrlSearchParams } from "use-url-search-params";
import { Card, Button, OverlayTrigger, Form, Tooltip } from "react-bootstrap";
import { Badge } from "reactstrap";
import CsLineIcons from "../../cs-line-icons/CsLineIcons";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Pagination from "@vlsergey/react-bootstrap-pagination";
// import { fetchMarketPlaceOrders } from "../action/action"; 
import { SORTING_ORDER, OMS_URL } from "../../constants";
import moment from "moment";

import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  NavLink,
  Nav,
  NavItem,
} from "reactstrap";
import CountUp from "react-countup";

const ReportList = () => {
  const location = useLocation();

  const [btnprimary1, setBtnprimary1] = useState(false);

  const dispatch = useDispatch();
  const [sortingName, setSortingName] = useState({ id: "sort.date_asc" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: "_date",
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
  const [customActiveTab, setcustomActiveTab] = useState("All");
  const [orderMode, setOrderMode] = useState("All");
  
  const { country = [] } = useSelector((state) => state.country);

//   useEffect(() => {
//     setDataLoading(true);
//     fetchMarketPlaceOrders({
//       params: {
//         country: country,
//         start: Number(paginationValue.page * limitValue.limit),
//         limit: Number(limitValue.limit),
//         sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
//         search_qry: searchValue.search,
//         status: customActiveTab,
//         is_split:0,
        
   
//       },
//       dispatch,
//     });
  
//     setDataLoading(false);
//   }, [
//     paginationValue.page,
//     limitValue,
//     sortingValue.sort,
//     searchValue.search,
//     customActiveTab,
//     country,
//     orderMode,
//   ]);

  const limitByValues = [5, 10, 20, 50];
  const { marketPlaceList = [], isLoading } = useSelector((state) => state.marketPlace);
 // console.log(marketPlaceList ,"marketPlaceList ")
  const { statusCounts = [], isStsCntsLoading } = useSelector(
    (state) => state.order
  );

  const [isDataLoading, setDataLoading] = useState(false);

  const { listOrders } = marketPlaceList;
  const orderData = listOrders?.orderList;

  let LsSts = "New";
  const [allCnts, setallCnts] = useState(0);
  const [notFlowntolsCnts, setNotFlowntolsCnts] = useState(0);
  const [pendingCnts, setpendingCnts] = useState(0);
  const [dmsNotFlownCnts, setdmsNotFlownCnts] = useState(0);
  const [missingLatCnts, setmissingLatCnts] = useState(0);
  const [postedCnts, setpostedCnts] = useState(0);
  const [deliveredCnts, setdeliveredCnts] = useState(0);

  const stsCounts = statusCounts?.data;

  var statusArr = [];
  // const countsData = { ...statusArr };
  let getValue = (statusArr, StsKey) => {
    let returnSts = 0;
    statusArr.forEach((o) => {
      if (o[StsKey] !== undefined) {
        returnSts = o[StsKey];
      }
    });
    return returnSts;
  };

  useEffect(() => {
    statusArr = [];
    let allCounts = 0;

    if (stsCounts) {
      Object.keys(stsCounts).forEach(function (key) {
        let Sts = stsCounts[key]?.ls_status.replace(/\s+/g, "");
        let Counts = stsCounts[key].counts;
        allCounts += Number(stsCounts[key].counts);
        const countsArr = { [Sts]: Counts };
        statusArr.push(countsArr);
      });
      statusArr.push({ allCounts: allCounts });
    }
    setallCnts(getValue(statusArr, "allCounts"));
    setNotFlowntolsCnts(getValue(statusArr, "NotFlowntoLS"));
    setpendingCnts(getValue(statusArr, "Pending"));
    setdmsNotFlownCnts(getValue(statusArr, "dmsNotFlown"));
    setmissingLatCnts(getValue(statusArr, "missingLat"));

    setpostedCnts(getValue(statusArr, "Posted"));
    setdeliveredCnts(getValue(statusArr, "Delivered"));
  
    
  }, [stsCounts]);

  const sortByValues = [
    { key: "date", order: SORTING_ORDER.ASC, label: { id: "sort.date_asc" } },
    { key: "date", order: SORTING_ORDER.DESC, label: { id: "sort.date_desc" } },
    {
      key: "status",
      order: SORTING_ORDER.ASC,
      label: { id: "sort.status_asc" },
    },
    {
      key: "status",
      order: SORTING_ORDER.DESC,
      label: { id: "sort.status_desc" },
    },
  ];

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  let pageIndex = Number(listOrders?.start);

  return (
    <>
      <Row className="align-items-end">
        <Col xl={2} md={6} className="mb-3 textAlignRight">
          <div className="searchBox">
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 bg-foreground">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <span className="search-magnifier-icon searchIcon">
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </div>
        </Col>
      </Row>

      <div className="table-responsive dt-table">
        {isLoading || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {orderData?.length > 0 ? (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th>Image</th>
                  <th>Sku</th>
                  <th>Name</th>
                  <th>Qty Ordered</th>
                  <th>Avg Price</th>
                  <th>Total Revenue</th>
                  <th>Grand Total</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {(orderData || []).map((order, index) => {
                pageIndex++;
               
                let cdate = order.created_at,
                  orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                  orderTime = moment(new Date(cdate)).format("h:mm:ss a");

                let stsBtn = "bg-lightgrey";
                const ls = order.status;

                if (order.status === "Open") {
                  stsBtn = "bg-dark";
                  LsSts = "Pending";
                } else {
                  LsSts = order.status;
                }
                if (order.status === "Picked") {
                  stsBtn = "bg-warning";
                  LsSts = "Picked";
                } else {
                  LsSts = order.status;
                }

                if (order.ls_status === "0") {
                  stsBtn = "bg-lightgrey";
                  LsSts = "New";
                } else {
                  LsSts = order.status;
                }
                if (
                  order.status === "Posted" ||
                  order.status === "Confirmed"
                ) {
                  stsBtn = "bg-primary";
                } else if (
                  order.status === "Cancel" ||
                  order.status === "cancel" ||
                  order.status === "pending_payment"
                ) {
                  stsBtn = "bg-danger";
                } else if (
                  order.status === "Delivered" ||
                  order.status === "Completed"
                ) {
                  stsBtn = "bg-success";
                }

                if (
                  order.status === "canceled" ||
                  order.status === "Canceled" ||
                  order.status === "cancel"
                ) {
                  LsSts = "Canceled";
                  stsBtn = "bg-danger";
                }
                if (typeof ls === "string" && ls.length === 0) {
                  stsBtn = "bg-dark";
                  LsSts = "Pending";
                }
            

                return (
                  <tbody key={order.entity_id}>
                    <tr>
                      <th scope="row">{pageIndex}</th>
                      <td>
                        {orderDt} <br></br>
                        {orderTime}
                      </td>
                      <td>
                        {order.reference_no}
                      </td>
                      <td> <img
                              alt=""
                              width="120"
                              height="120"
                              className="img-thumbnail"
                              src={OMS_URL.BASE_URL+'uploads/logo/'+order.marketplace_id+'.png'}
                            /></td>
                      <td>{order.shipping_method}</td>
                      <td>{order.full_name}</td>
                      <td>{order.region}</td>
                      <td>{order.grand_total}</td>
                      <td>
                        <Badge
                          className={` ${stsBtn} me-2`}
                        >
                          {LsSts}
                        </Badge>
                      </td>
                      <td>
                        <a 
                         href={`marketplaceorders/${order?.entity_id}`}
                          type="button"
                          className="btn btn-sm btn-outline-warning waves-effect waves-light"
                        >
                          {" "}
                          <i className="mdi mdi-eye-outline"></i>{" "}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>

            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                <th>SI.No</th>
                  <th>Date</th>
                  <th>Reference No</th>
                  <th>Vendor</th>
                  <th>Shipment Type</th>
                  <th>Name</th>
                  <th>Region</th>
                  <th>Grand Total</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
};

export default ReportList ;