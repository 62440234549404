import React, { useEffect, useState } from 'react';
import "../../Orders/components/OrderShipmentProgress.scss";

const StatusBarReturnCustomer = ({ detail, orderValue }) => {
  let itemMode = 1;
  if (orderValue.reference_no && orderValue.reference_no.startsWith("BB-")) {
    itemMode = 2;
  }

  const refundStatuses = ["0", "11", "16", "17", "14","19", "20", "13", "15","24",  "2", "5", "6", "1"];
  const walletStatuses = ["0", "11", "16", "17", "19","14", "20", "13", "15","24",  "2", "9", "10", "1"];
  const cashStatuses = ["0", "11", "16", "19", "17", "14","20", "13", "15", "24", "1"];
  const exchangeStatuses = ["0", "11", "16", "17", "19", "13", "15", "3", "21", "2", "1"];

  const getAllowedStatuses = (resolutionType) => {
    let statuses = [];
    switch (resolutionType) {
      case 'refund':
        statuses = [...refundStatuses];
        break;
      case 'wallet':
        statuses = [...walletStatuses];
        break;
      case 'cashondelivery':
        statuses = [...cashStatuses];
        break;
      case 'exchange':
        statuses = [...exchangeStatuses];
        break;
      default:
        statuses = [];
    }

    if (itemMode === 2 && (resolutionType === 'refund' || resolutionType === 'wallet')) {
      const index = statuses.indexOf("0");
      if (index !== -1) {
        statuses.splice(index + 1, 0, "7", "8", "17"); // Insert "7" and "8" right after "0"
      }
    }
    return statuses;
  };

  const allowedStatus = getAllowedStatuses(orderValue.resolution_type);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    const statusList = detail.map((dt) => dt.status);
    setStatuses(statusList);
  }, [detail]);

  // Filter out specific statuses if "20" is present
  const filteredAllowedStatus = allowedStatus.filter(status => {
    if (statuses.includes("14")) {
      return ["0", "14"].includes(status);
    }
    if (statuses.includes("20") && ["13","14", "15", "5", "6", "9", "10","3","2","5","6","21","1"].includes(status)) {
      return false;
    }
    if(statuses.includes("20")) {
      return true;
    }else{
      return status !== "20" && status !== "24" && status !== "14";
    }
  });

  return (
    <div className="trackStatus">
      {filteredAllowedStatus.map((status, index) => {
        const isActive = statuses.includes(String(status));
        const createdDate = detail.find(dt => dt.status === status)?.created_at;
        const returnDate = detail.find(dt => dt.status === status)?.date;
        return (
          <div className="trackView" key={status}>
            <div className="trackingIcon">
              <span className={isActive ? "Active" : ""}>
                {isActive ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.004" height="12.549" viewBox="0 0 15.004 12.549">
                    <path
                      id="Path_663"
                      data-name="Path 663"
                      d="M13.639.75,4.433,11.491.75,7.195"
                      transform="translate(0.308 0.308)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.004" height="12.549" viewBox="0 0 15.004 12.549">
                    <rect width="15.004" height="12.549" fill="none" />
                  </svg>
                )}
              </span>
            </div>
            <div className="trackingStatus">
              <p>
                <strong>
                  {status === "0" && 'Received Return Request'}
                  {status === "1" && 'Completed'}
                  {status === "2" && 'Return LS Approval'}
                  {status === "3" && 'Waiting for LS Cancel'}
                  {status === "21" && 'LS API Triggered'}
                  {status === "7" && 'Push to Seller'}
                  {status === "8" && 'Seller API Triggered'}
                  {status === "11" && 'Return Authorized'}
                  {status === "16" && 'Return Initiated'}
                  {status === "17" && 'Return Scheduled'}
                  {status === "19" && 'Return out for delivery'}
                  {status === "20" && 'Return out for delivery Failed'}
                  {status === "13" && 'Return Received'}
                  {status === "15" && 'Return Approved'}
                  {status === "14" && 'Return Rejected'}
                  {status === "24" && 'Return Canceled'}
                  {status === "5" && orderValue.resolution_type === 'refund' && 'Refund Initiated'}
                  {status === "6" && orderValue.resolution_type === 'refund' && 'Refund Completed'}
                  {status === "9" && orderValue.resolution_type === 'wallet' && 'Wallet Initiated'}
                  {status === "10" && orderValue.resolution_type === 'wallet' && 'Wallet Completed'}
                </strong>
              </p>
              {isActive && (
                <span>
                  <em>Created on: {createdDate}</em>
                  {status === "17" && (<p><em>Return Scheduled on: {returnDate}</em></p>)}
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StatusBarReturnCustomer;
