import React from "react"
import ReactApexChart from "react-apexcharts"

const Apaexlinecolumn = ({Apaexline}) => {

  const series = [
    {
      name: "Total Order",
      data:  Apaexline?.no_orders,
    },
    {
      name: "B2B Orders",
      data: Apaexline?.b2b_no_orders,
    },
    {
      name: "Click & Collect",
      data:  Apaexline?.click_order_no,
    },
    {
      name: "Payment Failure",
      data: Apaexline?.failure_order_no,
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#556ee6", "#34c38f","#f1be65","#f47f7f","#800080"],
    xaxis: {
      type: "date",
      categories: Apaexline?.dates
    },
    yaxis: {
      title: {
        text: "AED",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
       x: {
            format: "dd-MM-yy",
          },
      },
    },
  }

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  )
}

export default Apaexlinecolumn
