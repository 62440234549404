import React from "react";
import { Table, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function MarketPlaceInvoice({ orderData }) {
  return (
    <>
      <Card className="InvoiceBreakUp">
        <CardHeader>
          <CardTitle>INVOICE BREAK UP</CardTitle>
        </CardHeader>
        {orderData !== undefined ? (
          <>
        <CardBody>
          <div className="table-responsive">
            <Table className="table mb-0">
              <tbody>
                <tr>
                  <td>Sub Total :</td>
                  <td>
                    {orderData?.Currency} {orderData?.SubTotal}
                  </td>
                </tr>
                <tr>
                  <td>Shipping Fee :</td>
                  <td>
                    {orderData?.Currency} {orderData?.ShippingCharge}
                  </td>
                </tr>
                <tr>
                  <td>COD Fee :</td>
                  <td>
                    {orderData?.Currency} {orderData?.COD_charges}
                  </td>
                </tr>
                <tr>
                  <td>Discount :</td>
                  <td>
                    -({orderData?.Currency} {orderData?.DiscountTotal})
                  </td>
                </tr>
                <tr>
                  <td>Discount Code :</td>
                  <td> {orderData?.CouponCode}</td>
                </tr>
                <tr>
                  <td>Discount Desc :</td>
                  <td>{orderData?.CouponRuleDescription}</td>
                </tr>
                <tr>
                  <th className="Border">Grand Total :</th>
                  <th className="Border">AED {orderData?.OrderTotal}</th>
                </tr>
              </tbody>
            </Table>
          </div>
        </CardBody>
        </>
          ) : (<>
            <CardBody>
             <Skeleton count={6} />
             </CardBody>
              </>
            )}
      </Card>
    </>
  );
}
export default MarketPlaceInvoice;
