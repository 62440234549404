import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useUrlSearchParams } from "use-url-search-params";
import { Card, Form } from "react-bootstrap";
import { Badge } from "reactstrap";
import classnames from "classnames";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import { fetchMarketPlaceOrders } from "../action/action";
import { SORTING_ORDER, OMS_URL } from "../../../constants/index";
import ACE from "../../../assets/images/ACE_logo.png";
import moment from "moment";

import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  NavLink,
  Nav,
  NavItem,
} from "reactstrap";
const MarketPlaceOrderList = () => {
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [ActiveFilter, setActiveFilter] = useState(false);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState();
  const [isShipmentOrVendorUpdated, setIsShipmentOrVendorUpdated] =
    useState(true);
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: "_date",
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
  const [statusValue, setStatusValue] = useUrlSearchParams({
    activeTab: "All",
  });
  const [isApiCall, setApiCall] = useState(0);
  let currentCountry = localStorage.getItem("WEBSITE_COUNTRY");
  useEffect(() => {
    setDataLoading(true);

    if (isApiCall === 0) {
      fetchMarketPlaceOrders({
        params: {
          country: currentCountry,
          start: Number(paginationValue.page * limitValue.limit),
          limit: Number(limitValue.limit),
          sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
          search_qry: searchValue?.search,
          status: statusValue.activeTab,
          is_split: 0,
          marketId: selectedVendor?.value,
          shippingType: selectedShipment?.value,
        },
        dispatch,
      });
      setTimeout(() => setApiCall(0), 8000);
    }
    if (isShipmentOrVendorUpdated === true) {
      setIsShipmentOrVendorUpdated(false);
    }

    setDataLoading(false);
  }, [
    paginationValue.page,
    limitValue,
    sortingValue.sort,
    searchValue.search,
    statusValue.activeTab,
    isShipmentOrVendorUpdated,
  ]);

  const limitByValues = [5, 10, 20, 50];
  const { marketPlaceList = [], isLoading } = useSelector(
    (state) => state.marketPlace
  );
  const { statusCounts = [], isStsCntsLoading } = useSelector(
    (state) => state.order
  );

  const [isDataLoading, setDataLoading] = useState(false);

  const { listOrders } = marketPlaceList;
  const orderData = listOrders?.orderList;
  const onDrawerClose = () => {
    setOpen(false);
  };
  let LsSts = "New";

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };

  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };

  const toggleCustom = (tab) => {
    setStatusValue({ activeTab: tab });
  };

  let pageIndex = Number(listOrders?.start);
  const AdvanceFilterOnClick = (filterStatus) => {
    if (filterStatus === false) {
      setActiveFilter(true);
      setPosition("right");
      setOpen(!open);
    } else {
      setActiveFilter(false);
    }
  };

  const optionGroup = [
    {
      label: "Vendor",
      options: [
        { label: "All", value: "All" },
        { label: "Amazon", value: "Amazon" },
        { label: "Noon", value: "Noon" },
        { label: "Dubai Store ", value: "Dubaistore" },
        { label: "Ace ", value: "Ace" },
        { label: "Flitit", value: "Flitit" },
      ],
    },
  ];
  const optionGroup1 = [
    {
      label: "Shipment Type",
      options: [
        { label: "Amazon Easy Ship", value: "Amazon Easy Ship" },
        { label: "Amazon Express Ship", value: "Amazon Express Ship" },
        { label: "Amazon Retail Easy Ship", value: "Amazon Retail Easy Ship" },
        { label: "Noon Easy Ship", value: "Noon Easy Ship" },
        { label: "Carrefour Easy Ship", value: "Carrefour Easy Ship" },
        { label: "Amazon Self Ship", value: "Amazon Self Ship" },

        { label: "Carrefour Easy Ship", value: "Carrefour Easy Ship" },

        { label: "Noon Self Ship", value: "Noon Self Ship" },

        { label: "Dubaistore Self Ship", value: "Dubaistore Self Ship" },
        { label: "Flitit Self Ship", value: "Flitit Self Ship" },
        { label: "Ace Self Ship", value: "Ace Self Ship" },
      ],
    },
  ];
  const handleSelectVendor = (selectedGroup) => {
    setSelectedVendor(selectedGroup);
  };

  const handleSelectShipment = (selectedGroup) => {
    setSelectedShipment(selectedGroup);
  };

  const AdvanceFilterClear = (filterStatus) => {
    setActiveFilter(false);
    setSearchValue({ search: "" });
    setSelectedVendor(null)
    setSelectedShipment(null)
  };

  return (
    <>
      <Row className="justify-content-between align-items-center dh-TabRow">
        <Col md={7} sm={6} xs={12} className=" overflow-y-hidden">
          <div className="align-items-center">
            <Nav
              tabs
              className="nav-tabs-custom card-header-tabs  flex-nowrap scrollNav dh-custom-tab"
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: statusValue.activeTab === "All",
                  })}
                  onClick={() => {
                    toggleCustom("All");
                  }}
                >
                  <span className="d-sm-block"> ALL</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: statusValue.activeTab === "Open",
                  })}
                  onClick={() => {
                    toggleCustom("Open");
                  }}
                >
                  <span className="d-sm-block">NEW</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: statusValue.activeTab === "Confirmed",
                  })}
                  onClick={() => {
                    toggleCustom("Confirmed");
                  }}
                >
                  <span className="d-sm-block"> CONFIRMED</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: statusValue.activeTab === "posted",
                  })}
                  onClick={() => {
                    toggleCustom("posted");
                  }}
                >
                  <span className=" d-sm-block">POSTED</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: statusValue.activeTab === "Picked",
                  })}
                  onClick={() => {
                    toggleCustom("Picked");
                  }}
                >
                  <span className="d-sm-block">SHIPPED</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: statusValue.activeTab === "delivered",
                  })}
                  onClick={() => {
                    toggleCustom("delivered");
                  }}
                >
                  <span className=" d-sm-block"> DELIVERED</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: statusValue.activeTab === "cancel",
                  })}
                  onClick={() => {
                    toggleCustom("cancel");
                  }}
                >
                  <span className="d-sm-block"> CANCELED</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: statusValue.activeTab === "DmsNotFlow",
                  })}
                  onClick={() => {
                    toggleCustom("DmsNotFlow");
                  }}
                >
                  <span className=" d-sm-block NavWhitespace">
                    Dms Not Flow
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="MobileView">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>

          <a
            onClick={() => {
              AdvanceFilterOnClick(ActiveFilter);
            }}
            className="filterTxt"
          >
            Filter
            {ActiveFilter === true ? (
              <i className="bx bx-filter-alt"></i>
            ) : (
              <i className="bx bx-filter-alt"></i>
            )}
          </a>
        </Col>
      </Row>

      <ReactDrawer
        open={open}
        position={position}
        onClose={onDrawerClose}
        className="sideNavWidth"
      >
        <Card
          className="card-h-100 OrderDetailsBox"
          style={{ overflowY: "scroll" }}
        >
          <CardHeader>
            <Row className="mb-3">
              <Col>
                <CardTitle>Advance Filter </CardTitle>
              </Col>
              <Col className="textAlignRight">
                        <button
                          type="button"
                          className="btn-close "
                          onClick={onDrawerClose}
                          aria-label="Close"
                        ></button>
                      </Col>
            </Row>
          </CardHeader>
          <CardBody>
           
              <>
                {" "}
                <Row>
                  <Col>
                    <div className="mb-3">
                      <label
                        htmlFor="choices-single-default"
                        className="form-label font-size-13 text-muted"
                      >
                        Vendor
                      </label>
                      <Select
                        value={selectedVendor}
                        onChange={handleSelectVendor}
                        options={optionGroup}
                        defaultValue={selectedVendor}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  {}
                  <Col>
                    <div className="mb-3">
                      <label
                        htmlFor="choices-single-default"
                        className="form-label font-size-13 text-muted"
                      >
                        Shipment Type
                      </label>
                      <Select
                        value={selectedShipment}
                        onChange={handleSelectShipment}
                        options={optionGroup1}
                        defaultValue={selectedShipment}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                </Row>
              
              <Row>
                <Col >
                <button
                  className="btn btn-success marginRiight"
                  onClick={() => {
                    setIsShipmentOrVendorUpdated(true);
                    onDrawerClose();
                  }}
                >
                  Apply
                </button>
             
                <button
                  type="button"
                  className="btn btn-outline-info
                    waves-effect waves-light "
                  onClick={() => {
                    AdvanceFilterClear(ActiveFilter);
                
                  }}
                >
                  Clear Filter{" "}
                </button>
                </Col>
           </Row>
              </>
          
          </CardBody>
        </Card>
      </ReactDrawer>

      <div className="table-responsive dt-table">
        {isLoading || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {orderData?.length > 0 ? (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>

                  <th>Reference No</th>
                  <th>Vendor</th>
                  <th>Shipment Type</th>
                  <th>Name</th>

                  <th>Grand Total</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {(orderData || []).map((order, index) => {
                pageIndex++;

                let cdate = order.created_at,
                  orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                  orderTime = moment(new Date(cdate)).format("h:mm:ss a");

                let stsBtn = "bg-lightgrey";
                const ls = order.status;

                if (order.status === "open" ) {
                  stsBtn = "bg-dark";
                  LsSts = "Pending";
                } else {
                  LsSts = order.status;
                }
                if (order.status === "picked") {
                  stsBtn = "bg-warning";
                  LsSts = "Picked";
                } else {
                  LsSts = order.status;
                }
                if (order.status === "pending") {
                  stsBtn = "bg-dark";
                  LsSts = "Pending";
                } else {
                  LsSts = order.status;
                }

                if (order.ls_status === "0") {
                  stsBtn = "bg-lightgrey";
                  LsSts = "New";
                } else {
                  LsSts = order.status;
                }
                if (order.status === "posted" ) {
                  LsSts = "Posted";
                  stsBtn = "bg-primary";
                } 
                if (order.status === "confirmed" ) {
                  LsSts = "Confirmed";
                  stsBtn = "bg-primary";
                }
                
                
                else if (
                  order.status === "Cancel" ||
                  order.status === "cancel" ||
                  order.status === "pending_payment"
                ) {
                  LsSts = "Cancel";
                  stsBtn = "bg-danger";

                } else if (
                  order.status === "delivered" ||
                  order.status === "completed" 
                 
                ) {
                  LsSts = "Delivered";
                  stsBtn = "bg-success";
                }
                else if( order.status === "delivery_scheduled" )
                {
                  LsSts = "Delivered Scheduled";
                  stsBtn = "bg-success";
                }
                else if( order.status === "rescheduled" )
                {
                  LsSts = "Rescheduled";
                  stsBtn = "bg-success";
                }
                else if(order.status === "material n/a"){
                  LsSts = "Material N/A";
                  stsBtn = "bg-warning";
                }
                

                if (
                  order.status === "canceled" ||
                  order.status === "Canceled" ||
                  order.status === "cancel"
                ) {
                  LsSts = "Canceled";
                  stsBtn = "bg-danger";
                }
                if (typeof ls === "string" && ls.length === 0) {
                  stsBtn = "bg-dark";
                  LsSts = "Pending";
                }

                return (
                  <tbody key={order.entity_id}>
                    <tr>
                      <th scope="row">{pageIndex}</th>
                   
                      <td nowrap="nowrap">
                        <strong>{order.reference_no}</strong>
                        <span className="highlightInfo2 ">
                          {orderDt} {orderTime}
                        </span>
                      </td>
                      <td>
                        {order.marketplace_id === "Ace" ? (
                          <img
                            alt=""
                            width="129"
                            height="41"
                            className="img-thumbnail"
                            src={ACE}
                          />
                        ) : (
                          <img
                            alt=""
                            width="120"
                            height="120"
                            className="img-thumbnail"
                            src={
                              OMS_URL.BASE_URL +
                              "uploads/logo/" +
                              order.marketplace_id +
                              ".png"
                            }
                          />
                        )}{" "}
                      </td>
                      <td>{order.shipping_method}</td>
                      <td>
                        {order.full_name}

                        <span className="noTxtWrap" title={order?.region}>
                          {order?.country}-{order?.region}
                        </span>
                      </td>
                     
                      <td>{order.grand_total}</td>
                      <td>
                        <Badge className={` ${stsBtn} me-2`}>{LsSts}</Badge>
                      </td>
                      <td>
                        <a
                          href={`marketplaceorders/${order?.entity_id}`}
                          type="button"
                          className="btn btn-sm btn-outline-warning waves-effect waves-light"
                        >
                          {" "}
                          <i className="mdi mdi-eye-outline"></i>{" "}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>

            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th>Reference No</th>
                  <th>Vendor</th>
                  <th>Shipment Type</th>
                  <th>Name</th>
                  <th>Grand Total</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
};

export default MarketPlaceOrderList;
