import Carousel from 'react-bootstrap/Carousel';



import React, { useState } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
  const Slideshow = (imageArray) => {

  const [open, setOpen] = useState(false);
   const [photoIndex, setphotoIndex] = useState(0);
   
      return (
        <>
        <div  className="imageDiv">
      <img src={imageArray?.imageArray?.[0]}  width="100" 
          height="100"
          className="img-thumbnail1" onClick={() => setOpen(true)}/>
        
      </div>
      {open && (
      
      <Lightbox
		  mainSrc={imageArray?.imageArray?.[photoIndex]}
            nextSrc={imageArray?.imageArray?.[(photoIndex + 1) % imageArray?.imageArray?.length]}
            prevSrc={imageArray?.imageArray?.[(photoIndex + imageArray?.imageArray?.length - 1) % imageArray?.imageArray?.length]}
            onCloseRequest={() => setOpen(false)}
            onMovePrevRequest={() =>
              setphotoIndex(
                (photoIndex + imageArray?.imageArray?.length - 1) % imageArray?.imageArray?.length
              )
            }
            onMoveNextRequest={() =>
              setphotoIndex((photoIndex + 1) % imageArray?.imageArray?.length
              )
            }
     
			/>
      )}
      </>
      )
  }
  export default Slideshow;