import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

const Error403 = () => {
    return (
        <React.Fragment>
            <div className="my-5 pt-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mb-5">
                                <h1 className="display-1 fw-semibold">
                                <i className='mdi mdi-lock-open-alert' style={{color:"#ef5048"}}></i>
                                </h1>
                                <h1 className="">Access Denied</h1>
                                <p>You don't have permissions to access this page. Please contact OMS Support team for more details.</p>
                                <div className="mt-5 text-center">
                                    <Link className="btn btn-primary" to="/dashboard">Back to Dashboard</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Error403;