import React from "react";
import { MetaTags } from "react-meta-tags";
import {
    DashBreadcrumb
  } from "../../components/Common/Breadcrumb";
import ReviewList from "./ReviewList";
import DeliveryFeedbackExportReport from "./DeliveryFeedbackExport";
import {
    Col,
    Container,
    Row
  } from "reactstrap";

const Reviews = () => {

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mange Orders</title>
        </MetaTags>
        
        <Container fluid>
        <Row>
            <Col>
              <DashBreadcrumb
                title="Reviews"
                breadcrumbItem=" Delivery Reviewss"
              />
            </Col>
            <Col className="productExport mb-2">
            <DeliveryFeedbackExportReport />
            </Col>
            </Row>
      
            <ReviewList  />
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Reviews;
