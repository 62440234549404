import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartDetails: {},
  setShowFreebieGiftPopup: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartDetails(state, action) {
      state.cartDetails = action.payload;
    },
    // setShowFreebieGiftPopup(state, action) {
    //   state.setShowFreebieGiftPopup = action?.payload;
    // },
  },
});

export const { setCartDetails/*, setShowFreebieGiftPopup*/ } = cartSlice.actions;
const cartReducer = cartSlice.reducer;

export default cartReducer;
