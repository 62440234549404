
import expresslogo from '../../../../assets/images/xpress-shipping.gif';

const ExpressDelivery = (props) => {
    //debugger;
    let {shippingAmt, width} = props;
    shippingAmt=parseInt(shippingAmt,10);
    if(shippingAmt > 0){
        return (
        <span><img src={expresslogo} style={{width:width}} alt="Express Delivery"></img></span>
      )
    }
    else{
        return null;
    }
};

export default ExpressDelivery;