import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  callbackList: [],
  isLoading: true,
 
 
};

const callbackSlice = createSlice({
  name: "CallBack",
  initialState,
  reducers: {
    setCallBackList(state, actions) {
      state.callbackList = actions.payload;
      state.isLoading = false;
    },
    setCallbackUpdate(state, actions) {
      state.callbackUpdate = actions.payload;
      state.isUpdateLoading = false;
    },
    setCallbackDetails(state, actions) {
      state.callbackDetail = actions.payload;
      state.isDetailLoading = false;
    },
   

  },
});

export const { setCallBackList, setCallbackUpdate, setCallbackDetails }= callbackSlice.actions;

const callbackReducer = callbackSlice.reducer;

export default callbackReducer;
