import axios from "axios";
import { setQaList } from "../../../../Redux/order/orderSlice";
import UserAuthKey from "../../../../components/Common/UserAuthKey";

const apiUrl = process.env.REACT_APP_OMS_API;
const bearerToken = UserAuthKey();
export const fetchQaList = async({ params, dispatch }) => {
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/orders/list-qa`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  
  try {
    const response = await axios(config);
    dispatch(setQaList(response?.data));
  } catch (error) {

  }
};









