import React, { useEffect,useState } from "react";
import './StoreSelection.scss' 
import { useDispatch, useSelector } from "react-redux"; 
import "react-drawer/lib/react-drawer.css";
import { useUrlSearchParams } from "use-url-search-params";
import { fetchApprovalList ,updateOrderStatus} from "../../action/action";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import { Form ,FormGroup,Modal,Button} from "react-bootstrap";
import { AvForm, AvField  } from "availity-reactstrap-validation";


import {Card,
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner
} from "reactstrap";
import toast, { Toaster } from "react-hot-toast";

const ListComponent = () => {
  const [open, setOpen] = useState(false);
  const [orderValue, setorderValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [orderno, setOrderNo] = useState(false);
  const [country, setCountry] = useState(false);

  const [cancelno, setCancelNo] = useState(false);
  const [amount, setAmount] = useState(false);
  const [indexno, setIndexNo] = useState(false);

  const [buttonTexts, setButtonTexts] = useState(Array(6).fill('Approve'));
  const handleButtonClick = (index) => {
    const newButtonTexts = [...buttonTexts]; // Copy current state
    newButtonTexts[index] = 'Approving'; // Change text at clicked index
    setButtonTexts(newButtonTexts); // Update state
  };
  const handleCancelClick = (index) => {
    const newButtonTexts = [...buttonTexts]; // Copy current state
    newButtonTexts[index] = 'Approve'; // Change text at clicked index
    setButtonTexts(newButtonTexts); // Update state
  };

  const refundAction=(data)=>{
    console.log(data);
        updateOrderStatus({
        params: {
          reference_no: data.reference_no,
          country: data.country, 
          cancel_no: data.cancel_no,
          refund_reason: 'canceled',
          amount: data.refund_amount,
          loginuser: "1",
          refund_remark: "canceled",
          payment_mode: "payfort",
          refund_type: "partially_refunded", 
          
        },
        dispatch,
      }); 
 
  }


 
  
    useEffect(() => {
      fetchApprovalList({
          params: {
            start: Number(paginationValue.page * limitValue.limit),
            limit: Number(limitValue.limit),
            search_qry: searchValue.search,
            country:'ae',
            date_from: startDate,
             date_to:
            endDate === undefined || endDate === "" ? startDate : endDate,
            
            orderBy: "id",
          },
          dispatch,
        })
      },[startDate,paginationValue.page, limitValue,loading]);
    const limitByValues = [5, 10, 20, 50];
    const { queue1 = [] } = useSelector((state) => state.queueMaster);
    const { listOrders } = queue1;
    const orderData = listOrders?.orderList;
    const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
    let pageIndex = Number(listOrders?.start);
    const handlePagination = (e) => {
      setPaginationValue({ page: e.target.value });
    };
    const handleSearch = (e) => {
      setSearchValue({ search: e.target.value });
    };
    const handleLimit = (e) => {
      setLimitValue({ limit: Number(e) });
    };
    const [btnprimary1, setBtnprimary1] = useState(false);
    const toggleRightDrawer = ( historyData) => {
      if (window.confirm("Are you sure you want to refund?")) {
      }
      setOpen(true);
      setorderValue(historyData);
      

    };
    const onDrawerClose = () => {
      setOpen(!open);
    };
    
    const { orderupdateDetails = []} = useSelector(
      (state) => state.queueMaster
    );

    // const handleValidSubmit=(events, values,reference_number,cancel_no,refund_amount)=>{
    //   setUpdateDetailsLoad(true);

    // const reference_no = reference_number;
    // const cancel_number = cancel_no;
    // const amount = refund_amount;

    
   
    
    //   updateOrderStatus({
    //     params: {
    //       reference_no: reference_no,
    //       country: 'ae',
    //       cancel_no: cancel_number,
    //       refund_reason: 'canceled',
    //       amount: amount,
    //       loginuser: "1",
    //       refund_remark: "canceled",
    //       payment_mode: "payfort",
    //       refund_type: "partially_refunded", 
          
    //     },
    //     dispatch,
    //   });  
    //   onDrawerClose();
    // };

    useEffect(() => {
      if (orderupdateDetails?.code === 100) {
        setUpdateDetailsLoad(false);
        setLoading(true);

        console.log("toster",orderupdateDetails?.message);
       
        toast.success(orderupdateDetails?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#4CAF50",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#4CAF50",
          },
        });
      } else {
        if (orderupdateDetails?.message) {
          setUpdateDetailsLoad(false);
          toast.error(orderupdateDetails?.message, {
            style: {
              border: "1px solid #fff",
              padding: "20px",
              color: "#ffffff",
              background: "#f44336",
            },
            iconTheme: {
              primary: "#FFFAEE",
              secondary: "#f44336",
            },
          });
        }
      }
 
    }, [orderupdateDetails]);
   
 
return (
    <div className="table-responsive dt-table">
      <Row><Col md={7}>
</Col>
  
   </Row>
   <Row className=" dh-TabRow textAlignRight "  >
        
        <Col className="MobileView textAlignRight ">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
            <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
       </Col>
      </Row>

    
   
    {orderData?.length > 0 ? (
      <>
        <Table className="OrderListTable table mb-0">
          <thead>
            <tr>
              <th>SI.No</th>
              <th nowrap="nowrap">Date</th>
              <th nowrap="nowrap">Reference NO</th>
              <th nowrap="nowrap">Payment Mode</th>
              <th>Cancel No</th>
              <th>Refund Amount</th>
              <th>Resolution Mode</th>
              <th>Status</th>
              
            </tr>
          </thead>
          {(orderData || []).map((order, index) => {
            pageIndex++;
            
          return (
            
              <tbody key={order.id}>
                <tr className={order.date_diff>0 ? 'my-class' : ''}>
                  <th scope="row">{pageIndex}</th>
                  <td nowrap="nowrap">{order.created_at}
                
                </td>
                  <td nowrap="nowrap">{order.reference_no}
                
                  </td><td nowrap="nowrap">{order.resolution_type==='refund'?'Payfort':''}
                
                </td>
                  <td  nowrap="nowrap">{order.transaction_reference}</td>
                  <td nowrap="nowrap"><span style={{paddingRight:"10px"}}>{order.refund_amount}</span></td>
                  <td nowrap="nowrap">{order.resolution_type}</td>
                  <td nowrap="nowrap">
                 { order?.status==='0' ? (
                         <button
                         onClick={() => {
                         
                          setShowModal(true);
                          setOrderNo(order.reference_no);
                          setCountry(order.country);

                          setCancelNo(order.transaction_reference);
                          setAmount(order.refund_amount);
                          handleButtonClick(index);
                          setIndexNo(index);
                      }}
                         type="button"
                         className="btn btn-sm btn-outline-warning waves-effect waves-light"
                     >
                    
                    {buttonTexts[index]}
                     </button>
                      ) : order?.status === '1' ? (
                        <>
                          Completed
                        </>
                      ) :order?.status === '2' ? (
                        <>
                          LS Canceled 
                        </>
                      ) :order?.status === '3' ? (
                        <>
                          Waiting for LS cancel
                        </>
                      ) : 
                      order?.status === '5' ? (
                        <>
                           Refund Initiated
                        </>
                      ):(
                        <>
                          Refund Approved
                        </>
                      )}
                    
                      {showModal && ( <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to proceed?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => {setShowModal(false);
              handleCancelClick(indexno);
              }
              }>
                Cancel
              </Button>
              <Button variant="primary" onClick={() =>{
                 setShowModal(false);
                 
                 refundAction({"reference_no":orderno,"cancel_no":cancelno,"refund_amount":amount,"country":country
                 });
              }
               }>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>)}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </Table>
        <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
        
      </>
    ) : (
      <>
        <Table className="OrderListTable table mb-0">
          <thead>
          <tr>
              <th>SI.No</th>
              <th nowrap="nowrap">Reference NO</th>
              <th>Transaction Ref</th>
              <th>Created date</th>
              <th>Status</th>
              <th>Action </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={7}>No Records found</td>
            </tr>
          </tbody>
        </Table>
      </>
    )}
   <Toaster position="top-right" reverseOrder={false} />
  </div>

  
)

};
export default ListComponent;