import axios from "axios";
import { setOrderList } from "../../../Redux/customers/customerSlice";
import { setReturnList } from "../../../Redux/customers/customerSlice";
import UserAuthKey from "../../../components/Common/UserAuthKey";
import { setUserData } from "../../../Redux/user/userSlice";
const apiUrl = process.env.REACT_APP_OMS_API;
const bearerToken = UserAuthKey();
export const getOrderList= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setOrderList(response?.data));
  } catch (error) {

    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  
  }

    }
    export const getReturnList= async({ params, dispatch }) => {
      const bearerToken = UserAuthKey();
      const config = {
        method: 'POST',
        url: `${apiUrl}v1/orders/return-list`,
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          ...params,
        },
      };
      try {
        const response = await axios(config);
        dispatch(setReturnList(response?.data));
      } catch (error) {
    
        if(error?.response?.status === 401){
          dispatch(setUserData(null))
          window.location.replace('/logout');
       }
       else{
   
       }
      
      }
    
      }