import React from "react"
import { Doughnut } from "react-chartjs-2"

const DountChart = ({orderSummaryMode}) => {
  const data = {
    labels: orderSummaryMode && orderSummaryMode?.map((orderMode)=>{
return orderMode?.deliveryMode
    }),
    datasets: [
      {
        data:orderSummaryMode && orderSummaryMode?.map((orderData)=>{
          return orderData?.count
              }),
        backgroundColor: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c","#00FFFF"],
        hoverBackgroundColor: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c","#00FFFF"],
        hoverBorderColor: "#fff",
      },
    ],
  }

  return <Doughnut width={474} height={260} data={data} />
}

export default DountChart
