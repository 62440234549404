import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, gql } from '@apollo/client';
import { useUrlSearchParams } from "use-url-search-params";
import { Card, Button, OverlayTrigger, Form, Tooltip } from "react-bootstrap";
import { Badge } from "reactstrap";
import CsLineIcons from "../../cs-line-icons/CsLineIcons";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import { SORTING_CUSTOMER } from "../../constants";
import toast, { Toaster } from "react-hot-toast";
import { GET_CUSTOMER_DATA_LIST } from "./query/GetCustomerDataList";
import moment from "moment";

import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  FormGroup,
  NavLink,
  Nav,
  NavItem,
  
} from "reactstrap";
import Select from "react-select";

const  AllCustomersList = () => {
  const location = useLocation();

  const [btnprimary1, setBtnprimary1] = useState(false);

  const dispatch = useDispatch();
  const [selectedGroup, setselectedGroup] = useState(null)
  let  currentCountry = localStorage.getItem("WEBSITE_COUNTRY");
  if(currentCountry === "all"){
    currentCountry = "ae"
  }

  const [sortingName, setSortingName] = useState({ id: "sort.date_asc" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: "0" });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit:"10" });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: "_date",
    customer: SORTING_CUSTOMER.ASC,
  });
  const [isDataLoading, setDataLoading] = useState(false);

  const [searchTrigger, setSearchTrigger] = useState("");
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
  const [customActiveTab, setcustomActiveTab] = useState("All");
  const [orderMode, setOrderMode] = useState("All");
  let is_split = 0;
  const { country = [] } = useSelector((state) => state.country);
  

  const splitVal = location?.pathname.split("/");
  
 
  
 
  const limitByValues = [5, 10, 20, 50];

  let LsSts = "New";
  
  const stsCounts = [];

  var statusArr = [];
  // const countsData = { ...statusArr };
  let getValue = (statusArr, StsKey) => {
    let returnSts = 0;
    statusArr.forEach((o) => {
      if (o[StsKey] !== undefined) {
        returnSts = o[StsKey];
      }
    });
    return returnSts;
  };

 

  const sortByValues = [
    { key: "date", customer: SORTING_CUSTOMER.ASC, label: { id: "sort.date_asc" } },
    { key: "date", customer: SORTING_CUSTOMER.DESC, label: { id: "sort.date_desc" } },
    {
      key: "status",
      customer: SORTING_CUSTOMER.ASC,
      label: { id: "sort.status_asc" },
    },
    {
      key: "status",
      customer: SORTING_CUSTOMER.DESC,
      label: { id: "sort.status_desc" },
    },
  ];

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });

  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const handleSorting = (type, customer = SORTING_CUSTOMER.ASC, name) => {
    setSortingValue({ sort: type, customer });
    setSortingName(name);
  };
  const handleSearch = (e) => {

    setSearchValue({ search: e.target.value });
  };
  const handleTriggerSearch = (search_key) => {
    
    setSearchTrigger(search_key);
  };


  const toggleCustom = (tab) => {
  
    if (customActiveTab !== tab) {

      setcustomActiveTab(tab);
    }
  };
  const optionGroup = [
    {
      options: [
        { label: "Edit", value: "Edit" },
        { label: "Delete", value: "Delete" },
       
      ],
    },
  ];
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }
  const [mutateFunction, {isLoading, Error, data} ] = useMutation(GET_CUSTOMER_DATA_LIST);

  
  useEffect(() => {
    setDataLoading(true);
    try {
      mutateFunction({
        variables: {
          searchKey: searchTrigger,
          page: paginationValue.page,
          limit: limitValue.limit,
          sortBy: '_id',
          sortDir: 'desc',
        },
        dispatch,
      
      });
    
    } catch (error) {
    
    }

    
  }, [paginationValue.page, limitValue.limit,searchTrigger ]);
 
  
    const customerDataList = data?.getAllCustomersAdmin?.customerData;
   

   
useEffect(() => {
  
  if(customerDataList?.length > 0){
    setDataLoading(false)
  }
  else if(customerDataList?.length === 0){
    
    setDataLoading(false)
  }
  
}, [customerDataList]);

  let pageIndex = Number(paginationValue.page * limitValue.limit);
 
  
  return (
    <>

      <Row className="align-items-end">
     
        <Col xl={3} md={6} className="mb-3 textAlignRight">
         
          <div className="searchBox">
            <div className="d-inline-block float-md-start me-1  search-input-container w-100 bg-foreground">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <span className="search-magnifier-icon searchIcon" onClick={()=>{
                handleTriggerSearch(searchValue.search)
              }}>
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </div>
        </Col>
       
      </Row>

      <div className=" dt-table">
      {isLoading || isDataLoading ? (
              <Spinner className="ms-2 sploader" color="primary" />
            ) : null}
       
       {customerDataList?.length > 0 ? (
        <>
        <div className="table-responsive">
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                <th nowrap="nowrap">SI.No</th>
                
                <th  nowrap="nowrap">First Name</th>
                <th  nowrap="nowrap">Last Name</th>
                <th  nowrap="nowrap"> Customer Email</th>
                <th  nowrap="nowrap">Group</th>
                <th  nowrap="nowrap">Phone </th>
                <th nowrap="nowrap">Website </th>
                <th nowrap="nowrap">Account Created At</th>
               <th>Action</th>
                </tr>
              </thead>
              
              {(customerDataList || []).map((customer, index) => {
                
                   pageIndex++;
                  
                    let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(customer?.createdAt)
                  
                    let cdate = date,
                        orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                        orderTime = moment(new Date(cdate)).format("h:mm:ss a");
                   
                    return (
                    
                      <tbody key={customer._id}>
                          
                        <tr>
                          <th scope="row">{pageIndex}</th>
                          <td nowrap="nowrap">
                            {customer?.firstName} 
                          </td>
                          <td nowrap="nowrap">
                            {customer?.lastName} 
                          </td>
                         
                          <td nowrap="nowrap">
                            {customer?.email} 
                          </td>
                          <td nowrap="nowrap">
                            {customer?.country?.[0]?.data?.basicInfo?.group} 
                          </td>
                          <td nowrap="nowrap">
                            {customer?.country?.[0]?.data?.basicInfo?.mobile}

                          </td>
                          <td nowrap="nowrap">
                            {currentCountry === customer?.country?.[0]?.code ?customer?.country?.[0]?.code.toUpperCase() : currentCountry.toUpperCase() }

                          </td>
                          <td nowrap="nowrap">
                            {orderDt}<br/>
                            {orderTime}

                          </td>
                       
                       
                          <td> <a
                              href={`/AddCustomerFormDetail/${customer?._id}`}
                              type="button"
                              className="btn btn-sm btn-outline-warning waves-effect waves-light"
                            >
                              {" "}
                              <i className="mdi mdi-eye-outline"></i>{" "}
                            </a></td>
                        </tr>

                       
                      </tbody>
                    );
                  })}
              
            
            </Table>
            </div>
            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={Math.ceil(data?.getAllCustomersAdmin?.totalRecord/limitValue.limit)}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
      </> ):(<><Table className="OrderListTable table mb-0">
      <thead>
                <tr>
                <th>ID</th>
                
                <th>First Name</th>
                <th>Last Name</th>
                <th> Customer Email</th>
                <th>Group</th>
                <th>Phone </th>
                <th nowrap="nowrap">Website </th>
                <th nowrap="nowrap">Account Created At</th>
               <th>Action</th>
                </tr>
              </thead>
                  <tbody>
                    <tr>
                      <td colSpan={7}>No Records found</td>
                    </tr>
                  </tbody>
                </Table>
              </>)}
       
         
       
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};



export default AllCustomersList
