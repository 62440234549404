import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Button,
  Input,
  Form,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import { DashBreadcrumb } from "../../../../components/Common/Breadcrumb";
import {
  fetchCreateReturnDetail,
  fetchOrderReturnDetail,
} from "../../actions/orderActions";
import { useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { fetchRefundDetail } from "../../../refund/action/action";
import toast, { Toaster } from "react-hot-toast";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";
import ReactDrawer from "react-drawer";
import classnames from "classnames";
import Dropzone from "react-dropzone";
const CreateFormReturn = () => {
  const dispatch = useDispatch();
  const [position, setPosition] = useState();
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [stErr, setStErr] = useState(0);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const history = useHistory();
  const [selectedItemForReturn, setSelectedItemForReturn] = useState(null);
  const [counter, setCounter] = useState(0);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [headerTab, setHeaderTab] = useState("1");
  const [headerPillsTab, setHeaderPillsTab] = useState("1");
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMessageCounter, setErrorMessageForCounter] = useState(null);
  const { country = [] } = useSelector((state) => state.country);

  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };
  const toggleHeaderPills = (tab) => {
    if (headerPillsTab !== tab) setHeaderPillsTab(tab);
  };

  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };
  const toggleRightDrawer = () => {
    setPosition("right");
    setOpen(!open);
  };
  const onDrawerClose = () => {
    setOpen(false);
  };
  function handleAcceptedFiles(files) {
    setErrorMessage(null);
    let images = [...selectedFiles, ...files];
    let previewImages = [];
    images.map((file, index) => {
      previewImages.push(URL.createObjectURL(file));
    });
    if (images.length > 5) {
      setErrorMessage("More than 5 images not allowed");
    }
    setImagePreviews(previewImages);
    setselectedFiles(images);
  }

  const incrementCounter = (qty) => {

    if (counter < qty) {
      setCounter(counter + 1);
    } else {
      // console.log("Return quantity is more then order Quantity");
    }
  };

  const decrementCounter = () => {
    if(counter > 1){
    setCounter(counter - 1);
  }
  };
  // if (counter <= 1) {
  //   decrementCounter = () => {
  //     setCounter(1);
  //   };
  // }
  const handleReturnSubmit = (events, values) => {
    const reference_no = values?.reference_no ? values?.reference_no : "";
    fetchOrderReturnDetail({
      params: {
        reference_no: reference_no,
      },
      dispatch,
    });
  };
  const { orderDetailReturn, isOrderReturnDetailLoading } = useSelector(
    (state) => state.order
  );
  const handleValidSubmit = (events, value) => {
    console.log(counter,"counter ")
   
    if(selectedFiles.length===0){ 
      setUpdateDetailsLoad(false);
      toast.error('Please upload image', {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#f44336",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#f44336",
        },
      });
      return;
    }
    setUpdateDetailsLoad(true);
    const form = events.currentTarget;
    const formData = new FormData();
    selectedFiles.map((file, index) => {
      formData.append(`upload_image[${index}]`, file);
    });
    const { time_slot } = events.target.elements;

    if (orderDetailReturn?.OrderDetails?.[0]?.Country ==='ae' ) {
      formData.append("time_slot", time_slot.value);
    }
    formData.append("order_id", selectedItemForReturn?.OrderRef);
    formData.append("item_sku", selectedItemForReturn?.Sku);
    formData.append("ordered_item_qty", selectedItemForReturn?.Qty);
    formData.append("return_item_qty", counter);
    formData.append("item_condition", events.target.Ic.value);
    formData.append("return_reason", events.target.rr.value);
    formData.append("return_comments", events.target.return_comment.value);
    formData.append("country", country);
    formData.append("approved_payment_mode", events.target.payment_mode.value);
    //formData.append("time_slot", events.target.time_slot.value?events.target.time_slot.value:'');
    fetchCreateReturnDetail({
      formData,

      dispatch,
    });
  };
  const { createReturn = [], isReturnDetailLoading } = useSelector(
    (state) => state.order
  );
  useEffect(() => {
    if (createReturn?.code === 100) {
      setUpdateDetailsLoad(false);
      toast.success(createReturn?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
      setTimeout(() => {
        history.push("/returnorders");
      }, 10);
    } else {
      if (createReturn?.message) {
        setUpdateDetailsLoad(false);
        toast.error(createReturn?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
        onDrawerClose();
      }
    }
  }, [createReturn]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={9}>
              <DashBreadcrumb
                title="Orders"
                breadcrumbItem="Manage Return Orders"
              />
            </Col>
            <Col>
              <div className="searchBox">
                <AvForm
                  className="needs-validation"
                  onValidSubmit={(e, v) => {
                    handleReturnSubmit(e, v);
                  }}
                >
                  <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 ">
                    <AvField
                      name="reference_no"
                      className="form-control"
                      placeholder="Search for Order ID"
                      type="text"
                      value=""
                    />
                    <button
                      className="btn btn-primary "
                      type="submit"
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <i className="bx bx-search-alt align-middle "></i>
                    </button>
                  </div>
                </AvForm>
              </div>
            </Col>
          </Row>
          {orderDetailReturn?.code === 100 ? (
            <>
              <Row>
                <Col md={6} xl={4}>
                  <Card>
                    <CardHeader className="bg-light">
                      <CardTitle>RETURN ORDER DETAILS</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <span className="mb-2 text-muted text-uppercase font-size-11 ">
                            Order Number
                          </span>
                        </Col>
                        <Col>
                          <h6 className="fw-medium textAlignRight">
                            {orderDetailReturn?.OrderDetails?.[0]?.OrderNo}
                          </h6>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <span className="mb-2 text-muted text-uppercase font-size-11">
                            Order Date
                          </span>
                        </Col>
                        <Col>
                          <h6 className="fw-medium textAlignRight">
                            {orderDetailReturn?.OrderDetails?.[0]?.CreatedDate}
                            <br />
                          </h6>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <span className="mb-2 text-muted text-uppercase font-size-11">
                            Order Amount
                          </span>
                        </Col>
                        <Col>
                          <h6 className="fw-medium textAlignRight">
                            {orderDetailReturn?.OrderDetails?.[0]?.Currency}{" "}
                            {orderDetailReturn?.OrderDetails?.[0]?.OrderTotal}
                          </h6>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} xl={4}>
                  <Card>
                    <CardHeader className="bg-light">
                      <CardTitle>RETURN ORDER DETAILS</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <span className="mb-2 text-muted text-uppercase font-size-11">
                            Shipping Fee
                          </span>
                        </Col>
                        <Col>
                          <h6 className="fw-medium textAlignRight">
                            {
                              orderDetailReturn?.OrderDetails?.[0]
                                ?.ShippingCharge
                            }
                          </h6>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <span className="mb-2 text-muted text-uppercase font-size-11">
                            Delivered Type
                          </span>
                        </Col>
                        <Col>
                          <h6 className="fw-medium textAlignRight ">
                            {
                              orderDetailReturn?.OrderDetails?.[0]
                                ?.orderitems?.[0]?.DeliveryLocn
                            }
                            <br />
                          </h6>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <span className="mb-2 text-muted text-uppercase font-size-11">
                            Delivered By
                          </span>
                        </Col>
                        <Col>
                          <h6 className="fw-medium textAlignRight ">
                            {orderDetailReturn?.OrderDetails?.[0]?.DeliverBy}
                          </h6>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <CardBody>
                <div className="table-responsive   dt-table ">
                  <Table className=" table  mb-0 ">
                    <thead>
                      <tr>
                        <th>Details</th>
                        <th className="text-center">Qty</th>
                        <th className="text-center" nowrap="nowrap">
                          Original Price
                        </th>
                        <th className="text-center" nowrap="nowrap">
                          Now Price
                        </th>
                        <th className="text-center" nowrap="nowrap">
                          Discount
                        </th>
                        <th className="text-center" nowrap="nowrap">
                          Total
                        </th>
                        <th className="text-center" nowrap="nowrap"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {orderDetailReturn?.OrderDetails?.[0]?.orderitems.map(
                        (item, itemInx) => {
                          return (
                            <tr key={itemInx}>
                              <td className="table-bordered ProductDetailsSec">
                                <div className="ProductDetails">
                                  <div>
                                    <img
                                      alt=""
                                      width="100"
                                      height="100"
                                      className="img-thumbnail1"
                                      src={item?.Image}
                                    />
                                  </div>
                                  <div className="itemDetail">
                                    <span>{item?.Name}</span>

                                    <span>SKU: {item?.Sku}</span>

                                    <span> LS Ref: {item?.OrderRef}</span>
                                  </div>
                                </div>
                              </td>
                              <td
                                className="table-bordered align-middle text-center"
                                nowrap="nowrap"
                              >
                                {item?.Qty}
                              </td>
                              <td
                                className="table-bordered align-middle text-center"
                                nowrap="nowrap"
                              >
                                {item?.Currency} {item?.WasPrice}
                              </td>
                              <td
                                className="table-bordered align-middle text-center"
                                nowrap="nowrap"
                              >
                                {item?.Currency} {item?.NowPrice}
                              </td>
                              <td
                                className="table-bordered align-middle text-center"
                                nowrap="nowrap"
                              >
                                {item?.Currency} {item?.Discount}
                              </td>
                              <td
                                className="table-bordered align-middle text-center"
                                nowrap="nowrap"
                              >
                                {item?.Currency} {item?.TotalPrice}
                              </td>
                              <td
                                className="table-bordered align-middle text-center"
                                nowrap="nowrap"
                              >
                                <button
                                  className={
                                    item?.ReturnEligible === 1
                                      ? "btnTracking"
                                      : "btnTracking1"
                                  }
                                  type="submit"
                                  onClick={() => {
                                    toggleRightDrawer();
                                    setSelectedItemForReturn(item);
                                  }}
                                  disabled={
                                    item?.ReturnEligible === 1 ? open : !open
                                  }
                                >
                                  Make a Return{" "}
                                </button>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              <ReactDrawer
                open={open}
                position={position}
                onClose={onDrawerClose}
                className="sideNavWidth"
              >
                <Card
                  className="card-h-100 OrderDetailsBox"
                  style={{ overflowY: "scroll" }}
                >
                  <CardHeader style={{ borderBottom: "1px solid #e4e4e4" }}>
                    <Row>
                      <Col>
                        <CardTitle>Return Request</CardTitle>
                      </Col>
                      <Col className="textAlignRight">
                        <button
                          type="button"
                          className="btn-close "
                          onClick={onDrawerClose}
                          aria-label="Close"
                        ></button>
                      </Col>
                    </Row>
                    <Row>
                      <span style={{ paddingRight: "10px" }}>
                        Create your return request
                      </span>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <div className="ProductDetailsSec">
                        <div
                          className="ProductDetails mb-3 mt-3"
                          nowrap="nowrap"
                        >
                          <div>
                            <img
                              alt=""
                              width="100"
                              height="100"
                              className="img-thumbnail"
                              src={selectedItemForReturn?.Image}
                            />
                          </div>
                          <div className="itemDetail1">
                            <span>{selectedItemForReturn?.Name}</span>

                            <span>SKU: {selectedItemForReturn?.Sku}</span>
                            <div className="counterdesign">
                              {" "}
                              <span
                                className="text-center"
                                onClick={()=>decrementCounter()}
                              >
                                -
                              </span>
                              <span className="text-center">{counter === ""? selectedItemForReturn?.Qty : counter}</span>
                              <span
                                className="text-center"
                                onClick={() => {
                                  incrementCounter(selectedItemForReturn?.Qty);
                                }}
                              >
                                +
                              </span>
                        
                       
                            </div>
                          </div>
                        </div>
                      </div>
                      <Row>
                        <Col lg="12">
                          <FormGroup className="mb-3">
                            <AvField
                              type="select"
                              name="rr"
                              label="Return Reason"
                              className="form-select"
                              required
                            >
                              <option value="">Select Option</option>
                              <option value="Out of service">
                              Out of service

                              </option>
                              <option value="Wrong color">
                              Wrong color

                              </option>
                              <option value="Wrong Size">
                              Wrong Size

                              </option>
                              <option value="Not Happy with Product Quality">
                              Not Happy with Product Quality</option>
                              <option value="Wrong installation">
                              Wrong installation

                              </option>
                              <option value="Damage Item Recieved ">Damage Item Recieved 
                              </option>
                              <option value="Not Happy with the Product Design">Not Happy with the Product Design

                              </option>
                              <option value="Got a better price">Got a better price

                              </option>
                              <option value="Wrong color">Wrong color

                              </option>
                              <option value="Wrong Size">Wrong Size</option>
                              <option value="Parts Missing">Parts Missing</option>
                              <option value="Wrong Material Delivered">Wrong Material Delivered
                              </option>
                              <option value="Other">Other</option>
                            </AvField>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="mb-3">
                            <AvField
                              type="select"
                              name="Ic"
                              label="Item Condition"
                              className="form-select"
                              required
                            >
                              <option value="">Select Option</option>
                              <option value=" opened">Opened</option>
                              <option value=" Damaged">Damaged</option>
                              <option value=" Unopened">Unopened</option>
                            </AvField>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles(acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4 style={{ color: "#c0bcbc" }}>
                                    Upload Image Here
                                  </h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <span className="text-danger">{errorMessage}</span>
                          {imagePreviews && (
                            <div className="mt-2">
                              {imagePreviews.map((img, i) => {
                                return (
                                  <img
                                    alt=""
                                    width="100"
                                    height="100"
                                    className="img-thumbnail"
                                    src={img}
                                    alt={"image-" + i}
                                    key={i}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </Col>
                      </Row>

                      {orderDetailReturn?.OrderDetails?.[0]?.Country=='ae'?( <Row className="mt-3">
                        <h6 style={{ color: "#495057" }}>
                          Time Slot*
                        </h6>
                        <Col>
                          <FormGroup className="mb-3">
                            <Row>
                              <Col>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="09:00-13:00"
                                    name="time_slot"
                                    defaultChecked
                                    id="exchangeRadios"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exchangeRadios"
                                  >
                                    9 AM-12 PM
                                  </label>
                                </div>
                              </Col>
                              <Col>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="13:00-17:00"
                                    name="time_slot"
                                    id="refundRadios"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="refundRadios"
                                  >
                                    12 PM-5 PM
                                  </label>
                                </div>
                              </Col>
                              <Col>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="17:00-21:00"
                                    name="time_slot"
                                    id="storecreditRadios"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="storecreditRadios"
                                  >
                                   5 PM-9 PM 
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>):''}
                      <Row className="mt-3">
                        <h6 style={{ color: "#495057" }}>
                          Resolution Mode*
                        </h6>
                        <Col>
                          <FormGroup className="mb-3">
                            <Row>
                              <Col>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="exchange"
                                    name="payment_mode"
                                    defaultChecked
                                    id="exchangeRadios"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exchangeRadios"
                                  >
                                    Exchange/Repair
                                  </label>
                                </div>
                              </Col>
                              {orderDetailReturn?.OrderDetails?.[0]?.PaymentMode !== 'cashondelivery' ?
                              (<Col>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="Refund"
                                    name="payment_mode"
                                    id="refundRadios"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="refundRadios"
                                  >
                                    Refund
                                  </label>
                                </div>
                              </Col>):''}
                              <Col>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="wallet"
                                    name="payment_mode"
                                    id="storecreditRadios"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="storecreditRadios"
                                  >
                                   Wallet
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup className="mb-3">
                            <AvField
                              name="return_comment"
                              value=""
                              className="form-control"
                              cols="60"
                              rows="5"
                              type="textarea"
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <button
                          type="submit"
                          className="btn btn-success"
                          data-toggle="modal"
                        >
                           {updateStsLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "}
                          Submit
                        </button>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </ReactDrawer>
            </>
          ) : (
            <>
              {orderDetailReturn?.code === 101 ? (
                <>
                  <div className="orderError">{orderDetailReturn?.message}</div>
                </>
              ) : null}
            </>
          )}
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default CreateFormReturn;
