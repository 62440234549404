import axios from "axios";
import { insertLsStockSku, setStockSkuList} from "../../../Redux/importexport/importexportSlice";
import UserAuthKey from "../../../components/Common/UserAuthKey";
import { setUserData } from "../../../Redux/user/userSlice";
const apiUrl = process.env.REACT_APP_OMS_API;

export const lsStockSkuInsert = async({ formData, dispatch }) => {
  const bearerToken = UserAuthKey();
    const config = {
       method: 'POST',
       url: `${apiUrl}v1/lsapi/checkInsertSkuMaster`,
       headers: {
        Authorization: `Bearer ${bearerToken}`,
       'Content-Type': "multipart/form-data",
      
        },
       
        data: formData, 
      
      };
    try {
       const response = await axios(config);
       dispatch(insertLsStockSku(response?.data));
       } catch (error) {
        if(error?.response?.status === 401){
          dispatch(setUserData(null))
          window.location.replace('/logout');
       }
       else{
   
       }
      }
    }

    export const fetchSkuDataList = async({ params, dispatch }) => {
      const bearerToken = UserAuthKey();
      const config = {
        method: 'POST',
        url: `${apiUrl}v1/lsapi/getSkuList`,
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          ...params,
        },
      };
      try {
        const response = await axios(config);
        dispatch(setStockSkuList(response?.data));
      } catch (error) {
        if(error?.response?.status === 401){
          dispatch(setUserData(null))
          window.location.replace('/logout');
       }
       else{
   
       }
      }
    };