import React, {
  useEffect,
  useState,
  useRef,
  EffectCallback,
  DependencyList,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useUrlSearchParams } from "use-url-search-params";
import { Card, Button, OverlayTrigger, Form, Tooltip } from "react-bootstrap";
import { Badge } from "reactstrap";
import CsLineIcons from "../../../../cs-line-icons/CsLineIcons";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import {
 
  NonServiceAreaList
} from "../../actions/orderActions";
import { SORTING_ORDER } from "../../../../constants/index";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import moment from "moment";


import {
  Table,
  Row,
  Col,

  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  NavLink,
  Nav,
  NavItem,
} from "reactstrap";
import CountUp from "react-countup";
import { capitalize } from "lodash";

const OrderList = () => {
  const location = useLocation();

 
  const dispatch = useDispatch();
  const [sortingName, setSortingName] = useState({ id: "sort.date_asc" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState("All");
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: "_date",
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });


  const [orderMode, setOrderMode] = useState("All");
  let is_split = 0;

  const [isViewItemsVal, setViewActionLoad] = useState("");

  const { country = [] } = useSelector((state) => state.country);
 
  const currentCountry = localStorage.getItem("WEBSITE_COUNTRY");
 
  let cntry = currentCountry;

 
  useEffect(() => {
    setDataLoading(true);
  
    NonServiceAreaList({
      params: {
        country:cntry,
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
        search_qry: searchValue.search,
        status: customActiveTab,
       
       
      },
      dispatch,
    });

    setDataLoading(false);
  }, [
    paginationValue.page,
    limitValue,
    sortingValue.sort,
    searchValue.search,
    cntry,
  ]);

  const limitByValues = [5, 10, 20, 50];

  const { NonService = [], isNonServiceLoading } = useSelector((state) => state.order);

  const [isDataLoading, setDataLoading] = useState(false);

  const  listOrders = NonService;
  const orderData = listOrders?.data?.nonservicearealist;

  let pageIndex = Number(listOrders?.data?.start);

 
  const { splitStatus = [], issplitLoading } = useSelector(
    (state) => state.order
  );

  const splitData = splitStatus?.data;

 
 
 
 
 

  const sortByValues = [
    { key: "date", order: SORTING_ORDER.ASC, label: { id: "sort.date_asc" } },
    { key: "date", order: SORTING_ORDER.DESC, label: { id: "sort.date_desc" } },
    {
      key: "status",
      order: SORTING_ORDER.ASC,
      label: { id: "sort.status_asc" },
    },
    {
      key: "status",
      order: SORTING_ORDER.DESC,
      label: { id: "sort.status_desc" },
    },
  ];

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };

  
  
 


  return (
    <>
      <Row className="align-items-end">
        <Col xl={2} md={7} className="mb-2 textAlignRight">
          <div className="searchBox">
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 bg-foreground">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <span className="search-magnifier-icon searchIcon">
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </div>
        </Col>
       
      </Row>
   

      <div className="table-responsive dt-table">
        {isNonServiceLoading || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {orderData?.length > 0 ? (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                <th>SI.No</th>
                  <th>Sub Area</th>
                  <th>Created at</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {(orderData || []).map((order, index) => {
                const viewMoreBtn = `itv-${index}`;
                pageIndex++;
                const { entity_id: orderid } = order;
              
                let cdate = order.Date,
                  orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                  orderTime = moment(new Date(cdate)).format("h:mm:ss a");

                  let checked = '';
                  let LsSts = '';
                  let stsBtn = "bg-lightgrey";
                  if (order.Status === "0") {
                    stsBtn = "bg-dark";
                    LsSts = "In Active";
                   
                  } else {
                    stsBtn = "bg-success"
                    LsSts = "Active";
                   
                  }
             

                return (
                  <tbody key={order.Id}>
                    <tr>
                      <th scope="row">{pageIndex}</th>
                      <td  nowrap="nowrap">{order.Area}</td>
                      <td nowrap="nowrap">
                        {orderDt} <br></br>
                        {orderTime}
                      </td>
                      <td>
                        <Badge
                          className={` ${stsBtn} me-2`}
                        >
                          {LsSts}
                        </Badge>
                      </td>
                        
                      <td>
                        <a
                          href={`NonServicesAreaForm/${order.Id}`}
                          type="button"
                          className="btn btn-sm btn-outline-warning waves-effect waves-light"
                        >
                          {" "}
                          <i className="mdi mdi-eye-outline"></i>{" "}
                        </a>
                      </td>
                 
                  
                      
                    </tr>

                  </tbody>
                );
              })}
            </Table>

            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          className="DropdownItem1"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.data?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
              <tr>
              <th>SI.No</th>
                  <th>Sub Area</th>
                  <th>Created at</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
};

export default OrderList;