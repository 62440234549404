import React ,{useEffect,useState} from "react";
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Col,Spinner, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown,  FormGroup,
    Label,
    Button,
    Input,
    Form,ListGroup, ListGroupItem  } from 'reactstrap';
    import { useDispatch, useSelector } from "react-redux";
    import toast, { Toaster } from "react-hot-toast";
    import { UpdateUserProfile } from '../actions/action';
    import { AvForm, AvField } from "availity-reactstrap-validation"

    
const ProfileTab1 = () => {
    const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
    const [file, setFile] = useState();
    const dispatch = useDispatch();

    let userID = '';
    let userName= ''; 
    let userEmail = '';
    if (localStorage.getItem("authUser")) {
      const userObj = JSON.parse(localStorage.getItem("authUser"));
      if (userObj?.role_id !== undefined) {
          
            userID = userObj?.user_id
            userName = userObj?.user_name;
            userEmail= userObj?.user_email;
      }
    }
    function handleChange(event) {
        setFile(event.target.files[0])
      }
    const handleValidSubmit = (events, values) => {
        setUpdateDetailsLoad(true);
     

       const formData = new FormData();
       formData.append('id', userID);
       formData.append('name', events.target.name.value);
       formData.append('email', events.target.email.value);
       formData.append('file', file);
    
        
         UpdateUserProfile({
            formData,
      
            dispatch,
          });
        
       };
       const {userProfile = [], isProfileLoading} = useSelector(
        (state) => state.user
      );
      const userProfileDetail = userProfile?.data;
       useEffect(() => {
        if (userProfile?.code === 100) {
          setUpdateDetailsLoad(false);
          toast.success(userProfile?.message, {
            style: {
              border: "1px solid #fff",
              padding: "20px",
              color: "#ffffff",
              background: "#4CAF50",
            },
            iconTheme: {
              primary: "#FFFAEE",
              secondary: "#4CAF50",
            },
          });
        } else {
          if (userProfile?.message) {
            toast.error(userProfile?.message, {
              style: {
                border: "1px solid #fff",
                padding: "20px",
                color: "#ffffff",
                background: "#f44336",
              },
              iconTheme: {
                primary: "#FFFAEE",
                secondary: "#f44336",
              },
            });
          }
        }
      }, [userProfile]);
    return (
        <React.Fragment>
            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Overview</CardTitle>
                </CardHeader>
                <CardBody>
                        <AvForm  
                        onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v);
                          }}
                        
                        className="needs-validation">
                            <Row>
                            
                             
                                <Col md="12">
                                     <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01">User Name</Label>
                                        <AvField
                                                name="name"
                                                placeholder="Type Something"
                                                type="text"
                                                value={userName}
                                                errorMessage="Enter Name"
                                                validate={{ required: { value: true } }}
                                            />
                                    </FormGroup>
                                </Col>
                                </Row>
                                <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom03">Email</Label>
                                        <AvField
                                                name="email"
                                                value={userEmail}
                                                placeholder="Enter Valid Email"
                                                type="email"
                                               disabled
                                               
                                            />
                                    </FormGroup>
                                </Col>
                            </Row>
                           
                           
                     <Row className="mb-3">
                     <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom03">Profile Image</Label>
                                        <AvField
                                                name="file"
                                                type="file"
                                                onChange={handleChange}
                                               
                                            />
                                    </FormGroup>
                    </Row>      
                    <div className="d-flex flex-wrap gap-2">
                    <button type="submit" className="btn btn-primary">
                  {updateStsLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "}
                  Save
                </button>{" "}
                                     
                                    </div>
                        </AvForm>
                    
                    </CardBody>
            </Card>

            <Toaster position="top-right" reverseOrder={false} /> 
        </React.Fragment>
    );
}

export default ProfileTab1;