import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  queue1: [],
  queueDetails: [],

  isLoading: true,
  isPasswordLoading:true,
  isupdaterfeundLoading:true

 
};

const queueSlice = createSlice({
  name: "Queue",
  initialState,
  reducers: {
    setQueueList(state, actions) {
      state.queue1 = actions.payload;
      state.isLoading = false;
    },
    setReturnQueueList(state, actions) {
      state.queue1 = actions.payload;
      state.isLoading = false;
    },
    setRefundList(state, actions) {
      state.queue1 = actions.payload;
      state.isLoading = false;
    },
    setQueueDetail(state, actions) {
      state.queueDetails = actions.payload;
      state.isLoading = false;
    },
    updateOrderDetails(state, actions) {
      state.orderupdateDetails = actions.payload;
      state.isUpdateLoading = false;
      state.apiTriggerCount++
    },
    


  },
});

export const { setQueueList,setReturnQueueList,setRefundList,updateOrderDetails,setQueueDetail }= queueSlice.actions;

const queueReducer = queueSlice.reducer;

export default queueReducer;
