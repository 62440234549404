import { useState, useEffect, button } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row,
  Input,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import toast, { Toaster } from "react-hot-toast";
import { PhotoShootUpdateDetail } from "../actions/photoshootAction";

function PhotoShootUpdateDetailForm({ orderData }) {
  const { id } = useParams();
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const dispatch = useDispatch();
  let UserRoleID = '';

  //console.log(UserRoleID,"UserRoleID")
  if (localStorage.getItem("authUser")) {
    const userObj = JSON.parse(localStorage.getItem("authUser"));
    if (userObj?.role_id !== undefined) {
          UserRoleID = userObj?.role_id
    }
  }
  const handleValidSubmit = (events, values) => {
    setUpdateDetailsLoad(true);
    //console.log("values", values, events);
    const status = values?.status ? values?.status : "";
    const remarks = values?.remarks ? values?.remarks : "";
    const Iscompleted = values?.iscompleted ? values?.iscompleted : "";
    const delivery_dt = values?.delivery_dt ? values?.delivery_dt : "";
    
    PhotoShootUpdateDetail({
      params: {
        remarks:remarks,
        status:status,
        completed:Iscompleted,
        product_id:id,
        loginuser:UserRoleID
      },
      dispatch,
    });
  };


  const { PhotoShootUpdate = [], isUpdateLoading } = useSelector(
    (state) => state.photoshoot
  );
  //console.log(PhotoShootUpdate,"PhotoShootUpdatePhotoShootUpdatePhotoShootUpdatePhotoShootUpdate")

  useEffect(() => {
    if (PhotoShootUpdate?.code === 100) {
      setUpdateDetailsLoad(false);
      toast.success(PhotoShootUpdate?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
    } else {
      if (PhotoShootUpdate?.message) {
        toast.error(PhotoShootUpdate?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [PhotoShootUpdate]);
  
  const updateDetail = (data) => {
    //data,"data")
   
    const LsStatus = data?.[0].Status ;
   // console.log(LsStatus,"LsStatus")
    let LsSts = [];
    if ((UserRoleID==='1' || UserRoleID==='14') && (LsStatus==='merchandising')) {
      LsSts = ["Merchandising"]
     // console.log(LsSts,"LsStsLsStsLsStsLsSts")
    }
    else if((UserRoleID==='1' || UserRoleID==='15') && (LsStatus==='photoshoot')) {
      LsSts = ["photoshoot"]
    }
     else if ((UserRoleID==='1' || UserRoleID==='15') && (LsStatus==='editing')) {
      LsSts = ["Editing"]
    } else if ((UserRoleID==='1' || UserRoleID==='15') && (LsStatus==='content')) {
      LsSts = ["Content"]
    } else if  ((UserRoleID==='1'|| UserRoleID==='18') && (LsStatus==='website')) {
      LsSts = ["Website"]
    }
    if (data?.[0].Status !== 'completed') {
      return (
        <Card className="Order UpdateDetailsForm">
          <CardHeader>
            <CardTitle>UPDATE DETAILS</CardTitle>
          </CardHeader>
          <CardBody>
            <AvForm
              className="needs-validation row gx-3 gy-2 align-items-center"
              onValidSubmit={(e, v) => {
                console.log(e, v);
                handleValidSubmit(e, v);
             }}
            >
           <Col xl={12} md={12}>
                <Row>
                  <Col xl={12} md={12}>
                    <AvField
                      type="select"
                      name="status"
                      label="Status"
                      className="form-select"
                      // onChange={(e) => setOrderStatus(e.target.value)}
                      required
                    >
                       {LsSts?.map((e, inx) => (
                        <option  key={inx}>{e}</option>
                      
                      ))}
                      
                   
                    </AvField>
                  </Col>
                </Row>
              </Col>
              <Col xl={12} md={12}>
                    <AvField
                      type="select"
                      name="iscompleted"
                      label="Is Completed"
                      className="form-select"
                     
                      required
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </AvField>
                  </Col>
           
            
              <Col xl={12} md={12}>
                <AvField
                  name="remarks"
                  label="Remarks"
                  value=""
                  className="form-control"
                  type="textarea"
                  required
                />
              </Col>

              <Col xl={12} md={12}>
                <button type="submit" className="btn btn-primary">
                  {updateStsLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "}
                 Update
                </button>
              </Col>
            </AvForm>
          </CardBody>
        </Card>
      );
                  }
  };

  return (
    <>
      {updateDetail(orderData)}
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
}
export default PhotoShootUpdateDetailForm;
