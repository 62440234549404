import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Col, Row, Progress } from "reactstrap";

function OrderProgressbar({ orderData }) {
  const progressStatus = orderData?.LsStatus?.toLowerCase();

  let progressArr = [];
  let isCancel = 0;
  let bar = 0;
  let stsBtn = "bg-lightgrey";
  if (progressStatus === "cancel" || progressStatus === "canceled" || progressStatus === "rejected") {
    progressArr = [0, 0, 0, 0, 0];
    isCancel = 1;
    bar = 100;
    stsBtn = "bg-danger1";
  } else if (progressStatus === "open") {
    progressArr = [1, 0, 0, 0, 0];
    isCancel = 0;
    bar = 0;

  } else if (progressStatus === "confirmed" || progressStatus === "packed") {
    progressArr = [1, 1, 0, 0, 0];
    isCancel = 0;
    bar = 25;

  } else if (progressStatus === "posted") {
    progressArr = [1, 1, 1, 0, 0];
    isCancel = 0;
    bar = 50;

  } else if (progressStatus === "picked" || progressStatus === "delivery_scheduled" || progressStatus === "rescheduled" || progressStatus === "out_for_delivery" || progressStatus === "assigned" || progressStatus === "unassigned") {
    progressArr = [1, 1, 1, 1, 0];
    isCancel = 0;
    bar = 75;

  } else if (progressStatus === "delivered" || progressStatus === "partially_delivered") {
    progressArr = [1, 1, 1, 1, 1];
    isCancel = 0;
    bar = 100;

  }
  return (

    <>
      <div className="ProgressStepBlock">
        {isCancel ? (


          <>
            <Row className="ProgressbarStatus align-items-center">
              <Col xl={4}>
                <div className="position-relative">
                  <Progress
                    value={bar}
                    color="cancel"
                    style={{ height: "4px" }}
                  ></Progress>
                  <span className={` ${stsBtn} status ProgressStep position-absolute ProgressStepTop-0 ProgressStep-0 translate-middle`}>
                    Open
                  </span>
                  <span className={` ${stsBtn} status ProgressStep position-absolute ProgressStepTop-0 ProgressStep-4 translate-middle`}>
                    Canceled
                  </span>

                </div>
              </Col>
            </Row>
          </>

        ) : (
          <>

            <Row className="ProgressbarStatus align-items-center">
              <Col xl={8}>
                <div className="position-relative">
                  <Progress
                    value={bar}
                    color="primary"
                    style={{ height: "4px" }}
                  ></Progress>
                  <span className={`  ${progressArr[0] === 1 ? "bg-success1" : ""} status ProgressStep position-absolute ProgressStepTop-0 ProgressStep-0 translate-middle`}>
                    Open
                  </span>
                  <span className={`  ${progressArr[1] === 1 ? "bg-success1" : ""} status ProgressStep position-absolute ProgressStepTop-0 ProgressStep-1 translate-middle`}>
                    Confirmed
                  </span>
                  <span className={`${progressArr[2] === 1 ? "bg-success1" : ""
                    } status ProgressStep position-absolute ProgressStepTop-0 ProgressStep-2 translate-middle`}>
                    Posted
                  </span>
                  <span className={` ${progressArr[3] === 1 ? "bg-success1" : ""
                    } status ProgressStep position-absolute ProgressStepTop-0 ProgressStep-3 translate-middle`}>
                    Delivery Sch
                  </span>
                  <span className={` ${progressArr[4] === 1 ? "bg-success1" : ""
                    } status ProgressStep position-absolute ProgressStepTop-0 ProgressStep-4 translate-middle`}>
                    Delivered
                  </span>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}
export default OrderProgressbar;
