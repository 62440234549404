import { gql } from '@apollo/client';
export const CREATE_CATEGORY= gql`
mutation(
   $input:CategoriesInput!
  ){
    createCategory(input: $input) {
      _id
    name {
      en
      ar
    }
    slug
    icon
    shortDescription {
      en
      ar
    }
    metaTitle {
      en
      ar
    }
    metaDescription {
      en
      ar
    }
    metaKeywords {
      en
      ar
    }
    status
    createdBy
    createdAt
    updatedAt
    updatedBy
    departmentId
    departmentName
  }
  }`;
