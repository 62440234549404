import React ,{useEffect,useState} from "react";
import { Card, CardBody, CardHeader, CardTitle, Col,Spinner, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserProfile } from '../actions/action';
import toast, { Toaster } from "react-hot-toast";
import classnames from "classnames"
import ProfileTab1 from './ProfileTab1';
import ProfileTab2 from './Profiletab2';


const ProfileTab = () => {
    let  user_Img = ''
    let user_Name = ''
    let user_Email = ''
    const [activeTab, toggleTab] = useState("1");
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userImage, setUserImage] = useState("");
    if (localStorage.getItem("authUser")) {
        const userObj = JSON.parse(localStorage.getItem("authUser"));
        if (userObj?.role_id !== undefined) {
    
              user_Img = userObj?.image;
              user_Name= userObj?.user_name
              user_Email = userObj?.user_email
        }
      }
    const {userProfile = [], isProfileLoading} = useSelector(
        (state) => state.user
      );
      useEffect(() => {
        setUserName(userProfile?.data?.name);
        setUserEmail(userProfile?.data?.email)
        setUserImage(userProfile?.data?.image)
      }, [userProfile]);
      const userProfileDetail = userProfile?.data
     
    return (
        <React.Fragment>
            <Row>
                <Col xl={9} lg={8}>
                    <Card>
                        <CardBody>
                            <Row>
                                <div className="col-sm order-2 order-sm-1">
                                    <div className="d-flex align-items-start mt-3 mt-sm-0">
                                        <div className="flex-shrink-0">
                                            <div className="avatar-xl me-3">
                                                {userImage === '' || userImage === undefined || userImage === null ? (<> <img
                                                  src={user_Img}
                                                alt="" className="img-fluid rounded-circle d-block" /></>):(<> <img
                                                    src={userImage}
                                                  alt="" className="img-fluid rounded-circle d-block" /></>)}
                                               
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <div>
                                                {userName === '' || userName === undefined || userName === null ? (<> <h5 className="font-size-16 mb-1">{user_Name}</h5></>):(<> <h5 className="font-size-16 mb-1">{userName}</h5></>)}
                                             {userEmail === ''|| userEmail === undefined || userEmail === null ?(<><p className="text-muted font-size-13">{user_Email}</p></>):(<><p className="text-muted font-size-13">{userEmail}</p></>)}
                                               

                                                {/* <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                                    <div><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>Development</div>
                                                    <div><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>{userEmail}</div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </Row>

                            <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({
                                            active: activeTab === "1",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("1")
                                        }}>
                                        Update Profile</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({
                                            active: activeTab === "2",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("2")
                                        }}
                                    >Change Password</NavLink>
                                </NavItem>
                               
                            </Nav>
                        </CardBody>
                    </Card>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <ProfileTab1 />
                        </TabPane>
                        <TabPane tabId="2">
                            <ProfileTab2 />
                        </TabPane>
                      
                    </TabContent>
                </Col>
               
            </Row>
        </React.Fragment>
    );
}

export default ProfileTab;