import "./AskDeliveryHelp.scss";

import { useState } from 'react';
import Modal from 'react-modal';
import {


  Spinner,
} from "reactstrap";
import { insertOrderShipQustionAnswer } from './action';
import { useSelector } from 'react-redux';
import Mask from "../../../../assets/images/MaskGroup.svg";
// import { computeSegEndResizable } from "@fullcalendar/react";

// style for popup
const customStyles = {
  content: {
    zIndex: 1000,
    padding: '0px',
    Width: '100%',
    maxWidth: '763px',
  },
};

function AskDeliveryHelpfunc({ shipment}) {
  const [isOpenAskHelpPopupModal, setIsOpenAskHelpPopupModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { customerData } = useSelector((state) => state?.customer);
  const [askQues, setAskQues] = useState('');

  const handleCloseModal = () => {
    setIsOpenAskHelpPopupModal(false);
  };

  const askQuestionFunc = async (shipmentNum) => {
    if (askQues?.length < 100) { // Corrected the minimum length to 100 characters
      setErrorMessage('Minimum 100 characters required.');
    } else {
      setIsLoading(true);
      let isSuccess = await insertOrderShipQustionAnswer({
        params: {
          qa_description: askQues, reference_no: shipmentNum, qa_type: '1', created_by: 'customer', parent_id: '0',is_replied:"1"
        },

      });  
   
      if(isSuccess){
        setAskQues('');
        setIsSubmitted(true);
           setTimeout(() => {
          setIsSubmitted(false);
          // setIsOpenAskHelpPopupModal(false);
        }, 5000);
      }

    }
  };
  
  return (
    <>
      <div className="askHelp">
        <span className="askHelpBtn" onClick={() => setIsOpenAskHelpPopupModal(true)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18.435" height="12.162" viewBox="0 0 14.435 8.162">
            <g id="Group_175560" data-name="Group 175560" transform="translate(-4.996 -3.004)">
              <path
                id="Path_85078"
                data-name="Path 85078"
                d="M77.953,4.3a.721.721,0,0,1,.474-.618.665.665,0,0,1,.638.158c.856.865,1.72,1.721,2.577,2.585.1.1.152.115.26.007Q85.039,3.289,88.182.149a.654.654,0,0,1,1.032.288.57.57,0,0,1-.145.609q-.829.843-1.657,1.687l-1.561,1.59-1.6,1.633c-.524.534-1.052,1.064-1.569,1.6a1.865,1.865,0,0,1-.616.522.6.6,0,0,1-.772-.161c-.41-.429-.837-.841-1.254-1.263Q79.222,5.833,78.412,5a2.435,2.435,0,0,1-.3-.334,2.186,2.186,0,0,1-.161-.365"
                transform="translate(-69.816 3.004)"
                fill="#fff"
              />
              <path
                id="Path_85079"
                data-name="Path 85079"
                d="M167.264,1.552a.574.574,0,0,1,.633.411.6.6,0,0,1-.166.677q-1.695,1.686-3.381,3.38a.63.63,0,0,1-1.081-.271.6.6,0,0,1,.163-.623q1.7-1.7,3.4-3.4a.471.471,0,0,1,.428-.174"
                transform="translate(-151.667 1.521)"
                fill="#fff"
              />
              <path
                id="Path_85080"
                data-name="Path 85080"
                d="M3.79,96.716a.529.529,0,0,1-.407-.178Q1.792,94.95.206,93.358a.591.591,0,0,1-.021-.88A.61.61,0,0,1,1.02,92.4a1.344,1.344,0,0,1,.182.151q1.521,1.518,3.04,3.039a.644.644,0,0,1-.277,1.1,1.173,1.173,0,0,1-.174.027"
                transform="translate(4.996 -85.55)"
                fill="#fff"
              />
            </g>
          </svg>
          Ask a Help?
        </span>
      </div>

      <Modal
        isOpen={isOpenAskHelpPopupModal}
        style={customStyles}
        closeTimeoutMS={1000}
        className= "openCenter"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={handleCloseModal}
      >
        {!isSubmitted ? (
          <div className="QuickPopup">
            <div className="QuickPopupTitle">
              <h3>Ask a Help?</h3>
              <p>Ask your Delivery Related Questions</p>
              <p>
                <i>
                  {' '}
                  Shipping Ref ID : <b>{shipment}</b>
                </i>
              </p>
              <div className="closeBtn">
                <button
                  onClick={() => {
                    setIsOpenAskHelpPopupModal(false);
                  }}
                  className="close"
                ></button>
              </div>
            </div>
            <div className="QuickContain">
              <p>
                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface
                without relying on meaningful content.
              </p>
              <div className="askHelpTextArea">
              <textarea
          value={askQues}
          onChange={(e)=>{
            setAskQues(e.target.value)
          }}
          placeholder="Please Enter Your Queries"
          className="form-control"
          rows={5}
          cols={5}
        />
        {errorMessage? (
                                    
                                      <span style={{ color: "red" }}>
                                      minimum 50 character required
                                      </span>
             ):null}
              </div>
              <div
                className="btn btn-primary"
                onClick={() => {
                  askQuestionFunc(shipment);
                }}
               
              >
                <span className="Button" >  {isLoading ? (
                  <Spinner
                    className="ms-1 cntsloader"
                    size="sm"
                    color="secondary"
                    style={{display:'none'}}
                  />):null}Send</span>
              </div>
            </div>
   
          </div>
        ) : (
          <div className="QuickPopup successContainer">
            <div className="closeBtn">
              <button
                onClick={() => {
                  setIsOpenAskHelpPopupModal(false);
                }}
                className="close"
              ></button>
            </div>
            <div className="QuickContain">
              <span className="thankyouPopupImg">
                <img src={Mask} alt="" width={70} height={70} />
              </span>
              <div className="thankyouContent">
                <h4>
                Thank you, Abdul Bari! 
                  <br />
                  Your Message send Successfully
                </h4>
                <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface
                  without relying on meaningful content.
                </p>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default AskDeliveryHelpfunc;
