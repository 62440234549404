/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import { Link } from "react-router-dom";
import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  CardHeader,
  Input,
  Table,
  Form,
  FormGroup,
  InputGroup,
  Label,
} from "reactstrap";
import Dashboard from "../Dashboard";
import ProductSlider from "./components/ProductSlider";

const Dashboardd = () => {
  return (
    <>
      <div className="page-content Dashboard">
        <Container fluid>
          <Row className="Dashboardheader">
            <Col>
              <DashBreadcrumb title="Dashboard" breadcrumbItem="Dashboard" />
            </Col>
            <Col className="textAlignRight ">
              <Row className="align-items-end">
                <Col xl={12}>DateRangePicker</Col>
              </Row>
            </Col>
          </Row>
          {/*  */}
          <Row>
            <Col xl={5}>
              <Card className="card-h-100">
                <CardBody className="dashboardCardBody">
                  <Row>
                    <Col>
                      <div className="dashboardCard">
                        <span className="refresh">
                          <svg
                            id="ic-sessions"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <g
                              id="Oval"
                              fill="#f5f6fa"
                              stroke="#0980fd"
                              stroke-miterlimit="10"
                              strokeWidth="1"
                            >
                              <circle cx="12" cy="12" r="12" stroke="none" />
                              <circle cx="12" cy="12" r="11.5" fill="none" />
                            </g>
                            <g id="refresh" transform="translate(3 3)">
                              <path
                                id="Path_446"
                                data-name="Path 446"
                                d="M0,0H18.42V18.42H0Z"
                                fill="none"
                              />
                              <path
                                id="Path_447"
                                data-name="Path 447"
                                d="M16.28,9.372a6.217,6.217,0,0,0-11.9-1.535M4,4V7.837H7.837"
                                transform="translate(-0.93 -0.93)"
                                fill="none"
                                stroke="#0980fd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_448"
                                data-name="Path 448"
                                d="M4,13a6.217,6.217,0,0,0,11.9,1.535m.384,3.837V14.535H12.442"
                                transform="translate(-0.93 -3.023)"
                                fill="none"
                                stroke="#0980fd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </span>
                        <p>Total No.of Orders</p>
                        <h2>2644</h2>
                        <Link>View All</Link>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <div className="dashboardCard">
                        <p>Success</p>
                        <h3 className="successOrder">44</h3>
                        <Link>View All</Link>
                      </div>
                    </Col>
                    <Col className="bdr">
                      <div className="dashboardCard">
                        <p>failed/cancel</p>
                        <h3 className="failedOrder">150</h3>
                        <Link>View All</Link>
                      </div>
                    </Col>
                    <Col>
                      <div className="dashboardCard">
                        <p>Not flow in LS</p>
                        <h3 className="notFlowOrder">35</h3>
                        <Link>View All</Link>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <div className="dashboardCard">
                        <p>Yesterday orders</p>
                        <h3>500</h3>
                        <Link>View All</Link>
                      </div>
                    </Col>
                    <Col className="bdr">
                      <div className="dashboardCard">
                        <p>Last 7days orders</p>
                        <h3>4150</h3>
                        <Link>View All</Link>
                      </div>
                    </Col>
                    <Col>
                      <div className="dashboardCard">
                        <p>Last 30days orders</p>
                        <h3>42589</h3>
                        <Link>View All</Link>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={7}>
              <Row>
                <Col>
                  <Card className="card-h-100">
                    <CardBody className="dashboardCardBody">
                      <div className="dashboardCard">
                        <span className="refresh">
                          <svg
                            id="ic-sessions"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <g
                              id="Oval"
                              fill="#f5f6fa"
                              stroke="#0980fd"
                              stroke-miterlimit="10"
                              strokeWidth="1"
                            >
                              <circle cx="12" cy="12" r="12" stroke="none" />
                              <circle cx="12" cy="12" r="11.5" fill="none" />
                            </g>
                            <g id="refresh" transform="translate(3 3)">
                              <path
                                id="Path_446"
                                data-name="Path 446"
                                d="M0,0H18.42V18.42H0Z"
                                fill="none"
                              />
                              <path
                                id="Path_447"
                                data-name="Path 447"
                                d="M16.28,9.372a6.217,6.217,0,0,0-11.9-1.535M4,4V7.837H7.837"
                                transform="translate(-0.93 -0.93)"
                                fill="none"
                                stroke="#0980fd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_448"
                                data-name="Path 448"
                                d="M4,13a6.217,6.217,0,0,0,11.9,1.535m.384,3.837V14.535H12.442"
                                transform="translate(-0.93 -3.023)"
                                fill="none"
                                stroke="#0980fd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </span>
                        <p>Order value</p>
                        <h3>10,989,99.00</h3>
                        <Link>View All</Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="card-h-100">
                    <CardBody className="dashboardCardBody">
                      <div className="dashboardCard">
                        <span className="refresh">
                          <svg
                            id="ic-sessions"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <g
                              id="Oval"
                              fill="#f5f6fa"
                              stroke="#0980fd"
                              stroke-miterlimit="10"
                              strokeWidth="1"
                            >
                              <circle cx="12" cy="12" r="12" stroke="none" />
                              <circle cx="12" cy="12" r="11.5" fill="none" />
                            </g>
                            <g id="refresh" transform="translate(3 3)">
                              <path
                                id="Path_446"
                                data-name="Path 446"
                                d="M0,0H18.42V18.42H0Z"
                                fill="none"
                              />
                              <path
                                id="Path_447"
                                data-name="Path 447"
                                d="M16.28,9.372a6.217,6.217,0,0,0-11.9-1.535M4,4V7.837H7.837"
                                transform="translate(-0.93 -0.93)"
                                fill="none"
                                stroke="#0980fd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_448"
                                data-name="Path 448"
                                d="M4,13a6.217,6.217,0,0,0,11.9,1.535m.384,3.837V14.535H12.442"
                                transform="translate(-0.93 -3.023)"
                                fill="none"
                                stroke="#0980fd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </span>
                        <p>Average order value</p>
                        <h3>750.6666</h3>
                        <Link>View All</Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card className="card-h-100">
                    <CardBody className="dashboardCardBody">
                      <div className="dashboardCard">
                        <span className="refresh">
                          <svg
                            id="ic-sessions"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <g
                              id="Oval"
                              fill="#f5f6fa"
                              stroke="#0980fd"
                              stroke-miterlimit="10"
                              strokeWidth="1"
                            >
                              <circle cx="12" cy="12" r="12" stroke="none" />
                              <circle cx="12" cy="12" r="11.5" fill="none" />
                            </g>
                            <g id="refresh" transform="translate(3 3)">
                              <path
                                id="Path_446"
                                data-name="Path 446"
                                d="M0,0H18.42V18.42H0Z"
                                fill="none"
                              />
                              <path
                                id="Path_447"
                                data-name="Path 447"
                                d="M16.28,9.372a6.217,6.217,0,0,0-11.9-1.535M4,4V7.837H7.837"
                                transform="translate(-0.93 -0.93)"
                                fill="none"
                                stroke="#0980fd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_448"
                                data-name="Path 448"
                                d="M4,13a6.217,6.217,0,0,0,11.9,1.535m.384,3.837V14.535H12.442"
                                transform="translate(-0.93 -3.023)"
                                fill="none"
                                stroke="#0980fd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </span>
                        <p>B2B Order value</p>
                        <h3>1099.00</h3>
                        <Link>View All</Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="card-h-100">
                    <CardBody className="dashboardCardBody">
                      <div className="dashboardCard">
                        <span className="refresh">
                          <svg
                            id="ic-sessions"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <g
                              id="Oval"
                              fill="#f5f6fa"
                              stroke="#0980fd"
                              stroke-miterlimit="10"
                              strokeWidth="1"
                            >
                              <circle cx="12" cy="12" r="12" stroke="none" />
                              <circle cx="12" cy="12" r="11.5" fill="none" />
                            </g>
                            <g id="refresh" transform="translate(3 3)">
                              <path
                                id="Path_446"
                                data-name="Path 446"
                                d="M0,0H18.42V18.42H0Z"
                                fill="none"
                              />
                              <path
                                id="Path_447"
                                data-name="Path 447"
                                d="M16.28,9.372a6.217,6.217,0,0,0-11.9-1.535M4,4V7.837H7.837"
                                transform="translate(-0.93 -0.93)"
                                fill="none"
                                stroke="#0980fd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_448"
                                data-name="Path 448"
                                d="M4,13a6.217,6.217,0,0,0,11.9,1.535m.384,3.837V14.535H12.442"
                                transform="translate(-0.93 -3.023)"
                                fill="none"
                                stroke="#0980fd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </span>
                        <p>Fulfillment orders</p>
                        <h3>600</h3>
                        <Link>View All</Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Card className="card-h-100">
                    <CardBody className="dashboardCardBody">
                      <div className="dashboardCard">
                        <span className="refresh">
                          <svg
                            id="ic-sessions"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <g
                              id="Oval"
                              fill="#f5f6fa"
                              stroke="#0980fd"
                              stroke-miterlimit="10"
                              strokeWidth="1"
                            >
                              <circle cx="12" cy="12" r="12" stroke="none" />
                              <circle cx="12" cy="12" r="11.5" fill="none" />
                            </g>
                            <g id="refresh" transform="translate(3 3)">
                              <path
                                id="Path_446"
                                data-name="Path 446"
                                d="M0,0H18.42V18.42H0Z"
                                fill="none"
                              />
                              <path
                                id="Path_447"
                                data-name="Path 447"
                                d="M16.28,9.372a6.217,6.217,0,0,0-11.9-1.535M4,4V7.837H7.837"
                                transform="translate(-0.93 -0.93)"
                                fill="none"
                                stroke="#0980fd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_448"
                                data-name="Path 448"
                                d="M4,13a6.217,6.217,0,0,0,11.9,1.535m.384,3.837V14.535H12.442"
                                transform="translate(-0.93 -3.023)"
                                fill="none"
                                stroke="#0980fd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </span>
                        <p>Split Order value</p>
                        <h3>8999.00</h3>
                        <Link>View All</Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <Row>
                <Col md={12}>
                  <h4 className="font-size-18">Orders by Location</h4>
                </Col>
                <Col md={12}>
                  <Card className="card-h-100">
                    <CardBody className="dashboardCardBody"></CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col>
                      <h4 className="font-size-18">Comparison</h4>
                    </Col>
                    <Col>Payments</Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Card className="card-h-100">
                    <CardBody className="dashboardCardBody"></CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <h4 className="font-size-18">Best sellers</h4>
            </Col>
            <Col md={12}>
              <div className="productList">
                <ProductSlider />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboardd;
