import React, { useState } from 'react';
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
//import OrderList from './components/orderList';

import "react-datepicker/dist/react-datepicker.css";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import EditNewRole from './components/EditNewRole';
import UserRole from  "./components/UserRole";
import Flatpickr from "react-flatpickr"

import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
    Button
  } from "reactstrap";
  import { useHistory, useLocation } from "react-router-dom";

const UserRoleList = () => {
  

  
  const [showRoleForm, setshowRoleForm] = useState(false);
    
  const handleRoleForm = () => {
        
        setshowRoleForm(true)
      }
  const title = "Roles";
  const description = "Role Details";

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Roles</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={11}>
              <DashBreadcrumb title="Role Details" breadcrumbItem="Roles" />
            </Col>
            <Col xl={1} >
            <a
                          href={`/NewRoleForm`}
                          type="button"
                          className="btn btn-secondary"
                        >
                          {" "}
                          Add New Role
                        </a>
             </Col>
           
          </Row>
         
    
        {!showRoleForm? <UserRole  />  :UserRoleList }
    
        {showRoleForm? <EditNewRole/> :null}
          
          
       
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserRoleList;
