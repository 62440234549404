import React from "react";

import { MetaTags } from "react-meta-tags";
import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../../components/Common/Breadcrumb";
//../../../components/Common/Breadcrumb
import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
  } from "reactstrap";

import StoreSelection from "./components/StoreSelection/StoreSelection.component"
const DeliveryReport = () => {
  const title = "Delivery Report";
  const description = "Delivery Report";
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Delivery Report</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Delivery Report" breadcrumbItem="Manage Delivery Report" />
            </Col>
          </Row>
         <StoreSelection></StoreSelection>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default DeliveryReport;
