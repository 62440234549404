import axios from "axios";
import { setDeliveryReportList,setUpdateOrderDetails,getCheckData,deliveryImportOrders,excelGenerateFile } from "../../../../Redux/delivery/deliverySlice";
import UserAuthKey from "../../../../components/Common/UserAuthKey";

const apiUrl = process.env.REACT_APP_OMS_API;
const bearerToken = UserAuthKey();
export const fetchDeliveryDataList = async({ params, dispatch }) => {
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/get-delivery-schedule-orders`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  
  try {
    const response = await axios(config);
    dispatch(setDeliveryReportList(response?.data));
  } catch (error) {

  }
};


export const updateOrderStatus = async({ params, dispatch }) => {
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/update-delivery-date`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setUpdateOrderDetails(response?.data));
  } catch (error) {

  }
};

export const fetchCheckData = async({ formData, dispatch }) => {
  const config = {
     method: 'POST',
     url: `${apiUrl}v1/orders/deliveryorder-checkData`,
     headers: {
      Authorization: `Bearer ${bearerToken}`,
     'Content-Type': "multipart/form-data",
    
      },
     
      data: formData, 
    
    };
  try {
     const response = await axios(config);
     dispatch(getCheckData(response?.data));
     } catch (error) {
  
    }
  // return axios
  // .post(`${apiUrl}v1/marketplace-orders/marketplaceorder-checkData`,formData,  {
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //   },
  // })
  // .then((response) => {
  //   dispatch(getCheckData(response?.data));
  // })
  // .catch((error) => {
  //   throw error;
  // });
    
};

export const deliveryImportSummary = async({ params, dispatch }) => {
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/deliveryorder-importData`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(deliveryImportOrders(response?.data));

  } catch (error) {

  }

};

export const excelGenerate = async({ params, dispatch }) => {
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/export_result`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(excelGenerateFile(response?.data));
    window.location.href = response?.data?.data?.[0]?.report_link
  } catch (error) {
    console.log(error)
  }
};
