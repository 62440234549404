import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useUrlSearchParams } from "use-url-search-params";
import { Card, Button, OverlayTrigger, Form, Tooltip } from "react-bootstrap";
import { Badge } from "reactstrap";
import CsLineIcons from "../../../../cs-line-icons/CsLineIcons";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import {
  bulkOrderDetail,
  getOrderStatusCounts,
} from "../../actions/orderActions";
import { SORTING_ORDER } from "../../../../constants/index";

import moment from "moment";

import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  NavLink,
  Nav,
  NavItem,
} from "reactstrap";
//import {useIntl} from 'react-intl';
import CountUp from "react-countup";

const BulkOrderList = () => {
  const location = useLocation();
  const [isApiCall, setApiCall] = useState(0);
  const [btnprimary1, setBtnprimary1] = useState(false);

  const dispatch = useDispatch();
  //const { formatMessage: f } = useIntl();
  //const [countryCode, setCountryCode] = useUrlSearchParams({ country: "" });

  const [sortingName, setSortingName] = useState({ id: "sort.date_asc" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: "_date",
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
  const [customActiveTab, setcustomActiveTab] = useState("All");
  const [orderMode, setOrderMode] = useState("All");
  let is_split = 0;
  const { country = [] } = useSelector((state) => state.country);

  const splitVal = location?.pathname.split("/");

  useEffect(() => {
    setDataLoading(true);
    if(isApiCall === 0 )
   {
    bulkOrderDetail({
      params: {
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
        search_qry: searchValue.search,
        status: customActiveTab,
        country:localStorage.getItem("WEBSITE_COUNTRY"),
        orderBy: "id",
      },
      dispatch,
    })
    setTimeout(() => setApiCall(0), 8000)
  }
    setDataLoading(false);
  }, [
    paginationValue.page,
    limitValue,
    sortingValue.sort,
    searchValue.search,
    customActiveTab,
    
  ]);
 
  

  const limitByValues = [5, 10, 20, 50];
  const { bulkOrders = [], isBulkLoading } = useSelector((state) => state.order);
  const { statusCounts = [], isStsCntsLoading } = useSelector(
    (state) => state.order
  );

  const [isDataLoading, setDataLoading] = useState(false);

  const { listOrders } = bulkOrders;
  const orderData = listOrders?.orderList;

  let LsSts = "New";
  
  const stsCounts = statusCounts?.data;

  var statusArr = [];
  // const countsData = { ...statusArr };
  let getValue = (statusArr, StsKey) => {
    let returnSts = 0;
    statusArr.forEach((o) => {
      if (o[StsKey] !== undefined) {
        returnSts = o[StsKey];
      }
    });
    return returnSts;
  };
  useEffect(() => {
    
    if (bulkOrders?.code === 100) {
      setApiCall(1);
      //setTimeout(() => setApiCall(0), 5000)
   
    } else {
      // console.log("listOrders?-------------",bulkOrders?.code);
    }
  }, [listOrders]);
 

  const sortByValues = [
    { key: "date", order: SORTING_ORDER.ASC, label: { id: "sort.date_asc" } },
    { key: "date", order: SORTING_ORDER.DESC, label: { id: "sort.date_desc" } },
    {
      key: "status",
      order: SORTING_ORDER.ASC,
      label: { id: "sort.status_asc" },
    },
    {
      key: "status",
      order: SORTING_ORDER.DESC,
      label: { id: "sort.status_desc" },
    },
  ];

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
    setApiCall(0)
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
    setApiCall(0)
  };
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
    setApiCall(0)
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
    setApiCall(0)
  };

  const toggleCustom = (tab) => {
    // console.log("ActiveTab----", tab);
    if (customActiveTab !== tab) {
      // console.log("customActiveTab----", tab);
      setcustomActiveTab(tab);
    }
  };

  let pageIndex = Number(listOrders?.start);

  return (
    <>

      <Row className="align-items-end ">
        <Col xl={2} md={6} className="mb-3 textAlignRight">
          <div className="searchBox">
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 bg-foreground">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <span className="search-magnifier-icon searchIcon">
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </div>
        </Col>
      </Row>

      <div className="table-responsive dt-table">
        {isBulkLoading || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {orderData?.length > 0 ? (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Email</th>
                  <th nowrap="nowrap">Company Name</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {(orderData || []).map((order, index) => {
                pageIndex++;
                
                //  console.log("order_id----------",order_id);
                let cdate = order.Date,
                  orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                  orderTime = moment(new Date(cdate)).format("h:mm:ss a");

                let stsBtn = "bg-lightgrey";
                if (order.Status === "0") {
                  stsBtn = "bg-dark";
                  LsSts = "Pending";
                } else {
                  stsBtn = "bg-success"
                  LsSts = "Completed";
                }
             
            
                return (
                  <tbody key={order.BulkId}>
                    <tr>
                      <th scope="row">{pageIndex}</th>
                      <td nowrap="nowrap">
                        {orderDt} <br></br>
                        {orderTime}
                      </td>
                      <td>{order.FullName}</td>
                      <td>{order.MobileNumber}</td>
                      <td>{order.Email}</td>
                      <td nowrap="nowrap">{order.Company}</td>
                      <td>
                        <Badge
                          className={` ${stsBtn} me-2`}
                        >
                          {LsSts}
                        </Badge>
                      </td>
                      <td>
                        <a
                          href={`bulkorders/${order.BulkId}`}
                          type="button"
                          className="btn btn-sm btn-outline-warning waves-effect waves-light"
                        >
                          {" "}
                          <i className="mdi mdi-border-color"></i>{" "}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>

            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
              <tr>
                  <th>SI.No</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Email</th>
                  <th>Company Name</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
};
export default BulkOrderList;