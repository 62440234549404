import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useUrlSearchParams } from "use-url-search-params";
import { Card, Button, OverlayTrigger, Form, Tooltip } from "react-bootstrap";
import { Badge } from "reactstrap";
import CsLineIcons from "../../../cs-line-icons/CsLineIcons";
import { Link } from "react-router-dom";
import { allUsersList } from "../action/action";
import classnames from "classnames";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import { fetchUserRoleLists,updateRoleStatus,removeUser } from "../action/action";
import { SORTING_ORDER } from "../../../constants/index";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";

import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  Modal,
} from "reactstrap";
//import {useIntl} from 'react-intl';
import CountUp from "react-countup";



const  PermissionList= () => {
  const location = useLocation();

  const [btnprimary1, setBtnprimary1] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  //const { formatMessage: f } = useIntl();
  //const [countryCode, setCountryCode] = useUrlSearchParams({ country: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [sortingName, setSortingName] = useState({ id: "sort.date_asc" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: "_date",
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
  const [customActiveTab, setcustomActiveTab] = useState("All");
  const [orderMode, setOrderMode] = useState("All");
  let is_split = 0;
  const { country = [] } = useSelector((state) => state.country);
  

  const splitVal = location?.pathname.split("/");
  let UserID = '';
  if (localStorage.getItem("authUser")) {
    const userObj = JSON.parse(localStorage.getItem("authUser"));
    if (userObj?.role_id !== undefined) {
          UserID = userObj?.user_id
        
    }
  }

  useEffect(() => {
    setDataLoading(true);
    allUsersList({
      params: {
        country:country,
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
        search_qry: searchValue.search,
        status:"All",
        
      },
      dispatch,
    })
    
    setDataLoading(false);
  }, [
    paginationValue.page,
    limitValue,
    sortingValue.sort,
    searchValue.search,
    country,
  
  ]);

  const limitByValues = [5, 10, 20, 50];
  const { allUsers = [], isAllUsersLoading } = useSelector((state) => state.permissions);
  const { roleStatus = [], isStsLoading } = useSelector((state) => state.permissions);
  const [isDataLoading, setDataLoading] = useState(false);

  const { data } = allUsers;
  const orderData = data?.Userlist;
  let LsSts = "New";
  
  const stsCounts = [];

  var statusArr = [];
  // const countsData = { ...statusArr };
  let getValue = (statusArr, StsKey) => {
    let returnSts = 0;
    statusArr.forEach((o) => {
      if (o[StsKey] !== undefined) {
        returnSts = o[StsKey];
      }
    });
    return returnSts;
  };

 

  const sortByValues = [
    { key: "date", order: SORTING_ORDER.ASC, label: { id: "sort.date_asc" } },
    { key: "date", order: SORTING_ORDER.DESC, label: { id: "sort.date_desc" } },
    {
      key: "status",
      order: SORTING_ORDER.ASC,
      label: { id: "sort.status_asc" },
    },
    {
      key: "status",
      order: SORTING_ORDER.DESC,
      label: { id: "sort.status_desc" },
    },
  ];
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
 
}
  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };

  const toggleCustom = (tab) => {
    // console.log("ActiveTab----", tab);
    if (customActiveTab !== tab) {
      // console.log("customActiveTab----", tab);
      setcustomActiveTab(tab);
    }
  };

  let pageIndex = Number(data?.start);
 
  
  const handleSwitch = (event) => {
   let status = 0;
    if (event.target.checked) {

        status = 1;
    } else {
        status = 0;

    }
    const roleId = event.target.value;
    updateRoleStatus({
        params: {
            role_id: roleId,
            status: status,
        },
        dispatch,
      })
  }
  useEffect(() => {
    if (roleStatus?.code === 100) {
     // setUpdateDetailsLoad(false);
      toast.success(roleStatus?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
    } else {
      if (roleStatus?.message) {
        toast.error(roleStatus?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [roleStatus]);
  
  const deleteUser= async() => {
    try {
     await removeUser({
      params: {
        userid:orderData?.[0]?.id
      },
      dispatch,
    })
      }
  catch (error) {}
    }
  const { UserDelete = [], isLoadingDelete } = useSelector((state) => state.permissions);
  useEffect(() => {
    if (UserDelete?.code === 100) {
      toast.success(UserDelete?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
      setTimeout(() => {
        history.push("/permission");
       
      }, 10);
    } else {
      if (UserDelete?.message) {
        toast.error(UserDelete?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [UserDelete]);

  return (
    <>

      <Row className="align-items-end">
        <Col xl={2} md={6} className="mb-3 textAlignRight">
          <div className="searchBox">
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 bg-foreground">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <span className="search-magnifier-icon searchIcon">
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </div>
        </Col>
      </Row>

      <div className="table-responsive dt-table">
        {isAllUsersLoading  || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {orderData?.length > 0 ? (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                
                  <th> Name</th>
                  <th>Role Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Action </th>
                </tr>
              </thead>
              {(orderData || []).map((order, index) => {
                {console.log(orderData,"orderData------")}
                pageIndex++;
                
                //  console.log("order_id----------",order_id);
                  let checked = '';
                let stsBtn = "bg-lightgrey";
                if (order.status === "0") {
                  stsBtn = "bg-dark";
                  LsSts = "In Active";
                  checked = false;
                } else {
                  stsBtn = "bg-success"
                  LsSts = "Active";
                  checked  =true;
                }
             
            
                return (
                  <tbody key={order.role_id}>
                    <tr>
                      <th scope="row">{pageIndex}</th>
                    
                      <td>{order.name}</td>
                  
                      <td>
                        {order.role_name}
                      </td>
                      <td>
                        {order.email}
                      </td>
                      <td>
                        <Badge
                          className={` ${stsBtn} me-2`}
                        >
                          {LsSts}
                        </Badge>
                      </td>
                      <td>
                   
                                     
                       <Link
                          to={`EditUserForm/${order.id}`}
                          type="button"
                          className="btn btn-sm btn-outline-warning waves-effect waves-light"
                        >
                          {" "}
                          <i className="mdi mdi-border-color"></i>{" "}
                        </Link>    {"   "}
                        <button
                         
                         type="button"
                         onClick={()=> tog_backdrop()}
                         className="btn btn-sm btn-outline-warning waves-effect waves-light fw-medium"
                       >
                         {" "}
                         <i className="mdi mdi-delete"></i>{" "}
                       </button>
                       
                        </td>
                       
                     
                    </tr>
                  </tbody>
                );
              })}
            </Table>
            <Modal
                                            isOpen={modal_backdrop}
                                            toggle={() => {
                                                tog_backdrop()
                                            }}
                                            backdrop={'static'}
                                            id="staticBackdrop"
                                        >
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="staticBackdropLabel">Delete User</h5>
                                                <button type="button" className="btn-close"
                                                    onClick={() => {
                                                        setmodal_backdrop(false)
                                                    }} aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <p>Are you sure you want to delete this user?</p>
                                              
                                            </div>

                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-light" onClick={() => {
                                                    setmodal_backdrop(false)
                                                }}>Close</button>
                                                <button type="button" className="btn btn-success" onClick={() => {
                                             deleteUser()
                                             setmodal_backdrop(false)
                                                }}>Submit</button>
                                            </div>
                                        </Modal>

            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={data?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
              <tr>
              <th>SI.No</th>
              <th> Name</th>
                  <th>Role Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Action </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};



export default PermissionList
