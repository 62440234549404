import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Spinner,
  FormGroup,
} from "reactstrap";
import { MetaTags } from "react-meta-tags";
import { DashBreadcrumb } from "../../components/Common/Breadcrumb";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import ReactDrawer from "react-drawer";
import { InvoiceCreate, InvoiceList } from "./action/invoiceAction";
import toast, { Toaster } from "react-hot-toast";
import InvoiceListData from "./component/InvoiceList";
import "./style.scss";

const ManageInvoices = () => {
  const dispatch = useDispatch();
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [invAmount, setInvAmount] = useState(0.0);
  const [currency, setCurrency] = useState();
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isValidPhone, setValidPhone] = useState(null);
  const { country = [] } = useSelector((state) => state.country);
  const [isInvoiceReload, setInvoiceReload] = useState(false);

  const toggleRightDrawer = () => {
    setPosition("right");
    setOpen(!open);
  };
  const onDrawerClose = () => {
    setOpen(false);
  };

  const formRef = useRef(null);
  const handleReset = () => {
    setCurrency();
    formRef.current.reset(); // Reset the form
  };

  const PhoneNumberHandle = (e) => {
    debugger;
    const phoneNumber = e.target.value;
    let NumberRegex = "";
    if (country === "ae") {
      NumberRegex =
        /^((\+971)|(00971)|0)?(5[0245689]|2[0-9]|3[0-9]|4[0-9]|6[0-9]|7[0-9]|9[0-9])\d{7}$/;
    } else if (country === "qa") {
      NumberRegex =
      /^(?:\+974|00974|0)?(?:30|31|32|33|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|69|70|71|72|73|74|75|76|77|78|79)\d{6}$/;
    } else {
      setValidPhone(false);
      return;
    }
    setValidPhone(NumberRegex.test(phoneNumber));
    return;
  };

  console.log(isValidPhone,'isValidPhone');
  const handleInvoiceSubmit = async(event, values) => {
    setIsBtnLoading(true);
    console.log(isBtnLoading,'isLoading11');
    const data = { ...values, gateway: "payfort" };
    if (isValidPhone) {
      event.preventDefault();
      InvoiceCreate({
        params: data,
        dispatch,
      });
    } else {
      console.log(isBtnLoading,'isLoading13');
      event.preventDefault();
       setIsBtnLoading(false);
    }
  };

  const { invoiceCreate = [] } = useSelector((state) => state.ordInvoice);
  useEffect(() => {
    if (invoiceCreate?.code === 100) {
      setInvoiceReload(true);
      handleReset();
      toast.success(invoiceCreate?.message);
      setOpen(false);
       setIsBtnLoading(false);
    } else {
     
      if (invoiceCreate?.message) {
        setIsBtnLoading(false);
        toast.error(invoiceCreate?.message);
       
      }
    }
  }, [invoiceCreate]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mange Invoices</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Invoices"
                breadcrumbItem="Manage Invoices"
              />
            </Col>
            <Col
              xl={6}
              className="d-flex align-items-center  justify-content-end"
            >
              <button
                className="invoiceBtn mb-2"
                onClick={() => {
                  toggleRightDrawer();
                }}
              >
                Create Invoice
              </button>
            </Col>
          </Row>

          <InvoiceListData isInvoiceReload={isInvoiceReload} country={country} />

          <Row>
            <ReactDrawer
              open={open}
              position={position}
              onClose={onDrawerClose}
            >
              <Card>
                <CardHeader>
                  <Row>
                    <Col className="textAlignCenter">
                      <CardTitle>Generate New Invoice</CardTitle>
                    </Col>
                    <Col className="textAlignRight">
                      {" "}
                      <button
                        type="button"
                        className="btn-close "
                        onClick={() => {
                          onDrawerClose();
                        }}
                        aria-label="Close"
                      ></button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleInvoiceSubmit(e, v);
                        }}
                        ref={formRef}
                      >
                        <FormGroup className="mb-3 productFeedback">
                          {/* <AvField name="feedBackId" value="" type="hidden" /> */}
                          <Row>
                            <Col xl={6}>
                              <AvField
                                name="referenceNo"
                                label="Invoice Reference"
                                type="text"
                                value=""
                                required
                              />
                            </Col>
                            <Col xl={6}>
                              <AvField
                                name="description"
                                label="Invoice Description"
                                type="text"
                                value=""
                                required
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col xl={6}>
                              <AvField
                                name="fullName"
                                label="Customer Name"
                                type="text"
                                value=""
                                required
                              />
                            </Col>
                            <Col xl={6}>
                              <AvField
                                name="email"
                                label="Email Id"
                                type="email"
                                value=""
                                required
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col xl={6}>
                              <AvField
                                name="invoiceAmt"
                                label="Amount"
                                type="number"
                                value="0"
                                minLength="2"
                                min="1"
                                required
                                onChange={(e) => {
                                  setInvAmount(e.target.value);
                                }}
                              />
                            </Col>
                            <Col xl={6}>
                              <AvField
                                type="select"
                                name="invCurrency"
                                label="Currency"
                                required
                                value={currency}
                                onChange={(e) => {
                                  setCurrency(e.target.value);
                                }}
                              >
                                <option value="">Choose a Currency</option>
                                <option value="AED">AED</option>
                                <option value="QAR">QAR</option>
                              </AvField>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl={6}>
                              <AvField
                                name="mobile"
                                label="Mobile No"
                                type="number"
                                value=""
                                required
                                onBlur={PhoneNumberHandle}
                              />
                              {!isValidPhone && isValidPhone!=null ? (
                                <>
                                  <p className="validationError">Invalid Phone Number</p>
                                </>
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col xl={6}>
                              <AvField
                                name="remarks"
                                label="Remarks"
                                type="textarea"
                                value=""
                                required
                              />
                            </Col>
                          </Row>

                          <AvField
                            type="checkbox"
                            name="isNotifyCustomer"
                            label="Send notification to Customer"
                            checked={true}
                          />
                        </FormGroup>
                        <Row>
                          <Col xl={12}>
                            <div className="productFeedbackCustomerMsg">
                              <p
                                className="productFeedbackCustomer"
                                style={{ alignItems: "center" }}
                              >
                                <strong>Invoice Amount</strong> :{" "}
                                <b style={{ fontSize: 32 }}>
                                  {currency} {invAmount}
                                </b>
                              </p>
                            </div>
                          </Col>
                        </Row>

                        <div className="d-flex mt-2 flex-wrap gap-2">
                          <button type="submit" className="btn btn-secondary" disabled={isBtnLoading}>
                            {isBtnLoading && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}{" "}
                            Generate Invoice
                          </button>{" "}
                          <button
                            type="reset"
                            className="btn"
                            onClick={handleReset}
                          >
                            Cancel
                          </button>
                        </div>
                      </AvForm>
                    </Col>
                  </Row>

                  <Row></Row>
                </CardBody>
              </Card>
            </ReactDrawer>
          </Row>
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default ManageInvoices;
