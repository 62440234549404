/**
 * Get GA Clint from cookies
 * @returns
 */
export const getGAClientId = async () => {
  try {
    const ga4Cookie = document.cookie.match(/_ga=([^;]+)/);
    if (ga4Cookie) {
      const clientIdEncoded = ga4Cookie[1].split('.')?.slice(-2).join('.');
      return clientIdEncoded || null;
    }
  } catch (e) {
    return null;
  }
};
