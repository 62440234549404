import React ,{useEffect,useState} from "react";
import { Card, CardBody, CardHeader, CardTitle ,Spinner } from 'reactstrap';
    import { useDispatch, useSelector } from "react-redux";
    import { AvForm, AvField } from "availity-reactstrap-validation"
    import { fetchUpdatedPassword } from '../actions/action';
    import toast, { Toaster } from "react-hot-toast";






const Profiletab2 = () => {
    const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
    const dispatch = useDispatch();


    let userID = '';
    if (localStorage.getItem("authUser")) {
      const userObj = JSON.parse(localStorage.getItem("authUser"));
      if (userObj?.role_id !== undefined) {
          
            userID = userObj?.user_id
      }
    }

    const handleValidSubmit = (events, values) => {
        setUpdateDetailsLoad(true);
        
         const current_pass = values?.current_pass ? values?.current_pass : "";
         const new_pass = values?.new_pass ? values?.new_pass : "";
         const confirm_pass = values?.confirm_pass ? values?.confirm_pass : "";
        
         fetchUpdatedPassword({
           params: {
            user_id:userID,
            current_pass:current_pass,
            new_pass:new_pass,
            confirm_pass:confirm_pass 
           },
           dispatch,
         })
        
       };
       const {updatePassword = [], isPasswordLoading} = useSelector(
        (state) => state.user
      );
       useEffect(() => {
        if (updatePassword?.code === 100) {
          setUpdateDetailsLoad(false);
          toast.success(updatePassword?.message, {
            style: {
              border: "1px solid #fff",
              padding: "20px",
              color: "#ffffff",
              background: "#4CAF50",
            },
            iconTheme: {
              primary: "#FFFAEE",
              secondary: "#4CAF50",
            },
          });
        } else {
          if (updatePassword?.message) {
            toast.error(updatePassword?.message, {
              style: {
                border: "1px solid #fff",
                padding: "20px",
                color: "#ffffff",
                background: "#f44336",
              },
              iconTheme: {
                primary: "#FFFAEE",
                secondary: "#f44336",
              },
            });
          }
        }
      }, [updatePassword]);
    return (
        <React.Fragment>
            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">About</CardTitle>
                </CardHeader>
                <CardBody>
                              
                              <AvForm
                              onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v);
                              }}
                              
                              
                              
                              >

                                
                                  <div className="mb-3">
                                      <AvField
                                          name="current_pass"
                                          label="Current Password"
                                          type="password"
                                          value= ""
                                          required
                                        
                                      />
                                  </div>
                                  <div className="mb-3">
                                      <AvField
                                          name="new_pass"
                                          label="New Password"
                                          type="password"
                                          value= ""
                                          required
                                          validate={{ required: { value: true } }}
                                        
                                      />
                                  </div>
                                  <div className="mb-3">
                                      <AvField
                                          name="confirm_pass"
                                          label="ConfirmPassword"
                                          type="password"
                                          value= ""
                                          required
                                          validate={{
                                            required: { value: true },
                                            match: { value: "new_pass" },
                                        }}
                                        
                                      />
                                  </div>
                                 
                  



                                  <div className="d-flex flex-wrap gap-2">
                                  <button type="submit" className="btn btn-primary">
                  {updateStsLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "}
                  Save
                </button>{" "}
                                    
                                  </div>
                              </AvForm>
                          </CardBody>
            </Card>
            <Toaster position="top-right" reverseOrder={false} />
        </React.Fragment>
    );
}

export default Profiletab2;