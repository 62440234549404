import React from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
import DeliveryList from "./components/DeliveryList";
import "react-datepicker/dist/react-datepicker.css"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
// import OrderExportReport from "./components/OrderExportReport";
import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
  } from "reactstrap";

const ManageSettings = () => {
  const title = "Orders";
  const description = "Manage Orders";

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mange Delivery</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Delivery" breadcrumbItem="Manage Delivery Area" />
            </Col>
            {/* <OrderExportReport/>  */}
          </Row>
          
            <DeliveryList />
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageSettings ;
