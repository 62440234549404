import { useState, useEffect, button } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row,
  Input,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getReturnOrderRemarks
} from "../../actions/orderActions";
import toast, { Toaster } from "react-hot-toast";

function ReturnOrderUpdateDetailsForm({ orderData, itemData }) {
 
  let userID = '';
  if (localStorage.getItem("authUser")) {
    const userObj = JSON.parse(localStorage.getItem("authUser"));
    if (userObj?.role_id !== undefined) {
        
          userID = userObj?.user_id
    }
  }
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const [OrderStatus, setOrderStatus] = useState("");
  const [qtyStatus, setQtyStatus] = useState("");
  const [textRemarks, setTextRemarks] = useState("");
  const [textRemarksError, setTextRemarksError] = useState("");

  const dispatch = useDispatch();

  const LsStatus = orderData?.Status;
  

  let LsStsArr = [];
  if (LsStatus === "Cancel Pending" || LsStatus === "Pending" || LsStatus === "pending") {
    LsStsArr = ["Pending", "Authorized", "Rejected"];
  } else if (LsStatus === "Authorized" || LsStatus === "authorized") {
    LsStsArr = ["Authorized", "Received"];
  } else if (LsStatus === "return_initated") {
    LsStsArr = ["Return Initated"];
  }
  else if (LsStatus === "return_scheduled") {
    LsStsArr = ["Return Scheduled"];
  }else if (LsStatus === "out_for_return_pickup") {
    LsStsArr = ["Return Out for pickup"];
  }else if (LsStatus === "return_pickup_fail") {
    LsStsArr = ["Return pickup failed","Return Canceled"];
  }
  else if (LsStatus === "return_canceled") {
    LsStsArr = ["Return Canceled"];
  }
  else if (LsStatus === "Rejected" || LsStatus === "rejected" ) {
    LsStsArr = ["Rejected"];
  } else if (LsStatus === "Approved" || LsStatus === "approved" ) {
    LsStsArr = ["Approved"];
  }
  else if (LsStatus === "Received"  || LsStatus === "received" || LsStatus === "return_drop_success") {
    LsStsArr = ["Received","Approved","Rejected"];
  }
  function handleChange(event) {
    setOrderStatus(event.target.value)
  }
  const [counter, setCounter] = useState([]);
  
  useEffect(() => {
    setOrderStatus(orderData?.Status);
    setQtyStatus(orderData?.ItemDetails?.[0]?.ReturnItemQty === "0"? "1": orderData?.ItemDetails?.[0]?.ReturnItemQty );
   
    let a = [];
    for (
      let i = 1;
      i <= parseInt(orderData?.ItemDetails?.[0]?.ReturnItemQty=== "0"? "1": orderData?.ItemDetails?.[0]?.ReturnItemQty );
      i++
    ) {
      a.push(i);
      setCounter([...a]);
    }
  }, [orderData?.ItemDetails?.[0]?.ReturnItemQty,orderData?.Status]);

  function reset(){
    setTextRemarks("");
    setTextRemarksError("");
  }
  const [itemsArr, setitemsArr] = useState([]);
  
  useEffect(() => {

   
    orderData?.ItemDetails?.forEach((items) => {
                setitemsArr([
                    
                    {
                        sku: items?.ItemSku,
                        quantity: items?.ReturnItemQty,
                       
                    },
                ]);
            });
       
    
}, [orderData]);

  
  const handleValidSubmit = (events, values) => {
    events.preventDefault();
    setUpdateDetailsLoad(true);
    const returnOredrRemark = values?.remarks ? values?.remarks : "";
    const returnQty = values?.returnQty ? values?.returnQty :qtyStatus;
    const rejected_reason = values?.rejected_reason ? values?.rejected_reason : "";
    const approved_item_condition = values?.approved_item_condition ? values?.approved_item_condition : "";
    const authorized_item_condition = values?.authorized_item_condition ? values?.authorized_item_condition : "";

    
    const status = values?.status ? values?.status : OrderStatus;
    const approved_payment_mode = values?.approved_payment_mode ? values?.approved_payment_mode : "";

   if(returnOredrRemark.length !== 0){
    getReturnOrderRemarks({
      params: {
        status:status,
        returnQty:returnQty,
        approved_payment_mode:approved_payment_mode,
        rejected_reason:rejected_reason,
        approved_item_condition:approved_item_condition,
        authorized_item_condition:authorized_item_condition,
        returnOredrRemark:returnOredrRemark,
        return_reference: orderData?.ReturnNo,
        items: itemsArr,
        country : orderData?.ShippingDetails?.[0]?.Country,
      },
      dispatch,
    });
  }
 else{
  setTextRemarksError("Return Remarks is required")
 }
  };

  const { returnRemarks = [], isRemarksLoading } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    
    if (returnRemarks?.code === 100) {
      setUpdateDetailsLoad(false);
      reset();
      toast.success(returnRemarks?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });

    } else {
      if (returnRemarks?.message) {
        setUpdateDetailsLoad(false);
        toast.error(returnRemarks?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [returnRemarks]);
  return (
    <>
      {
        <Card className="Return UpdateDetailsForm">
          <CardHeader>
            <CardTitle>RETURN ORDER UPDATE DETAILS</CardTitle>
          </CardHeader>
          <CardBody>
          <AvForm
              className="needs-validation row gx-3 gy-2 align-items-center"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <Col xl={12} md={12}>
                <AvField
                      type="select"
                      name="status"
                      label="Status*"
                      className="form-select"
                      onChange={(e) => setOrderStatus(e.target.value)}
              
                    >
                  {LsStsArr?.map((e, inx) => (
                    <option key={inx} selected>{e}</option>
                  ))}
                       </AvField>
              </Col>
              {OrderStatus === "Rejected" ? (
                <>
                  <Col xl={12} md={12}>
                  <AvField
                      type="select"
                      name="rejected_reason"
                      label="Status"
                      className="form-select"
                    >
                
                <option value="">Select a Rejected Reason</option>
                                <option value="Not Eligible">Not Eligible</option>
                                <option value="Item Installed">Item Installed</option>
                                <option value="Item Damaged">Item Damaged</option>
                                <option value="Item Opened">Item Opened</option>
                 
                </AvField>
                  </Col>
                </>
              ) : (
              null
              )}
            
              {OrderStatus === "Authorized" && orderData?.ItemMode === '2' ? (
                <>
                  <Col xl={12} md={12}>
                  <AvField
                      type="select"
                      name="authorized_item_condition"
                      label="Item Condition"
                      className="form-select"
                    >
                
                <option value="">Select a Condition</option>
                                <option value="Supplier Damaged">Supplier Damaged</option>
                                <option value="Customer Damaged">Customer Damaged</option>
                               
                 
                </AvField>
                  </Col>
                </>
              ) : (
              null
              )}
              {OrderStatus === "Approved" ? (
                <>
                  <Col xl={12} md={12}>
                  <AvField
                      type="select"
                      name="approved_item_condition"
                      label="Item Condition"
                      className="form-select"
                    >
                
                <option value="">Select a Condition</option>
                                <option value="Item Received with Damaged Condition">Item Received with Damaged Condition</option>
                                <option value="Item Received with Good condition">Item Received with Good condition</option>
                               
                 
                </AvField>
                  </Col>
                </>
              ) : (
              null
              )}
              <Col xl={12} md={12}>
                <AvField
                      type="select"
                      name="returnQty"
                      label="Return Qty*"
                      className="form-select"
                      onChange={(e) => setQtyStatus(e.target.value)}
                    >
                  {counter?.map((item,inx) => {
               
                    return <option key={inx} selected>{item}</option>;
                  })}
                </AvField>
              </Col>
              <Col xl={12} md={12}>
              <AvField
                  name="remarks"
                  label="Remarks"
                  value={textRemarks}
                  onChange={event => setTextRemarks(event.target.value)}
                  onFocus = {()=>{
                    setTextRemarksError(null)
                 }}
                  className="form-control"
                  type="textarea"
           
              
                />
              </Col>
              <span style={{color:"red"}}>{textRemarksError}</span>

              <Col xl={12} md={12}>
              <button type="submit" className="btn btn-primary">
                  {updateStsLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "}
                  Submit
                </button>
              </Col>
            </AvForm>
          </CardBody>
        </Card>
        
      }
         <Toaster position="top-right" reverseOrder={false} />
    </>
  );
  
}
export default ReturnOrderUpdateDetailsForm;