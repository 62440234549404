import axios from "axios";
import { setSettingsData, getUpdateSettings, dmsExpressDate,getdmsExpressDate,updatedmsExpressDate } from "../../../Redux/settings/settingSlice";
import UserAuthKey from "../../../components/Common/UserAuthKey";
import { setUserData } from "../../../Redux/user/userSlice";

const apiUrl = process.env.REACT_APP_OMS_API;

export const fetchSettingData= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/settings/settings-data`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setSettingsData(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
  
};
export const SettingUpdateData= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/settings/update-settings`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getUpdateSettings(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
 
};
export const SettingdmsExpressDate= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/settings/dms-expressdate`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(dmsExpressDate(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }

};
export const getDmsExpressDateDetail= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/settings/get-dms-expressdate`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getdmsExpressDate(response?.data));

  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
 
};
export const updateExpressDateDetail= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/settings/update-dms-expressdate`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(updatedmsExpressDate(response?.data));

  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
 
};





