
import React ,{useEffect, useState} from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
  import {  Form, Container, Row, Col, Card, FormGroup, CardBody, CardTitle, CardHeader, Collapse, CardSubtitle, Label, Button,  Spinner } from "reactstrap";

  import { AvForm, AvField , AvCheckboxGroup, AvCheckbox} from "availity-reactstrap-validation";
  import { Editor } from "react-draft-wysiwyg";
  import draftToHtml from "draftjs-to-html";
  import htmlToDraft from "html-to-draftjs";
  import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
  import { EditorState, ContentState, convertFromHTML,convertToRaw,CompositeDecorator } from 'draft-js'
  import { useParams } from 'react-router-dom';
  import { useDispatch, useSelector } from "react-redux";
  import { fetchSettingData } from "./action/action";

  import classnames from "classnames"
 
const TemplateSettings = () => {
    const [activeTab, setactiveTab] = useState("1")
  const [activeTab1, setactiveTab1] = useState("5")
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [headerTab, setHeaderTab] = useState("1")
  const [headerPillsTab, setHeaderPillsTab] = useState("1")
  const [type, setType] = useState(4)
  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)
  const [col4, setcol4] = useState(false)
  const [col5, setcol5] = useState(false)
  const [col6, setcol6] = useState(false)
  const [col7, setcol7] = useState(false)
  const [col8, setcol8] = useState(false)
  const [col9, setcol9] = useState(false)

 

  const toggleHeader = tab => {
      if (headerTab !== tab) setHeaderTab(tab);
  }
  const toggleHeaderPills = tab => {
      if (headerPillsTab !== tab) setHeaderPillsTab(tab);
  }


  const t_col1 = () => {
      setType(4)
      setcol1(!col1)
      setcol2(false)
      setcol3(false)
  }

  const t_col2 = () => {
    setType(5)
      setcol2(!col2)
      setcol1(false)
      setcol3(false)
  }

  const t_col3 = () => {
    setType(6)
      setcol3(!col3)
      setcol1(false)
      setcol2(false)
  }
  const t_col4 = () => {
    setType(7)
    setcol4(!col4)
    setcol1(false)
    setcol2(false)
    setcol3(false)
}
const t_col5 = () => {
    setType(8)
    setcol5(!col5)
    setcol1(false)
    setcol2(false)
    setcol3(false)
    setcol4(false)
}
const t_col6 = () => {
    setType(9)
    setcol6(!col6)
    setcol1(false)
    setcol2(false)
    setcol3(false)
    setcol4(false)
    setcol5(false)
}
const t_col7 = () => {
    setType(10)
    setcol7(!col7)
    setcol1(false)
    setcol2(false)
    setcol3(false)
    setcol4(false)
    setcol5(false)
    setcol6(false)
}
const t_col8 = () => {
    setType(11)
    setcol8(!col8)
    setcol1(false)
    setcol2(false)
    setcol3(false)
    setcol4(false)
    setcol5(false)
    setcol6(false)
    setcol7(false)

}
const t_col9 = () => {
    setType(12)
    setcol9(!col9)
    setcol1(false)
    setcol2(false)
    setcol3(false)
    setcol4(false)
    setcol5(false)
    setcol6(false)
    setcol7(false)
    setcol8(false)

}




 
  

  const toggle = tab => {
      if (tab !== tab) {
          setactiveTab(tab)
      }
  }


  const toggle1 = tab => {
      if (activeTab1 !== tab) {
          setactiveTab1(tab)
          
      }
  }

  const toggleVertical = tab => {
      if (verticalActiveTab !== tab) {
          setverticalActiveTab(tab)
      }
  }
  const toggleCustom = tab => {
      if (customActiveTab !== tab) {
          setcustomActiveTab(tab)
      }
  }
  
  const title = "Orders";
  const description = "Manage Orders";
 // const countryAll = "All";
 const dispatch = useDispatch();
 const { id } = useParams();
 const { settingList = [], isLoading } = useSelector(
    (state) => state.setting
  );
  
    useEffect(() => {
       
      fetchSettingData({
          params: {
            country : "All",
            type:type
          },
          dispatch,
        })
       
      
      },[]);
   
      const settingData = settingList?.data;
    

    const createState = (html) => {

        // return EditorState.createWithContent(ContentState.createFromText(html));
        const blocksFromHTML = convertFromHTML(html);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        return EditorState.createWithContent(state,decorator);
       };
       const ControlledEditor = ({ htmlContent }) => {
         // define the local state, using the createState callback to create the initial value
         const [editorState, setEditorState] = useState(createState(htmlContent));
       
         // override the local state any time that the props change
         useEffect(() => {
           setEditorState(createState(htmlContent));
         }, [htmlContent]);
       
       const onEditorStateChange = () =>{
         setEditorState(createState(htmlContent));
       }
         return (
           <Editor
             editorState={editorState}
             onEditorStateChange={setEditorState}
           />
         );
       };
     
      const logState = () => {
         const content = EditorState.getCurrentContent();
     
       };
       const Image = (props) => {
         const {
           height,
           src,
           width,
         } = props.contentState.getEntity(props.entityKey).getData();
     
         return (
           <img src={src} height={height} width={width} />
         );
       };
       const Link = (props) => {
         const {url} = props.contentState.getEntity(props.entityKey).getData();
         return (
           <a href={url} style={styles.link}>
             {props.children}
           </a>
         );
       };
     
       const styles = {
         root: {
           fontFamily: '\'Helvetica\', sans-serif',
           padding: 20,
           width: 600,
         },
         editor: {
           border: '1px solid #ccc',
           cursor: 'text',
           minHeight: 80,
           padding: 10,
         },
         button: {
           marginTop: 10,
           textAlign: 'center',
         },
       };
     
       const findLinkEntities =(contentBlock, callback, contentState) => {
         contentBlock.findEntityRanges(
           (character) => {
             const entityKey = character.getEntity();
             return (
               entityKey !== null &&
               contentState.getEntity(entityKey).getType() === 'LINK'
             );
           },
           callback
         );
       }
       const findImageEntities = (contentBlock, callback, contentState) => {
         contentBlock.findEntityRanges(
           (character) => {
             const entityKey = character.getEntity();
             return (
               entityKey !== null &&
               contentState.getEntity(entityKey).getType() === 'IMAGE'
             );
           },
           callback
         );
       }
       const decorator = new CompositeDecorator([
         {
           strategy: findLinkEntities,
           component: Link,
         },
         {
           strategy: findImageEntities,
           component: Image,
         },
       ]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Template settings </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Settings" breadcrumbItem="Tempalate Settings" />
            </Col>
          
           
          </Row>

          <Row>
                        <Col xl={12}>
                            <Card>
                            
                                <CardBody>
                                    <div className="accordion" id="accordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col1 }
                                                    )}
                                                    type="button"
                                                    onClick={t_col1}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Order Cancel
                                                </button>
                                            </h2>

                                            <Collapse isOpen={col1} className="accordion-collapse">
                                                <div className="accordion-body">
                                                    <div className="text-muted">
                                                    <Row style={{display:"flex",
      flexDirection:"row",
     justifyContent : "center"
}}>
                    <Col lg={9}>
                        <Card>
                            <CardBody>
                              
                                <AvForm
                                 onValidSubmit={(e, v) => {
                                    console.log(e, v);
                                    // handleBulkStatus(e, v);
                                  }}
                                >
                             
                                 <div className="mb-3">
                                        <AvField
                                            name="toaddress"
                                            label="To Address "
                                            
                                            type="text"
                                             value= {settingData?.[0]?.value}
                                           
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="ccaddress"
                                            label="CC Address "
                                            
                                            type="text"
                                            value= {settingData?.[1]?.value}
                                           
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="bccaddress"
                                            label="BCC Address "
                                            
                                            type="text"
                                            value= {settingData?.[2]?.value}
                                           
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="fromaddress"
                                            label="From Address "
                                            
                                            type="text"
                                            value= {settingData?.[3]?.value}
                                           
                                        
                                        />
                                    </div>

                                  
                                    <div className="mb-3">
                                        <AvField
                                            name="subject"
                                            label="Subject"
                                            
                                            type="text"
                                            value= {settingData?.[4]?.value}
                                           
                                        
                                        />
                                    </div>
                                    <div className="mb-3">

 <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle>Body</CardTitle>
                                    <Form method="post">
                                    {settingData?.[5]?.value !==  undefined ? (<>
                                         <ControlledEditor htmlContent={settingData?.[5]?.value} />
                                     {/*   <input
                                        onClick={logState}
                                        style={styles.button}
                                        type="button"
                                        value="Log State"
                                    /> */}
                                 </>):(<></>)}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                                  
</div>
                                  
                                  
                                   
                    



                                    <div className="d-flex flex-wrap gap-2">
                                        <Button type="submit" color="primary" className="">
                                        {/* {updatebulkLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "} */}
                                            Save
                                        </Button>{" "}
                                        <Button type="reset" color="secondary" className="">
                                            Cancel
                                        </Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>

                </Row> 
          
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col2 }
                                                    )}
                                                    type="button"
                                                    onClick={t_col2}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Order Shipped
                                                </button>
                                            </h2>

                                            <Collapse isOpen={col2} className="accordion-collapse">
                                            <div className="accordion-body">
                                                    <div className="text-muted">
                                                    <Row style={{display:"flex",
      flexDirection:"row",
     justifyContent : "center"
}}>
                    <Col lg={9}>
                        <Card>
                            <CardBody>
                              
                                <AvForm
                                 onValidSubmit={(e, v) => {
                                    console.log(e, v);
                                    // handleBulkStatus(e, v);
                                  }}
                                >
                             
                                 <div className="mb-3">
                                        <AvField
                                            name="toaddress"
                                            label="To Address "
                                            
                                            type="text"
                                            value= {settingData?.[0]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="ccaddress"
                                            label="CC Address "
                                            
                                            type="text"
                                            value= {settingData?.[1]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="bccaddress"
                                            label="BCC Address "
                                            
                                            type="text"
                                            value= {settingData?.[2]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="fromaddress"
                                            label="From Address "
                                            
                                            type="text"
                                            value={settingData?.[3]?.value}
                                            disabled
                                        
                                        />
                                    </div>

                                  
                                    <div className="mb-3">
                                        <AvField
                                            name="subject"
                                            label="Subject"
                                            
                                            type="text"
                                            value= {settingData?.[4]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">

 <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle>Body</CardTitle>
                                   

                                    <Form method="post">
                                    {settingData?.[5]?.value !==  undefined ? (<>
                                         <ControlledEditor htmlContent={settingData?.[5]?.value} />
                                     {/*   <input
                                        onClick={logState}
                                        style={styles.button}
                                        type="button"
                                        value="Log State"
                                    /> */}
                                 </>):(<></>)}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                                  
</div>
                                  
                                  
                                   
                    



                                    <div className="d-flex flex-wrap gap-2">
                                        <Button type="submit" color="primary" className="">
                                        {/* {updatebulkLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "} */}
                                            Save
                                        </Button>{" "}
                                        <Button type="reset" color="secondary" className="">
                                            Cancel
                                        </Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>

                </Row> 
          
                                                    </div>
                                                </div>

                                            </Collapse>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col3 }
                                                    )}
                                                    type="button"
                                                    onClick={t_col3}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Order Delivered
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col3} className="accordion-collapse">
                                            <div className="accordion-body">
                                                    <div className="text-muted">
                                                    <Row style={{display:"flex",
      flexDirection:"row",
     justifyContent : "center"
}}>
                    <Col lg={9}>
                        <Card>
                            <CardBody>
                              
                                <AvForm
                                 onValidSubmit={(e, v) => {
                                    console.log(e, v);
                                    // handleBulkStatus(e, v);
                                  }}
                                >
                             
                                 <div className="mb-3">
                                        <AvField
                                            name="toaddress"
                                            label="To Address "
                                            
                                            type="text"
                                            value= {settingData?.[0]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="ccaddress"
                                            label="CC Address "
                                            
                                            type="text"
                                            value= {settingData?.[1]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="bccaddress"
                                            label="BCC Address "
                                            
                                            type="text"
                                            value= {settingData?.[2]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="fromaddress"
                                            label="From Address "
                                            
                                            type="text"
                                            value= {settingData?.[3]?.value}
                                            disabled
                                        
                                        />
                                    </div>

                                  
                                    <div className="mb-3">
                                        <AvField
                                            name="subject"
                                            label="Subject"
                                            
                                            type="text"
                                            value= {settingData?.[4]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">

 <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle>Body</CardTitle>
                                   

                                    <Form method="post">
                                    {settingData?.[5]?.value !==  undefined ? (<>
                                         <ControlledEditor htmlContent={settingData?.[5]?.value} />
                                     {/*   <input
                                        onClick={logState}
                                        style={styles.button}
                                        type="button"
                                        value="Log State"
                                    /> */}
                                 </>):(<></>)}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                                  
</div>
                                  
                                  
                                   
                    



                                    <div className="d-flex flex-wrap gap-2">
                                        <Button type="submit" color="primary" className="">
                                        {/* {updatebulkLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "} */}
                                            Save
                                        </Button>{" "}
                                        <Button type="reset" color="secondary" className="">
                                            Cancel
                                        </Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>

                </Row> 
          
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col4 }
                                                    )}
                                                    type="button"
                                                    onClick={t_col4}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                   Order Feedback
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col4} className="accordion-collapse">
                                            <div className="accordion-body">
                                                    <div className="text-muted">
                                                    <Row style={{display:"flex",
      flexDirection:"row",
     justifyContent : "center"
}}>
                    <Col lg={9}>
                        <Card>
                            <CardBody>
                              
                                <AvForm
                                 onValidSubmit={(e, v) => {
                                    console.log(e, v);
                                    // handleBulkStatus(e, v);
                                  }}
                                >
                             
                                 <div className="mb-3">
                                        <AvField
                                            name="toaddress"
                                            label="To Address "
                                            
                                            type="text"
                                            value= {settingData?.[0]?.value}
                                            
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="ccaddress"
                                            label="CC Address "
                                            
                                            type="text"
                                            value= {settingData?.[1]?.value}
                                            
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="bccaddress"
                                            label="BCC Address "
                                            
                                            type="text"
                                            value= {settingData?.[2]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="fromaddress"
                                            label="From Address "
                                            
                                            type="text"
                                            value= {settingData?.[3]?.value}
                                            disabled
                                        
                                        />
                                    </div>

                                  
                                    <div className="mb-3">
                                        <AvField
                                            name="subject"
                                            label="Subject"
                                            
                                            type="text"
                                            value= {settingData?.[4]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">

 <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle>Body</CardTitle>
                                   

                                    <Form method="post">
                                    {settingData?.[5]?.value !==  undefined ? (<>
                                         <ControlledEditor htmlContent={settingData?.[5]?.value} />
                                     {/*   <input
                                        onClick={logState}
                                        style={styles.button}
                                        type="button"
                                        value="Log State"
                                    /> */}
                                 </>):(<></>)}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                                  
</div>
                                  
                                  
                                   
                    



                                    <div className="d-flex flex-wrap gap-2">
                                        <Button type="submit" color="primary" className="">
                                        {/* {updatebulkLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "} */}
                                            Save
                                        </Button>{" "}
                                        <Button type="reset" color="secondary" className="">
                                            Cancel
                                        </Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>

                </Row> 
          
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col6 }
                                                    )}
                                                    type="button"
                                                    onClick={t_col6}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                  Order Pending payment
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col6} className="accordion-collapse">
                                            <div className="accordion-body">
                                                    <div className="text-muted">
                                                    <Row style={{display:"flex",
      flexDirection:"row",
     justifyContent : "center"
}}>
                    <Col lg={9}>
                        <Card>
                            <CardBody>
                              
                                <AvForm
                                 onValidSubmit={(e, v) => {
                                    console.log(e, v);
                                    // handleBulkStatus(e, v);
                                  }}
                                >
                             
                                 <div className="mb-3">
                                        <AvField
                                            name="toaddress"
                                            label="To Address "
                                            
                                            type="text"
                                            value= {settingData?.[0]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="ccaddress"
                                            label="CC Address "
                                            
                                            type="text"
                                            value= {settingData?.[1]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="bccaddress"
                                            label="BCC Address "
                                            
                                            type="text"
                                            value= {settingData?.[2]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="fromaddress"
                                            label="From Address "
                                            
                                            type="text"
                                            value= {settingData?.[3]?.value}
                                            disabled
                                        
                                        />
                                    </div>

                                  
                                    <div className="mb-3">
                                        <AvField
                                            name="subject"
                                            label="Subject"
                                            
                                            type="text"
                                            value= {settingData?.[4]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">

 <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle>Body</CardTitle>
                                   

                                    <Form method="post">
                                    {settingData?.[5]?.value !==  undefined ? (<>
                                         <ControlledEditor htmlContent={settingData?.[5]?.value} />
                                     {/*   <input
                                        onClick={logState}
                                        style={styles.button}
                                        type="button"
                                        value="Log State"
                                    /> */}
                                 </>):(<></>)}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                                  
</div>
                                  
                                  
                                   
                    



                                    <div className="d-flex flex-wrap gap-2">
                                        <Button type="submit" color="primary" className="">
                                        {/* {updatebulkLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "} */}
                                            Save
                                        </Button>{" "}
                                        <Button type="reset" color="secondary" className="">
                                            Cancel
                                        </Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>

                </Row> 
          
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col7 }
                                                    )}
                                                    type="button"
                                                    onClick={t_col7}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Return Accept
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col7} className="accordion-collapse">
                                            <div className="accordion-body">
                                                    <div className="text-muted">
                                                    <Row style={{display:"flex",
      flexDirection:"row",
     justifyContent : "center"
}}>
                    <Col lg={9}>
                        <Card>
                            <CardBody>
                              
                                <AvForm
                                 onValidSubmit={(e, v) => {
                                    console.log(e, v);
                                    // handleBulkStatus(e, v);
                                  }}
                                >
                             
                                 <div className="mb-3">
                                        <AvField
                                            name="toaddress"
                                            label="To Address "
                                            
                                            type="text"
                                            value= {settingData?.[0]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="ccaddress"
                                            label="CC Address "
                                            
                                            type="text"
                                            value= {settingData?.[1]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="bccaddress"
                                            label="BCC Address "
                                            
                                            type="text"
                                            value= {settingData?.[2]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="fromaddress"
                                            label="From Address "
                                            
                                            type="text"
                                            value= {settingData?.[3]?.value}
                                            disabled
                                        
                                        />
                                    </div>

                                  
                                    <div className="mb-3">
                                        <AvField
                                            name="subject"
                                            label="Subject"
                                            
                                            type="text"
                                            value= {settingData?.[4]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">

 <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle>Body</CardTitle>
                                   

                                    <Form method="post">
                                    {settingData?.[5]?.value !==  undefined ? (<>
                                         <ControlledEditor htmlContent={settingData?.[5]?.value} />
                                     {/*   <input
                                        onClick={logState}
                                        style={styles.button}
                                        type="button"
                                        value="Log State"
                                    /> */}
                                 </>):(<></>)}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                                  
</div>
                                  
                                  
                                   
                    



                                    <div className="d-flex flex-wrap gap-2">
                                        <Button type="submit" color="primary" className="">
                                        {/* {updatebulkLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "} */}
                                            Save
                                        </Button>{" "}
                                        <Button type="reset" color="secondary" className="">
                                            Cancel
                                        </Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>

                </Row> 
          
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col8}
                                                    )}
                                                    type="button"
                                                    onClick={t_col8}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Return Reject
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col8} className="accordion-collapse">
                                            <div className="accordion-body">
                                                    <div className="text-muted">
                                                    <Row style={{display:"flex",
      flexDirection:"row",
     justifyContent : "center"
}}>
                    <Col lg={9}>
                        <Card>
                            <CardBody>
                              
                                <AvForm
                                 onValidSubmit={(e, v) => {
                                    console.log(e, v);
                                    // handleBulkStatus(e, v);
                                  }}
                                >
                             
                                 <div className="mb-3">
                                        <AvField
                                            name="toaddress"
                                            label="To Address "
                                            
                                            type="text"
                                            value= {settingData?.[0]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="ccaddress"
                                            label="CC Address "
                                            
                                            type="text"
                                            value= {settingData?.[1]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="bccaddress"
                                            label="BCC Address "
                                            
                                            type="text"
                                            value= {settingData?.[2]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="fromaddress"
                                            label="From Address "
                                            
                                            type="text"
                                            value= {settingData?.[3]?.value}
                                            disabled
                                        
                                        />
                                    </div>

                                  
                                    <div className="mb-3">
                                        <AvField
                                            name="subject"
                                            label="Subject"
                                            
                                            type="text"
                                            value= {settingData?.[4]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">

 <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle>Body</CardTitle>
                                   

                                    <Form method="post">
                                    {settingData?.[5]?.value !==  undefined ? (<>
                                         <ControlledEditor htmlContent={settingData?.[5]?.value} />
                                     {/*   <input
                                        onClick={logState}
                                        style={styles.button}
                                        type="button"
                                        value="Log State"
                                    /> */}
                                 </>):(<></>)}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                                  
</div>
                                  
                                  
                                   
                    



                                    <div className="d-flex flex-wrap gap-2">
                                        <Button type="submit" color="primary" className="">
                                        {/* {updatebulkLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "} */}
                                            Save
                                        </Button>{" "}
                                        <Button type="reset" color="secondary" className="">
                                            Cancel
                                        </Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>

                </Row> 
          
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col9}
                                                    )}
                                                    type="button"
                                                    onClick={t_col9}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Return Authorized
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col9} className="accordion-collapse">
                                            <div className="accordion-body">
                                                    <div className="text-muted">
                                                    <Row style={{display:"flex",
      flexDirection:"row",
     justifyContent : "center"
}}>
                    <Col lg={9}>
                        <Card>
                            <CardBody>
                              
                                <AvForm
                                 onValidSubmit={(e, v) => {
                                    console.log(e, v);
                                    // handleBulkStatus(e, v);
                                  }}
                                >
                             
                                 <div className="mb-3">
                                        <AvField
                                            name="toaddress"
                                            label="To Address "
                                            
                                            type="text"
                                            value={settingData?.[0]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="ccaddress"
                                            label="CC Address "
                                            
                                            type="text"
                                            value= {settingData?.[1]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="bccaddress"
                                            label="BCC Address "
                                            
                                            type="text"
                                            value= {settingData?.[2]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="fromaddress"
                                            label="From Address "
                                            
                                            type="text"
                                            value= {settingData?.[3]?.value}
                                            disabled
                                        
                                        />
                                    </div>

                                  
                                    <div className="mb-3">
                                        <AvField
                                            name="subject"
                                            label="Subject"
                                            
                                            type="text"
                                            value= {settingData?.[4]?.value}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">

 <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle>Body</CardTitle>
                                   

                                    <Form method="post">
                                    {settingData?.[5]?.value !==  undefined ? (<>
                                         <ControlledEditor htmlContent={settingData?.[5]?.value} />
                                     {/*   <input
                                        onClick={logState}
                                        style={styles.button}
                                        type="button"
                                        value="Log State"
                                    /> */}
                                 </>):(<></>)}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                                  
</div>
                                  
                                  
                                   
                    



                                    <div className="d-flex flex-wrap gap-2">
                                        <Button type="submit" color="primary" className="">
                                        {/* {updatebulkLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "} */}
                                            Save
                                        </Button>{" "}
                                        <Button type="reset" color="secondary" className="">
                                            Cancel
                                        </Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>

                </Row> 
          
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                     

                    </Row>

        
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TemplateSettings;
