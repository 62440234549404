import React ,{useEffect} from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../../components/Common/Breadcrumb";
import DeleteOrdersList from "./DeleteOrders";


import {
   Row,
   Col,
    Container
 
  } from "reactstrap";

const DeleteOrders = () => {


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Delete Orders</title>
        </MetaTags>
        
        <Container fluid>
        <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Orders" breadcrumbItem="Delete Orders" />
            </Col>
        
           
          </Row>
            <DeleteOrdersList  />
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DeleteOrders;
