import Lottie from 'lottie-react';
import loading from '../../../../../assets/lottie/loading.json';

import loadingScreenStyle from './loadingScreenStyle.module.scss';
import { useSelector } from 'react-redux';

function LoadingScreen() {
  const { pageLoader } = useSelector((state) => state?.createorder);
  if (!pageLoader) {
    return null;
  }
  return (
    <>
      <div className={loadingScreenStyle.loadingContain}>
        <Lottie animationData={loading} loop={true} style={{ width: 40, height: 40 }} />
      </div>
    </>
  );
}

export default LoadingScreen;
