import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './i18n';
import { Provider } from 'react-redux';
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import store from './store';

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_SMILE_API}` });
const authLink = setContext((_, { headers }) => {
  const currentCountry = localStorage.getItem('WEBSITE_COUNTRY');

  const countryLanguage = localStorage.getItem('CUSTOMER_LANGUAGE');

  const token = process.env.REACT_APP_OMS_API_KEY;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      country: currentCountry === 'all' ? 'ae' : currentCountry,
      language: countryLanguage === null ? 'en' : countryLanguage,
      dhintapp: 'oms_wagon',
    },
  };
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

// const client = new ApolloClient({
//   uri: 'http://localhost:8080/graphql',
//   cache: new InMemoryCache(),
// });

const app = (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
);

ReactDOM.render(app, document.getElementById('root'));
