import React, { useEffect, useState } from "react";
import axios from "axios";
import MetaTags from "react-meta-tags";
import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../components/Common/Breadcrumb";
import {
  ProductsFeedBackDetails,
  ProductsFeedBackUpdate,
} from "./action/productsAction";

import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

import toast, { Toaster } from "react-hot-toast";

const ProductFeedbackDetail = () => {
  const dispatch = useDispatch();
  const [updateLoad, setUpdateDetailLoad] = useState(false);
  const { productsFeedBackDetail = [], isDetailLoading } = useSelector(
    (state) => state.products
  );
  const feedbackData = productsFeedBackDetail;
  const feedbackDatas = feedbackData?.data;
  const feedbackStatus = feedbackDatas?.[0]?.status;
  let feedbackStsArr = [];
  if (feedbackStatus === "0") {
    feedbackStsArr = ["Pending", "Completed"];
  } else {
    feedbackStsArr = ["Completed"];
  }
  const { id } = useParams();

  useEffect(() => {
    ProductsFeedBackDetails({
      params: {
        id: id,
      },
      dispatch,
    });
  }, []);
  const handleFeedbackStatus = (events,values) => {
    setUpdateDetailLoad(true);
    const feedBackRemark = values?.remarks ? values?.remarks : "";
    const status = values?.status ? values?.status : "";

    ProductsFeedBackUpdate({
      params: {
        id: id,
        remark: feedBackRemark,
        status: status,
      },
      dispatch,
    });
  };
  const { productsFeedBackUpdate = [], isUpdateLoading } = useSelector(
    (state) => state.products
  );
  useEffect(() => {
    if(productsFeedBackUpdate?.code === 100) {
      setUpdateDetailLoad(false);
      toast.success(productsFeedBackUpdate?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
    } else {
      if(productsFeedBackUpdate?.message) {
        toast.error(productsFeedBackUpdate?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [productsFeedBackUpdate]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Product Feedback"
                breadcrumbItem="Product Feedback Detail"
              />
            </Col>
          </Row>

          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleFeedbackStatus(e, v);
                    }}
                  >
                    <div className="mb-3">
                      <AvField
                        name="email"
                        label="Email  "
                        placeholder="Email"
                        type="text"
                        value={feedbackDatas?.[0]?.email || ''}
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="number"
                        label="Mobile Number  "
                        placeholder="Mobile Number"
                        type="number"
                        value={feedbackDatas?.[0]?.mobile || ''}
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="sku"
                        label="Sku"
                        value={feedbackDatas?.[0]?.sku || ''}
                        disabled
                        className="form-control"
                        type="text"
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="country"
                        label="Country"
                        placeholder=""
                        type="text"
                        value={feedbackDatas?.[0]?.country || ''}
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="reason"
                        label="Reason"
                        value={feedbackDatas?.[0]?.reason || ''}
                        className="form-control"
                        type="textarea"
                        disabled
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="feedback"
                        label="Feedback"
                        value={feedbackDatas?.[0]?.feedback || ''}
                        className="form-control"
                        type="textarea"
                        disabled
                        required
                        style={{ minHeight: "100px" }}
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="remarks"
                        label="Remarks"
                        value={feedbackDatas?.[0]?.remark || ''}
                        className="form-control"
                        type="textarea"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        type="select"
                        name="status"
                        label="Status*"
                        className="form-select"
                      >
                        {feedbackStsArr?.map((item, inx) => {
                          return (
                            <option
                              key={inx}
                              value={item === "Pending" ? "0" : "1"}
                            >
                              {item}
                            </option>
                          );
                        })}
                      </AvField>
                    </div>

                    <div className="d-flex flex-wrap gap-2">
                      <button type="submit" className="btn btn-success">
                        {updateLoad === true && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}{" "}
                        Save
                      </button>{" "}
                      <button type="reset" className="btn btn-secondary">
                        Cancel
                      </button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default ProductFeedbackDetail;
