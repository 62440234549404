import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { useState, useEffect } from 'react';

export default function CustomRichTextEditor({
  updateArticleContent,
  content,
  data_rtl,
}) {
  const [code, setCode] = useState('');

  useEffect(() => {
    updateArticleContent(code);
  }, [code]);

  return (
    <div style={{ marginTop: 20 }}>
      <SunEditor
        setContents={content}
        setAllPlugins
        defaultValue={content}
        onChange={setCode}
        setOptions={{
          buttonList: [
            ['undo', 'redo'],
            ['font', 'fontSize'],
            ['paragraphStyle', 'blockquote'],
            [
              'bold',
              'underline',
              'italic',
              'strike',
              'subscript',
              'superscript',
            ],
            ['fontColor', 'hiliteColor'],
            ['align', 'list', 'lineHeight'],
            ['outdent', 'indent'],
            ['table', 'horizontalRule', 'link', 'image', 'video'],
            ['fullScreen', 'showBlocks', 'codeView'],
            ['preview', 'print'],
            ['removeFormat'],
          ],
          defaultTag: 'div',
          minHeight: '300px',
          showPathLabel: false,
          rtl: data_rtl,

          attributesWhitelist: {
            all: 'style|id|class|data-.+', // Apply to all tags
            '???': '*', // "*" === all attributes
          },
          templates: {
            name: 'Template-1',
            html: '<div>HTML source1</div>',
          },
        }}
      />
    </div>
  );
}
