import React, { useState } from 'react';
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
//import OrderList from './components/orderList';

import "react-datepicker/dist/react-datepicker.css";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import PermissionList from "./components/PermissionList";
import UserForm from "./components/UserForm";
import Flatpickr from "react-flatpickr"

import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
    Button
  } from "reactstrap";


const Permissions = () => {
 
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col >
              <DashBreadcrumb title="Users Detail" breadcrumbItem="User" />
            </Col>
            
             <Col className="customerButton textAlignRight mb-4">
            <a
                              href={`/UserForm`}
                              type="button"
                              className="btn btn-primary"
                            >
                                New User
                            </a>
             </Col>
          </Row>
       

    
        <PermissionList/> 
          
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Permissions;
