import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  DEFAULT_LANGUAGE,
  DEFAULT_COUNTRY,
  AUTH_TOKEN,
  CURRENT_LANGUAGE,
  CURRENT_COUNTRY,
  DEFAULT_IS_FREE_COD,
  DEFAULT_IS_FREE_SHIPPING,
  DEFAULT_IS_REMOVE_PROMO_PRICES,
} from '../src/constants';
const endpoint = process.env.REACT_APP_WAGON_SERVICE_GRAPHQL_ENDPOINT;
const httpLink = createHttpLink({
  uri: endpoint,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = localStorage?.getItem(AUTH_TOKEN);
  let language = localStorage?.getItem(CURRENT_LANGUAGE) || DEFAULT_LANGUAGE;
  let country = localStorage?.getItem(CURRENT_COUNTRY) || DEFAULT_COUNTRY;
  let isfreecod = localStorage?.getItem('is_cod_free') || DEFAULT_IS_FREE_COD;
  let isfreeshipping =
    localStorage?.getItem('is_shipping_free') || DEFAULT_IS_FREE_SHIPPING;
  let isDisablePromotions =
    localStorage?.getItem('is_remove_promo_prices') ||
    DEFAULT_IS_REMOVE_PROMO_PRICES;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      language: language,
      country: country,
      platform: 'oms',
      isfreecod: isfreecod,
      isfreeshipping: isfreeshipping,
      removeminiumordervalue: true,
      isDisablePromotions,
    },
  };
});

const ApolloClientWagonService = new ApolloClient({
  // ssrMode: true,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
export default ApolloClientWagonService;
