import axios from 'axios';
import UserAuthKey from '../../../../components/Common/UserAuthKey';
import { setUserData } from '../../../../Redux/user/userSlice';
const apiUrl = process.env.REACT_APP_OMS_API;

export const insertOrderShipQustionAnswer = async({ params, dispatch }) => {
  console.log(params,"params")
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/insert-order-qa`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    console.log(response,"response")
    if (response?.data?.code === 100) {
       return true;
       }
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
};
export const getQuestionAnswer = (data, dispatch) => {
  const bearerToken = UserAuthKey();

  return axios({
    method: 'post',
    url: `${apiUrl}v1/orders/get-qa`,
    data: data,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (response) => {
      if (response?.data?.code === 100) {
        return response?.data?.data;
      } else {
      }
    })
    .catch((error) => {
 
      console.log(error);
    });
};
