import React ,{useEffect,useState} from "react";
import MetaTags from "react-meta-tags"
import {
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    FormGroup,
    Label,
    Button, 
    Spinner
} from "reactstrap"

// import { useQuery, gql } from '@apollo/client';
import { useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { CustomerCountryHeader } from "../../Redux/customers/customerSlice";
import toast, { Toaster } from "react-hot-toast";
import { AvForm, AvField } from "availity-reactstrap-validation"

import { withRouter, Link, useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { CREATE_CUSTOMER } from "./query/Customer";
import classnames from "classnames"
const AddCustomerForm   = () => {
    const [variables, setVariable ] = useState(null);
    const [errorMessage,setErrorMessage] = useState(null);
    const [errMessage,setErrMessage] = useState(null);
    const [saveCustomerLoad, setSaveCustomerLoad] = useState(false);
   
    const dispatch = useDispatch();
    const history = useHistory();
 const [stErr, setStErr] = useState(0);
    const [createCustomer,{errors, data }] = useLazyQuery(CREATE_CUSTOMER);
    
   
   
    
   const handleValidSubmit = async(events, values) => {
    setSaveCustomerLoad(true)
    await createCustomer({
        variables: {firstName: values.firstname,
        lastName:values.lastname ,
        email: values.email,
        mobile:values.mobilenumber,
        otp:"1234",
        password:values.password,
        isSocial:Boolean(values.isSocialLogin),
        source:"admin"
    }})
        
  
   
   }
   useEffect(() => {

    if (data !== null && data !== undefined) {
        if (data?.createCustomerAdmin?._id) {
            setSaveCustomerLoad(false)
        toast.success("Customer Created Successfully", {
            style: {
              border: "1px solid #fff",
              padding: "20px",
              color: "#ffffff",
              background: "#4CAF50",
            },
            iconTheme: {
              primary: "#FFFAEE",
              secondary: "#4CAF50",
            },
          });
        setTimeout(() => {
          history.push("/AllCustomer");
        }, 10);
    }
    }
if (errors !== undefined) {
    setSaveCustomerLoad(false)
    toast.error(errors?.[0]?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#f44336",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#f44336",
        },
      });  
}}, [data, errors]);


    const [verticalActiveTab, setverticalActiveTab] = useState("1")
    const [phoneValue,setPhoneValue] = useState("");
  
    const [headerTab, setHeaderTab] = useState("1")
    const [headerPillsTab, setHeaderPillsTab] = useState("1")
  const[addressCountry, setAddressCountry] = useState('');
const [isValidPhone, setValidPhone] = useState(true);
    const toggleHeader = tab => {
        if (headerTab !== tab) setHeaderTab(tab);
    }
    const toggleHeaderPills = tab => {
        if (headerPillsTab !== tab) setHeaderPillsTab(tab);
    }

    // 
 

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab)
        }
    }
    const handleCountry = e => {
        setAddressCountry(e.target.value);
        localStorage.setItem("CUSTOMER_COUNTRY", e.target.value);
      };
      const handleLanguage = e => {
        
        localStorage.setItem("CUSTOMER_LANGUAGE", e.target.value);
      };
     // const addressCountry =  localStorage.getItem("CUSTOMER_COUNTRY");
      
    const testHandle = e =>{
        let isValid = false;
     
        let phLength = e.target.value.length;
        if(phLength >=8)
        {
        if(addressCountry === "ae"){
        var pattern = new RegExp(/^(?:50|51|52|54|55|56|2|3|4|6|7|9)\d{7}$/);
        if (!pattern.test(e.target.value)) {
           isValid = false 
          
          setErrorMessage('invalid Phone Number');
     
        }
   
        else{
           
         
        }
    }
    else if(addressCountry ==="bh"){
        pattern = new RegExp(/^(?:\+973|00973|0)?(?:30|31|32|33|34|35|36|37|38|39|60|61|62|63|64|65|66|67|68|69)\d{6}$/);
        if (!pattern.test(e.target.value) ) {
            isValid = false;
            setErrorMessage('invalid Phone Number');
          
          }
       
        }
        else if(addressCountry === "om"){
            pattern = new RegExp(/^(?:\+968|00968|0)?(?:71|72|73|74|75|76|77|78|79|91|92|93|94|95|96|97|98|99)\d{6}$/);
            if (!pattern.test(e.target.value)) {
                isValid = false;
                setErrorMessage('invalid Phone Number');
               
              }
              
            }
            else if(addressCountry === "kw"){
              pattern = new RegExp( /^(?:\+965|00965|0)?(?:21|22|23|24|25|26|27|28|29|41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|69|90|91|92|93|94|95|96|97|98|99)\d{6}$/);
              if (!pattern.test(e.target.value)) {
                  isValid = false;
                  setErrorMessage('invalid Phone Number');
        
                }
                
            }
            else {
                pattern = new RegExp(/^(?:\+974|00974|0)?(?:30|31|32|33|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|69|70|71|72|73|74|75|76|77|78|79)\d{6}$/);
                if (!pattern.test(e.target.value)) {
                    isValid = false;
                    setErrorMessage('invalid Phone Number');
                 
                  }
                 
                }
            }
            else
            {
                isValid = true;
                setErrorMessage('');
            }
      }
     
      
   
    return (
        <React.Fragment>
            <div className="page-content">
               
                <Container fluid>
                   
                     <Breadcrumbs title="Customers Detail Form" breadcrumbItem="Customer" /> 
                  
                    <Row>

                        <Col >
                         
                                    <Row>
                                        
                                        <Col md="3">
                                            <Card>
                                            <Nav pills className="flex-column">
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            "mb-2": true,
                                                            active: verticalActiveTab === "1",
                                                        })}
                                                        onClick={() => {
                                                            toggleVertical("1")
                                                        }}
                                                    >
                                                       Account Information
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            "mb-2": true,
                                                            active: verticalActiveTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            toggleVertical("2")
                                                        }}
                                                    >
                                                        
                                                      
                                                    </NavLink>
                                                </NavItem>
                                               
                                            </Nav>
                                            </Card>
                                        </Col>
                                        <Col md="9">
                                            <TabContent
                                                activeTab={verticalActiveTab}
                                                className="text-muted mt-4 mt-md-0"
                                            >
                                                <TabPane tabId="1">
                                                   
                                                <React.Fragment>
             <Col xl={9}>
                <Card>
                   
                   <CardBody>
                        <AvForm className="needs-validation"
                        onValidSubmit={(e, v) => {
                            if(!errorMessage){
                            handleValidSubmit(e, v);
                            }
                            // testHandle(e,v);
                          }}>
                            <Row>
                            
                                <Col md="6">
                                <FormGroup className="mb-3">
                                    <AvField
                      type="select"
                      name="isSocialLogin"
                      label="Is Social Login"
                      className="form-select"
                     value ="false"
                      required
                    > 
                    <option value="">Select</option>
                    <option value="true">Yes</option>
                    <option value="false" selected>No</option>
                       </AvField>
                                 </FormGroup>  
                                </Col>
                                <Col md="6">
                                <FormGroup className="mb-3">
                                    <AvField
                      type="select"
                      name="status"
                      label="Associate to Website"
                      className="form-select"
                      onChange={handleCountry}
                      value =""
                      required
                    > 
                     <option value="">Select</option>
                    <option value="ae">UAE</option>
                    <option value="bh">Bahrain</option>
                    <option value="om">Oman</option>
                    <option value="kw">Kuwait</option>
                    <option value="qa">Qatar</option>
                   
                       </AvField>
                                 </FormGroup>  

                                   
                                </Col>
                                <Col md="6">
                                     <FormGroup className="mb-3">
                                        <AvField
                      type="select"
                      name="status"
                      label="Group"
                      className="form-select"
                     value =""
                    
                    > 
                    <option value="General">General</option>
                  
                       </AvField>
                                    </FormGroup>
                                
                                </Col> 
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom04">Email</Label>
                                        <AvField
                                                name="email"
                                                type="email"
                                                required
                                            />
                                    </FormGroup>
                                </Col>
                                
                                </Row>
                                <Row>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom04">First Name</Label>
                                        <AvField
                                                name="firstname"
                                                type="text"
                                                required
                                            />
                                    </FormGroup>
                                </Col>

                              
                          
                        
                         
                            <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom04">Last Name</Label>
                                        <AvField
                                                name="lastname"
                                                type="text"
                                                required
                                            />
                                    </FormGroup>
                                </Col>
                               
                            </Row>
                     <Row >
                    
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom04">Mobile Number</Label>
                                        <AvField
                                                name="mobilenumber"
                                                type="text"
                                                value={phoneValue}
                                               // onChange={testHandle}
                                               onBlur ={testHandle}
                                               onFocus = {()=>{
                                                setErrorMessage(null)
                                             }}
                                            />
                                         
                                         <span className="text-danger">{errorMessage}</span>
                    
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                            <FormGroup className="mb-3">
                                <AvField
                  type="select"
                  name="language"
                  label="Send Welcome Email From"
                  className="form-select"
                  value=""
                  onChange={handleLanguage}
                 
                > 
                 <option value="">Select</option>
                <option value="en">English</option>
                <option value="ar">Arabic</option>
                   </AvField>
                             </FormGroup>  
                            </Col>
                                
                               </Row> 
                                  
                                <Row >
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom04">Password</Label>
                                        <AvField
                                                name="password"
                                                type="password"
                                                required
                                            />
                                    </FormGroup>
                                </Col>
                                
                           
                            </Row>
                         
                            {/* <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom04">Ls Customer Id</Label>
                                        <AvField
                                                name="customernumber"
                                                type="text"
                                                required
                                            />
                                    </FormGroup>
                                </Col> */}
                       
                          
                            <Button color="primary" type="submit">
                            {saveCustomerLoad === true && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />)}
                                Save Customer
                            </Button>
                           
                        </AvForm>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
                                                </TabPane>
                                             
                                              
                                            </TabContent>
                                        </Col>
                                    </Row>
                             
                        </Col>
                        
                    </Row>
                  
                </Container>
            </div>
            <Toaster position="top-right" reverseOrder={false} />
        </React.Fragment>
    );
}


export default AddCustomerForm ;