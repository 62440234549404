import toast, { Toaster } from "react-hot-toast";
/**
 *
 * @param {*} message
 * @param {*} time
 */
export const SuccessToast = (message) => {
  return toast.success(message, {
    style: {
      border: "1px solid #fff",
      padding: "20px",
      color: "#ffffff",
      background: "#4CAF50",
    },
    iconTheme: {
      primary: "#FFFAEE",
      secondary: "#4CAF50",
    },
  });
};
/**
 *
 * @param {*} message
 * @param {*} time
 */
export const ErrorToast = (message) => {
  return toast.error(message, {
    style: {
      border: "1px solid #fff",
      padding: "20px",
      color: "#ffffff",
      background: "#f44336",
    },
    iconTheme: {
      primary: "#FFFAEE",
      secondary: "#f44336",
    },
  });
};
