import React, { useState } from "react";
import ReactDrawer from "react-drawer";

import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import moment from "moment";

function GiftCardInfo({orderData}) {
  let giftRecepInfo = ""
  if(orderData?.ShippingMethod === "giftcard"){
    giftRecepInfo=JSON?.parse(orderData?.GiftCardInfo)
 
  
    } 
     const [position, setPosition] = useState();
    const [open, setOpen] = useState(false);
   
    const toggleRightDrawer = () => {
        
        setPosition("right");
        setOpen(!open);
      };
      const onDrawerClose = () => {
        setOpen(false);
      };
   
     
     
 
  return (
    <>
    {orderData?.ShippingMethod === "giftcard" ? (<>
    <button
                        className="btnTracking mb-2"
                        onClick={() =>{
                          toggleRightDrawer()
                         
                        }}
                      >
                        Gift Card Info{" "}
                      </button>
                      </>):(<></>)}  
            <ReactDrawer
              open={open}
              position={position}
              onClose={onDrawerClose}
            >
                  <div className="scrollShipNav">
              <Card>
              
                  <CardHeader>
                  <Row>
                  <Col className="textAlignCenter">
                    <CardTitle>Gift Card Recipient Details</CardTitle>
                  </Col>
                  <Col className="textAlignRight">
                    {" "}
                    <button
                      type="button"
                      className="btn-close "
                      onClick={() => {
                        onDrawerClose()
                      }}
                      aria-label="Close"
                    ></button>
                  </Col>
                </Row>
            
                  </CardHeader>
             
    
                 
              </Card>
            
              {giftRecepInfo?.giftCards?.map((item, itemInx) => {
                   let cdate = item?.deliveryDateTime,
                   orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                   orderTime = moment(new Date(cdate)).format("h:mm:ss a");
                 return (
                    <><Card  key ={itemInx} className="cardBackground">
                        <CardBody>
                <Row>
                <Col lg={3}>
                                  <img
                                    alt=""
                                    width="100"
                                    height="100"
                                    className="img-thumbnail"
                                    src={item?.productImage}
                                  />
                                  </Col>

                                  <Col className="cardStyle">
                                    <label>Name: <b>{item?.recipientInfo?.name}</b></label>

                                    <label>Email : <b>{item?.recipientInfo?.email}</b></label>
                                    {item?.recipientInfo?.mobile !== null? ( <label>Mobile :<b>{item?.recipientInfo?.mobile}</b> </label>):(null)}
                                   
                                    <label>Template Type: <b>{item?.giftCardName}</b></label>
                                    <label>Amount : <b>{item?.productPrice}</b></label>
                                    {item?.recipientInfo?.deliveryDateTime !== null ? (<label>Schedule On : <b>{item?.recipientInfo?.deliveryDateTime} </b></label>):(null)} 
                                
                                 

                                  
                                  </Col>
                            
            
                </Row>
            
               
                      
         
         
             </CardBody>
                        </Card></>
                 );
                   })}
              </div>
             
            </ReactDrawer>

    </>
  );
}

export default GiftCardInfo;
