import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  UserRoleList: [],
  UserMenuList: [],
  UserRoleMaster: [],
  isLoading: true,
  isStsLoading: true,
  isStoreLoading: true,
  storesList: [],
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    getUserRoleLists(state, actions) {
      state.UserRoleList = actions.payload;
      state.isLoading = false;
    },
    getUserRoleMaster(state, actions) {
      state.UserRoleMaster = actions.payload;
      state.isLoadingMaster = false;
    },
    getUserView(state, actions) {
      state.UserView = actions.payload;
      state.isLoadingView = false;
    },
    getUserEdit(state, actions) {
      state.UserEdit = actions.payload;
      state.isLoadingEdit = false;
    },
    getUserDelete(state, actions) {
      state.UserDelete = actions.payload;
      state.isLoadingDelete = false;
    },

    roleStatusUpdate(state, actions) {
      state.roleStatus = actions.payload;
      state.isStsLoading = false;
    },
    getUserMenuLists(state, actions) {
      state.UserMenuList = actions.payload;
      state.isStsLoading = false;
    },
    getUserRoleView(state, actions) {
      state.UserMenuView = actions.payload;
      state.isMenuViewLoading = false;
    },

    getUpdateRole(state, actions) {
      state.updateRole = actions.payload;
      state.isUpdateRoleLoading = false;
    },
    getAllUsers(state, actions) {
      state.allUsers = actions.payload;
      state.isAllUsersLoading = false;
    },
    createUsers(state, actions) {
      state.Users = actions.payload;
      state.isUsersLoading = false;
    },
    ClickandCOllectList(state, actions) {
      state.ccList = actions.payload;
      state.isCCListLoading = false;
    },
    CCStoreInsert(state, actions) {
      state.ccDataInsert = actions.payload;
      state.isCCDataInsertLoading = false;
    },
    CCStoreDetail(state, actions) {
      state.ccStoreDetail = actions.payload;
      state.isCCStoreDetailLoading = false;
    },
    CCStoreUpdate(state, actions) {
      state.ccStoreUpdate = actions.payload;
      state.isCCStoreUpdateLoading = false;
    },
    createStore(state, actions) {
      state.store = actions.payload;
      state.isStoreLoading = false;
    },
    getStoresList(state, actions) {
      state.storesList = actions.payload;
      state.isStoreDataLoading = false;
    },
  },
});

export const {
  getUserRoleLists,
  getUserRoleMaster,
  getUserDelete,
  getUserView,
  getUserEdit,
  roleStatusUpdate,
  getUserMenuLists,
  CCStoreUpdate,
  getUpdateRole,
  CCStoreDetail,
  getUserRoleView,
  getAllUsers,
  createUsers,
  ClickandCOllectList,
  CCStoreInsert,
  createStore,
  getStoresList,
} = permissionsSlice.actions;

const permissionsReducer = permissionsSlice.reducer;

export default permissionsReducer;
