import './PaymentMethods.scss';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Label } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { setPaymentMethodCart } from '../../actions/action';
import { SuccessToast, ErrorToast } from '../../../../../utils/Toaster';
import { setPageLoader } from '../../../../../Redux/order/createOrderSlice';
import toast, { Toaster } from 'react-hot-toast';
function PaymentMethodsComponent(props) {
  const dispatch = useDispatch();
  const {
    cart = {},
    showPaymentMethod,
    totalShipingfee,
    showShippingControll,
  } = props || {};
  const { availablePaymentMethods = [] } = cart || {};
  const setPaymentMethod = async (code) => {
    dispatch(setPageLoader(true));
    const response = await setPaymentMethodCart(
      { paymentMethodCode: code },
      dispatch
    );
    if (response) {
      dispatch(setPageLoader(false));
      SuccessToast('Payment method updated');
    } else {
      dispatch(setPageLoader(false));
      ErrorToast('Failed to select payment method');
    }
  };
  const updateCodFee = (event) => {
    localStorage.setItem('is_cod_free', event.target.checked);
    setPaymentMethod(cart.paymentMethod?.code);
  };

  const updateShippingFee = (event) => {
    localStorage.setItem('is_shipping_free', event.target.checked);
    setPaymentMethod(cart.paymentMethod?.code);
  };

  const check_cod_free = localStorage.getItem('is_cod_free');
  const check_shipping_free = localStorage.getItem('is_shipping_free');
  return showPaymentMethod ? (
    <>
      <div className="paymentMethods">
        <div className="contain p-4">
          <h4 className="font-size-16 title pt-3 pb-3 p-4">Payment Methods</h4>
          <Row>
            {availablePaymentMethods.map((item, index) => {
              return item.code !== 'apple_pay' ? (
                <Col className="col-12" key={index}>
                  <div className="paymentCheckBox  mb-2">
                    {item.title.code}
                    <input
                      type="radio"
                      name="Assembly Installation Fee"
                      className="form-check-input me-2"
                      id="assemblyInstallationFee"
                      checked={item.code === cart.paymentMethod.code}
                      onChange={() => {
                        setPaymentMethod(item.code);
                      }}
                    />
                    <label
                      className="form-check-label font-size-15"
                      htmlFor="cod"
                    >
                      {item.title.en}
                      <img
                        className="ms-3"
                        alt={item.logo.src}
                        src={item.logo.src}
                      />
                    </label>
                  </div>
                  {cart.paymentMethod.code == 'cashondelivery' &&
                    item.code == 'cashondelivery' ? (
                    <div className="RemoveCod" onChange={updateCodFee}>
                      <input
                        type="checkbox"
                        name="RemoveCod"
                        checked={check_cod_free != 'true' ? false : true}
                      />
                      <label>Remove COD Fee </label>
                    </div>
                  ) : (
                    ''
                  )}
                </Col>
              ) : (
                ''
              );
            })}
            <div>
              {totalShipingfee > 0 && showShippingControll ? (
                <div className="RemoveCod" onChange={updateShippingFee}>
                  <input
                    type="checkbox"
                    name="RemoveShipping"
                    checked={check_shipping_free != 'true' ? false : true}
                  />
                  <label>Remove Shipping Fee </label>
                </div>
              ) : (
                ''
              )}
            </div>
            <span>
              The payment link is automatically generated and sent to the
              customer
            </span>
          </Row>
        </div>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </>
  ) : (
    ''
  );
}

export default PaymentMethodsComponent;
