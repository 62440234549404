import { Button } from "reactstrap";
import "./CustomerDetails.scss";
import React, { useState, useEffect } from "react";

function CustomerDetailsComponent(props) {
  const { address = {}, setShowmapandAddress, showCustomerDetails } = props || {};

  //console.log('cus',address)
  const [addressData, setAddressData] = useState({});
  useEffect(() => {
    setAddressData({
      ...address,
    });
  }, [address]);
  // const EditAddressFinal = () => {
  //   setShowmapandAddress(false);
  // };
  return showCustomerDetails ? (
    <>
      <div className="customerDetails">
        <div className="contain p-4">
          <h4 className="font-size-16 title pt-3 pb-3 p-4">Customer Details</h4>
          <div className="lh-base">
            <p className="m-0">
              <strong className="font-size-15">
                {addressData.firstname} {addressData.lastname}
              </strong>
            </p>
            <p className="m-0">
              {addressData?.mapData}
            </p>
            <p className="m-0">
              <b>Apartment/Flat No :</b>{addressData.fulladdress}
            </p>
            <p className="m-0"><b>Email : </b>{addressData.email}</p>
            <p className="m-0"><b>Phone :</b> {addressData.mobile}
              {addressData.alternateTelephone ? (<span className="ms-5">&nbsp;<b>Alternate TelPhone :</b> {addressData?.alternateTelephone}</span>) : ("")}
            </p>
            {addressData.companyName ? (<><p></p>
              <p className="m-0"><b>Company Name : </b>{addressData?.companyName}</p>
              <p className="m-0"><b>TRN : </b>{addressData?.trn}</p></>) : ""}

          </div>
          <Button
            className="actionBtn px-3 py-0 mt-2"
            onClick={() => {
              setShowmapandAddress(true);
            }}
          >
            Edit
          </Button>
        </div>
      </div>
    </>
  ) : ('');
}

export default CustomerDetailsComponent;
