/**
 * Modify customer address to shipping address format
 * @param {*} address
 * @returns
 */
export const modify = async (address) => {  
  return {
    _id: address?._id || 'guest',
    countryCode: address?.countryCode,
    firstName: address?.firstname,
    lastName: address?.lastname,
    telephone: address?.mobile, 
    address1: address?.fulladdress,
    address2: address?.fulladdress,
    city: address?.city,
    region: address?.region,
    country: address?.country,
    mapData: address?.mapData,
    latitude: address?.latitude,
    longitude: address?.longitude,
    addressType: address?.addressType,
    flatNo: address?.flatNo,
    plusCode: address?.plusCode,
    alternateTelephone: address?.alternateTelephone,
    companyName: address?.companyName,
    trn: address?.trn,
    isUsedBilling: address?.isUsedBilling,
  };
};
