import axios from 'axios';
import UserAuthKey from '../../components/Common/UserAuthKey';
import { setDashboardList , setStatusCount, setDashboardGraph, setDashboardPieGraph, setCouponSummaryGraph} from '../../Redux/dashboard/dashboardSlice';
import { setUserData } from '../../Redux/user/userSlice';
const apiUrl = process.env.REACT_APP_OMS_API;
export const getDeliveryData = (data, dispatch) => {
    const bearerToken = UserAuthKey();
  
    return axios({
      method: 'post',
      url: `${apiUrl}v1/dashboard/ordersDeliverySummery`,
      data: data,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        if (response?.data?.code === 100) {
          return response?.data?.data;
        } else {
        }
      })
      .catch((error) => {
   
        console.log(error);
      });
  };
  

  export const getDeliveryList = ({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
  
    return axios({
      method: 'post',
      url: `${apiUrl}v1/dashboard/ordersByDeliveryDate`,
      data: {
        ...params,
      },
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        if (response?.data?.code === 100) {
          return response?.data?.data;
        } else {
        }
      })
      .catch((error) => {
   
        console.log(error);
      });
  };
  export const getReviewsSummary = (data, dispatch) => {
    const bearerToken = UserAuthKey();
  
    return axios({
      method: 'post',
      url: `${apiUrl}v1/dashboard/review-summary`,
      data: data,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        if (response?.data?.code === 100) {
          return response?.data?.data;
        } else {
        }
      })
      .catch((error) => {
   
        console.log(error);
      });
  };
  export const getOrderSummaryByMode = (data, dispatch) => {
    const bearerToken = UserAuthKey();
  
    return axios({
      method: 'post',
      url: `${apiUrl}v1/dashboard/order-summary`,
      data: data,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        if (response?.data?.code === 100) {
          return response?.data?.data;
        } else {
        }
      })
      .catch((error) => {
   
        console.log(error);
      });
  };




export const fetchDashboardList= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/dashboard-counts`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setDashboardList(response?.data));

  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
 
};
export const fetchStatausCount= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/status-counts`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setStatusCount(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }   
  }
 
};
export const fetchDashboardGraphdata= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/dashboard-graph`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setDashboardGraph(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
 
};
export const fetchDashboardPieGraphdata= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/dashboard-payment`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
  dispatch(setDashboardPieGraph(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
        dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
  
};
export const fetchOrdersCouponSummary= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/dashboard/order-coupon-summary`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    return response?.data;
  } catch (error) {
    if(error?.response?.status === 401){
        dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
  
};
