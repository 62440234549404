import React, { useState ,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {fetchInvoiceList} from "../action/invoiceAction";
import { useUrlSearchParams } from "use-url-search-params";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import moment from "moment";
import {
    Col,
    Row,
    Spinner,
    Table,
    Badge,DropdownMenu,
    DropdownItem,DropdownToggle,
    Dropdown,
  } from "reactstrap";
  import { Card, Form } from "react-bootstrap";

const InvoiceListData = ({isInvoiceReload, country}) => {
    const dispatch = useDispatch();
    const [btnprimary1, setBtnprimary1] = useState(false);
    const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
    const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
    const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
    const [isDataLoading, setDataLoading] = useState(false);
    const [copiedId, setCopiedId] = useState(null);

    const handleSearch = (e) => {
        setSearchValue({ search: e.target.value });
      };
      const handlePagination = (e) => {
        setPaginationValue({ page: e.target.value });
      };
      const handleLimit = (e) => {
        setLimitValue({ limit: Number(e) });
      };

      const handleCopyData = (data,id) => {
        navigator.clipboard.writeText(data);
        setCopiedId(id);
      };
      const { invoiceList = [], isLoading } = useSelector(
        (state) => state?.ordInvoice
      );
    useEffect(() => {
        if(country){
        setDataLoading(true);
        fetchInvoiceList({
          params: {
            start: Number(paginationValue.page * limitValue.limit),
            limit: Number(limitValue.limit),
            sort_by: "",
            order_by: "id",
            search_qry: searchValue.search,
            country:country
          },
          dispatch,
        });
        setDataLoading(false);
    }
        //setFeedbackReload(false);
      }, [paginationValue.page, limitValue, searchValue.search, isInvoiceReload,country]);
      const limitByValues = [5, 10, 20, 50];
    
     
     
     const invoiceData = invoiceList?.invoiceList?.data;
     let pageIndex = Number(invoiceList?.invoiceList?.start);
    return (
        <>
        <Row className=" dh-TabRow textAlignRight">
        <Col className="MobileView textAlignRight ">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
        <div className="table-responsive dt-table">
        {isLoading || isDataLoading ? (<Spinner className="ms-2 sploader" color="primary" />) : null}
        {invoiceData?.length > 0 ? (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th nowrap="nowrap">Date</th>
                  <th>Reference No</th>
                  <th>Customer Info</th>
                  <th>Amount</th>
                  <th>Gateway</th>
                  <th>Payment Info</th>
                  <th>Country</th>
                  <th>Status</th>
                 
                </tr>
              </thead>
              {(invoiceData || []).map((invoice, index) => {
                pageIndex++;
                let cdate = invoice.created_at,
                  orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                  orderTime = moment(new Date(cdate)).format("h:mm:ss a");
                const expiryDt=moment(new Date(invoice?.request_expiry_date)).format("Do MMM, YYYY h:mm:ss A");
                const isExpired = moment(invoice?.request_expiry_date).isBefore(moment(), "day");

                return (
                  <tbody key={invoice?.id}>
                    <tr>
                      <th scope="row">{pageIndex}</th>
                      <td nowrap="nowrap">
                        {orderDt} <br></br>
                        {orderTime}
                      </td>

                      <td nowrap="nowrap">
                        <span>{invoice?.order_reference}</span><br></br>
                        <span>Payment Link ID : {invoice?.payment_link_id}</span>
                        {invoice?.remarks?(<p>Remarks : {invoice?.remarks}</p>):''}
                      </td>
                      <td nowrap="nowrap">
                        <span>Name : {invoice?.full_name}</span><br></br>
                        <span>Mobile : {invoice?.phone}</span><br></br>
                        <span>Email : {invoice?.email}</span>
                      </td>
                      <td>{invoice?.currency} {(Math.round(invoice?.amount*100)/100).toFixed(2)}</td>
                      <td>{invoice?.payment_mode}</td>
                      <td>
                        <span className={`copy-btn ${copiedId === invoice?.id?'copied':''}`} 
                        onClick={() => { handleCopyData(invoice?.payment_link,invoice?.id);}} ><i className="bx bx-copy-alt"></i> {copiedId === invoice?.id?'Copied':'Copy Link'} </span>
                        <span><a className={'link-btn'} href={invoice?.payment_link} target="_blank"><i className="bx bx-link-external"></i></a></span>
                        <span className={'expire-txt'}>Expire On : {expiryDt}</span>
                  
                      </td>
                      <td>{invoice?.country}</td>
                      <td>
                        <Badge className={`${invoice?.is_done==='1'?'bgSuccess':isExpired?'bgWarning':'bgGrey'} me-2`}>{invoice?.is_done==="1"?'Success':isExpired?'Expired':'Pending'}</Badge>
                      </td>
                      
                   
                    </tr>
                  </tbody>
                );
              })}
            </Table>
            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={invoiceList?.invoiceList?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                <th>SI.No</th>
                  <th nowrap="nowrap">Date</th>
                  <th>Reference No</th>
                  <th>Customer Info</th>
                  <th>Amount</th>
                  <th>Gateway</th>
                  <th>Payment Info</th>
                  <th>Country</th>
                  <th>Status</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
        </div>
        </Col>
      </Row>
      
      </>
    );
};

export default InvoiceListData;