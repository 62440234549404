import { gql } from '@apollo/client';
export const GET_ARTICLE_DATA_DETAIL= gql`
query GET_ARTICLE_DATA_DETAIL(
  $input:dataReqInput!
)
{
    getArticlesDetails(input: $input) {
    _id
    title {
      en
      ar
    }
    slug
    icon
    categoryId
    categoryName
    articleType
    content {
      en
      ar
    }
    shortDescription {
      en
      ar
    }
    metaTitle {
      en
      ar
    }
    metaDescription {
      en
      ar
    }
    metaKeywords {
      en
      ar
    }
    status
    createdBy
  
    createdAt
    position

  }
    }`;