import React ,{useEffect} from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
import MarketPlaceOrderList from "./components/MarketplaceOrderList";
import MarketExportReport from "./components/ExcelReport";




import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
  } from "reactstrap";
 
  import Error403 from "../../components/Common/Error403";

const marketplaceOrders = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { country = [] } = useSelector((state) => state.country);
  let cntry = country;

  if (country !== "All" && country !== null) {
    cntry = country?.toUpperCase();
  }
  

  let UserID = '';
  if (localStorage.getItem("authUser")) {
    const userObj = JSON.parse(localStorage.getItem("authUser"));
    if (userObj?.role_id !== undefined) {
          UserID = userObj?.user_id  
    }
  }
 
  const title = "Orders";
  const description = "Marketplace Orders";
 // const countryAll = "All";

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>MarketPlace Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Orders" breadcrumbItem="MarketPlace Orders" />
            </Col>
            <MarketExportReport />
         
          </Row>
         
         {UserID !== '9'? 
          
             (<><MarketPlaceOrderList  /> </>
         ):(<> <Error403 /></>)}
                
           
        </Container>
      </div>
    </React.Fragment>
  );
};

export default marketplaceOrders;
