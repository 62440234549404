import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [],
  isLoading:true
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserData(state, actions) {
      state.users = actions.payload;
      state.isLoading = false;
    },
    getUserRoleData(state, actions) {
      state.roles = actions.payload;
      state.isLoading = false;
    },
    getRoleResource(state, actions) {
      state.rolesResource = actions.payload;
      state.isLoadingResource = false;
    },
    
    setUpdatedpassword(state, actions) {
      state.updatePassword = actions.payload;
      state.isPasswordLoading = false;
    },

    setResetPassword(state, actions) {
      state.updatePassword = actions.payload;
      state.isLoading = false;
    },

    setUserPofile(state, actions) {
      state.userProfile= actions.payload;
      state.isProfileLoading = false;
    },
    },
});


export const { setUserData,getUserRoleData,setUpdatedpassword,setResetPassword,setUserPofile,getRoleResource } = userSlice.actions;
const userReducer = userSlice.reducer;

export default userReducer;
