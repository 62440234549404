import React from "react";
import { Table, Card, CardHeader, CardTitle, CardBody } from "reactstrap";

function MarketplaceNotebyAdmin({ orderData }) {
  const AdminRemarksDetails = orderData?.Remarks;
  
if (AdminRemarksDetails?.[0]) {
   
  
  return (
    <>
      <Card className="OrderNotebyAdmin">
        <CardHeader>
          <CardTitle>ORDER NOTES BY ADMIN:</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="table-responsive dt-table">
            <Table className=" table mb-0">
              <thead>
                <tr>
                  <th style={{ maxWidth: 180, minWidth: 180 }}>Updated Date</th>
                  <th style={{ maxWidth: 180, minWidth: 180 }}>Updated By</th>
                  <th style={{ maxWidth: 150, minWidth: 150 }}>Return Status</th>
                  <th style={{ maxWidth: 280, minWidth: 280 }}>Payment Mode</th>
                  <th style={{ maxWidth: 280, minWidth: 280 }}>Remarks</th>
                </tr>
              </thead>
              <tbody>
              {AdminRemarksDetails?.map((remark,inRem) => {
                  return ( <tr key={inRem}>
                    <td>{remark?.UpdatedAt}</td>
                    <td>{remark?.UpdatedBy}</td>
                    <td>
                      <p>
                        <span className="status">{orderData?.Status}</span>
                      </p>
                    </td>
                    <td>{remark?.ApprovedPaymentMode}</td>
                    <td style={{ maxWidth: 200 }}>{remark?.AdminComments}</td>
                  </tr> )
              })}
             
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
else{
  return(<></>)
}
}
export default MarketplaceNotebyAdmin;
