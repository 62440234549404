import { gql } from '@apollo/client';
export const GET_CUSTOMER_DATA_LIST= gql`
mutation(
    $searchKey: String! 
    $page: String!
    $limit: String!
    $sortBy:String!
    $sortDir:String!
)

{
  getAllCustomersAdmin(
    input: {
      args: {
        searchKey:$searchKey
        page:$page
        limit:  $limit
        sortBy: $sortBy
        sortDir: $sortDir
      }
    }
  ) {
    customerData {
      _id
      createdAt
      updatedAt
      firstName
      lastName
      email
      country {
       code
       data {
         basicInfo {
           websiteId
           defaultLanguage
           mobile
           groupId
           group
         }
         newsLetter {
           isSubscribed
           subscribedAt
           storeId  
         }
       }
     } 
      oldCustomer
     
    }
    totalRecord
  }

    }`;