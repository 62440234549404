import React from "react";
import axios from "axios";
// import OrderProgressbar from "../OrderProgressbar";
import ManageRiverHillOrderBlock from "./components/ManageRiverHillOrderBlock";
import RiverOrderItemDetails from "./components/ManageriverHillItemDetail";
import OrderRiverHillInvoice from "./components/ManageRiverHillInvoiceDetail";

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, button } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
} from "reactstrap";

function RiverOrderDetails({ orderData, itemData }) {

  return (
    <>
      <Card className="card-dh card-h-100">
        <CardBody className="card-body-dh">
          <Row>
            <Col>
             BCCW Order Details : Ref# <strong>{orderData?.OrderNo}</strong> / Order
              Date :{orderData?.OrderCreatedDate} Status:
              <strong>{orderData?.Status} </strong>
            </Col>
            {/* <OrderProgressbar orderData={orderData} /> */}
          </Row>

          <Row>
            <Col xl={9} md={7}>
              <ManageRiverHillOrderBlock orderData={orderData} />

              <RiverOrderItemDetails itemData={itemData} orderData={orderData}/> 
            </Col>
            <Col xl={3} md={5}>
              <OrderRiverHillInvoice orderData={orderData} /> 
            
            </Col>
          </Row>

          <Row>
            <Col xl={9} md={7}>
           {/* <ReturnNotebyAdmin  orderData={orderData}/> */}
            </Col>
            <Col xl={3} md={5}></Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default RiverOrderDetails;
