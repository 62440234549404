import { useState, useEffect, button, useSearchParams } from "react";
import { MetaTags } from "react-meta-tags";

import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";
import { fetchDelievryReiewDetail } from "./actions/action";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Badge } from "reactstrap";
import {
  Col,
  Container,
  Row,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";

const ReviewRatingDetail = () => {
  const { ref } = useParams();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_mode = params.get("order_mode");
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [apiCounts, setApiCounts] = useState(0);
  useEffect(() => {
    fetchDelievryReiewDetail({
      params: {
        reference_no:ref
      },
      dispatch,
      
    });
  }, [apiCounts]);
  const { deliveryReview = [], isReviewLoading} = useSelector(
    (state) => state.review
  );
const deliveryReviewData = deliveryReview?.data?.[0];

let stsBtn = "bg-lightgrey";
let reviewArr = deliveryReviewData?.review.split(",");
let STsReview = "";
if (deliveryReviewData?.rating === "1") {
  stsBtn = "bg-danger";
  STsReview = reviewArr;
} else if (deliveryReviewData?.rating === "2") {
  stsBtn = "bg-primary";
  STsReview = reviewArr;
} else if (deliveryReviewData?.rating === "3") {
  stsBtn = "bg-secondary";
  STsReview = reviewArr;
} else if (deliveryReviewData?.rating === "4") {
  stsBtn = "bg-success";
  STsReview = reviewArr;
} else {
  stsBtn = "bg-success";
  STsReview = reviewArr;
}
 

 
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Review Rating</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Review Rating"
                breadcrumbItem="Review Rating Detail"
              />
            </Col>
          
          </Row>
          <Row className="row-fluid-scroll">
        <Col md={6} xl={4}>
          <Card className="OrderDetailsBox card-h-100">
            <CardHeader className="bg-light">
              <CardTitle>CUSTOMER DETAILS</CardTitle>
            </CardHeader>
            {deliveryReview !== undefined ? (
             <div className="paymentDetailSec">
                <CardBody>
                
                    <label>
                      Email: {" "}
                    <b> {deliveryReviewData?.email}</b> 
                    </label>
                
                  <label>
                  
                      Reference_No:{" "}
                     <b> {deliveryReviewData?.reference_no}</b>
                 
                  </label>
                  <label>
                  
                  Delivered At:{" "}
                 <b> {deliveryReviewData?.delivered_at}</b>
             
              </label>
              <label>
                  
                  Review:{" "}
                  {reviewArr?.map((item, index) => {
                    console.log(item,"item")
                            return  <Badge className={` ${stsBtn} me-2`} key={index}><span >{item}</span> </Badge>
                          })}
              </label>
              <label>
                  
                  Source:{" "}
                 <b>{deliveryReviewData?.source}</b> 
             
              </label>
              <label>   Delivery Rating  {" "}:    <span class="highlight deliveryreviewSpan"> {deliveryReviewData?.rating}{" "}
                          <span
                            className="star"
                            star1
                            style={{ color: "#FBAB32" }}
                          >
                            &#9733;
                          </span>
                          </span>
                          </label>  
             
             
             
                 
                  
                </CardBody>
              </div>
            ) : (
              <>
                <CardBody>
                  <Skeleton count={8} />
                </CardBody>
              </>
            )}
          </Card>
        </Col>
       </Row>
        </Container>
      </div>
    </>
  );
};

export default ReviewRatingDetail;
