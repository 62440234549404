import Carousel from "react-bootstrap/Carousel";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  CardHeader,
  Input,
  Table,
  Form,
  FormGroup,
  InputGroup,
  Label,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "../Dashboard/dashboard.scss";

function UncontrolledExample({ Total }) {
  const { country = [] } = useSelector((state) => state.country);
  let cntry = country;

  if (cntry !== "all" && cntry !== null) {
    cntry = cntry?.toUpperCase();
  }

  if (cntry === "all") {
    return (
      <Carousel>
        <Carousel.Item>
          <Card className="TotalOrderCard TotalOrderCard1 ">
            <CardHeader>
              <CardTitle>Today UAE Orders</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Open</td>
                      <td>AED {Total?.AE?.total_open}</td>
                    </tr>
                    <tr>
                      <td>Posted</td>
                      <td>AED {Total?.AE?.total_posted}</td>
                    </tr>
                    <tr className="notLs">
                      <td>Not in LS</td>
                      <td>AED {Total?.AE?.total_not_in_ls}</td>
                    </tr>

                    <tr className="notLs">
                      <td>(B2B + Normal)</td>
                      <td>
                        AED {Total?.AE?.total_not_in_ls_b2b} +{" "}
                        {Total?.AE?.total_not_in_ls_normal}
                      </td>
                    </tr>
                    <tr>
                      <td>Pending Payment</td>
                      <td>AED {Total?.AE?.total_pending_payment}</td>
                    </tr>
                    <tr>
                      <td>Cancelled</td>
                      <td>AED {Total?.AE?.total_canceled}</td>
                    </tr>
                    <tr className="bordertop netTotal">
                      <th>Net Total </th>
                      <th>
                        AED{" "}
                        {Number(
                          Total?.AE?.total_open +
                            Total?.AE?.total_posted +
                            Total?.AE?.total_not_in_ls
                        ).toFixed(2)}
                      </th>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Carousel.Item>

        <Carousel.Item>
          <Card className="TotalOrderCard TotalOrderCard1">
            <CardHeader>
              <CardTitle>Today Kuwait Orders</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Open</td>
                      <td>KWD {Total?.KW?.total_open}</td>
                    </tr>
                    <tr>
                      <td>Posted</td>
                      <td>KWD {Total?.KW?.total_posted}</td>
                    </tr>
                    <tr className="notLs">
                      <td>Not in LS</td>
                      <td>KWD {Total?.KW?.total_not_in_ls}</td>
                    </tr>
                    <tr>
                      <td>Pending Payment</td>
                      <td>KWD {Total?.KW?.total_pending_payment}</td>
                    </tr>
                    <tr>
                      <td>Cancelled</td>
                      <td>KWD {Total?.KW?.total_canceled}</td>
                    </tr>
                    <tr className="bordertop netTotal">
                      <th>Net Total </th>
                      <th>
                        KWD{" "}
                        {Number(
                          Total?.KW?.total_open +
                            Total?.KW?.total_posted +
                            Total?.KW?.total_not_in_ls
                        ).toFixed(2)}
                      </th>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Carousel.Item>
        <Carousel.Item>
          <Card className="TotalOrderCard TotalOrderCard1">
            <CardHeader>
              <CardTitle>Today Oman Orders</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Open</td>
                      <td>OMR {Total?.OM?.total_open}</td>
                    </tr>
                    <tr>
                      <td>Posted</td>
                      <td>OMR {Total?.OM?.total_posted}</td>
                    </tr>
                    <tr className="notLs">
                      <td>Not in LS</td>
                      <td>OMR {Total?.OM?.total_not_in_ls}</td>
                    </tr>
                    <tr>
                      <td>Pending Payment</td>
                      <td>OMR {Total?.OM?.total_pending_payment}</td>
                    </tr>
                    <tr>
                      <td>Cancelled</td>
                      <td>OMR {Total?.OM?.total_canceled}</td>
                    </tr>
                    <tr className="bordertop netTotal">
                      <th>Net Total </th>
                      <th>
                        OMR{" "}
                        {Number(
                          Total?.OM?.total_open +
                            Total?.OM?.total_posted +
                            Total?.OM?.total_not_in_ls
                        ).toFixed(2)}
                      </th>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Carousel.Item>
        <Carousel.Item>
          <Card className="TotalOrderCard TotalOrderCard1">
            <CardHeader>
              <CardTitle>Today Bahrain Orders</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Open</td>
                      <td>BHD {Total?.BH?.total_open}</td>
                    </tr>
                    <tr>
                      <td>Posted</td>
                      <td>BHD {Total?.BH?.total_posted}</td>
                    </tr>
                    <tr className="notLs">
                      <td>Not in LS</td>
                      <td>BHD {Total?.BH?.total_not_in_ls}</td>
                    </tr>
                    <tr>
                      <td>Pending Payment</td>
                      <td>BHD {Total?.BH?.total_pending_payment}</td>
                    </tr>
                    <tr>
                      <td>Cancelled</td>
                      <td>BHD {Total?.BH?.total_canceled}</td>
                    </tr>
                    <tr className="bordertop netTotal">
                      <th>Net Total </th>
                      <th>
                        BHD{" "}
                        {Number(
                          Total?.BH?.total_posted + Total?.BH?.total_not_in_ls
                        ).toFixed(2)}
                      </th>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Carousel.Item>
        <Carousel.Item>
          <Card className="TotalOrderCard TotalOrderCard1">
            <CardHeader>
              <CardTitle>Today Qatar Orders</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Open</td>
                      <td>QAR {Total?.QA?.total_open}</td>
                    </tr>
                    <tr>
                      <td>Posted</td>
                      <td>QAR {Total?.QA?.total_posted}</td>
                    </tr>
                    <tr className="notLs">
                      <td>Not in LS</td>
                      <td>QAR {Total?.QA?.total_not_in_ls}</td>
                    </tr>
                    <tr>
                      <td>Pending Payment</td>
                      <td>QAR {Total?.QA?.total_pending_payment}</td>
                    </tr>
                    <tr>
                      <td>Cancelled</td>
                      <td>QAR {Total?.QA?.total_canceled}</td>
                    </tr>
                    <tr className="bordertop netTotal">
                      <th>Net Total </th>
                      <th>
                        QAR{" "}
                        {Number(
                          Total?.QA?.total_posted + Total?.QA?.total_not_in_ls
                        ).toFixed(2)}
                      </th>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Carousel.Item>
      </Carousel>
    );
  } else {
    let contVal = cntry;
    let currency = "";
    if (cntry === "AE") {
      currency = "AED";
    } else if (cntry === "KW") {
      currency = "KWD";
    } else if (cntry === "QA") {
      currency = "QAR";
    } else if (cntry === "BH") {
      currency = "BHD";
    } else if (cntry === "OM") {
      currency = "OMR";
    }
    return (
      <Card className="TotalOrderCard">
        <CardHeader>
          <CardTitle>Today {contVal} Orders</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Open</td>
                  <td>
                    {currency} {Total?.[contVal]?.total_open}
                  </td>
                </tr>
                <tr>
                  <td>Posted</td>
                  <td>
                    {currency} {Total?.[contVal]?.total_posted}
                  </td>
                </tr>
                <tr className="notLs">
                  <td>Not in LS</td>
                  <td>
                    {currency} {Total?.[contVal]?.total_not_in_ls}
                  </td>
                </tr>
                {
                  currency==='AED'?<>
                  <tr className="notLs">
                  <td>(B2B + Normal)</td>
                  <td>
                    AED {Total?.AE?.total_not_in_ls_b2b} +{" "}
                    {Total?.AE?.total_not_in_ls_normal}
                  </td>
                </tr>
                  </>:null
                }
                

                <tr>
                  <td>Pending Payment</td>
                  <td>
                    {currency} {Total?.[contVal]?.total_pending_payment}
                  </td>
                </tr>
                <tr>
                  <td>Cancelled</td>
                  <td>
                    {currency} {Total?.[contVal]?.total_canceled}
                  </td>
                </tr>
                <tr className="bordertop netTotal">
                  <th>Net Total </th>
                  <th>
                    {currency}{" "}
                    {Number(
                      Total?.[contVal]?.total_open +
                        Total?.[contVal]?.total_posted +
                        Total?.[contVal]?.total_not_in_ls
                    ).toFixed(2)}{" "}
                  </th>
                </tr>
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default UncontrolledExample;
