import { useState, useEffect, button, useSearchParams } from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../../components/Common/Breadcrumb";
import { fetchAllDeleteOrdersDetail } from "../actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import OrderDetails from "./orderDetails";

import {
  Col,
  Container,
  Row
} from "reactstrap";


const DeleteOrderDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const orderCountry = params.get("orderCn");
  const [apiCounts, setApiCounts] = useState(0);
  const  currentCountry = localStorage.getItem("WEBSITE_COUNTRY");

 
  useEffect(() => {
   let ocountry = currentCountry;
    if(orderCountry !== '')
    {
      ocountry = orderCountry;
    }
    fetchAllDeleteOrdersDetail({
      params: {
        country: ocountry,
        order_id: id
     
      },
      dispatch,
      
    });
  }, [currentCountry,orderCountry]);
  const { orderDeleteDetail = [], isOrderDeleteDetailLoading, apiTriggerCount } = useSelector(
    (state) => state.order
  );

 
  useEffect(() => {
    setApiCounts(apiTriggerCount);
  },[apiTriggerCount]);
 
 
  
  let orderData = [];
   orderData = orderDeleteDetail.OrderDetails?.[0];  
   let itemData = [];
   itemData = orderDeleteDetail.OrderDetails?.[0]?.ShipmentDetails;



  
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Mange Order View</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Delete Orders"
                breadcrumbItem="Delete Order View"
              />
            </Col>
       
          </Row>
        
          <OrderDetails orderData={orderData} itemData={itemData} />
        </Container>
      </div>
    </>
  );
};

export default DeleteOrderDetails;