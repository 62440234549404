

 let countryList = '';
if (localStorage.getItem("authUser")) {
  const userObj = JSON.parse(localStorage.getItem("authUser"));
  if (userObj?.role_id !== undefined) {
        
        countryList = userObj?.countryList;
  }
}

const countries = countryList; 

 /* const countries = {
  All:{
    label: "All", 
    flag: All_flag
  },
  ae: {
    label: "UAE",
    flag: uae,
    currency: "AED",
  },
  om: {
    label: "Oman",
    flag: oman,
    currency: "OMR",
  },
  bh: {
    label: "Bahrain",
    flag: bahrain,
    currency: "BHD",
  },
  qa: {
    label: "Qatar",
    flag: qatar,
    currency: "QAR",
  },
  kw: {
    label: "Kuwait",
    flag: kuwait,
    currency: "KWD",
  },
}   */

export default countries