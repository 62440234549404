import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts"
const PieChart = ({chartData}) => {
  // const series = [55, 34,23,21,20]
 let series=[];
 let labels=[];
 Object.keys(chartData).map((key) => {
    if (chartData?.[key]){
            series.push(parseInt(chartData?.[key]));
            labels.push(key)
    }
})
            
  const options = {
    labels:labels,
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c","#00FFFF","#800080"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 380,
          },
          legend: {
            show: true,
          },
        },
      },
    ],
  }

  return (
    <ReactApexChart options={options} series={series} type="pie" height="380" />
  )
}

export default PieChart
