import { gql } from '@apollo/client';
export const GET_CATEGORIES_DATA_LIST= gql`
query GET_CATEGORIES_DATA_LIST(
  $input:CategoriesAllInput!
)
{
  getAllCategoriesList(input: $input)
{
  categoriesData {
      _id
      name {
        en
        ar
      }
      slug
      icon
      shortDescription {
        en
        ar
      }
      metaTitle {
        en
        ar
      }
      metaDescription {
        en
        ar
      }
      metaKeywords {
        en
        ar
      }
      noOfArticles
      createdAt
      updatedAt
      status
      updatedBy
      createdBy
    }
    totalRecord
  }
    }`;