import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
  import { useDispatch, useSelector } from "react-redux";
  import { AvForm, AvField } from "availity-reactstrap-validation";
  import { lsStockSkuInsert } from "./action/action";
  import { CardHeader } from "semantic-ui-react";
  import toast, { Toaster } from "react-hot-toast";
  import {
    Container,
    Row,
    Col,
    Card,
    FormGroup,
    CardBody,
    CardTitle,
    CardSubtitle,
    Label,
    Button,
    Spinner,
  } from "reactstrap";

const ImportExport = () => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState();

  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isTypeError, setisTypeError] = useState(false);
  const [excelImport, setexcelImport] =useState(false);
  const handleValidSubmit = (events, values) => {

    const form = events.currentTarget;
   
    const formData = new FormData();
    if(values?.importType === '')
    {
      setisTypeError(true);
      setErrorMsg("Please select the import type")
      
    }
    else if(selectedFile === undefined){
      setisTypeError(false);
      setErrorMsg("Please select the file")
    }
    else{
    setUpdateDetailsLoad(true);
    formData.append("import_file", selectedFile);
      
    lsStockSkuInsert({
      formData,
      dispatch,
    }); 
  }
  };
 
  const { importExportList = [], isLoading } = useSelector(
    (state) => state.importexport
  );

console.log("importExportList-------",importExportList)
  useEffect(() => {
    if (importExportList?.code === 100) {
      setUpdateDetailsLoad(false);
      toast.success(importExportList?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
    } else {
      if (importExportList?.message) {
        setUpdateDetailsLoad(false);

        toast.error(importExportList?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [importExportList]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Import Export</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Import/Export" breadcrumbItem="Manage Import/Export" />
            </Col>
       
           
          </Row>
          <Row className="rowCenter">
            <Col lg={8}>
              <Card>
                
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
               
                    }}
                  >
                    <Row>
                      <Col>
                      <AvField
                    type="select"
                    name="importType"
                    className="form-select"
                   
                  >
                   
                      <option value="">Select Import</option>
                      <option value="lsstock">LsStock</option>
               
                  </AvField>
                  {isTypeError && (
                      <div
                       className="importError"
                      >
                        {errorMsg}
                      </div>
                    )}
                      </Col>
                    <Col  style={{ paddingTop: "5px" }}>
                      <input
                        type="file"
                        name="file"
                        required
                        onChange={(e) => {
                          setSelectedFile(e.target.files[0]);
                        }}
                      />
                 
                    {setSelectedFile && isTypeError === false && (
                      <div
                       className="importError"
                      >
                        {errorMsg}
                      </div>
                    )}
                       </Col>
                    <Col className="mb-3">
                      <button
                        className="btn btn-primary"
                       
                      >
                        {updateStsLoad === true && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}{" "}
                        Submit
                      </button>
                    </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ImportExport;
