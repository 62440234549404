import { gql } from '@apollo/client';

/**
 *  PLACE ORDER OMS
 */
export const OMS_PLACE_ORDER = gql`
  mutation OMS_PLACE_ORDER($cartToken: String, $additionalInfo: String, $GAclientId: String) {
    placeOrder(cartToken: $cartToken, additionalInfo: $additionalInfo, GAclientId: $GAclientId) {
      token
    }
  }
`;
