import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Form,
  InputGroup,
  Col,
  Row,
  Input,
  Label,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

import { ExportToExcel } from '../../Orders/ExportToExcel';
import toast, { Toaster } from 'react-hot-toast';
import { excelGenerate } from '../../Orders/actions/orderActions';
import { findElements } from '@fullcalendar/react';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
function MarketExportReport({ orderData }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [startDate, setStartDate] = useState(
    new moment(new Date()).format('YYYY-MM-DD')
  );

  const [endDate, setEndDate] = useState(
    new moment(new Date()).format('YYYY-MM-DD')
  );

  const [windowSize, setWindowSize] = useState(getWindowSize());
  let isMobileView = 0;
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  if (windowSize.innerWidth <= 1279 && isMobileView === 0) {
    isMobileView++;
  }
  const { afterToday, combine, allowedMaxDays } = DateRangePicker;
  const [data, setData] = React.useState([]);
  const [isExportEnable, setisExportEnable] = useState(false);
  const [isTypeError, setisTypeError] = useState(false);
  const [isDateError, setisDateError] = useState(false);
  const [excelExportReport, setexcelExportReport] = useState(false);
  const fileName = 'myfile';

  const { country = [] } = useSelector((state) => state.country);
  const [orderMode, setOrderMode] = useState('All');
  const splitVal = location?.pathname.split('/');
  useEffect(() => {
    if (splitVal[1] === 'marketplaceorders') {
      setOrderMode('mktplace');
    } else {
      setOrderMode('All');
    }
  }, [splitVal]);

  const handleSubmit = (event) => {
    setexcelExportReport(true);
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const exportType = event.target.elements.export_type.value;
    setisTypeError(exportType === '' ? true : false);

    if (exportType !== '') {
      excelGenerate({
        params: {
          export_type: exportType,
          date_from: startDate,
          date_to:
            endDate === undefined || endDate === '' ? startDate : endDate,
          country: country,
        },
        dispatch,
      });

      // setisExportEnable(true);
    }
  };
  const { generateFile = [], generateFileLoading } = useSelector(
    (state) => state.order
  );
  useEffect(() => {
    setexcelExportReport(false);
    if (generateFile?.code === 100) {
      toast.success(generateFile?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (generateFile?.message) {
        toast.error(generateFile?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [generateFile]);

  return (
    <>
      <Col xl={6} style={{ marginBottom: '20px' }}>
        <Row className="align-items-end">
          <Form onSubmit={handleSubmit}>
            <Col xl={12} className="textAlignRigh">
              <div className="DashbordFliter ">
                <div className="InputBg">
                  <FormGroup>
                    <Input type="select" name="export_type" id="exampleSelect">
                      <option value="">Choose Export Report Type</option>
                      <option value="marketplace_orders">Order Summary</option>
                      <option value="marketplace_items">
                        Order Item Summary
                      </option>
                    </Input>
                    <FormFeedback type="invalid">
                      Please Select Export Type.
                    </FormFeedback>
                  </FormGroup>
                  {isTypeError && (
                    <span
                      style={{
                        position: 'absolute',
                        top: 40,
                        left: 0,
                        color: '#f00',
                      }}
                    >
                      Please Select Export Type.
                    </span>
                  )}
                </div>
                <div className="InputBg">
                  {isMobileView !== 0 ? (
                    <>
                      <DateRangePicker
                        placement="auto"
                        placeholder="Select Date Range"
                        onClean={() => {
                          setStartDate(
                            new moment(new Date()).format('YYYY-MM-DD')
                          );
                          setEndDate(
                            new moment(new Date()).format('YYYY-MM-DD')
                          );
                        }}
                        showOneCalendar
                        disabledDate={combine(allowedMaxDays(30), afterToday())}
                        onChange={(selectedDates, instance) => {
                          const dateStr = selectedDates?.[0];
                          const first_Date = new Date(dateStr)?.toString();
                          const firstD = moment(new Date(first_Date))?.format(
                            'YYYY/MM/DD'
                          );
                          const firstDateReplace = firstD?.replaceAll('/', '-');
                          const dateStr1 = selectedDates?.[1];
                          const second_Date = new Date(dateStr1)?.toString();
                          const secondD = moment(new Date(second_Date))?.format(
                            'YYYY/MM/DD'
                          );
                          const secondDateReplace = secondD?.replaceAll(
                            '/',
                            '-'
                          );
                          setStartDate(firstDateReplace);
                          setEndDate(secondDateReplace);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <DateRangePicker
                        placement="leftStart"
                        placeholder="Select Date Range"
                        onClean={() => {
                          setStartDate(
                            new moment(new Date()).format('YYYY-MM-DD')
                          );
                          setEndDate(
                            new moment(new Date()).format('YYYY-MM-DD')
                          );
                        }}
                        disabledDate={combine(allowedMaxDays(30), afterToday())}
                        onChange={(selectedDates, instance) => {
                          const dateStr = selectedDates?.[0];
                          const first_Date = new Date(dateStr)?.toString();
                          const firstD = moment(new Date(first_Date))?.format(
                            'YYYY/MM/DD'
                          );
                          const firstDateReplace = firstD?.replaceAll('/', '-');
                          const dateStr1 = selectedDates?.[1];
                          const second_Date = new Date(dateStr1)?.toString();
                          const secondD = moment(new Date(second_Date))?.format(
                            'YYYY/MM/DD'
                          );
                          const secondDateReplace = secondD?.replaceAll(
                            '/',
                            '-'
                          );
                          setStartDate(firstDateReplace);
                          setEndDate(secondDateReplace);
                        }}
                      />{' '}
                    </>
                  )}

                  {isDateError && (
                    <span
                      style={{
                        position: 'absolute',
                        top: 40,
                        left: 0,
                        color: '#f00',
                      }}
                    >
                      Please Select Export Date.
                    </span>
                  )}
                </div>

                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ display: 'flex' }}
                >
                  {' '}
                  {excelExportReport === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{' '}
                  Export{' '}
                  <i className="bx bx-download" style={{ padding: '3px' }}></i>
                </button>
              </div>
            </Col>
          </Form>
        </Row>
      </Col>
      {/* {isExportEnable?<ExportToExcel apiData={data} fileName={fileName}/>:null} */}
    </>
  );
}
export default MarketExportReport;
