// import { iteratorSymbol } from "immer/dist/internal";
import React, { useState } from "react";
import {
  Table,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Modal,
} from "reactstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Badge } from "reactstrap";
import moment from "moment";

function PhotoShootItemDetails({ orderData }) {
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_large, setmodal_large] = useState(false);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [modal_small, setmodal_small] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [modal_scroll, setmodal_scroll] = useState(false);
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [modal_backdrop, setmodal_backdrop] = useState(false);
 
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_mode = params.get("order_mode");
 //console.log(order_mode,"--------order_mode");
  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  function tog_small() {
    setmodal_small(!modal_small);
    removeBodyCss();
  }

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }


 // console.log(orderData, "item-data");


  return (
    <>  
    <Card>              
        <CardHeader>
          <CardTitle> ITEM DETAILS</CardTitle>
        </CardHeader>
        {orderData !== undefined ? (

          <>
        <CardBody>
          <div className="table-responsive   dt-table ">
            <Table className=" table  mb-0 ">
              <thead>
                <tr>
                 
                  <th style={{ maxWidth: 70, minWidth: 70 ,textAlign: "center"}}>SI.No</th>
                  <th style={{ maxWidth: 110, minWidth: 110 ,textAlign: "center"}}>Name</th>
                  <th style={{ maxWidth: 110, minWidth: 110 ,textAlign: "center"}}>Sku</th>
                  <th style={{ maxWidth: 110, minWidth: 110, textAlign: "center" }}>Qty</th>
                  <th style={{ maxWidth: 110, minWidth: 110 ,textAlign: "center"}}>BudgetCategory</th>
                </tr>
              </thead>
              <tbody >
                {orderData?.map((item,itemInx) => {
                  let cdate = item?.Date,
                  orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                  orderTime = moment(new Date(cdate)).format("h:mm:ss a");
                  
                 
                  return (
                    <>
                    <tr key={itemInx}>
                    <td className="table-bordered align-middle"style={{textAlign: "center"}}>{itemInx}</td>
                      <td className="table-bordered align-middle"style={{textAlign: "center"}}>{item?.Name}</td>
                      <td className="table-bordered align-middle"style={{textAlign: "center"}}>{item?.Sku}</td>
                      <td className="table-bordered align-middle"style={{textAlign: "center"}}>
                        {item?.Qty}
                      </td>
                      <td className="table-bordered align-middle"style={{textAlign: "center"}}>
                        {item?.BudgetCategory}
                      </td>
                     
                    </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
        </>
        ) : (<>
          <CardBody>
           <Skeleton count={6} />
           </CardBody>
            </>
          )}
      </Card>
    </>
  );
}
export default PhotoShootItemDetails;