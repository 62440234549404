import React, { useState, useRef, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Nav,
  Spinner,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Button,
  Table,
} from 'reactstrap';
import { useMutation } from '@apollo/client';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  StandaloneSearchBox,
} from '@react-google-maps/api';

import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import Geocode from 'react-geocode';
import { Badge } from 'reactstrap';
import Select from 'react-select';
import CsLineIcons from '../../cs-line-icons/CsLineIcons';
import 'react-drawer/lib/react-drawer.css';
import { useParams, useHistory } from 'react-router-dom';
import { useUrlSearchParams } from 'use-url-search-params';
import { DEFAULT_ADDRESS } from './query/DefaultAddress';
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from 'availity-reactstrap-validation';
import { useLazyQuery } from '@apollo/client';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import classnames from 'classnames';
import { fetchReviewList } from '../Reviews/actions/action';
import { getOrderList } from './action/action';
import { GET_CUSTOMER_DATA_ADMIN } from './query/GetCustomer';
import { DELETE_ADDRESS_DATA } from './query/DeleteAddress';
import { CREATE_ADDRESS } from './query/CreateAddress';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import { UPDATE_CUSTOMER_ADMIN } from './query/EditCustomerProfile';
import AddCustomerEditForm from './EditAddressForm';
import AddWishListDetail from './WishList';
import { getReturnList } from './action/action';
import { set } from 'lodash';
import { format, parseISO, isValid } from 'date-fns';
import { GET_AUTH_TOKEN_ADMIN } from './query/AddCustomerOrderAuth';

const AddCustomerFormDetail = () => {
  const containerStyle = {
    width: '100%',
    height: '600px',
  };
  const countryCode = localStorage.getItem('CUSTOMER_COUNTRY');
  const contryBounds = {
    ae: {
      north: 26.427929393569457,
      south: 22.427935149720497,
      west: 49.88720042141598,
      east: 57.8381892803542,
    },
    qa: {
      north: 26.2171,
      south: 24.471118,
      west: 50.7211001,
      east: 51.7144001,
    },
    om: {
      north: 28.808004285620157,
      south: 12.427888171440916,
      west: 38.38409066937567,
      east: 70.18813730023504,
    },
    kw: {
      north: 33.15088596365788,
      south: 25.497326905942987,
      west: 39.665945329232116,
      east: 55.5679686446618,
    },
    bh: {
      north: 26.653167758085463,
      south: 25.667091017781722,
      west: 49.63994697023152,
      east: 51.627694184966074,
    },
  };

  let center = {
    lat: 25.1675739,
    lng: 55.2614555,
  };
  if (countryCode === 'bh') {
    center = {
      lat: 26.2213902,
      lng: 50.5848642,
    };
  } else if (countryCode === 'om') {
    center = {
      lat: 23.5880307,
      lng: 58.3828717,
    };
  } else if (countryCode === 'qa') {
    center = {
      lat: 25.2842534,
      lng: 51.3719073,
    };
  } else if (countryCode === 'kw') {
    center = {
      lat: 29.3760606,
      lng: 47.9468472,
    };
  }
  const webUrl = process.env.REACT_APP_WEB_URL;
  const [map, setMap] = React.useState(null);
  const [selectedLatLng, setSelectedLatLng] = useState(center);
  const [activeTab, setactiveTab] = useState('1');
  const [activeTab1, setactiveTab1] = useState('5');
  const [selectedPlusCode, setSelectedPlusCode] = useState(null);
  const [customActiveTab, setcustomActiveTab] = useState('1');
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [addressCountry, setAddressCountry] = useState('');
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedAddressForEdit, setSelectedAddressForEdit] = useState(null);
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [showMap, setShowMap] = useState(false);
  const [orderToken, setOrderToken] = useState(null);

  const [orderCountry, setOrderCountry] = useState(null);
  const [orderCountryErrorMsg, setOrderCountryErrorMessage] = useState(false);
  const [requestType, setRequestType] = useState(null);
  const [requestTypeError, setRequestTypeError] = useState(null);
  const [returnTrigger, setreturnTrigger] = useState(0);
  const [orderTrigger, setOrderTrigger] = useState(0);
  const searchBox = useRef(null);

  const wishlistRef = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_APP_KEY,
    libraries: ['places'],
  });
  Geocode.setApiKey('AIzaSyDbpGEtOjH0Udl-dMEHPYuIl7gXItfWuKU');

  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [verticalActiveTab, setverticalActiveTab] = useState('1');
  const [openEdit, setOpenEDit] = useState(false);
  const [isDataLoading, setDataLoading] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [headerTab, setHeaderTab] = useState('1');
  const [headerPillsTab, setHeaderPillsTab] = useState('1');
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [formValue, setFormValues] = useState({});
  const [phoneValue, setPhoneValue] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [starValue, setStarValue] = useState(0);
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 5 });
  const limitByValues = [5, 10, 20, 50];
  const onMapOpen = () => {
    setShowMap(true);
    setOpenMap(true);
  };
  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };
  const toggleHeaderPills = (tab) => {
    if (headerPillsTab !== tab) setHeaderPillsTab(tab);
  };
  const toggleRightDrawer = () => {
    setPosition('right');
    setOpen(!open);
  };
  const onDrawerClose = () => {
    setOpen(false);
  };
  function tog_backdrop() {
    onDrawerClose();
  }

  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setOpenEDit(false);
      setverticalActiveTab(tab);
    }
  };
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }
  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map);
    map.setZoom(14);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onClick = (...args) => {
    setSelectedLatLng({
      lat: args[0].latLng.lat(),
      lng: args[0].latLng.lng(),
    });
    getFormattedAddressAndCode(args[0].latLng.lat(), args[0].latLng.lng());
  };

  const getFormattedAddressAndCode = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        setSelectedPlusCode(response?.plus_code);
        setSelectedAddress(response.results[0].formatted_address);
      },
      (error) => {
        console.error(error);
      }
    );
    getCountryCityRegionFromGeoCode(lat, lng);
  };

  const onPlacesChanged = () => {
    searchBox?.current?.state?.searchBox?.getPlaces()?.map((item) => {
      if (item?.plus_code) {
        setSelectedPlusCode(item?.plus_code);
        setSelectedAddress(item?.formatted_address);
      } else {
        getFormattedAddressAndCode(
          item?.geometry?.location?.lat(),
          item?.geometry?.location?.lng()
        );
      }

      setSelectedLatLng({
        lat: item?.geometry?.location.lat(),
        lng: item?.geometry?.location.lng(),
      });
    });
  };

  const getCountryCityRegionFromGeoCode = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        response?.results?.map((result) => {
          if (result?.types[0] === 'country') {
            setSelectedCountry(result?.address_components[0]?.long_name);
            // return result?.address_components[0].long_name;
          }
          if (result?.types[0] === 'locality') {
            setSelectedRegion(result?.address_components[0]?.long_name);
            // return result?.address_components[0].long_name;
          }
          if (result?.types[0] === 'administrative_area_level_1') {
            setSelectedCity(result?.address_components[0]?.long_name);
            // return result?.address_components[0].long_name;
          }
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  let checked = '';

  let status = false;
  const handleSwitch = (event) => {
    if (event.target.checked) {
      status = true;
    } else {
      status = false;
    }
  };
  const options = [
    { label: 'UAE', value: 'ae' },
    { label: 'Bahrain', value: 'bh' },
    { label: 'Oman', value: 'om' },
    { label: 'Kuwait', value: 'kw' },
    { label: 'Qatar', value: 'qa' },
  ];
  const options1 = [
    { label: 'Whatsup', value: 'Whatsup' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Email', value: 'email' },
  ];

  const [mutateFunction, { Error, data }] = useMutation(
    GET_CUSTOMER_DATA_ADMIN,
    { errorPolicy: 'all' }
  );

  useEffect(() => {
    getCustomerDataAdminFunc();
  }, []);
  const getCustomerDataAdminFunc = () => {
    try {
      mutateFunction({
        variables: {
          _id: id,
        },
        dispatch,
      });
    } catch {}
  };

  const editValue = data?.getCustomerDataAdmin;

  const [varFeild, setVarFeild] = useState(null);
  const testHandle = (e) => {
    let isValid = false;
    let phLength = e.target.value.length;
    if (phLength >= 7) {
      if (addressCountry === 'ae') {
        var pattern = new RegExp(/^(?:50|51|52|54|55|56|2|3|4|6|7|9)\d{7}$/);
        if (!pattern.test(e.target.value)) {
          isValid = false;
          setErrorMessage('invalid Phone Number');
        }
      } else if (addressCountry === 'bh') {
        pattern = new RegExp(
          /^(?:\+973|00973|0)?(?:30|31|32|33|34|35|36|37|38|39|60|61|62|63|64|65|66|67|68|69)\d{6}$/
        );
        if (!pattern.test(e.target.value)) {
          isValid = false;
          setErrorMessage('invalid Phone Number');
        }
      } else if (addressCountry === 'om') {
        pattern = new RegExp(
          /^(?:\+968|00968|0)?(?:71|72|73|74|75|76|77|78|79|91|92|93|94|95|96|97|98|99)\d{6}$/
        );
        if (!pattern.test(e.target.value)) {
          isValid = false;
          setErrorMessage('invalid Phone Number');
        }
      } else if (addressCountry === 'kw') {
        pattern = new RegExp(
          /^(?:\+965|00965|0)?(?:21|22|23|24|25|26|27|28|29|41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|69|90|91|92|93|94|95|96|97|98|99)\d{6}$/
        );
        if (!pattern.test(e.target.value)) {
          isValid = false;
          setErrorMessage('invalid Phone Number');
        }
      } else {
        pattern = new RegExp(
          /^(?:\+974|00974|0)?(?:30|31|32|33|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|69|70|71|72|73|74|75|76|77|78|79)\d{6}$/
        );
        if (!pattern.test(e.target.value)) {
          isValid = false;
          setErrorMessage('invalid Phone Number');
        }
      }
    } else {
      isValid = false;
      setErrorMessage('Mobile number min 7 digits');
    }
  };

  const [addressFunction] = useMutation(CREATE_ADDRESS, { errorPolicy: 'all' });

  const handleValidSubmit = async (events, values) => {
    try {
      let result = await addressFunction({
        variables: {
          uuid: id,
          addressData: {
            countryCode: editValue?.country?.[0]?.code,
            firstName: values.firstname,
            lastName: values.lastname,
            telephone: values.mobileNumber,
            alternateTelephone: values.mobileNumber,
            address1: values.fulladdress,
            address2: values.fulladdress,
            flatNo: values?.flatno,
            city: selectedCity || selectedRegion,
            region: selectedRegion,
            country: selectedCountry,
            mapData: selectedAddress,
            latitude: selectedLatLng?.lat.toString(),
            longitude: selectedLatLng?.lng.toString(),
            addressType: values.addressType,
            isDefault: status,
            plusCode: selectedPlusCode?.global_code,
          },
        },
      });
      if (result?.data?.createAddressAdmin?._id) {
        toast.success('Address Added Successfully', {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#4CAF50',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#4CAF50',
          },
        });
        reLoadAddressList();
      }
    } catch (error) {
      if (error?.message) {
        toast.error(error?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  };

  const reLoadAddressList = async () => {
    await mutateFunction({
      variables: {
        _id: id,
      },
      dispatch,
    });
    setShowMap(false);
    setOpenMap(false);
    setOpenEDit(false);
  };

  const [DeleteFunction, { isLoading, delData = data }] = useMutation(
    DELETE_ADDRESS_DATA,
    { errorPolicy: 'all' }
  );

  const getAddressDeleteAdminFunc = async () => {
    try {
      await DeleteFunction({
        variables: {
          input: {
            uuid: id,
            _id: editValue?.address?.[0]?._id,
          },
        },
        dispatch,
      });

      reLoadAddressList();
    } catch (error) {}
  };

  ///
  let orderDt = '--';
  let orderTime = '--';
  if (editValue?.createdAt) {
    let Isodate = parseISO(editValue?.createdAt);
    let isValidDate = isValid(Isodate);
    if (isValidDate) {
      orderDt = format(Isodate, 'Qo MMM,yyyy');
      orderTime = format(Isodate, `hh:mm:ss aaaa`);
    } else {
      let date = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(editValue?.createdAt);

      let cdate = date;
      orderDt = moment(new Date(cdate)).format('Do MMM, YYYY');
      orderTime = moment(new Date(cdate)).format('h:mm:ss a');
    }
  }

  const [DefaultFunction, { loading, client }] = useMutation(DEFAULT_ADDRESS, {
    errorPolicy: 'all',
  });
  const getDefaultAddressAdminFunc = async (address_id) => {
    try {
      await DefaultFunction({
        variables: {
          input: {
            uuid: id,
            _id: address_id,
          },
        },
        dispatch,
      });
      reLoadAddressList();
    } catch (error) {}
  };
  const [updateCustomerFunction] = useMutation(UPDATE_CUSTOMER_ADMIN);
  const handleUpdateAddress = async (events, values) => {
    try {
      setUpdateDetailsLoad(true);
      let result = await updateCustomerFunction({
        variables: {
          input: {
            firstName: values.firstname,
            lastName: values.lastname,
            mobile: values.mobilenumber,
            otp: '1234',
            otpType: '12345',
            password: values.password,
            uuid: id.toString(),
          },
        },
      });
      if (result?.data?.updateCustomerProfileAdmin?._id) {
        setUpdateDetailsLoad(false);
        toast.success('Customer Updated Successfully', {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#4CAF50',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#4CAF50',
          },
        });
        setTimeout(() => {
          history.push('/AllCustomer');
        }, 10);
      }
    } catch (error) {
      if (error?.message) {
        setUpdateDetailsLoad(false);
        toast.error(error?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  };

  useEffect(() => {
    fetchReviewList({
      params: {
        start: '0',
        limit: '10',
        customer_id: id,
      },
      dispatch,
    });
  }, [id]);
  const { reviewList = [], isdataLoading } = useSelector(
    (state) => state.review
  );
  const { listRating } = reviewList;
  const reviewData = listRating?.reviewList;

  let ratingInt = parseInt(reviewData?.[0]?.rating) || 0;
  let reviewArr = reviewData?.[0]?.review?.split(',');
  let STsReview = '';

  let stsBtn = 'bg-lightgrey';
  if (reviewData?.[0]?.rating === '1') {
    stsBtn = 'bg-danger';
    STsReview = reviewArr;
  } else if (reviewData?.[0]?.rating === '2') {
    stsBtn = 'bg-primary';
    STsReview = reviewArr;
  } else if (reviewData?.[0]?.rating === '3') {
    stsBtn = 'bg-secondary';
    STsReview = reviewArr;
  } else if (reviewData?.[0]?.rating === '4') {
    stsBtn = 'bg-success';
    STsReview = reviewArr;
  } else {
    stsBtn = 'bg-success';
    STsReview = reviewArr;
  }
  const getOrderDataListFunc = () => {
    try {
      setDataLoading(true);
      setOrderTrigger(1);
      getOrderList({
        params: {
          email: editValue?.email,
          country: editValue?.country?.[0]?.code,
          start: Number(paginationValue.page * limitValue.limit),
          limit: Number(limitValue.limit),
        },

        dispatch,
      });
      // setDataLoading(false);
    } catch {
      console.log('error');
    }
  };

  useEffect(() => {
    if (orderTrigger === 1) {
      getOrderDataListFunc();
    }
  }, [paginationValue.page, orderTrigger]);

  const { orderListDetail = [], isListLoading } = useSelector(
    (state) => state.customer
  );

  const orderData = orderListDetail?.data?.orderList;

  useEffect(() => {
    if (orderData?.length > 0) {
      setDataLoading(false);
    } else if (orderData?.length === 0) {
      setDataLoading(false);
    }
  }, [orderData]);
  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const getReturnDataListFunc = () => {
    try {
      setDataLoading(true);
      setreturnTrigger(1);

      getReturnList({
        params: {
          customer_id: id,
          country: editValue?.country?.[0]?.code,
          start: Number(paginationValue.page * limitValue.limit),
          limit: Number(limitValue.limit),
        },

        dispatch,
      });
    } catch {
      console.log('error');
    }
  };
  useEffect(() => {
    if (returnTrigger === 1) {
      getReturnDataListFunc();
    }
  }, [paginationValue.page, returnTrigger]);
  const { orderReturnDetail = [], isReturnListLoading } = useSelector(
    (state) => state.customer
  );
  useEffect(() => {
    if (orderReturnDetail?.status === 'failed') {
      setDataLoading(false);
    } else if (orderReturnDetail?.status === 'success') {
      setDataLoading(false);
    }
  }, [orderReturnDetail]);
  const handleCountry = (e) => {
    setAddressCountry(e.target.value);
    localStorage.setItem('CUSTOMER_COUNTRY', e.target.value);
  };
  const handleLanguage = (e) => {
    localStorage.setItem('CUSTOMER_LANGUAGE', e.target.value);
  };
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
  }
  let userID = '';

  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      userID = userObj?.user_id;
    }
  }
  const [getOrderToken, { errors }] = useLazyQuery(GET_AUTH_TOKEN_ADMIN);

  const getOrderAuthTokenFunc = async () => {
    try {
      let response = await getOrderToken({
        variables: {
          input: {
            id: id,
            email: editValue?.email,
          },
        },
        dispatch,
      });
      setOrderToken(response?.data?.getAuthTokenAdmin?.token);
    } catch {}
  };
  const createOrderFunc = () => {
    setOrderCountryErrorMessage(orderCountry === null ? true : false);
    setRequestTypeError(orderCountry === null ? true : false);
    if (orderCountry !== null && requestType !== null) {
      let url = `${webUrl}${orderCountry}/en/?omsRemoteAuth=${orderToken}&orderType=${requestType}&userId=${userID}`;
      window?.open(url, '_blank');
      setmodal_backdrop(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content refundOrder">
        <Container fluid>
          <Breadcrumbs
            title="Customers Detail Form"
            breadcrumbItem="Customer"
          />

          <Row>
            <Col>
              <Row>
                <Col md="3">
                  <Card>
                    <Nav pills className="flex-column">
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '1',
                          })}
                          onClick={() => {
                            toggleVertical('1');
                          }}
                        >
                          Customer View
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '2',
                          })}
                          onClick={() => {
                            toggleVertical('2');
                          }}
                        >
                          Account Information
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '3',
                          })}
                          onClick={() => {
                            toggleVertical('3');
                          }}
                        >
                          Addresses
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '4',
                          })}
                          onClick={() => {
                            toggleVertical('4');
                            getOrderDataListFunc();
                          }}
                        >
                          Orders
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '5',
                          })}
                          onClick={() => {
                            toggleVertical('5');
                            getReturnDataListFunc();
                          }}
                        >
                          Returns
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '6',
                          })}
                          onClick={() => {
                            toggleVertical('6');
                            wishlistRef?.current?.log();
                          }}
                        >
                          WishList
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '7',
                          })}
                          onClick={() => {
                            toggleVertical('7');
                          }}
                        >
                          Shopping Cart
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '8',
                          })}
                          onClick={() => {
                            toggleVertical('8');
                          }}
                        >
                          Store Credit
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '9',
                          })}
                          onClick={() => {
                            toggleVertical('9');
                          }}
                        >
                          NewsLetter
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '10',
                          })}
                          onClick={() => {
                            toggleVertical('10');
                            setStarValue(ratingInt);
                          }}
                        >
                          Delivery Reviews
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Card>
                </Col>
                <Col md="9">
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    <TabPane tabId="1">
                      <React.Fragment>
                        <Row>
                          <Col xl={12} md={6}>
                            <Card className="card-h-100 OrderDetailsBox">
                              <CardHeader className="bg-light">
                                <CardTitle>PERSONAL INFORMATION</CardTitle>
                              </CardHeader>

                              <CardBody>
                                <Row>
                                  <Col xs={12}>
                                    <Row>
                                      <Col xs={3}>
                                        <span className="mb-2 text-muted text-uppercase font-size-11">
                                          Last Logged In{' '}
                                        </span>
                                      </Col>
                                      <Col xs={9}>
                                        <h6 className="fw-medium">
                                          Never (Offline)
                                        </h6>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col xs={12}>
                                    <Row>
                                      <Col xs={3}>
                                        <p className="mb-2 text-muted text-uppercase font-size-11">
                                          Full Name
                                        </p>
                                      </Col>
                                      <Col xs={9}>
                                        <h6 className="fw-medium">
                                          {editValue?.firstName}{' '}
                                          {editValue?.lastName}
                                          <br />
                                        </h6>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col xs={12}>
                                    <Row>
                                      <Col xs={3}>
                                        <p className="mb-2 text-muted text-uppercase font-size-11">
                                          Confirmed Email
                                        </p>
                                      </Col>
                                      <Col xs={9}>
                                        <h6 className="fw-medium">
                                          {editValue?.email}
                                        </h6>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col xs={12}>
                                    <Row>
                                      <Col xs={3}>
                                        <p className="mb-2 text-muted text-uppercase font-size-11">
                                          Account Created
                                        </p>
                                      </Col>
                                      <Col xs={9}>
                                        <h6 className="fw-medium">
                                          {orderDt} {orderTime}
                                        </h6>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col xs={12}>
                                    <Row>
                                      <Col xs={3}>
                                        <p className="mb-2 text-muted text-uppercase font-size-11">
                                          Account Created In
                                        </p>
                                      </Col>
                                      <Col xs={9}>
                                        <h6 className="fw-medium">
                                          {editValue?.country?.[0]?.data
                                            ?.basicInfo?.defaultLanguage ===
                                          'en'
                                            ? 'English'
                                            : 'Arabic'}
                                        </h6>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col xs={12}>
                                    <Row>
                                      <Col xs={3}>
                                        <p className="mb-2 text-muted text-uppercase font-size-11">
                                          Customer Group
                                        </p>
                                      </Col>
                                      <Col xs={9}>
                                        <h6 className="fw-medium">General</h6>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col></Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={12}>
                            <Card>
                              <CardHeader>
                                <CardTitle>REWARD POINT BALANCE</CardTitle>
                              </CardHeader>
                              <CardBody>
                                <div className="table-responsive">
                                  <Table className="table table-striped mb-0">
                                    <thead>
                                      <tr>
                                        <th>Website</th>
                                        <th>Balance</th>
                                        <th>Current Amount</th>
                                        <th>Reward Points ThresHold</th>
                                        <th>Reward Points Cap</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={12}>
                            <Card>
                              <CardHeader>
                                <CardTitle>STORE CREDIT BALANCE</CardTitle>
                              </CardHeader>
                              <CardBody>
                                <div className="table-responsive">
                                  <Table className="table table-striped mb-0">
                                    <thead>
                                      <tr>
                                        <th>Balance</th>
                                        <th>Website</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </React.Fragment>
                    </TabPane>
                    <TabPane tabId="2">
                      <React.Fragment>
                        <Col xl={9}>
                          <Card>
                            <CardHeader className="bg-light">
                              <CardTitle>ACCOUNT INFORMATION </CardTitle>
                            </CardHeader>
                            <CardBody>
                              <AvForm
                                className="needs-validation"
                                onValidSubmit={(e, v) => {
                                  if (!errorMessage) {
                                    handleUpdateAddress(e, v);
                                  }
                                }}
                              >
                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <AvField
                                        type="select"
                                        name="isSocialLogin"
                                        label="Is Social Login"
                                        className="form-select"
                                        value="false"
                                        disabled
                                      >
                                        <option value="true">Yes</option>
                                        <option value="false" selected>
                                          No
                                        </option>
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <AvField
                                        type="select"
                                        name="status"
                                        label="Associate to Website"
                                        className="form-select"
                                        value={editValue?.country?.[0]?.code}
                                        onChange={handleCountry}
                                      >
                                        <option value="ae">UAE</option>
                                        <option value="bh">Bahrain</option>
                                        <option value="om">Oman</option>
                                        <option value="kw">Kuwait</option>
                                        <option value="qa">Qatar</option>
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <AvField
                                        type="select"
                                        name="status"
                                        label="Group"
                                        className="form-select"
                                        value=""
                                      >
                                        <option value="General">General</option>
                                      </AvField>
                                    </FormGroup>
                                  </Col>{' '}
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="validationCustom04">
                                        Email
                                      </Label>
                                      <AvField
                                        name="email"
                                        type="email"
                                        disabled
                                        value={editValue?.email}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="validationCustom04">
                                        First Name
                                      </Label>
                                      <AvField
                                        name="firstname"
                                        validate={{
                                          required: {
                                            value: true,
                                            errorMessage:
                                              'Please Fill First name.',
                                          },
                                        }}
                                        type="text"
                                        value={editValue?.firstName}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="validationCustom04">
                                        Last Name
                                      </Label>
                                      <AvField
                                        name="lastname"
                                        type="text"
                                        value={editValue?.lastName}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="validationCustom04">
                                        Mobile Number
                                      </Label>
                                      <AvField
                                        name="mobilenumber"
                                        type="number"
                                        validate={{
                                          required: {
                                            value: true,
                                            errorMessage:
                                              'Please Fill valid mobile number .',
                                          },
                                        }}
                                        value={
                                          editValue?.country?.[0]?.data
                                            ?.basicInfo?.mobile
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <AvField
                                        type="select"
                                        name="language"
                                        label="Send Welcome Email From"
                                        className="form-select"
                                        value={
                                          editValue?.country?.[0]?.data
                                            ?.basicInfo?.defaultLanguage
                                        }
                                        onChange={handleLanguage}
                                      >
                                        <option value="en">English</option>
                                        <option value="ar">Arabic</option>
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Button color="primary" type="submit">
                                  {updateStsLoad === true && (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  )}
                                  Save Customer
                                </Button>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </React.Fragment>
                    </TabPane>
                    {openEdit ? (
                      <>
                        <AddCustomerEditForm
                          editValue={editValue}
                          selectedAddressForEdit={selectedAddressForEdit}
                          reLoadAddressList={reLoadAddressList}
                        />
                      </>
                    ) : (
                      <>
                        <TabPane tabId="3">
                          <React.Fragment>
                            {!openMap ? (
                              <>
                                {' '}
                                <Row>
                                  {editValue?.address?.map((address) => {
                                    return (
                                      <Col xl={4} md={6}>
                                        <Card
                                          className={
                                            address?.isDefault === true
                                              ? 'OrderDetailsBox1'
                                              : 'OrderDetailsBox'
                                          }
                                        >
                                          <CardHeader
                                            className={
                                              address?.isDefault === true
                                                ? 'OrderDetailsBox2'
                                                : 'bg-light'
                                            }
                                          >
                                            <Row>
                                              <Col xl={8}>
                                                <CardTitle
                                                  style={{ fontSize: '16px' }}
                                                >
                                                  {' '}
                                                  <i className="bx bx-map"></i>
                                                  {'  '}
                                                  {
                                                    editValue?.address?.[0]
                                                      ?.addressType
                                                  }{' '}
                                                  {address?.isDefault ===
                                                  true ? (
                                                    <span
                                                      style={{
                                                        fontSize: '13px',
                                                        color: 'white',
                                                        backgroundColor:
                                                          'black',
                                                        borderRadius: '19px',
                                                        padding:
                                                          '1px 7px 1px 7px',
                                                      }}
                                                    >
                                                      Default
                                                    </span>
                                                  ) : (
                                                    ''
                                                  )}
                                                </CardTitle>
                                              </Col>
                                              <Col>
                                                <button
                                                  onClick={() => {
                                                    setDefaultAddress(
                                                      address?._id
                                                    );
                                                    getDefaultAddressAdminFunc(
                                                      address?._id
                                                    );
                                                  }}
                                                  type="button"
                                                  className="btn btn-sm btn-outline-dark waves-effect waves-light fw-medium"
                                                >
                                                  {' '}
                                                  Make Default
                                                </button>
                                              </Col>
                                            </Row>
                                          </CardHeader>

                                          <CardBody>
                                            <Row>
                                              <Col xs={12}>
                                                <Row>
                                                  <Col xs={3}>
                                                    <span className="mb-2 text-muted text-uppercase font-size-11">
                                                      Name{' '}
                                                    </span>
                                                  </Col>
                                                  <Col xs={9}>
                                                    <h6 className="fw-medium">
                                                      {address?.firstName}{' '}
                                                      {address?.lastName}
                                                    </h6>
                                                  </Col>
                                                </Row>
                                              </Col>

                                              <Col xs={12}>
                                                <Row>
                                                  <Col xs={3}>
                                                    <p className="mb-2 text-muted text-uppercase font-size-11">
                                                      Address
                                                    </p>
                                                  </Col>
                                                  <Col xs={9}>
                                                    <h6 className="fw-medium">
                                                      {address?.mapData}
                                                      <br />
                                                    </h6>
                                                  </Col>
                                                </Row>
                                              </Col>

                                              <Col xs={12}>
                                                <Row>
                                                  <Col xs={3}>
                                                    <p className="mb-2 text-muted text-uppercase font-size-11">
                                                      Phone
                                                    </p>
                                                  </Col>
                                                  <Col xs={9}>
                                                    <h6 className="fw-medium">
                                                      {address?.telephone}
                                                    </h6>
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col>
                                                <button
                                                  onClick={() => {
                                                    getAddressDeleteAdminFunc();
                                                  }}
                                                  type="button"
                                                  className="btn btn-sm btn-outline-dark waves-effect  fw-medium"
                                                >
                                                  {' '}
                                                  <i className="mdi mdi-delete"></i>{' '}
                                                </button>

                                                {'  '}
                                                <button
                                                  onClick={() => {
                                                    setOpenEDit(!openEdit);
                                                    setSelectedAddressForEdit(
                                                      address
                                                    );
                                                  }}
                                                  type="button"
                                                  className="btn btn-sm btn-outline-dark  fw-medium"
                                                >
                                                  {' '}
                                                  Edit
                                                </button>
                                              </Col>
                                            </Row>
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    );
                                  })}
                                </Row>
                                <Row
                                  style={{
                                    border: '1px dashed #CBC7C7',
                                    borderRadius: '4px',
                                    opacity: '1px',
                                    height: '70px',
                                  }}
                                >
                                  <button
                                    type="button"
                                    style={{ fontSize: '20px' }}
                                    onClick={onMapOpen}
                                    className="btn"
                                  >
                                    <i class="bx bx-plus"></i> {'   '}Add New
                                    Address
                                  </button>
                                </Row>
                              </>
                            ) : (
                              <>
                                {' '}
                                {showMap && isLoaded ? (
                                  <div style={{ position: 'relative' }}>
                                    <h4>Add a New Shipping Address</h4>
                                    <StandaloneSearchBox
                                      onPlacesChanged={onPlacesChanged}
                                      ref={searchBox}
                                    >
                                      <>
                                        <div className="d-inline-block float-md-start me-1 mb-2 search-input-container w-100 bg-foreground">
                                          <input
                                            type="text"
                                            placeholder="Customized your placeholder"
                                            className="form-control"
                                            value={selectedAddress}
                                            onChange={(event) => {
                                              setSelectedAddress(
                                                event?.target?.value
                                              );
                                            }}
                                          />
                                          <span className="search-magnifier-icon searchIcon">
                                            <CsLineIcons icon="search" />
                                          </span>
                                          <span className="search-delete-icon d-none">
                                            <CsLineIcons icon="close" />
                                          </span>
                                        </div>
                                      </>
                                    </StandaloneSearchBox>
                                    <GoogleMap
                                      mapContainerStyle={containerStyle}
                                      center={selectedLatLng}
                                      // zoom={10}
                                      onLoad={onLoad}
                                      onUnmount={onUnmount}
                                      onClick={onClick}
                                      options={{
                                        restriction: {
                                          latLngBounds:
                                            contryBounds[countryCode],
                                          strictBounds: false,
                                        },
                                      }}
                                    >
                                      <>
                                        {
                                          <Marker
                                            icon={
                                              'https://assets.danubehome.com/media/email/dhMapMarker.svg'
                                            }
                                            position={selectedLatLng}
                                          />
                                        }
                                      </>
                                    </GoogleMap>
                                    <div
                                      style={{
                                        marginTop: '20px',
                                        float: 'right',
                                      }}
                                    >
                                      <button
                                        className="btn btn-outline-dark waves-effect waves-light mb-3"
                                        onClick={() => {
                                          setOpenMap(false);
                                        }}
                                      >
                                        Back
                                      </button>
                                      &nbsp;&nbsp;
                                      <button
                                        className="btn btn-secondary mb-3"
                                        onClick={() => {
                                          setShowMap(false);
                                        }}
                                      >
                                        Confirm & Save Location
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    {' '}
                                    <AvForm
                                      className="needs-validation"
                                      onValidSubmit={(e, v) => {
                                        if (!errorMessage) {
                                          handleValidSubmit(e, v);
                                        }
                                      }}
                                    >
                                      <Row>
                                        <Col lg={6}>
                                          <h4>Add a New Shipping Address</h4>
                                          <Row>
                                            <Col lg={9}>
                                              <input
                                                type="text"
                                                placeholder="Customized your placeholder"
                                                className="form-control"
                                                value={selectedAddress}
                                              />
                                            </Col>
                                            <Col lg={3} className="mb-3">
                                              <button
                                                onClick={() => {
                                                  setShowMap(true);
                                                }}
                                                className="btn btn-secondary"
                                              >
                                                Change
                                              </button>
                                            </Col>
                                          </Row>
                                          <Col>
                                            <div className="mapSize">
                                              <GoogleMap
                                                mapContainerStyle={{
                                                  minHeight: 350,
                                                  width: '100%',
                                                  height: '100%',
                                                }}
                                                center={selectedLatLng}
                                                zoom={15}
                                                onLoad={onLoad}
                                                onUnmount={onUnmount}
                                              >
                                                <>
                                                  <Marker
                                                    position={selectedLatLng}
                                                    icon={
                                                      'https://assets.danubehome.com/media/email/dhMapMarker.svg'
                                                    }
                                                  />
                                                </>
                                              </GoogleMap>
                                            </div>
                                          </Col>
                                        </Col>
                                        <Col lg={5}>
                                          <h4>Your Contact Details</h4>
                                          <Row>
                                            <Col>
                                              <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom04">
                                                  First Name
                                                </Label>
                                                <AvField
                                                  name="firstname"
                                                  type="text"
                                                  value={editValue?.firstName}
                                                />
                                              </FormGroup>
                                            </Col>
                                            <Col>
                                              <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom04">
                                                  Last Name
                                                </Label>
                                                <AvField
                                                  name="lastname"
                                                  type="text"
                                                  value={editValue?.lastName}
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col className="mb-3">
                                              <FormGroup className="mb-12">
                                                <Label htmlFor="validationCustom04">
                                                  Full Address
                                                </Label>
                                                <AvField
                                                  name="fulladdress"
                                                  type="text"
                                                  placeholder="e.g Apartment 4,Building Name, Street 3"
                                                  className="form-control"
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom04">
                                                  Flat/Apartment No
                                                </Label>
                                                <AvField
                                                  name="flatno"
                                                  type="text"
                                                  value=""
                                                />
                                              </FormGroup>
                                            </Col>

                                            <Col className="mb-3">
                                              <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom04">
                                                  Mobile Number
                                                </Label>
                                                <AvField
                                                  name="mobileNumber"
                                                  type="number"
                                                  required
                                                  value=""
                                                  onBlur={testHandle}
                                                  onFocus={() => {
                                                    setErrorMessage(null);
                                                  }}
                                                />
                                                <span className="text-danger">
                                                  {errorMessage}
                                                </span>
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                          <Col className="mb-3">
                                            <Label>
                                              Address Label(Optional)
                                            </Label>
                                            <AvRadioGroup
                                              inline
                                              name="addressType"
                                            >
                                              <AvRadio
                                                label="Home"
                                                value="Home"
                                              />
                                              <AvRadio
                                                label="Work"
                                                value="Work"
                                              />
                                            </AvRadioGroup>
                                          </Col>
                                          <Col className="mb-3">
                                            <div
                                              className="form-check form-switch mb-3"
                                              dir="ltr"
                                            >
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="customSwitch1"
                                                value="false"
                                                defaultChecked={checked}
                                                onChange={handleSwitch}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="customSwitch1"
                                              >
                                                Set as default address
                                              </label>
                                            </div>
                                          </Col>
                                          <button
                                            className="btn btn-outline-dark waves-effect waves-light"
                                            onClick={() => {
                                              setShowMap(true);
                                            }}
                                          >
                                            Back
                                          </button>
                                          &nbsp; &nbsp;
                                          <button
                                            className="btn btn-secondary"
                                            type="submit"
                                          >
                                            Save Address
                                          </button>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                  </>
                                )}
                              </>
                            )}
                          </React.Fragment>
                        </TabPane>
                      </>
                    )}

                    <TabPane tabId="4">
                      <Row>
                        <Col md={12}>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col xl={6}>
                                  {' '}
                                  <CardTitle>Orders </CardTitle>
                                </Col>

                                <Col xl={6} className="textAlignRight">
                                  <Button
                                    color="primary"
                                    className="mr-2"
                                    onClick={() => {
                                      tog_backdrop();
                                      getOrderAuthTokenFunc();
                                    }}
                                    disabled={open}
                                  >
                                    Create an Order
                                  </Button>
                                  <Modal
                                    isOpen={modal_backdrop}
                                    toggle={() => {
                                      tog_backdrop();
                                    }}
                                    backdrop={'static'}
                                    id="staticBackdrop"
                                  >
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="staticBackdropLabel"
                                      >
                                        Create an Order
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                          setmodal_backdrop(false);
                                        }}
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <p>
                                        Please Select the country and Order
                                        Request Type.
                                      </p>
                                      <Row>
                                        <Col>
                                          <label>Country</label>
                                          <Select
                                            options={options}
                                            onChange={(option) => {
                                              setOrderCountry(option?.value);
                                              setOrderCountryErrorMessage(
                                                false
                                              );
                                            }}
                                          />
                                          {orderCountryErrorMsg ? (
                                            <>
                                              {' '}
                                              <span
                                                style={{
                                                  position: 'absolute',
                                                  top: 63,
                                                  left: 11,
                                                  color: '#f00',
                                                }}
                                              >
                                                Please Select Country.
                                              </span>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </Col>
                                        <Col>
                                          <label>Order Request Type</label>
                                          <Select
                                            options={options1}
                                            onChange={(option) => {
                                              setRequestType(option?.value);
                                              setRequestTypeError(false);
                                            }}
                                          />
                                          {requestTypeError && (
                                            <span
                                              style={{
                                                position: 'absolute',
                                                top: 63,
                                                left: 11,
                                                color: '#f00',
                                              }}
                                            >
                                              Please Select Request Type.
                                            </span>
                                          )}
                                        </Col>
                                      </Row>
                                    </div>

                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-light"
                                        onClick={() => {
                                          setmodal_backdrop(false);
                                        }}
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-success"
                                        onClick={() => {
                                          createOrderFunc();
                                        }}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </Modal>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              {isListLoading || isDataLoading ? (
                                <Spinner
                                  className="ms-2 sploader"
                                  color="primary"
                                />
                              ) : null}
                              {orderData?.length > 0 ? (
                                <div class="table-responsive">
                                  <Table className="OrderListTable table mb-0">
                                    {orderData?.map((order) => {
                                      let cdate = order?.createdAt,
                                        orderDt = moment(
                                          new Date(cdate)
                                        ).format('Do MMM, YYYY'),
                                        orderTime = moment(
                                          new Date(cdate)
                                        ).format('h:mm:ss a');
                                      const deliveryMode = order?.deliveryMode;

                                      let delivMode = '';
                                      if (deliveryMode === '0') {
                                        delivMode = 'Courier';
                                      } else if (deliveryMode === '1') {
                                        delivMode = 'Warehouse';
                                      } else if (deliveryMode === '2') {
                                        delivMode = 'BackToBack';
                                      } else if (deliveryMode === '3') {
                                        delivMode = 'Warehouse + BackToBack';
                                      } else if (deliveryMode === '4') {
                                        delivMode = 'Courier + BackToBack';
                                      } else if (deliveryMode === '5') {
                                        delivMode =
                                          'Warehouse + BackToBack + Courier';
                                      } else {
                                        delivMode = 'Warehouse + Courier';
                                      }
                                      let countItems = 0;
                                      return (
                                        <tbody>
                                          <tr>
                                            <td>
                                              <strong>
                                                Ref No: {order?.referenceNo}{' '}
                                              </strong>
                                              <br></br>
                                              {orderDt} <br></br>
                                              {orderTime}
                                              <br></br>
                                              <strong>Grand_Total:</strong>{' '}
                                              {Number(
                                                order?.grandTotal
                                              )?.toFixed(0)}
                                            </td>
                                            <td>
                                              {order?.items?.map(
                                                (item, itemInx) => {
                                                  let stsBtn = 'bg-lightgrey';
                                                  let lsStatus = '';
                                                  if (
                                                    item?.status.toLowerCase() ===
                                                    'pending'
                                                  ) {
                                                    lsStatus = 'Pending';
                                                    stsBtn = 'bg-soft-dark';
                                                  } else if (
                                                    item?.status.toLowerCase() ===
                                                    'delivered'
                                                  ) {
                                                    lsStatus = 'Delivered';
                                                    stsBtn = 'bg-success';
                                                  } else if (
                                                    item?.status.toLowerCase() ===
                                                    'packed'
                                                  ) {
                                                    lsStatus = 'Packed';
                                                    stsBtn = 'bg-warning';
                                                  } else if (
                                                    item?.status.toLowerCase() ===
                                                    'posted'
                                                  ) {
                                                    stsBtn = 'bg-primary';
                                                    lsStatus = 'Posted';
                                                  } else if (
                                                    item?.status.toLowerCase() ===
                                                    'Processing'
                                                  ) {
                                                    stsBtn = 'bg-primary';
                                                    lsStatus = 'Processing';
                                                  } else if (
                                                    item?.status.toLowerCase() ===
                                                    'confirmed'
                                                  ) {
                                                    stsBtn = 'bg-primary';
                                                    lsStatus = 'Confirmed';
                                                  } else if (
                                                    item?.status.toLowerCase() ===
                                                    'accepted'
                                                  ) {
                                                    stsBtn = 'bg-primary';
                                                    lsStatus = 'Accepted';
                                                  } else if (
                                                    item?.status.toLowerCase() ===
                                                      'canceled' ||
                                                    item?.status ===
                                                      'Canceled' ||
                                                    item?.status.toLowerCase() ===
                                                      'cancel' ||
                                                    item?.status.toLowerCase() ===
                                                      'Cancel'
                                                  ) {
                                                    lsStatus = 'Canceled';
                                                    stsBtn = 'bg-danger';
                                                  }

                                                  return (
                                                    <>
                                                      {itemInx <= 1 ? (
                                                        <>
                                                          <td className="noWrapText2">
                                                            <div>
                                                              <img
                                                                alt=""
                                                                width="100"
                                                                height="100"
                                                                className="img-thumbnail"
                                                                src={
                                                                  item?.image
                                                                }
                                                              />

                                                              <div>
                                                                <span className="noWrapText1">
                                                                  <strong>
                                                                    Name:{' '}
                                                                  </strong>
                                                                  {item?.name}
                                                                </span>

                                                                <span>
                                                                  <strong>
                                                                    Sku :{' '}
                                                                  </strong>
                                                                  {item?.sku}
                                                                </span>
                                                                <span>
                                                                  <strong>
                                                                    Qty:
                                                                  </strong>{' '}
                                                                  {item?.qty}
                                                                </span>
                                                                <span>
                                                                  Status :{' '}
                                                                  <Badge
                                                                    className={` ${stsBtn} me-3`}
                                                                  >
                                                                    {' '}
                                                                    {lsStatus}
                                                                  </Badge>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </td>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <span
                                                            className="highlightInfo1"
                                                            style={{
                                                              textAlign:
                                                                'right',
                                                            }}
                                                          >
                                                            {itemInx === 2
                                                              ? '2+More'
                                                              : ''}
                                                          </span>
                                                        </>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </td>
                                            <td>
                                              <a
                                                href={`/orders/${order?.entityId}/?order_mode=All&orderCn=${editValue?.country?.[0]?.code}`}
                                                type="button"
                                                className=" orderViewButton btnTracking mb-2 mr-2"
                                              >
                                                {' '}
                                                View Detail
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      );
                                    })}
                                  </Table>
                                  <div className="dataTblFooter">
                                    <div>
                                      <Dropdown
                                        isOpen={btnprimary1}
                                        toggle={() =>
                                          setBtnprimary1(!btnprimary1)
                                        }
                                      >
                                        <DropdownToggle
                                          tag="button"
                                          className="btn btn-primary"
                                        >
                                          {limitValue.limit} Items{' '}
                                          <i className="mdi mdi-chevron-down" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          {limitByValues.map((limit, i) => {
                                            return (
                                              <DropdownItem
                                                key={i}
                                                href="#"
                                                onClick={() =>
                                                  handleLimit(limit)
                                                }
                                              >
                                                {limit} Items
                                              </DropdownItem>
                                            );
                                          })}
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                      <Pagination
                                        id="listPage"
                                        value={paginationValue.page}
                                        totalPages={Math.ceil(
                                          orderListDetail?.data?.recordsTotal /
                                            limitValue.limit
                                        )}
                                        onChange={(e) => handlePagination(e)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {' '}
                                  <Table className="OrderListTable table mb-0">
                                    <tbody>
                                      <tr>
                                        <td colSpan={7}>No Records found</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="5">
                      <Row>
                        <Col md={12}>
                          <Card>
                            <CardHeader>
                              <CardTitle>Returns</CardTitle>
                            </CardHeader>
                            <CardBody>
                              {isReturnListLoading || isDataLoading ? (
                                <Spinner
                                  className="ms-2 sploader"
                                  color="primary"
                                />
                              ) : null}
                              {orderReturnDetail?.data?.length > 0 ? (
                                <div class="table-responsive">
                                  <Table className="OrderListTable table mb-0">
                                    {orderReturnDetail?.data?.map((order) => {
                                      let cdate =
                                          order?.returnDetails?.returnDate,
                                        orderDt = moment(
                                          new Date(cdate)
                                        ).format('Do MMM, YYYY'),
                                        orderTime = moment(
                                          new Date(cdate)
                                        ).format('h:mm:ss a');
                                      return (
                                        <tbody>
                                          <tr>
                                            <td>
                                              <strong>
                                                {' '}
                                                Retrun No:
                                                {
                                                  order?.returnDetails
                                                    ?.returnReference
                                                }
                                              </strong>
                                              <br></br>
                                              <strong>
                                                {' '}
                                                ReturnDate:{' '}
                                              </strong>{' '}
                                              {orderDt} <br></br>
                                              {orderTime}
                                            </td>
                                            <td className="noWrapText1">
                                              <div>
                                                <img
                                                  src={
                                                    order?.productDetails?.image
                                                  }
                                                  alt="product-image"
                                                  width="80px"
                                                  height="80px"
                                                />
                                                <div>
                                                  <span>
                                                    <strong>Name:</strong>
                                                    {
                                                      order?.productDetails
                                                        ?.name
                                                    }
                                                  </span>
                                                  <span>
                                                    <strong>Sku:</strong>
                                                    {order?.productDetails?.sku}
                                                  </span>
                                                  <span>
                                                    <strong>Order_Qty:</strong>{' '}
                                                    {
                                                      order?.returnDetails
                                                        ?.qtyOrdered
                                                    }
                                                  </span>
                                                  <span>
                                                    <strong>Return_Oty:</strong>{' '}
                                                    {
                                                      order?.returnDetails
                                                        ?.qtyReturned
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td nowrap="nowrap"></td>

                                            <td>
                                              {' '}
                                              <a
                                                href={`/returnorders/${order?.returnDetails?.returnId}/${order?.returnDetails?.orderId}`}
                                                type="button"
                                                className=" orderViewButton btnTracking mb-2 mr-2"
                                              >
                                                {' '}
                                                View Detail
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      );
                                    })}
                                  </Table>
                                  <div className="dataTblFooter">
                                    <div>
                                      <Dropdown
                                        isOpen={btnprimary1}
                                        toggle={() =>
                                          setBtnprimary1(!btnprimary1)
                                        }
                                      >
                                        <DropdownToggle
                                          tag="button"
                                          className="btn btn-primary"
                                        >
                                          {limitValue.limit} Items{' '}
                                          <i className="mdi mdi-chevron-down" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          {limitByValues.map((limit, i) => {
                                            return (
                                              <DropdownItem
                                                key={i}
                                                href="#"
                                                onClick={() =>
                                                  handleLimit(limit)
                                                }
                                              >
                                                {limit} Items
                                              </DropdownItem>
                                            );
                                          })}
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                      <Pagination
                                        id="listPage"
                                        value={paginationValue.page}
                                        totalPages={Math.ceil(
                                          orderReturnDetail?.totalReturns /
                                            limitValue.limit
                                        )}
                                        onChange={(e) => handlePagination(e)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>No Record Found</>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6">
                      <AddWishListDetail ref={wishlistRef} />
                    </TabPane>
                    <TabPane tabId="7">
                      <Row>
                        <h4>Items in Card</h4>

                        <Col xs={4}>
                          <Card>
                            <CardBody>
                              <div className="ProductDetails" nowrap="nowrap">
                                <span>
                                  <img
                                    alt=""
                                    width="100"
                                    height="100"
                                    className="img-thumbnail"
                                    src="	https://assets.danubehome.com/media/catalog/product/cache…4022dc0344b8f4ff8bd922de/8/1/810302800163-gp1.jpg"
                                  />
                                </span>
                                <p>
                                  Ada 1-Seater Fabric Sofa
                                  <br />
                                  Sku : 810401100456
                                  <br />
                                  QTY - 1
                                  <br />
                                  AED <b>915</b> <del>AED 1,309</del>
                                  <br />
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={4}>
                          <Card>
                            <CardBody>
                              <div className="ProductDetails" nowrap="nowrap">
                                <span>
                                  <img
                                    alt=""
                                    width="100"
                                    height="100"
                                    className="img-thumbnail"
                                    src="	https://assets.danubehome.com/media/catalog/product/cache…4022dc0344b8f4ff8bd922de/8/1/810302800163-gp1.jpg"
                                  />
                                </span>
                                <p>
                                  Ada 1-Seater Fabric Sofa
                                  <br />
                                  Sku : 810401100456
                                  <br />
                                  QTY - 1
                                  <br />
                                  AED <b>915</b> <del>AED 1,309</del>
                                  <br />
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={4}>
                          <Card>
                            <CardBody>
                              <div className="ProductDetails" nowrap="nowrap">
                                <span>
                                  <img
                                    alt=""
                                    width="100"
                                    height="100"
                                    className="img-thumbnail"
                                    src="	https://assets.danubehome.com/media/catalog/product/cache…4022dc0344b8f4ff8bd922de/8/1/810302800163-gp1.jpg"
                                  />
                                </span>
                                <p>
                                  Ada 1-Seater Fabric Sofa
                                  <br />
                                  Sku : 810401100456
                                  <br />
                                  QTY - 1
                                  <br />
                                  AED <b>915</b> <del>AED 1,309</del>
                                  <br />
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="9">
                      <Col xs={4}>
                        <Card>
                          <CardHeader>
                            <CardTitle>Newsletter Information</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <AvForm>
                              {' '}
                              <FormGroup className="mb-3">
                                <AvField
                                  type="select"
                                  name="newsletter"
                                  label="Subscribed to Newsletter"
                                  className="form-select"
                                  value=""
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </AvField>
                              </FormGroup>
                            </AvForm>
                          </CardBody>
                        </Card>
                      </Col>
                    </TabPane>
                    <TabPane tabId="10">
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <Nav tabs className="nav-tabs-custom nav-justified">
                              <Col xs={2}>
                                <NavItem className="navitemalign">
                                  <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={classnames({
                                      active: customActiveTab === '1',
                                    })}
                                    onClick={() => {
                                      toggleCustom('1');
                                    }}
                                  >
                                    <span className="d-block d-sm-none">
                                      <i className="fas fa-home"></i>
                                    </span>
                                    <span className="d-none d-sm-block">
                                      Delivery Review
                                    </span>
                                  </NavLink>
                                </NavItem>
                              </Col>
                            </Nav>
                            <TabContent
                              activeTab={customActiveTab}
                              className="p-3 text-muted"
                            >
                              <TabPane tabId="1">
                                <Row>
                                  <Card>
                                    <CardBody>
                                      <h3>#{reviewData?.[0]?.reference_no}</h3>
                                      <Row>
                                        <Col>
                                          <label>
                                            Placed on &nbsp;
                                            <b>{reviewData?.[0]?.created_at}</b>
                                          </label>{' '}
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          <label>
                                            Delivered on &nbsp;{' '}
                                            <b>
                                              {reviewData?.[0]?.delivered_at}
                                            </b>
                                          </label>
                                        </Col>
                                      </Row>
                                      <br />

                                      <h5>What Want Well</h5>
                                      <div className="mb-3">
                                        <label>
                                          {reviewArr?.map((item, index) => {
                                            return (
                                              <Badge
                                                className={` ${stsBtn} me-2`}
                                              >
                                                <span key={index}>{item}</span>{' '}
                                              </Badge>
                                            );
                                          })}
                                        </label>{' '}
                                      </div>

                                      <div className="star-rating mb-2">
                                        {[...Array(starValue)].map((star) => {
                                          return (
                                            <span
                                              className="star"
                                              star1
                                              style={{ color: '#FBAB32' }}
                                            >
                                              &#9733;
                                            </span>
                                          );
                                        })}
                                        &nbsp;&nbsp;
                                        <b>{reviewData?.[0]?.rating}</b>
                                      </div>
                                      <label>
                                        Reviewd on &nbsp;{' '}
                                        {reviewData?.[0]?.created_at}
                                      </label>
                                    </CardBody>
                                  </Card>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </CardBody>
                        </Card>
                      </Col>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};
export default AddCustomerFormDetail;
