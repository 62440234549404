import { useState, useEffect, button } from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../components/Common/Breadcrumb";
import {  fetchOrderDetail} from "./actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import OrderDetails from "./components/orderDetails";

import "react-datepicker/dist/react-datepicker.css";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import Flatpickr from "react-flatpickr";

import WalletStatus from "./components/WalletStatus";
import { Col, Container, Row, FormGroup, InputGroup, Button } from "reactstrap";

const Details = () => {
  const { id } = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    fetchOrderDetail({
      params: {
        country: "AE",
        order_id:id,
        order_mode: "all"
        
      },
      dispatch,
    });
  }, []);
  const { orderDetail = [], isOrderLoading } = useSelector((state) => state.order);
  const orderData = orderDetail.OrderDetails?.[0];
  const itemData = orderDetail.OrderDetails?.[0]?.orderitems;

  return (
    <>

      <div className="page-content">
        <MetaTags>
          <title>Mange Order View</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
         
          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Manage Orders"
                breadcrumbItem="Order View"
              />
            </Col>
            <Col xl={6}>
              <Row className="align-items-end">
                <Col xl={12} className="textAlignRigh">
                  <div className="DashbordFliter ">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <button type="button" className="btn SecondaryBtn2">
                        Send Sms
                      </button>
                      <span>
                      <WalletStatus/>
                      </span>
                      
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <OrderDetails  orderData={orderData} itemData={itemData}/> 
    
        </Container>
    
      </div>
   </>
  );
};

export default Details;
