// import { iteratorSymbol } from "immer/dist/internal";
import React, { useState } from "react";
import {
  Table,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Modal,
} from "reactstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Badge } from "reactstrap";
import 'react-loading-skeleton/dist/skeleton.css'

function MarketplaceOrderItemDetails({ itemData, orderData }) {
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_large, setmodal_large] = useState(false);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [modal_small, setmodal_small] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [modal_scroll, setmodal_scroll] = useState(false);
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  
  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  function tog_small() {
    setmodal_small(!modal_small);
    removeBodyCss();
  }

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }

  

  return (
    <>
      <Card className="OrderItemDetails">
        <CardHeader>
          <CardTitle>ORDER ITEM DETAILS</CardTitle>
        </CardHeader>
        {itemData !== undefined ? (
          <>
        <CardBody>
          <div className="table-responsive dt-table">
          <Table className=" table mb-0">
              <thead>
                <tr>
                  <th style={{ maxWidth: 400, minWidth: 400 }}>Details</th>
                  <th style={{ maxWidth: 70, minWidth: 70 }}>Qty</th>
                  <th style={{ maxWidth: 110, minWidth: 110 }}>Original Price</th>
                  <th style={{ maxWidth: 110, minWidth: 110 }}>Now Price</th>
                  <th style={{ maxWidth: 110, minWidth: 110 }}>Discount</th>
                  <th style={{ maxWidth: 110, minWidth: 110 }}>Total</th>
                </tr>
              </thead>
              <tbody>
                {itemData?.map((item,itemInx) => {
                  let stsBtn = "bg-lightgrey";
              
                  let lsStatus = "";
                  let LsStatusOption = item?.LsStatus?.toLowerCase();

                  if (
                    item?.LsStatus === "open" ||
                    item?.LsStatus === ""
                  ) {
                    stsBtn = "bg-dark";
                    lsStatus = "Pending";
                  } else {
                    lsStatus = item?.LsStatus;
                  }
                  if (LsStatusOption === "picked") {
                    stsBtn = "bg-warning";
                    lsStatus = "Picked";
                  } else {
                    lsStatus = item?.LsStatus;
                  }

                  if (item?.LsStatus === "0" || item?.LsStatus === "") {
                    if (item?.LsStatus === "Canceled") {
                      stsBtn = "bg-danger";
                      lsStatus = "Canceled";
                    } else if (LsStatusOption === "pending_payment") {
                      lsStatus = "Pending Payment";
                      stsBtn = "bg-danger";
                    } else if (LsStatusOption === "pending") {
                      lsStatus = "Pending";
                      stsBtn = "bg-soft-dark";
                    } else {
                      lsStatus = "New";
                      stsBtn = "bg-soft-dark";
                    }
                  } else {
                    lsStatus = item?.LsStatus;
                  }
                  if (LsStatusOption === "posted") {
                    stsBtn = "bg-primary";
                    lsStatus = "Posted";
                  } else if (LsStatusOption === "confirmed") {
                    stsBtn = "bg-primary";
                    lsStatus = "Confirmed";
                  } else if (
                    LsStatusOption === "delivered" ||
                    LsStatusOption === "completed"
                  ) {
                    stsBtn = "bg-success";
                  }

                  if (
                    item?.LsStatus === "canceled" ||
                    item?.LsStatus === "Canceled" ||
                    item?.LsStatus === "cancel" ||
                    item?.LsStatus === "Cancel" ||
                    LsStatusOption === "pending_payment"
                  ) {
                    lsStatus = "Canceled";
                    stsBtn = "bg-danger";
                  }
                  return (
                    <tr key={itemInx}>
                       <td className="table-bordered">
                               
                               <div style={{gap:'1rem'}}>
                               
                                 <img
                               
                                   alt=""
                                   width="100"
                                   height="100"
                                   className="img-thumbnail1"
                                   src={item?.Image} style={{maxWidth:'100px'}}
                                 />
                              
                               <div className="d-flex flex-column" style={{maxWidth:'75%'}}> 
                                <span>{item?.Name}</span> 
                              
                                <span>Sku : {item?.Sku}</span> 
                                
                                 <span>Delivery Date : <b>{item?.DeliveryDate}</b></span>
                               
                                 <span>Status : {" "}
                                 <Badge className={` ${stsBtn} me-3`}>
                                        {" "}
                                        {lsStatus}
                                      </Badge></span>
                                 <span>LS Ref : <b>{item?.LsOrderRef}</b></span>
                              
                                 
                              
                                 
                               </div>
                             </div>
                           </td>
                      <td  className="table-bordered align-middle"
                                  style={{ textAlign: "center" }}
                                  nowrap="nowrap">{item?.Qty}</td>
                      <td  className="table-bordered align-middle"
                                  style={{ textAlign: "center" }}
                                  nowrap="nowrap">
                        {item?.Currency} {Number(item?.WasPrice).toFixed(2)}
                      </td>
                      <td  className="table-bordered align-middle"
                                  style={{ textAlign: "center" }}
                                  nowrap="nowrap">
                        {item?.Currency} {item?.NowPrice}
                      </td>
                      <td  className="table-bordered align-middle"
                                  style={{ textAlign: "center" }}
                                  nowrap="nowrap">
                        {item?.Currency} {item?.Discount === "" || item?.Discount === null  ? "0.00" : item?.Discount}
                      </td>
                      <td  className="table-bordered align-middle"
                                  style={{ textAlign: "center" }}
                                  nowrap="nowrap">
                        {item?.Currency} {Number(item?.TotalPrice).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
        </>
          ) : (<>
            <CardBody>
             <Skeleton count={6} />
             </CardBody>
              </>
            )}
      </Card>
    </>
  );
}
export default MarketplaceOrderItemDetails;
