import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  importExportList: [],
  skuList:[],
  isLoading: true,
  isDetailLoading:true,


 
};

const importexportSlice = createSlice({
  name: "ImportExport",
  initialState,
  reducers: {
   insertLsStockSku(state, actions) {
      state.importExportList = actions.payload;
      state.isLoading = false;
    },
    setStockSkuList(state, actions) {
      state.skuList = actions.payload;
      state.isLoading = false;
    }

  },
});

export const { insertLsStockSku, setStockSkuList}= importexportSlice.actions;

const importexportReducer = importexportSlice.reducer;

export default importexportReducer;
