import { gql } from '@apollo/client';
export const CREATE_ADDRESS= gql`
mutation(
  $uuid:String!
  $addressData:AddressInput!
  ){
    createAddressAdmin(
      input: {
        uuid:$uuid
        addressData: $addressData
      }
    ) {
      _id
      createdAt
      updatedAt
      firstName
      lastName
      email
      country {
        code
  
      }
      address {
        _id
        countryCode
        firstName
        lastName
        telephone
        alternateTelephone
        address1
        address2
        flatNo
        city
        region
        country
        mapData
        latitude
        longitude
        addressType
        isDefault
        plusCode
       
      }
    }
  }`;