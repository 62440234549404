import React ,{ useEffect, useState} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {  FormGroup, Form,
    InputGroup, Col,Row,Spinner} from "reactstrap";
    import "react-datepicker/dist/react-datepicker.css";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { fetchExportRefundDetail } from "../action/action";
import { DateRangePicker } from 'rsuite';
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";


function RefundExportReport({ orderData }) {
  const {  afterToday, combine,allowedMaxDays} =DateRangePicker;
  const [windowSize, setWindowSize] = useState(getWindowSize());
  let isMobileView = 0;
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
  
    window.addEventListener("resize", handleWindowResize);
  
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  
  if (windowSize.innerWidth <= 1279 && isMobileView === 0) {
    isMobileView++;
  }
  const [startDate, setStartDate] = useState(
    new moment(new Date()).format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState( new moment(new Date()).format("YYYY-MM-DD"));
    const dispatch = useDispatch();
    const location = useLocation();
    const [isTypeError, setisTypeError] = useState(false);
    const [isDateError, setisDateError] = useState(false);
    const [excelExportReport, setexcelExportReport] =useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
   
  const optionGroup = [
    {
      label: "Payment Methods",
      options: [
        { label: "All", value: "all" },
        { label: "PostPay", value: "postpay" },
        { label: "PayFort ", value: "payfort_fort_cc" },
        { label: "Payfort Installments ", value: "payfort_fort_installments" },
        { label: "Tamara ", value: "tamara" },
        { label: "Payfort Apple Pay ", value: "apple_pay" },
        { label: "Tap ", value: "tap" },
        { label: "Tap Careem Pay ", value: "tap_careem_pay" },
        { label: "Tap Apple Pay ", value: "tap_apple_pay" },
        { label: "Checkout ", value: "checkoutcom_card_payment" },
        { label: "Spotii ", value: "spotiipay" },
        { label: "COD ", value: "cashondelivery" },
        { label: "Tap Knet", value: "tap_knet" },
        { label: "Payfort Omannet", value: "payfort_omannet" },
        { label: "Cashew", value: "cashew" },
      ],
    },
  ];

 

  const handleSubmit = (event) => {
    setexcelExportReport(true);
    event.preventDefault();
    event.stopPropagation();
    setisTypeError(selectedPayment === null ? true : false)
    
   

    if(selectedPayment !== null)

    {
    
      fetchExportRefundDetail({
        params: {
          date_from: startDate,
         date_to: endDate === undefined || endDate === ""? startDate : endDate,
          payment_method:selectedPayment

        },
        dispatch,
        
      });
    
      // setisExportEnable(true);
     }
  }
  const { exportRefundDetail = [], isExportLoading } = useSelector(
    (state) => state.refund
  );
  useEffect(() => {
    setexcelExportReport(false);
    if (exportRefundDetail?.code === 100) {
      toast.success(exportRefundDetail?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
    } else {
      if (exportRefundDetail?.message) {
        toast.error(exportRefundDetail?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [exportRefundDetail]);

const handleSelectPayment = (selectedGroup) => {
   
    setSelectedPayment(selectedGroup?.value );
   
  };
  return (
    <>
     
              <Row >
             
              <Form  onSubmit={handleSubmit}>
                <Col xl={12}>
               
                <div className="DashbordFliter1 ">
                  <div className="InputBg" style={{width:"17%"}}>
                        <Select
                         
                          onChange={handleSelectPayment}
                          options={optionGroup}
                      
                          classNamePrefix="select2-selection"
                        />
       
        </div>
                   
        {isTypeError && 
                      <span style={{ position: "absolute", top: 40, left: 10, color:"#f00" }}>Please Select Export Type.</span>
                    }
        <div className="InputBg">
                 
                      { isMobileView !== 0 ? (<><DateRangePicker
                        placement="auto"
                        placeholder="Select Date Range"
                        showOneCalendar
                        
                        // disabledDate={combine(allowedMaxDays(30), afterToday())}
                        onChange={(selectedDates, instance) => {
                          const dateStr = selectedDates?.[0];
                          const first_Date = new Date(dateStr)?.toString();
                          const firstD = moment(new Date(first_Date))?.format(
                            "YYYY/MM/DD"
                          );
                          const firstDateReplace = firstD?.replaceAll("/", "-");
                          const dateStr1 = selectedDates?.[1];
                          const second_Date = new Date(dateStr1)?.toString();
                          const secondD = moment(new Date(second_Date))?.format(
                            "YYYY/MM/DD"
                          );
                          const secondDateReplace = secondD?.replaceAll(
                            "/",
                            "-"
                          );
                          setStartDate(firstDateReplace);
                          setEndDate(secondDateReplace);
                        }}
                        />
                      </>):(<><DateRangePicker
                        placement="rightStart"
                        placeholder="Select Date Range"
                        onClean={()=>{
                          setStartDate(new moment(new Date()).format("YYYY-MM-DD"));
                          setEndDate(new moment(new Date()).format("YYYY-MM-DD"));
                        }}
                        // disabledDate={combine(allowedMaxDays(30), afterToday())}
                        onChange={(selectedDates, instance) => {
                          const dateStr = selectedDates?.[0];
                          const first_Date = new Date(dateStr)?.toString();
                          const firstD = moment(new Date(first_Date))?.format(
                            "YYYY/MM/DD"
                          );
                          const firstDateReplace = firstD?.replaceAll("/", "-");
                          const dateStr1 = selectedDates?.[1];
                          const second_Date = new Date(dateStr1)?.toString();
                          const secondD = moment(new Date(second_Date))?.format(
                            "YYYY/MM/DD"
                          );
                          const secondDateReplace = secondD?.replaceAll(
                            "/",
                            "-"
                          );
                          setStartDate(firstDateReplace);
                          setEndDate(secondDateReplace);
                        }}
                     
                      /> </>)}
                    {isDateError && 
                      <span style={{ position: "absolute", top: 40, left: 0, color:"#f00" }}>Please Select Export Date.</span>
                    }
                    </div>
                    
                    <button type="submit" className="btn btn-primary" style={{display:"flex"}}>  {excelExportReport === true && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}{" "} Export  <i className="bx bx-download" style={{padding:"3px"}}></i> </button> 
                    
        </div>
    
                </Col>
                </Form>
              </Row>
          
           {/* {isExportEnable?<ExportToExcel apiData={data} fileName={fileName}/>:null} */}

    </>
    );
}
export default  RefundExportReport;