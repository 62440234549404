import React, { useEffect, useState } from 'react';
import "../../Orders/components/OrderShipmentProgress.scss";

const StatusBarCustomer = ({ detail, orderValue }) => {
  let itemMode = 1;
  if (orderValue.reference_no && orderValue.reference_no.startsWith("BB-")) {
    itemMode = 2;
  }

  const refundStatuses = ["0",  "2","5", "6", "1"];
  const walletStatuses = ["0", "2", "9", "10", "1"];
  const cashStatuses = ["0",  "21", "1"];

  const getAllowedStatuses = (resolutionType) => {
    let statuses = [];
    switch (resolutionType) {
      case 'refund':
        statuses = [...refundStatuses];
        break;
      case 'wallet':
        statuses = [...walletStatuses];
        break;
      case 'cashondelivery':
        statuses = [...cashStatuses];
        break;
      default:
        statuses = [];
    }

    if (itemMode === 2 && (resolutionType === 'refund' || resolutionType === 'wallet')) {
      const index = statuses.indexOf("0");
      if (index !== -1) {
        statuses.splice(index + 1, 0); // Insert "7", "8", and "17" right after "0"
      }
    } 
    return statuses;
  };

  const allowedStatus = getAllowedStatuses(orderValue.resolution_type);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    const statusList = detail.map((dt) => dt.status);
    setStatuses(statusList);
  }, [detail]);

  const pendingStatuses =  ["2","21"];
  let seenPending = false;

  return (
    <div className="trackStatus">
      {allowedStatus.map((status, index) => {
        

        const isActive = statuses.includes(String(status));
        const createdDate = detail.find(dt => dt.status === status)?.created_at;

        if (pendingStatuses.includes(status) && seenPending) {
          return null;
        }

        if (pendingStatuses.includes(status)) {
          seenPending = true;
        }

        return (
          <div className="trackView" key={status}>
            <div className="trackingIcon">
              <span className={isActive ? "Active" : ""}>
                {isActive ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.004" height="12.549" viewBox="0 0 15.004 12.549">
                    <path
                      id="Path_663"
                      data-name="Path 663"
                      d="M13.639.75,4.433,11.491.75,7.195"
                      transform="translate(0.308 0.308)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.004" height="12.549" viewBox="0 0 15.004 12.549">
                    <rect width="15.004" height="12.549" fill="none" />
                  </svg>
                )}
              </span>
            </div>
            <div className="trackingStatus">
              <p>
                <strong>
                  {status === "0" && 'Received Cancel Request'}
                  {pendingStatuses.includes(status) && 'Cancel Approved'}
                  {status === "1" && 'Cancellation Completed'}
                  {status === "5" && orderValue.resolution_type === 'refund' && 'Refund Initiated'}
                  {status === "6" && orderValue.resolution_type === 'refund' && 'Refund Completed'}
                  {status === "9" && orderValue.resolution_type === 'wallet' && 'Wallet Initiated'}
                  {status === "10" && orderValue.resolution_type === 'wallet' && 'Wallet Completed'}
                </strong>
              </p>
              {isActive && (
                <span>
                  <em>Created on: {createdDate}</em>
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StatusBarCustomer;
