import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactDrawer from 'react-drawer';

import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Spinner,
} from 'reactstrap';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import {
  getAvilableDaysDetail,
  getAvilableTimeDetail,
  getAvilableTimeSchedule,
} from '../actions/orderActions';

function Reschedule({ orderData }) {
  const dispatch = useDispatch();
  const shipmentRef = orderData?.ShipmentDetails?.[0]?.ShipmentReference;
  let startDate = new Date(`${orderData?.DeliveryDate}`);

  startDate?.setDate(startDate?.getDate() + 1);
  startDate = moment(new Date(startDate)).format('YYYY-MM-DD');
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(null);
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const [position, setPosition] = useState();
  const [selectedSlot, setselectedSlot] = useState(null);
  const [open, setOpen] = useState(false);
  const tConvert = (time) => {
    time = time
      ?.toString()
      ?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time?.length > 1) {
      time = time?.slice(1);
      time[5] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time?.join('');
  };
  const toggleRightDrawer = () => {
    setPosition('right');
    setOpen(!open);
  };
  const onDrawerClose = () => {
    setOpen(false);
  };
  const getAvailableDay = () => {
    getAvilableDaysDetail({
      params: {
        reference_no: shipmentRef,
        base_date: startDate,
      },
      dispatch,
    });
  };
  const { avilableDays = [], isavilableDaysLoading } = useSelector(
    (state) => state.order
  );

  const getAvailableSlot = (e, v) => {
    getAvilableTimeDetail({
      params: {
        reference_no: shipmentRef,
        base_date: startDate,
        selected_date: e,
      },
      dispatch,
    });
  };
  const { avilableTime = [], isavilableTimeLoading } = useSelector(
    (state) => state.order
  );

  const AvilableTime = avilableTime?.data?.availableSlots;

  const getAvailableSchedule = () => {
    setUpdateDetailsLoad(true);
    getAvilableTimeSchedule({
      params: {
        reference_no: shipmentRef,
        selected_date: selectedDeliveryDate,
        selected_slot: {
          start: selectedSlot?.start,
          end: selectedSlot?.end,
        },
      },
      dispatch,
    });
  };
  const { avilableSchedule = [], isavilableScheduleLoading } = useSelector(
    (state) => state.order
  );
  useEffect(() => {
    if (avilableSchedule?.code === 100) {
      setUpdateDetailsLoad(false);
      onDrawerClose();
      toast.success(avilableSchedule?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (avilableSchedule?.message) {
        setUpdateDetailsLoad(false);
        toast.error(avilableSchedule?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [avilableSchedule]);
  return (
    <>
      {(orderData?.LsStatus.toLowerCase() === 'rescheduled' ||
        orderData?.LsStatus.toLowerCase() === 'delivery_scheduled') &&
      orderData?.DeliverBy.toLowerCase() === 'own fleet' ? (
        <>
          <button
            className="btnTracking mb-2"
            onClick={() => {
              toggleRightDrawer();
              getAvailableDay();
            }}
          >
            Reschedule{' '}
          </button>
        </>
      ) : (
        <></>
      )}
      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <div className="scrollShipNav">
          <Card>
            <CardHeader>
              <Row>
                <Col className="textAlignCenter">
                  <CardTitle>Reschedule Your Delivery</CardTitle>
                </Col>
                <Col className="textAlignRight">
                  {' '}
                  <button
                    type="button"
                    className="btn-close "
                    onClick={() => {
                      onDrawerClose();
                    }}
                    aria-label="Close"
                  ></button>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              <Row className="deliverySchedule">
                <label>
                  Shipment Reference Number: <b>#{shipmentRef}</b>
                </label>
                <label>
                  Expected Delivery Date: <b>{orderData?.DeliveryDate} </b>
                </label>
              </Row>
              <Row>
                <Col className="scheduleImage">
                  {orderData?.ShipmentDetails?.[0]?.ShipmentItems?.map(
                    (item, itemInx) => {
                      return (
                        <>
                          {itemInx <= 1 ? (
                            <div key={item?.id}>
                              <img
                                alt=""
                                width="100"
                                height="100"
                                className="img-thumbnail mt-3"
                                src={item?.Image}
                              />
                            </div>
                          ) : (
                            <>
                              <span>{itemInx === 2 ? '2+More' : ''}</span>
                            </>
                          )}
                        </>
                      );
                    }
                  )}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h4>Choose a Delivery Date</h4>
                  <span>please select a convenient delivery day</span>
                </Col>
              </Row>
              <Row>
                <div className="checkoutSwiper">
                  <ul className="ChooseDeliveryDateSlot mt-3">
                    <li>
                      <span>
                        {moment(orderData?.DeliveryDate).format('ddd')}
                      </span>
                      <span>
                        {moment(orderData?.DeliveryDate).format('MMM')}{' '}
                        {moment(orderData?.DeliveryDate).format('D')}
                      </span>
                    </li>
                    {avilableDays?.data?.availableDates?.map((item, index) => {
                      return (
                        <li
                          className={`${
                            item === selectedDeliveryDate
                              ? 'Active'
                              : 'inActive'
                          }`}
                          key={item}
                          onClick={() => {
                            setSelectedDeliveryDate(item);
                            getAvailableSlot(item);
                            setselectedSlot(null);
                          }}
                        >
                          <span>{moment(item).format('ddd')}</span>
                          <span>
                            {moment(item).format('MMM')}{' '}
                            {moment(item).format('D')}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Row>
              <Row>
                {AvilableTime?.length > 0 ? (
                  <Col>
                    <h4>Pick a convenient time</h4>
                    <span>please select a convenient delivery time</span>
                    <div className="checkoutSwiper">
                      <ul className="PickConvenientTimeSlot mt-3">
                        {AvilableTime?.map((slot, slotInx) => {
                          return (
                            <li
                              className={` ${
                                slot?.start === selectedSlot?.start &&
                                slot?.end === selectedSlot?.end
                                  ? 'Active'
                                  : 'inActive'
                              } `}
                              key={slot?.start}
                              onClick={() => {
                                setselectedSlot(slot);
                              }}
                            >{`${tConvert(slot?.start)} - ${tConvert(
                              slot?.end
                            )}`}</li>
                          );
                        })}
                      </ul>
                    </div>
                  </Col>
                ) : (
                  <>
                    {selectedDeliveryDate !== null ? (
                      <h5>
                        Slots not available, please choose another date or
                        contact customer care.
                      </h5>
                    ) : (
                      <></>
                    )}
                  </>
                )}{' '}
              </Row>
              <Row>
                <Col>
                  <button
                    type="button"
                    className="btn btn-light cancelBtn"
                    onClick={onDrawerClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-toggle="modal"
                    onClick={() => {
                      getAvailableSchedule();
                    }}
                  >
                    {updateStsLoad === true && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{' '}
                    Reschedule
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </ReactDrawer>
    </>
  );
}

export default Reschedule;
