import React from "react";

import { MetaTags } from "react-meta-tags";
import {
  DashBreadcrumb,
} from "../../../components/Common/Breadcrumb";
//../../../components/Common/Breadcrumb
import {
    Col,
    Container,
    Row,
  } from "reactstrap";

import Listing from "./components/Listing/Listing.component"
const QaOrders = () => {
  const title = "QA Orders";
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>QA Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="QA Orders" breadcrumbItem="Manage QA Orders" />
            </Col>
          </Row>
        </Container><Listing></Listing>
      </div>
    </React.Fragment>
  );
};
export default QaOrders;
