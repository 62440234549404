import React, { useEffect, useState } from 'react';
import { Table, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Iframe from 'react-iframe';
function OrderInvoice({ orderData }) {
  const [gitMaskValue, setGiftMaskValue] = useState();
  function maskNumber(numberString) {
    const maskedLength = numberString?.length - 6;
    const maskedPart = 'x'?.repeat(maskedLength);
    const lastSixDigits = numberString?.slice(-6);
    return maskedPart + lastSixDigits;
  }
  const giftCardMask = orderData?.PaymentTenderDetails?.[0]?.GiftCardNumber;
  const creditNoteData = orderData?.creditNoteInfo;
  useEffect(() => {
    if (giftCardMask !== '' && giftCardMask !== '0') {
      const maskeddNumber = maskNumber(giftCardMask);
      setGiftMaskValue(maskeddNumber);
    }
  }, [giftCardMask]);

  return (
    <>
      <Card className="InvoiceBreakUp">
        <CardHeader>
          <CardTitle>INVOICE BREAK UP</CardTitle>
        </CardHeader>
        {orderData !== undefined ? (
          <>
            <CardBody>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <tbody>
                    <tr>
                      <td>Sub Total :</td>
                      <td>
                        {orderData?.Currency} {orderData?.SubTotal}
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping Fee :</td>
                      <td>
                        {orderData?.Currency} {orderData?.ShippingCharge}
                      </td>
                    </tr>
                    {orderData?.ExpressShippingCharge > '0.00' ? (
                      <>
                        <tr>
                          <td>Express Shipping Fee :</td>
                          <td>
                            {orderData?.Currency}{' '}
                            {orderData?.ExpressShippingCharge}
                          </td>
                        </tr>
                      </>
                    ) : null}

                    <tr>
                      <td>COD Fee :</td>
                      <td>
                        {orderData?.Currency} {orderData?.COD_charges}
                      </td>
                    </tr>
                    {Math.abs(orderData?.DiscountTotal) > 0 ? (
                      <>
                        <tr>
                          <td>Discount :</td>
                          <td>
                            -({orderData?.Currency} {orderData?.DiscountTotal})
                          </td>
                        </tr>
                      </>
                    ) : null}
                    {orderData?.CouponCode !== '' ? (
                      <>
                        <tr>
                          <td>Discount Code :</td>
                          <td> {orderData?.CouponCode}</td>
                        </tr>
                      </>
                    ) : null}
                    {orderData?.CouponRuleDescription !== null ? (
                      <>
                        <tr>
                          <td>Discount Desc :</td>
                          <td>{orderData?.CouponRuleDescription}</td>
                        </tr>
                      </>
                    ) : null}
                    {orderData?.assemblyAmount > 0 ? (
                      <>
                        <tr>
                          <td>Assembly Fee :</td>
                          <td>
                            {orderData?.Currency} {orderData?.assemblyAmount}
                          </td>
                        </tr>
                      </>
                    ) : null}
                    {orderData?.creditNoteRedeemInfo &&
                    orderData?.creditNoteRedeemInfo?.redeemedAmount > 0 ? (
                      <>
                        <tr>
                          <td>Credit Note :</td>
                          <td>
                            -({orderData?.Currency}
                            {Math.round(
                              orderData?.creditNoteRedeemInfo?.redeemedAmount *
                                100
                            ) / 100}
                            )
                            <span className="highlightInfo1">
                              {maskNumber(
                                orderData?.creditNoteRedeemInfo
                                  ?.creditNoteNumber
                              )}
                            </span>
                          </td>
                        </tr>
                      </>
                    ) : null}

                    {orderData?.walletRedeemInfo &&
                    orderData?.walletRedeemInfo?.redeemedAmount > 0 ? (
                      <>
                        <tr>
                          <td>Wallet :</td>
                          <td>
                            -({orderData?.Currency}
                            {Math.round(
                              orderData?.walletRedeemInfo?.redeemedAmount * 100
                            ) / 100}
                            )
                            <span className="highlightInfo1">
                              {maskNumber(
                                orderData?.walletRedeemInfo?.walletNumber
                              )}
                            </span>
                          </td>
                        </tr>
                      </>
                    ) : null}

                    {orderData?.ahlanRedeemInfo &&
                    orderData?.ahlanRedeemInfo?.redeemedAmount > 0 ? (
                      <>
                        <tr>
                          <td>Ahlan Reward :</td>
                          <td>
                            -({orderData?.Currency}
                            {Math.round(
                              orderData?.ahlanRedeemInfo?.redeemedAmount * 100
                            ) / 100}
                            )
                            <span className="highlightInfo1">
                              {maskNumber(
                                orderData?.ahlanRedeemInfo?.ahlanNumber
                              )}
                            </span>
                          </td>
                        </tr>
                      </>
                    ) : null}
                    {orderData?.giftCardRedeemInfo &&
                    orderData?.giftCardRedeemInfo?.redeemedAmount > 0 ? (
                      <>
                        <tr>
                          <td>G.V Discount :</td>
                          <td>
                            -({orderData?.Currency}
                            {Math.round(
                              orderData?.giftCardRedeemInfo?.redeemedAmount *
                                100
                            ) / 100}
                            )
                            <span className="highlightInfo1">
                              {maskNumber(
                                orderData?.giftCardRedeemInfo?.giftCardNumber
                              )}
                            </span>
                          </td>
                        </tr>
                      </>
                    ) : null}
                    <tr>
                      <th className="Border">Grand Total :</th>
                      <th className="Border">
                        {orderData?.Currency} {orderData?.OrderTotal}
                      </th>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </>
        ) : (
          <>
            <CardBody>
              <Skeleton count={7} />
            </CardBody>
          </>
        )}
      </Card>
    </>
  );
}
export default OrderInvoice;
