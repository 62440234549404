import axios from "axios";
import { setProductsFeedBackList, setProductsFeedBackDetails,setProductsFeedBackUpdate,setProductsFeedBackExport } from "../../../Redux/products/productsSlice";
import UserAuthKey from "../../../components/Common/UserAuthKey";
import { setUserData } from "../../../Redux/user/userSlice";


const apiUrl = process.env.REACT_APP_OMS_API;

export const fetchProductsFeedBackList =async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/products/product-feedback-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setProductsFeedBackList(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
 
};
export const ProductsFeedBackDetails = async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/products/product-feedback-details`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setProductsFeedBackDetails(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
  
};

export const getFeedbackDetailsByID = ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();

  return axios({
    method: 'post',
    url: `${apiUrl}v1/products/product-feedback-details`,
    data: {
      ...params,
    },
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (response) => {
      if (response?.data?.code === 100) {
        return response?.data?.data;
      } else {
      }
    })
    .catch((error) => {

    });
};

export const ProductsFeedBackUpdate = async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/products/update-product-feedback`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setProductsFeedBackUpdate(response?.data))
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
 
};

export const fetchProductsFeedBackExport = async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/products/export-product-feedback`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setProductsFeedBackExport(response?.data));
    window.location.href = response?.data?.data?.[0]?.report_link
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
 
};