import "./CustomerOrderingMode.scss";
import React, { useState } from "react";

function CustomerOrderingModeComponent(props) {
  const {showOrderingMode,changeOrderType } = props || {};
 
  return showOrderingMode ? (
    <>
      <div className="customerOrderingMode">
        <div className="contain p-4">
          <h4 className="font-size-16">Customer Ordering Mode*</h4>
          <select className="form-select" onChange={e=>changeOrderType(e.target.value)}>
            <option>Select Ordering Mode</option>
            <option defaultValue="whatsapp">Whatsapp</option>
            <option value="facebook">Facebook</option>
            <option value="email">Email</option>
            <option value="phone">Phone</option>           
          </select>
        </div>
      </div>
    </>
  ):('');
}

export default CustomerOrderingModeComponent;
