import React, { useEffect, useState } from 'react';

import { MetaTags } from 'react-meta-tags';
//import Breadcrumbs
import { DashBreadcrumb } from '../../components/Common/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
//import OrderList from './components/orderList';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import { useHistory } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import RefundOrderList from './components/ManageRefundList';
import { getRefundDetail, updateRefund } from '../../Redux/refund/refundSlice';
import {
  fetchRefundDetail,
  checkPasswordDetail,
  fetchUpdateRefundDetail,
} from './action/action';
import toast, { Toaster } from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {
  Col,
  Container,
  Row,
  FormGroup,
  CardBody,
  CardHeader,
  Modal,
  CardTitle,
  Spinner,
} from 'reactstrap';
const ManageRefunds = () => {
  const [position, setPosition] = useState();
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [isPartialRefund, setPartialRefund] = useState(true);
  const [isFullRefund, setFullRefund] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRefundError, setisRefundError] = useState(0);
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [modal_password, setmodal_password] = useState(false);
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const [isUpdateRefund, setUpdateRefund] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [refNo, setRefNumber] = useState('');
  const [refReason, setRefReason] = useState('');
  const [refAmount, setRefAmount] = useState('');
  const [refRemarks, setRefRemarks] = useState('');
  const [apiPassword, setApipassword] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const history = useHistory();
  const [refundType, setRefundType] = useState('partially_refunded');
  let userID = '';
  let UserRoleID = '';
  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      userID = userObj?.user_id;
      UserRoleID = userObj?.role_id;
    }
  }
  const handleChangeRefund = (e, types) => {
    if (types === 'full' && e.target.value === 'fully_refunded') {
      setFullRefund(true);
      setPartialRefund(false);
      setIsDisabled(true);
      setRefundType(e.target.value);
    } else {
      setPartialRefund(true);
      setFullRefund(false);
      setIsDisabled(false);
      setRefundType(e.target.value);
    }
  };
  const dispatch = useDispatch();
  const toggleRightDrawer = () => {
    setPosition('right');
    setOpen(!open);
  };
  const onDrawerClose = () => {
    setInputValue('');
    setOpen(false);
  };
  const resetInputField = () => {
    dispatch(getRefundDetail(null));
    setInputValue('');
  };

  const handleUserInput = (e) => {
    dispatch(getRefundDetail(null));
    setInputValue(e.target.value);
  };

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  const { refundDetail = [], isRefundLoading } = useSelector(
    (state) => state.refund
  );

  const handleRefundSubmit = (events, values) => {
    const reference_no = values?.reference_no ? values?.reference_no : '';

    setmodal_backdrop(false);
    fetchRefundDetail({
      params: {
        reference_no: reference_no,
      },
      dispatch,
    });
  };

  const { updateRefundDetail = [], isupdaterfeundLoading } = useSelector(
    (state) => state.refund
  );
  const handleRefundUpdate = (events, values) => {
    const referenceno = values?.reference_no ? values?.reference_no : '';
    const date = values?.date ? values?.date : '';
    const paidamount = values?.paidamount ? values?.paidamount : '';
    const refundamount = values?.refundamount ? values?.refundamount : '';
    const balanceamount = values?.balanceamount ? values?.balanceamount : '';
    const paymentMode = values?.payment_mode ? values?.payment_mode : '';
    const refundreason = values?.rc ? values?.rc : '';
    const remarks = values?.Remarks ? values?.Remarks : '';
    const newrefundamount = values?.newrefundamount
      ? values?.newrefundamount
      : '';
    const password = values?.password ? values?.password : '';
    const refund_type = values?.refund_type ? values?.refund_type : '';

    // if (!!(newrefundamount) && !isNaN()) {

    if (newrefundamount > balanceamount) {
      setisRefundError(paidamount);
    } else {
      setisRefundError(0);
      if (isUpdateRefund) {
        tog_backdrop();
      }
      setRefNumber(referenceno);
      setRefReason(refundreason);
      setRefAmount(newrefundamount);
      setRefRemarks(remarks);
      setPaymentMode(paymentMode);
      setApipassword(password);
      setRefundType(refundType);
    }
  };

  function tog_backdrop() {
    onDrawerClose();
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }
  function tog_password() {
    setmodal_backdrop(false);
    onDrawerClose();
    setmodal_password(!modal_password);
    removeBodyCss();
  }
  const RefundData = refundDetail?.OrderDetails?.[0];
  let BalanceAmount = RefundData?.Amount - RefundData?.RefundAmount;

  const handleRefundUpdateSubmit = () => {
    setLoading(true);
    fetchUpdateRefundDetail({
      params: {
        reference_no: refNo,
        refund_remark: refRemarks,
        payment_mode: paymentMode,
        refund_reason: refReason,
        amount: refAmount,
        loginuser: userID,
        password: apiPassword,
        refund_type: refundType,
      },
      dispatch,
    });
  };

  useEffect(() => {
    if (updateRefundDetail?.code === 100) {
      setLoading(false);
      setUpdateDetailsLoad(false);
      setCount(0);

      toast.success(updateRefundDetail?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      dispatch(updateRefund(null));
      setTimeout(() => {
        history.push('/ManageRefund');
      }, 10);
    } else {
      if (updateRefundDetail?.message) {
        if (updateRefundDetail?.message === 'Invalid password') {
          toggleRightDrawer();
        }
        setmodal_backdrop(false);
        setCount(0);

        setLoading(false);

        toast.error(updateRefundDetail?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
        dispatch(updateRefund(null));
      } else {
      }
    }
  }, [updateRefundDetail]);

  let refundValue = '';
  if (RefundData?.RefundAmount === 0) {
    refundValue = RefundData?.Amount;
  } else {
    refundValue = RefundData?.Amount - RefundData?.RefundAmount;
  }

  return (
    <React.Fragment>
      {loading ? (
        <div className="loader-container">
          <Spinner type="grow" className="ms-2" color="dark" />
        </div>
      ) : (
        <></>
      )}
      <div className="page-content refundOrder">
        <MetaTags>
          <title>Refund Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col>
              <DashBreadcrumb title="Orders" breadcrumbItem="Refund Orders" />
            </Col>
            {UserRoleID === '1' || UserRoleID === '28' || UserRoleID === '6' ? (
              <>
                <Col
                  className="textAlignRight mb-4"
                  style={{ marginTop: '20px' }}
                >
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={toggleRightDrawer}
                  >
                    Add Refund
                  </button>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>
          <ReactDrawer
            open={open}
            position={position}
            onClose={onDrawerClose}
            className="sideNavWidth"
          >
            <Card
              className="card-h-100 OrderDetailsBox"
              style={{ overflowY: 'scroll' }}
            >
              <CardHeader>
                <Row>
                  <Col>
                    <CardTitle>Refund Form </CardTitle>
                  </Col>
                  <Col className="textAlignRight">
                    {' '}
                    <button
                      type="button"
                      className="btn-close "
                      onClick={() => {
                        tog_backdrop();
                        resetInputField();
                      }}
                      aria-label="Close"
                    ></button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="searchBox">
                  <AvForm
                    id="refundForm"
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleRefundSubmit(e, v);
                    }}
                  >
                    <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 ">
                      <AvField
                        name="reference_no"
                        label="Reference No"
                        value={inputValue}
                        onChange={(e) => handleUserInput(e)}
                        className="form-control"
                        placeholder=""
                        type="text"
                      />
                      <button
                        className="btn btn-primary "
                        type="submit"
                        style={{ position: 'absolute', top: 24, right: 0 }}
                      >
                        <i className="bx bx-search-alt align-middle "></i>
                      </button>
                    </div>
                  </AvForm>
                </div>
                {RefundData !== undefined ? (
                  <>
                    {RefundData?.PaymentMode !== 'cashondelivery' ? (
                      <>
                        {' '}
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={(e, v) => {
                            // handlePasswordSubmit(e, v);
                            handleRefundUpdate(e, v);
                          }}
                        >
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <AvField
                                  name="full_name"
                                  label="Customer Name"
                                  value={RefundData?.Fullname}
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  required
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <AvField
                                  name="reference_no"
                                  label="Reference No"
                                  value={RefundData?.OrderNo}
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  required
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <AvField
                                  name="date"
                                  label="Order Created date"
                                  type="text"
                                  value={RefundData?.CreatedDate}
                                  required
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <AvField
                                  name="paidamount"
                                  label="Paid Amount
"
                                  value={RefundData?.Amount}
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  disabled
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup className="mb-3">
                                <AvField
                                  name="refundamount"
                                  label="Refunded Amount
"
                                  value={RefundData?.RefundAmount}
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <AvField
                                  name="balanceamount"
                                  label="Balance Amount
"
                                  value={BalanceAmount}
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  disabled
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup className="mb-3">
                                <AvField
                                  name="payment_mode"
                                  label="paymentMode"
                                  value={RefundData?.PaymentMode}
                                  className="form-control"
                                  placeholder=""
                                  type="text"
                                  required
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {RefundData?.RefundAmount !== RefundData?.Amount ? (
                            <>
                              <Row>
                                <Col md="6">
                                  <FormGroup className="mb-3">
                                    <Row>
                                      <Col md="6">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            value="partially_refunded"
                                            name="refund_type"
                                            onChange={(e) =>
                                              handleChangeRefund(e, 'partial')
                                            }
                                            defaultChecked
                                            id="refundRadios2"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="refundRadios2"
                                          >
                                            Partial Refund
                                          </label>
                                        </div>
                                      </Col>
                                      <Col md="6">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            value="fully_refunded"
                                            name="refund_type"
                                            id="refundRadios1"
                                            onChange={
                                              (e) =>
                                                handleChangeRefund(e, 'full')
                                              //setFullRefund(e.target.value)
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="refundRadios1"
                                          >
                                            Full Refund
                                          </label>
                                        </div>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                </Col>
                                {/* {isPartialRefund && isFullRefund === false ? ( */}

                                <>
                                  <Col xl={12} md={12}>
                                    <AvField
                                      name="newrefundamount"
                                      label="Refund Amount"
                                      value={
                                        isPartialRefund &&
                                        isFullRefund === false
                                          ? ''
                                          : refundValue
                                      }
                                      className="form-control"
                                      placeholder=""
                                      type="number"
                                      disabled={isDisabled}
                                    />
                                  </Col>
                                  {isRefundError !== 0 ? (
                                    <>
                                      <span style={{ color: 'red' }}>
                                        Value not be greater then the balance
                                        amount.
                                      </span>{' '}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                                {/* ) : ( */}

                                {/* )} */}
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <FormGroup className="mb-3">
                                    <AvField
                                      type="select"
                                      name="rc"
                                      label="Refund Reason"
                                      className="form-select"
                                      required
                                    >
                                      <option value="">Select Option</option>
                                      <option value=" Order Canceled">
                                        Order Canceled
                                      </option>
                                      <option value="Order Partially Canceled">
                                        Order Partially Canceled
                                      </option>
                                      <option value="Multiple Payment Received">
                                        Multiple Payment Received
                                      </option>
                                      <option value="Order Excess Amount">
                                        Order Excess Amount
                                      </option>
                                      <option value="Order Item Quantity Reduced">
                                        Order Item Quantity Reduced
                                      </option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <FormGroup className="mb-3">
                                    <AvField
                                      name="Remarks"
                                      label="Remarks"
                                      value=""
                                      className="form-control"
                                      cols="60"
                                      rows="5"
                                      type="textarea"
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col>
                                  <AvField
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    label="Password Authentication"
                                    value=""
                                    required
                                  />
                                </Col>
                              </Row>

                              <button
                                type="submit"
                                className="btn btn-success"
                                data-toggle="modal"
                              >
                                Submit
                              </button>

                              <button
                                type="button"
                                className=" btn btn-danger"
                                data-toggle="modal"
                                style={{ marginLeft: '10px' }}
                                onClick={() => {
                                  resetInputField();
                                }}
                              >
                                Cancel
                              </button>
                            </>
                          ) : (
                            <></>
                          )}

                          <Modal
                            isOpen={modal_backdrop}
                            toggle={() => {
                              tog_backdrop();
                            }}
                            backdrop={'static'}
                            id="staticBackdrop"
                          >
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="staticBackdropLabel"
                              >
                                Refund Amount
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={() => {
                                  setmodal_backdrop(false);
                                }}
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <p>Do you want to refund the Amount?</p>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => {
                                  setmodal_backdrop(false);
                                  dispatch(getRefundDetail(null));
                                }}
                              >
                                Cancel
                              </button>
                              {count < 1 ? (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      handleRefundUpdateSubmit();
                                      setCount(count + 1);
                                      setmodal_backdrop(false);
                                      dispatch(getRefundDetail(null));
                                    }}
                                  >
                                    {updateStsLoad === true && (
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    )}{' '}
                                    Submit
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </Modal>
                        </AvForm>
                      </>
                    ) : (
                      <>
                        <div
                          className=""
                          style={{
                            margin: '85px 0px',
                            padding: '11px',
                            width: '100%',
                            color: '#000',
                            backgroundColor: '#f3d8d8',
                            textAlign: 'center',
                          }}
                        >
                          Refund Payment Just Allowed for Credit Card Only.
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {refundDetail?.code === 101 ? (
                      <>
                        <div
                          className=""
                          style={{
                            margin: '85px 0px',
                            padding: '11px',
                            width: '100%',
                            color: '#000',
                            backgroundColor: '#f3d8d8',
                            textAlign: 'center',
                          }}
                        >
                          {refundDetail?.message}
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </CardBody>
            </Card>
          </ReactDrawer>

          <RefundOrderList />
        </Container>
      </div>

      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default ManageRefunds;
