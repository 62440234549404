import { GUEST_USER_CART_TOKEN_KEY } from '../../../src/constants';
//import Cookies from 'utils/BrowserDatabase/Cookies';

/**
 *
 * @returns
 */
export const getGuestCartToken = () => {
  return localStorage.getItem(GUEST_USER_CART_TOKEN_KEY);
};

/**
 *
 * @param {*} token
 */
export const setGuestCartToken = (token) => {
  localStorage.setItem(GUEST_USER_CART_TOKEN_KEY, token);
};

/**
 *
 * @param {*} token
 */
export const deleteGuestCartToken = (token) => {
  localStorage.removeItem(GUEST_USER_CART_TOKEN_KEY);
};

const guestUserManagement = { setGuestCartToken, getGuestCartToken, deleteGuestCartToken }

export default guestUserManagement;
