import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../components/Common/Breadcrumb";
import { fetchInstalCheckData, installationImportSummary } from "./action/action";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { CardHeader } from "semantic-ui-react";

const ImportInstallationOrders = () => {
  const dispatch = useDispatch();
  const title = "Orders";
  const description = "Marketplace Orders";
  // const countryAll = "All";
  const [selectedFile, setSelectedFile] = useState();

  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [items, setItems] = useState([]);

  let UserID = "";
  if (localStorage.getItem("authUser")) {
    const userObj = JSON.parse(localStorage.getItem("authUser"));
    if (userObj?.role_id !== undefined) {
      UserID = userObj?.user_id;
    }
  }
  const handleValidSubmit = (events, values) => {

    const form = events.currentTarget;
    const formData = new FormData();
    if(selectedFile === undefined){
      setErrorMsg("Please select the file")
    }
    else{
    setUpdateDetailsLoad(true);
    formData.append("installation_file", selectedFile);

    fetchInstalCheckData({
      formData,
      dispatch,
    });
  }
  };
  const { marketPlaceCheckdata = [], isCkeckdataLoading } = useSelector(
    (state) => state.marketPlace
  );


  useEffect(() => {
    if (marketPlaceCheckdata?.code === 100) {
      setUpdateDetailsLoad(false);

      installationImportSummary({
        params: {
          file_name: marketPlaceCheckdata?.file_name,
          user_id: UserID,
        },
        dispatch,
      });
    } else {
      if (marketPlaceCheckdata?.message) {
        setUpdateDetailsLoad(false);
        toast.error(marketPlaceCheckdata?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [marketPlaceCheckdata]);
  const { importSummary = [], isImportSummaryLoading } = useSelector(
    (state) => state.marketPlace
  );
  useEffect(() => {
    if (importSummary?.code === 100) {
      setUpdateDetailsLoad(false);
      toast.success(importSummary?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
    } else {
      if (importSummary?.message) {
        setUpdateDetailsLoad(false);

        toast.error(importSummary?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [importSummary]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Import Installation Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Orders" breadcrumbItem="Import Installation Orders" />
            </Col>
          </Row>
          <Row className="rowCenter">
            <Col lg={5}>
              <Card>
                <CardHeader className="headPadding">
                  <CardTitle>Installation File</CardTitle>
                </CardHeader>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
               
                    }}
                  >
                    <Row>
                    <Col>
                      <input
                        type="file"
                        name="file"
                        required
                        onChange={(e) => {
                          setSelectedFile(e.target.files[0]);
                        }}
                      />
                 
                    {setSelectedFile && (
                      <div
                       className="importError"
                      >
                        {errorMsg}
                      </div>
                    )}
                       </Col>
                    <Col className="mb-3">
                      <button
                        className="btn btn-primary"
                       
                      >
                        {updateStsLoad === true && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}{" "}
                        Submit
                      </button>
                    </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default ImportInstallationOrders;
