import React, { useEffect,useState } from "react";
import './StoreSelection.scss' 
import { useDispatch, useSelector } from "react-redux"; 
import {DateRangePicker, DatePicker } from "rsuite";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";
import { useUrlSearchParams } from "use-url-search-params";
import { fetchDeliveryDataList,updateOrderStatus,excelGenerate } from "../../action/action";
import moment from "moment";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import { Form } from "react-bootstrap";

import {Card,
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import toast, { Toaster } from "react-hot-toast";

import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import DeliveryAlert from "../../../components/DeliveryAlert/DeliveryAlert.cpmponent";
import {  CSVExport  } from 'react-bootstrap-table2-toolkit';
const StoreSelectionComponent = () => {
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState();
  const [orderValue, setorderValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [dateVal, setDateVal] = useState("");
  const [isDateEmpty, setDateEmpty] = useState(false);
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const {  afterToday, combine,allowedMaxDays} =DateRangePicker;


  let isMobileView = 0;

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [excelExportReport, setexcelExportReport] =useState(false);
  const [isTypeError, setisTypeError] = useState(false);

  const predefinedBottomRanges = [
    {
      label: "yesterday",
      value: addDays(new Date(), -1),
    },
    {
      label: "today",
      value: new Date(),
    },
    {
      label: "Prev Day",
      closeOverlay: false,
      value: (date) => {
        return subDays(date, 1);
      },
    },
  ];
  
    useEffect(() => {
        fetchDeliveryDataList({
          params: {
            start: Number(paginationValue.page * limitValue.limit),
            limit: Number(limitValue.limit),
            search_qry: searchValue.search,
            country:'ae',
            date_from: startDate,
             date_to:
            endDate === undefined || endDate === "" ? startDate : endDate,
            
            orderBy: "id",
          },
          dispatch,
        })
        
      },[startDate,paginationValue.page,
        limitValue,loading]);
    const limitByValues = [5, 10, 20, 50];
    const { DeliveryReportList = [] } = useSelector((state) => state.deliveryReport);
    const { listOrders } = DeliveryReportList;
    const orderData = listOrders?.orderList;
    const [updateStsLoad, setUpdateDetailsLoad] = useState(false);

    console.log("DeliveryReportList",DeliveryReportList);
    let pageIndex = Number(listOrders?.start);
    const handlePagination = (e) => {
      setPaginationValue({ page: e.target.value });
    };
    const handleSearch = (e) => {
      setSearchValue({ search: e.target.value });
    };
    const handleLimit = (e) => {
      setLimitValue({ limit: Number(e) });
    };
    const [btnprimary1, setBtnprimary1] = useState(false);
    const toggleRightDrawer = (data) => {
      setPosition("right");
      setOpen(!open);
      setorderValue(data);
      setDateVal("");
      

    };
    const onDrawerClose = () => {
     setOpen(false);
    };
   
    function tog_backdrop() {
      onDrawerClose();
     
    }
    const { orderupdateDetails = []} = useSelector(
      (state) => state.deliveryReport
    );
    const { ExportCSVButton } = CSVExport;

    useEffect(() => {
      if (orderupdateDetails?.code === 100) {
        setUpdateDetailsLoad(false);
        setLoading(true);

        console.log("toster",orderupdateDetails?.message);
       
        toast.success(orderupdateDetails?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#4CAF50",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#4CAF50",
          },
        });
      } else {
        if (orderupdateDetails?.message) {
          setUpdateDetailsLoad(false);
          toast.error(orderupdateDetails?.message, {
            style: {
              border: "1px solid #fff",
              padding: "20px",
              color: "#ffffff",
              background: "#f44336",
            },
            iconTheme: {
              primary: "#FFFAEE",
              secondary: "#f44336",
            },
          });
        }
      }
      onDrawerClose();
    }, [orderupdateDetails]);
    const handleValidSubmit=(events, values, delveryDate)=>{
      setUpdateDetailsLoad(true);

    const so_number = values?.so_number ? values?.so_number : "";
    const reference_no = values?.reference_no ? values?.reference_no : "";

    if (dateVal !== "" && dateVal !== null && dateVal !== undefined) {
 
    } else {
      setUpdateDetailsLoad(false);
      setDateEmpty(true);
    }
    
    console.log("spiner",isDateEmpty);
      updateOrderStatus({
        params: {
          order_reference: reference_no,
          country: 'ae',
          status: 'Delivered',
          reason: '',
          remarks: '',
          time_slot: "",
          shipment_type: "",
          deliver_by: "",
          ls_order_reference: so_number,
          delivery_dt:delveryDate,
          order_mode: "All",
          user_id: '',
          type: '',
          date: '',
          items: '',
        },
        dispatch,
      });  

    };
    const handleSubmit = (event) => {
      setexcelExportReport(true);
      event.preventDefault();
      event.stopPropagation();
  
      const form = event.currentTarget;
     // const exportType = event.target.elements.export_type.value;
     
      //setisTypeError(exportType === '' ? true : false)
      
      const exportType=true;
  
      
        excelGenerate({
          params: {
            export_type:'delivery_scheduled_orders',
            date_from: startDate,
            date_to: endDate === undefined || endDate === ""? startDate : endDate,
            country:'ae',
            status:'1'
           
          },
          dispatch,
          
        });
      
        // setisExportEnable(true);
       
    }
    const { generateFile = [], generateFileLoading } = useSelector(
      (state) => state.order
    );
    useEffect(() => {
      setexcelExportReport(false);
      if (generateFile?.code === 100) {
        toast.success(generateFile?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#4CAF50",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#4CAF50",
          },
        });
      } else {
        if (generateFile?.message) {
          toast.error(generateFile?.message, {
            style: {
              border: "1px solid #fff",
              padding: "20px",
              color: "#ffffff",
              background: "#f44336",
            },
            iconTheme: {
              primary: "#FFFAEE",
              secondary: "#f44336",
            },
          });
        }
      }
    }, [generateFile]);
return (
    <div className="table-responsive dt-table">
      <Row><Col md={7}>
</Col>
   <Col md={5}><Form onSubmit={handleSubmit}>
  <Row>
    <Col md={10}>
    <div className="DashbordFliter">
        { isMobileView !== 0 ? (<><DateRangePicker
              placement="auto"
              placeholder="Select Date Range"
              onClean={()=>{
                setStartDate(new moment(new Date()).format("YYYY-MM-DD"));
                setEndDate(new moment(new Date()).format("YYYY-MM-DD"));
              }}
              showOneCalendar
            // disabledDate={combine(allowedMaxDays(30), afterToday())}
              onChange={(selectedDates, instance) => {
                const dateStr = selectedDates?.[0];
                const first_Date = new Date(dateStr)?.toString();
                const firstD = moment(new Date(first_Date))?.format(
                  "YYYY/MM/DD"
                );
                const firstDateReplace = firstD?.replaceAll("/", "-");
                const dateStr1 = selectedDates?.[1];
                const second_Date = new Date(dateStr1)?.toString();
                const secondD = moment(new Date(second_Date))?.format(
                  "YYYY/MM/DD"
                );
                const secondDateReplace = secondD?.replaceAll(
                  "/",
                  "-"
                );
                setStartDate(firstDateReplace);
                setEndDate(secondDateReplace);
              }}
              />
            </>):(<><DateRangePicker
              placement="leftStart"
              placeholder="Select Date Range"
              onClean={()=>{
                setStartDate(new moment(new Date()).format("YYYY-MM-DD"));
                setEndDate(new moment(new Date()).format("YYYY-MM-DD"));
              }}
            // disabledDate={combine(allowedMaxDays(30), afterToday())}
              onChange={(selectedDates, instance) => {
                const dateStr = selectedDates?.[0];
                const first_Date = new Date(dateStr)?.toString();
                const firstD = moment(new Date(first_Date))?.format(
                  "YYYY/MM/DD"
                );
                const firstDateReplace = firstD?.replaceAll("/", "-");
                const dateStr1 = selectedDates?.[1];
                const second_Date = new Date(dateStr1)?.toString();
                const secondD = moment(new Date(second_Date))?.format(
                  "YYYY/MM/DD"
                );
                const secondDateReplace = secondD?.replaceAll(
                  "/",
                  "-"
                );
                setStartDate(firstDateReplace);
                setEndDate(secondDateReplace);
              }}
           
            /> </>)}
         
        </div>
    </Col>
    <Col md={2}>
    <button type="submit" className="btn btn-primary" > 
        <i className="bx bx-download" style={{padding:"3px"}}></i> </button>
    </Col>
  </Row></Form>
    
  
                
                </Col> 
   </Row>
   <Row className=" dh-TabRow textAlignRight "  >
        
        <Col className="MobileView textAlignRight ">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
            <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
       </Col>
      </Row>

       <ReactDrawer
            open={open}
            position={position}
            onClose={onDrawerClose}
           
            className="sideNavWidth"
          >
            <Card
              className="card-h-100 OrderDetailsBox"
              style={{ overflowY: "scroll" }}
            >
              <CardHeader>
             
                <Row>
                  <Col>
                    <CardTitle>Add Delivery Date </CardTitle>
                  </Col>
                  <Col className="textAlignRight">
                    {" "}
                    <button
                      type="button"
                      className="btn-close "
                      onClick={() => {
                        tog_backdrop();
                       
                      }}
                      aria-label="Close"
                    ></button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
              <AvForm
            className="needs-validation row gx-3 gy-2 align-items-center"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v, dateVal);
            }}
          >
                <div className="searchBox">
                  <Row>
                  <Col xl={12} md={12} style={{paddingBottom: "10px"}}>
                  <AvField
                    name="reference_no"
                    label="Reference No"
                    value={orderValue?.reference_no}
                    className="form-control"
                    
                    type="text"
                    required
                  /></Col>  
                  </Row>
                  <Row>
                  <Col xl={12} md={12} style={{paddingBottom: "10px"}}>
                  <AvField
                    name="so_number"
                    label="SO Number"
                    value={orderValue?.ls_reference}
                    className="form-control"
                    placeholder="Please Enter So Number"
                    type="text"
                    required
                  />
                </Col>      
                            </Row>
                <div className="searchBox" style={{paddingBottom: "10px"}}>
                  <Row>
                  <Col xl={12} md={12}>
                  Delivery Date</Col>  
                  </Row>
                  <Row>
                  <Col xl={12} md={12}>
                  <DatePicker
                    ranges={predefinedBottomRanges}
                    placeholder="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    value={dateVal !== null && dateVal !== "" ? dateVal : null}
                    onChange={setDateVal}
                  />   {isDateEmpty && (
                    <div
                      style={{
                        color: "#f00",
                      }}
                    >
                      Please Select Date.
                    </div>
                  )}
                  </Col></Row></div>
                <Col xl={12} md={12}>
              <button type="submit" className="btn btn-primary">
                {updateStsLoad === true && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}{" "}
                Submit
              </button>
            </Col>
                 </div>
                 </AvForm>
              </CardBody>
            </Card>
          </ReactDrawer>
   
    {orderData?.length > 0 ? (
      <>
        <Table className="OrderListTable table mb-0">
          <thead>
            <tr>
              <th>SI.No</th>
              <th nowrap="nowrap">Reference NO</th>
              <th>LS Order Ref</th>
              <th>Delivery Scheduled date</th>
              <th>Status</th>
              <th>Action </th>
            </tr>
          </thead>
          {(orderData || []).map((order, index) => {
            pageIndex++;
            
          return (
              <tbody key={order.order_id}>
                <tr className={order.date_diff>0 ? 'my-class' : ''}>
                  <th scope="row">{pageIndex}</th>
                  
                  <td nowrap="nowrap">{order.reference_no}
                  <span className="highlightInfo2 ">
                  {order.created_at}
                            </span></td>
                  <td  nowrap="nowrap">{order.ls_reference}</td>
                  <td nowrap="nowrap"><span style={{paddingRight:"10px"}}>{order.delivery_dt}</span>{order.date_diff>0 ?<DeliveryAlert key={order.order_id} deliveryDt={order.delivery_dt} status={order.ls_status} />: ''}</td>
                  <td nowrap="nowrap">Delivery scheduled</td>
                 <td>
                    <button  onClick={() => {
                     
                      toggleRightDrawer({"reference_no":order.reference_no,"ls_reference":order.ls_reference});
                    }}
                      type="button"
                      className="btn btn-sm btn-outline-warning waves-effect waves-light"
                    >
                      {" "}
                      <i className="mdi mdi-border-color"></i>{" "}
                    </button>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </Table>
        <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
        
      </>
    ) : (
      <>
        <Table className="OrderListTable table mb-0">
          <thead>
          <tr>
              <th>SI.No</th>
              <th nowrap="nowrap">Reference NO</th>
              <th>LS Order Ref</th>
              <th>Delivery Scheduled date</th>
              <th>Status</th>
              <th>Action </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={7}>No Records found</td>
            </tr>
          </tbody>
        </Table>
      </>
    )}
   <Toaster position="top-right" reverseOrder={false} />
  </div>

  
)

};
export default StoreSelectionComponent;