import React, { useState } from 'react';
import { Table, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
function PaymentInvoiceDetail({ orderData }) {
  const [CopyActive, setACopyActive] = useState('');

  const paymentInvoiceDetails = orderData?.PaymentInvoiceDetails;

  const handleCopyData = (data, id) => {
    navigator.clipboard.writeText(data);
    setACopyActive(id);
  };

  if (paymentInvoiceDetails?.[0]?.length > 0) {
    return (
      <>
        <Card className="OrderNotebyAdmin">
          <CardHeader>
            <CardTitle>Invoice Details</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="table-responsive dt-table">
              <Table className=" table mb-0">
                <thead>
                  <tr>
                    <th style={{ maxWidth: 180, minWidth: 180 }}>
                      Created Date
                    </th>
                    <th style={{ maxWidth: 180, minWidth: 180 }}>Invoice Id</th>
                    <th style={{ maxWidth: 100, minWidth: 100 }}>Link</th>
                    <th style={{ maxWidth: 280, minWidth: 280 }}>
                      Expiry date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentInvoiceDetails?.[0]?.map((paymentData, inxPay) => {
                    const copyBtn = `cc-${inxPay}`;
                    return (
                      <tr key={inxPay}>
                        <td>{paymentData?.PaymentCreatedAt}</td>
                        <td>{paymentData?.PaymentLinkId}</td>
                        <td style={{ maxWidth: 200 }}>
                          <a
                            href={paymentData?.PaymentLink}
                            className="btn btn-info btn-sm mr-2"
                            target="_blank"
                          >
                            {/* {orderData?.ShippingDetails?.[0]?.Latitude}, {orderData?.ShippingDetails?.[0]?.Longitude} */}
                            View
                          </a>
                          <span
                            className="btn btn-success btn-sm"
                            data-id={copyBtn}
                            onClick={() => {
                              handleCopyData(paymentData?.PaymentLink, copyBtn);
                            }}
                          >
                            {CopyActive === copyBtn ? 'Copied' : 'Copy'}
                          </span>
                        </td>

                        <td style={{ maxWidth: 200 }}>
                          {paymentData?.PaymentExpiryDate}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  } else {
    return <></>;
  }
}
export default PaymentInvoiceDetail;
