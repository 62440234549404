import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  DeliveryReportList: [],
  orderupdateDetails: [],
  deliveryCheckdata:[],
  importSummary:[],
  isLoading: true,
 
 
};

const deliverySlice = createSlice({
  name: "DeliveryReport",
  initialState,
  reducers: {
    setDeliveryReportList(state, actions) {
      state.DeliveryReportList = actions.payload;
      state.isLoading = false;
    },
    setUpdateOrderDetails(state, actions) {
      state.orderupdateDetails = actions.payload;
      
    },
    getCheckData(state, actions) {
      state.deliveryCheckdata = actions.payload;
      state.isCkeckdataLoading = false;
    },
    deliveryImportOrders(state, actions) {
      state.importSummary = actions.payload;
      state.isImportSummaryLoading = false;
    },
    excelGenerateFile(state, actions) {
      state.generateFile = actions.payload;
      state.isExportLoading = false;

    },

  },
});

export const { setDeliveryReportList ,setUpdateOrderDetails,getCheckData,deliveryImportOrders,excelGenerateFile}= deliverySlice.actions;

const deliveryReducer = deliverySlice.reducer;

export default deliveryReducer;
