import { useDropzone } from "react-dropzone";
const ImGDropzone = ({ onDrop, dropError }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop, // Callback function to handle dropped files
    accept: "image/*", // Define accepted file types (e.g., images)
  });

  return (
       <div className={`dropzone ${dropError?'dropzone-error':''}`}>
      <div
        className="dz-message needsclick mt-2"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div className="mb-3">
          <i className="display-4 text-muted bx bxs-cloud-upload" />
        </div>
        <h4>Drop files here or click to upload.</h4>
      </div>
    </div>
  );
};
export default ImGDropzone;
