import './StoreSelection.scss';
import React, { useEffect, useState } from 'react';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Row, Col, CardBody, CardHeader, Button } from 'reactstrap';
import {
  CURRENT_COUNTRY,
  CURRENT_LANGUAGE,
  STORE_LIST,
} from '../../../../constants/index';
function StoreSelectionComponent(args) {
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [OpenPopup, setActiveFilter] = useState(false);
  const [getCountry, setGetCountry] = useState(
    localStorage.getItem('currentCountry') || 'AE'
  );
  const { onlyExport } = args;
  const [getLanguage, setGetLanguage] = useState(
    localStorage.getItem('currentLanguage') || 'EN'
  );
  /**
   *
   * @param {*}
   */
  const CreateNewOrderOnClick = (filterStatus) => {
    if (filterStatus === false) {
      setActiveFilter(true);
      setPosition('right');
      setOpen(!open);
    } else {
      setActiveFilter(false);
    }
  };
  const onDrawerClose = () => {
    setOpen(false);
  };

  const history = useHistory();

  function CreateNewOrder() {
    localStorage.setItem(CURRENT_COUNTRY, getCountry?.toLowerCase());
    localStorage.setItem(CURRENT_LANGUAGE, getLanguage?.toLowerCase());
    localStorage.setItem('currentCountry', getCountry?.toLowerCase());
    localStorage.setItem('currentLanguage', getLanguage?.toLowerCase());
    localStorage.removeItem('guest_address');
    localStorage.removeItem('orderType');
    localStorage.removeItem('is_cod_free');
    localStorage.removeItem('is_shipping_free');
    history.push('orders/create');
  }

  const onCountryLangChange = (e) => {
    const value = e.target.value;
    const [country, language] = value.split('-');
    setGetCountry(country);
    setGetLanguage(language);
  };

  return (
    <>
      <Col xl={onlyExport === '0' ? 2 : 6}>
        <span
          onClick={() => {
            CreateNewOrderOnClick(OpenPopup);
          }}
          className="btn createNewOrderBtn"
        >
          Create New Order
        </span>
        <div className="createNewOrderPopup">
          <ReactDrawer
            open={open}
            position={position}
            onClose={onDrawerClose}
            className="createNewOrderPopup"
          >
            <Card
              className="card-h OrderDetailsBox"
              style={{ overflowY: 'scroll' }}
            >
              <CardHeader>
                <Row>
                  <Col>
                    <h5>Please select a store</h5>
                  </Col>
                  <Col className="textAlignRight">
                    <button
                      type="button"
                      className="btn-close "
                      onClick={onDrawerClose}
                      aria-label="Close"
                      name="selectStore"
                    ></button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <>
                  {STORE_LIST?.map((country, index) => {
                    return (
                      <Row key={`${country?.code}${index}`}>
                        <Col>
                          <p className="title">
                            <strong> {country?.country}</strong>
                          </p>
                        </Col>
                        <Row>
                          {country.language?.map((language, index) => {
                            return (
                              <Col
                                key={`${country?.code}${language?.code}${index}`}
                              >
                                <div className="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    name="countryLanguage"
                                    value={country?.code + '-' + language.code}
                                    className="form-check-input"
                                    id={country?.code + '-' + language.code}
                                    checked={
                                      getCountry.toUpperCase() +
                                        '-' +
                                        getLanguage.toLowerCase() ===
                                      country?.code + '-' + language.code
                                    }
                                    onChange={onCountryLangChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={
                                      country?.country + '-' + language.code
                                    }
                                  >
                                    {language.title}
                                  </label>
                                </div>
                              </Col>
                            );
                          })}

                          <Col></Col>
                        </Row>
                        <hr className="divider" />
                      </Row>
                    );
                  })}

                  <Row>
                    <Col className="mt-3 mb-3">
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        onClick={CreateNewOrder}
                      >
                        Continue
                      </Button>
                    </Col>
                  </Row>
                </>
              </CardBody>
            </Card>
          </ReactDrawer>
        </div>
      </Col>
    </>
  );
}

export default StoreSelectionComponent;
