import React, { useEffect, useState } from 'react';
import Calender from './components/deliveriesTotalCountDashboard';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import './dashboard.scss';
import { DateRangePicker } from 'rsuite';
import CouponOrdersDashboard from './components/couponOrdersDashboard';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import Dashboard from './components/ordersTotalCountDasboard';

const MainOrdersDashboard = () => {
  const [active, setActiveValue] = useState('O_D');
  const [value, setValue] = useState(['', '']);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  let isMobileView = 0;
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  if (windowSize.innerWidth <= 1279 && isMobileView === 0) {
    isMobileView++;
  }
  useEffect(() => {
    const storedValue = localStorage.getItem('dashboard_o');
    if (storedValue) {
      setActiveValue(storedValue);
    }
  }, []);
  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    localStorage.setItem('dashboard_o', selectedValue || 'O_D');

    setActiveValue(selectedValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Breadcrumbs
                title="Orders Dashboard"
                breadcrumbItem=" Dashboard"
              />
            </Col>
          </Row>
          <div className="dashboardHeader mb-3">
            <div className="selectDashBoardOption">
              <select
                className="form-control form-select"
                name="is_active"
                value={active}
                onChange={handleDropdownChange}
              >
                <option value="O_D">Orders Summary</option>
                <option value="O_M">Order Delivery Summary</option>
              </select>
            </div>
            {isMobileView !== 0 ? (
              <DateRangePicker
                showOneCalendar
                value={value}
                placement="auto"
                onChange={setValue}
                showMeridian
                format="yyyy-MM-dd"
                onClean={() => {
                  setValue(['', '']);
                }}
              />
            ) : (
              <DateRangePicker
                placement="leftStart"
                value={value}
                onChange={setValue}
                showMeridian
                format="yyyy-MM-dd"
                onClean={() => {
                  setValue(['', '']);
                }}
              />
            )}
          </div>
          {active === 'O_M' ? <Calender /> : null}
          {active === 'O_D' ? (
            <Dashboard startDate={value?.[0]} endDate={value?.[1]} />
          ) : null}
          {/* {active === "C_D" ? (<><CouponOrdersDashboard dataColors='["--vz-primary", "--vz-primary", "--vz-info", "--vz-info", "--vz-danger", "--vz-primary", "--vz-primary", "--vz-warning", "--vz-primary", "--vz-primary"]' startDate={value?.[0]} endDate={value?.[1]}/></>):null} */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MainOrdersDashboard;
