import axios from 'axios';
import {
  setDashboardList,
  setStatusCount,
  setDashboardGraph,
  setDashboardPieGraph,
  setDashboardSummeryData,
} from '../../../Redux/dashboard/dashboardSlice';
import UserAuthKey from '../../../components/Common/UserAuthKey';
import { setUserData } from '../../../Redux/user/userSlice';

const apiUrl = process.env.REACT_APP_OMS_API;
const bearerToken = UserAuthKey();

export const fetchDashboardList = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/dashboard-counts`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setDashboardList(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const fetchStatausCount = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/status-counts`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setStatusCount(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const fetchDashboardGraphdata = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/dashboard-graph`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setDashboardGraph(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const fetchDashboardPieGraphdata = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/dashboard-payment`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setDashboardPieGraph(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const fetchDashboardSummerydata = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/dashboard/dashboard-summery`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setDashboardSummeryData(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
