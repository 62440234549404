import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  PhotoShootLists: [],
  isLoading: true,
  isDetailLoading: true,
  isUpdateLoading: true
};

const PhotoshootSlice = createSlice({
  name: "photoshoot",
  initialState,
  reducers: {
    getPhotoShootLists(state, actions) {
      state.PhotoShootLists = actions.payload;
      state.isLoading = false;
    },
    getPhotoShootDetails(state, actions) {
      state.PhotoShootDetails = actions.payload;
      state.isDetailLoading = false;
    },
    getPhotoShootUpdate(state, actions) {
      state.PhotoShootUpdate = actions.payload;
      state.isUpdateLoading = false;
    },
   
  },
});

export const {getPhotoShootLists, getPhotoShootDetails, getPhotoShootUpdate} = PhotoshootSlice.actions;

const photoShootReducer = PhotoshootSlice.reducer;

export default photoShootReducer;
