import "./Sms.scss";
import React from "react"
import { Col, Row } from "reactstrap";
const SmsComponent = (props) => {
    console.log(props);
    const name = props.testData;
  return (
    <Row>
      <Col className="ReturnInfo">SMS Config Details : {name}</Col>
    </Row>
  );
};
export default SmsComponent;
