import axios from "axios";

import { setBccwList , setBccwDetail, setDesignHomeList, setDesignHomeDetail,getProjecttrackUpdate, setDesignHomeFormUpdate, getFormLeadData,getProjecttrackList, getProjecttrackDetail} from "../../../Redux/bccworder/bccwSlice";
import UserAuthKey from "../../../components/Common/UserAuthKey";
import { setUserData } from "../../../Redux/user/userSlice";

const apiUrl = process.env.REACT_APP_OMS_API;
export const fetchCBccwDataList = async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/bccw-orders/bccworder-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setBccwList(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }

  }
 
};
export const BccwDataDetail = async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/bccw-orders/bccworder-details`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setBccwDetail(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
  };
  export const DesignHomeDetail = async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/designhome/designhome-details`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(setDesignHomeDetail(response?.data));
    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
 
     }
    }
  };
  export const DesignHomeDetailList  = async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/designhome/designhome-list`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(setDesignHomeList(response?.data));
    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
 
     }
    }
  };
  export const DesignHomeDetailFormUpdate  = async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/designhome/designhome-update`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(setDesignHomeFormUpdate(response?.data));
    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
 
     }
    }
   
  };
  export const fetchLeadFormData  = ({ formData, dispatch }) => {
    const bearerToken = UserAuthKey();
    // const config = {
    //   method: 'POST',
    //   url: `${apiUrl}v1/projecttrack/projecttrack-data`,
    //   headers: {
    //     Authorization: `Bearer ${bearerToken}`,
    //     'Content-Type': "multipart/form-data",
    
    //   },
     
    //    data: formData, 
    
    // };
    // try {
    //   const response = await axios(config);
    //   dispatch(getFormLeadData(response?.data));
    // } catch (error) {
  
    // }
    return axios
      .post(`${apiUrl}v1/projecttrack/projecttrack-data`,formData,  {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch(getFormLeadData(response?.data));
      })
      .catch((error) => {
        if(error?.response?.status === 401){
          dispatch(setUserData(null))
          window.location.replace('/logout');
       }
       else{
   
       }
      });
  };
  export const fetchProjectTrackList  = async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/projecttrack/projecttrack-list`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(getProjecttrackList(response?.data));
    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
 
     }
    }
  
  };
  export const fetchProjectTrackDetail  = async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/projecttrack/projecttrack-detail`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(getProjecttrackDetail(response?.data));
    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
 
     }
    }
    
  };
  export const fetchProjectTrackUpdate = async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/projecttrack/projecttrack-update`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(getProjecttrackUpdate(response?.data));
    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
 
     }
    }
  };
 