import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { useHistory, useLocation } from 'react-router';
import { useUrlSearchParams } from 'use-url-search-params';
import { get, map } from 'lodash';
import countries from '../../../common/countries';
import { setCountryCode } from '../../../Redux/user/countrySlice';
import { LIVE_URL } from '../../../constants/index';
const CountryDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const removeCountryParams = () => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('cn')) {
      queryParams.delete('cn');
      history.replace({
        search: queryParams.toString(),
      });
    }
  };
  let UserRoleID = '';
  let countryList = '';
  let countryactive = '';
  let singlecountry = '';
  let userCountry = '';
  const [activeTab, setActiveTab] = useState(countryactive);
  const [selectedCountry, setSelectedCountry] = useState('');
  const apiUrl = process.env.REACT_APP_BASE_URL;

  //const [userCountry, setSUserCountry] = useState("");
  const [menu, setMenu] = useState(false);

  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      countryList = userObj?.countryList;
      countryactive = userObj?.countryList?.All;
      singlecountry = userObj?.country;
      let [a, b] = singlecountry.split(',');
      // localStorage.setItem("WEBSITE_COUNTRY", a);
    }
  }

  useEffect(() => {
    const currentCountry = localStorage.getItem('WEBSITE_COUNTRY');
    setSelectedCountry(currentCountry);
    dispatch(setCountryCode(currentCountry));
  }, []);
  const changeCountryAction = (country) => {
    localStorage.setItem('WEBSITE_COUNTRY', country.toLowerCase());
    setSelectedCountry(country);
    dispatch(setCountryCode(country));
    removeCountryParams();
    window.location.reload(false);
  };

  const toggle = () => {
    setMenu(!menu);
  };
  // const str = selectedCountry || '';

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item " tag="button">
          <img
            src={`${apiUrl}images/flags/${selectedCountry?.toLowerCase()}.jpg`}
            alt="images"
            height="16"
            className="me-1"
          />
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(countryList), (key) => (
            <DropdownItem
              key={key}
              onClick={() => changeCountryAction(key)}
              className={`notify-item ${
                selectedCountry === key ? 'active' : 'none'
              }`}
            >
              <img
                src={`${apiUrl}images/flags/${key === 'All' ? 'all' : key}.jpg`}
                alt="Skote"
                className="me-1"
                height="12"
              />
              <span className="align-middle">
                {get(countryList, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default CountryDropdown;
