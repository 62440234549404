import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboardList: [],
  couponGraph: null,
  isLoading: true,
  isSummeryLoading: true,
  isStatusLoading: true,
};

const dashboardSlice = createSlice({
  name: 'Dashboard',
  initialState,
  reducers: {
    setDashboardList(state, actions) {
      state.dashboardList = actions.payload;
      state.isLoading = false;
    },
    setStatusCount(state, actions) {
      state.StatusCount = actions.payload;
      state.isStatusLoading = false;
    },
    setDashboardGraph(state, actions) {
      state.dashboardGraph = actions.payload;
      state.isGraphLoading = false;
    },
    setDashboardPieGraph(state, actions) {
      state.dashboardPieGraph = actions.payload;
      state.isPieGraphLoading = false;
    },
    setCouponSummaryGraph(state, actions) {
      state.couponGraph = actions.payload;
      state.isCouponGraphLoading = false;
    },

    setDashboardSummeryData(state, actions) {
      state.summreryData = actions.payload;
      state.isSummeryLoading = false;
    },
  },
});

export const {
  setDashboardList,
  setStatusCount,
  setDashboardGraph,
  setDashboardPieGraph,
  setCouponSummaryGraph,
  setDashboardSummeryData,
} = dashboardSlice.actions;

const dashboardReducer = dashboardSlice.reducer;

export default dashboardReducer;
