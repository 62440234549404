import { gql } from '@apollo/client';
export const GET_WISHLIST_ADMIN = gql`
  query GET_WISHLIST_ADMIN($userId: String!) {
    getWishlistAdmin(input:{
        userId:$userId
    } ) {
      products {
        _id
        name
        selectedPrice {
          specialPrice
          currency
          country
        }
        defaultPrice {
          specialPrice
          currency
          country
        }
        media_gallery {
          value
          position
        }
        inventoryStock {
          qty
          country
          inStock
        }
      }
    }
  }
`;