import React from 'react';
const UserAuthKey=()=>{
    if (localStorage.getItem("authUser")) {
        const userObj = JSON.parse(localStorage.getItem("authUser"));
        if (userObj?.authToken !== undefined) {
              return userObj?.authToken;
        }
        else{
            return null;
        }
    }
};

export default UserAuthKey;