import { useState, useEffect, useSearchParams, useRef } from "react";
import { MetaTags } from "react-meta-tags";
import { DashBreadcrumb } from "../../../components/Common/Breadcrumb"; 
import { Col, Container, Card,CardBody,
  Row,Spinner
 
  } from "reactstrap";
  import { useParams } from "react-router-dom";
  import ApolloClientKnowledgeBaseService from "../../../ApolloClientKBService";
  import { GET_DEP_DATA_DETAIL } from "../../Customers/query/DepView";
import { UPDATE_DEPARTMENT } from "../../Customers/query/EditDep";
import { AvForm, AvField } from "availity-reactstrap-validation"

import toast, { Toaster } from "react-hot-toast";
const EditDepartment = () => {
    const [categoryName , setCategoryName] = useState("");
    const [KBDepData, setKbDepData] = useState("");
    const [depLoad, setDepLoad] = useState(false);
    const [updateDepData ,setUpdateDepData] = useState();

    const { id } = useParams();
    let userName = "";
if (localStorage.getItem("authUser")) {
  const userObj = JSON.parse(localStorage.getItem("authUser"));
  if (userObj?.role_id !== undefined) {
    userName = userObj?.user_name;
  }
}
    const kbDepData = async () => {
      try {
        const { data = {} } = await ApolloClientKnowledgeBaseService.query({
          query: GET_DEP_DATA_DETAIL,
          variables: {
            input: {
              _id: id,
              slug :"",
              requestType:"id"
            },
          },
          fetchPolicy: "no-cache",
        });
        setKbDepData(data);
      } catch (error) {}
    };
    useEffect(() => {
      kbDepData();
    }, []);
    const KabDepDetail = KBDepData?.getDepartmentsDetails;
    const updateDepartmentData = async (events,values) => {
      console.log(values,"values")
      setDepLoad(true)
      try {
        console.log(values,"values")
        const { data } = await ApolloClientKnowledgeBaseService.mutate({
          mutation: UPDATE_DEPARTMENT,
          variables:{
          
            
            input:{
              _id:id,
              departmentData:{
            name:values?.name,
            host:values?.host,
            icon:values?.Icon,
          slug: values?.slug,
          createdBy: userName,
          updatedBy: userName
         
           } }},
          fetchPolicy: 'network-only',
        });
        setUpdateDepData(data)
      }
       catch (error) {
        
      }
    };
    const depUpdateData = updateDepData?.updateDepartmentsDetails
    ;
 

    useEffect(() => {
      if (depUpdateData?._id !== undefined) {
    
        setDepLoad(false)
      toast.success("Department Successfully Updated", {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#4CAF50",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#4CAF50",
          },
        });
        setDepLoad("")
    
    }
    
    else {
    if(depUpdateData?._id  === ""){
      setDepLoad(false)
    toast.error("Department Not Updated", {
      style: {
        border: "1px solid #fff",
        padding: "20px",
        color: "#ffffff",
        background: "#f44336",
      },
      iconTheme: {
        primary: "#FFFAEE",
        secondary: "#f44336",
      },
    });  
    }}}, [depUpdateData]);


  return(
    <>
      <div className="page-content">
        <MetaTags>
          <title>Articles</title>
        </MetaTags>
        <Container fluid>
          <Row>
          <Col>    
            <DashBreadcrumb
                title="Articles"
                breadcrumbItem="Add New Articles"
              />
                  
          <Row>
                    <Col md={4}>
                        <Card>
                            <CardBody>
                              
                                <AvForm
                                onValidSubmit={(e, v) => {
                                  updateDepartmentData(e, v);
                                }}
                               
                                >
                                         
                                 
                                 <div className="mb-3">
                                        <AvField
                                            name="name"
                                            label="Department Name "
                                            placeholder="Type Something"
                                            type="text"
                                             value={KabDepDetail?.name}
                                             onChange ={(e)=>{
                                                setCategoryName(e?.target?.value)
                                               }}
                                             required
                                            
                                        /> 
                                    </div>

                                 
                                    <div className="mb-3">
                                        <AvField
                                            name="slug"
                                            label="Slug*"
                                            type="text"
                                            value={categoryName === '' ? KabDepDetail?.slug : categoryName?.replace(/[^A-Z0-9]+/ig, "-")?.toLowerCase()}
                                          
                                          
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <AvField
                                            name="host"
                                            label="Host*"
                                            type="text"
                                            value={KabDepDetail?.host}
                                          
                                          
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="Icon"
                                            label="Icon*"
                                            type="text"
                                            value={KabDepDetail?.icon}
                                          
                                          
                                        />
                                    </div>
                                  
                                    <button type="submit" className="btn btn-primary">
                                    {depLoad && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
               {" "}
                Submit
              </button>
                                   
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                  

                </Row> 
              
              </Col>
           
          
          </Row>
      
       
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};

export default EditDepartment;
