import axios from "axios";
import { setUpdatedpassword, setUserPofile } from "../../../Redux/user/userSlice";
import UserAuthKey from "../../../components/Common/UserAuthKey";
import { setUserData } from "../../../Redux/user/userSlice";
const apiUrl = process.env.REACT_APP_OMS_API;


const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export const fetchUpdatedPassword = async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/user/update-password`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setUpdatedpassword(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
};
export const UpdateUserProfile = async({ formData,dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/user/update-profile`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      // 'Content-Type': 'application/json',
      'Content-Type':'multipart/form-data'
    },
   
     data: formData, 
  
  };
  try {
    const response = await axios(config);
    dispatch(setUserPofile(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
  // return axios
  //   .post(`${apiUrl}v1/user/update-profile`, formData)
  //   .then((response) => {
  //     dispatch(setUserPofile(response?.data));


  //   })
  //   .catch((error) => {
  //     throw error;
  //   });
};