/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import {
  Link,
  BrowserRouter as Router,
} from "react-router-dom";
import { DateRangePicker } from 'rsuite';
import { useDispatch, useSelector } from "react-redux";
import SplineArea from "../../AllCharts/apex/SplineArea";
import PieChart from "../../AllCharts/apex/PieChart";
import Apaexlinecolumn from "../../AllCharts/apex/apaexlinecolumn";
import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import UncontrolledExample from "../../Dashboard/UiCareusol";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  CardHeader,
  FormGroup,
  InputGroup,
} from "reactstrap";

import CountUp from "react-countup";
import {
  fetchDashboardList,
  fetchStatausCount,
  fetchDashboardPieGraphdata,
  fetchDashboardGraphdata } from "../actions.js";
const Dashboard = ({startDate,endDate}) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
let isMobileView = 0;
useEffect(() => {
  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }

  window.addEventListener("resize", handleWindowResize);

  return () => {
    window.removeEventListener("resize", handleWindowResize);
  };
}, []);

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

if (windowSize.innerWidth <= 1279 && isMobileView === 0) {
  isMobileView++;
}
  const dispatch = useDispatch();
  const [startDateGraph, setStartDateGraph] = useState(
    new moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format(
      "YYYY-MM-DD"
    )
  );
  const [isApiCall, setApiCall] = useState(0);
  const [endDateGraph, setEndDateGraph] = useState(
    new moment(new Date()).format("YYYY-MM-DD")
  );
  const currentCountry = localStorage.getItem("WEBSITE_COUNTRY");

  let cntry = currentCountry;

  if (cntry !== "all" && cntry !== null) {
    cntry = cntry.toUpperCase();
  }
  useEffect(() => {

    if (isApiCall === 0) {
   
    fetchDashboardList({
      params: {
        date_from: startDate === "" ? moment(new Date()).format('YYYY-MM-DD') : moment(new Date(startDate)).format('YYYY-MM-DD') ,
        date_to:  endDate === ""? moment(new Date()).format('YYYY-MM-DD')  : moment(new Date(endDate)).format('YYYY-MM-DD'),
        country: cntry,
      },
      dispatch,
    });
    setTimeout(() => setApiCall(0), 8000);
    }
  }, [startDate,endDate , cntry]);

  const { dashboardList = [], isLoading } = useSelector(
    (state) => state.dashboard
  );


  const orderCountData = dashboardList?.data;
  useEffect(() => {
 

    fetchStatausCount({
      params: {
        date_from: startDate === "" ? moment(new Date()).format('YYYY-MM-DD') :  moment(new Date(startDate)).format('YYYY-MM-DD') ,
        date_to:  endDate === ""? moment(new Date()).format('YYYY-MM-DD')  :  moment(new Date(endDate)).format('YYYY-MM-DD'),
        country: cntry,
      },
      dispatch,
    });
  }, [startDate, endDate,cntry]);
  const { StatusCount = [], isStatusLoading } = useSelector(
    (state) => state.dashboard
  );
  const Total = StatusCount?.data;

  useEffect(() => {
    fetchDashboardPieGraphdata({
      params: {
        country: cntry,
        date_from: startDate === "" ? moment(new Date()).format('YYYY-MM-DD') :  moment(new Date(startDate)).format('YYYY-MM-DD')  ,
        date_to:  endDate === ""? moment(new Date()).format('YYYY-MM-DD')  : moment(new Date(endDate)).format('YYYY-MM-DD'),
      },
      dispatch,
    });
  }, [endDate, startDate, cntry]);
  const { dashboardPieGraph, isPieGraphLoading } = useSelector(
    (state) => state.dashboard
  );
  let dashboardPieGraphResult = { ...dashboardPieGraph?.results };
  let statsDate = "";
  let endsDate = "";
  useEffect(() => {
    localStorage.setItem("startsDate", startDate);
    localStorage.setItem("endsDate", endDate);
  }, []);
  statsDate = localStorage.getItem("startsDate");
  endsDate = localStorage.getItem("endsDate");
  useEffect(() => {
    let eDate = new moment(endDateGraph).format("YYYY-MM-DD");
    let sDate = new moment(startDateGraph).format("YYYY-MM-DD");
    if (
      startDateGraph === "" ||
      startDateGraph === undefined ||
      endDateGraph === "" ||
      endDateGraph === undefined
    ) {
      sDate = new moment(new Date() - 7).format("YYYY-MM-DD");
      eDate = new moment(new Date()).format("YYYY-MM-DD");
    }
    if (isApiCall === 0) {
      fetchDashboardGraphdata({
        params: {
          date_from: sDate === "" ? statsDate : sDate,
          date_to: eDate === "" ? endsDate : eDate,
          country: cntry,
        },
  
        dispatch,
      });
      setTimeout(() => setApiCall(0), 5000);
    }
  }, [endDateGraph, startDateGraph, cntry]);
  const { dashboardGraph = [], isGraphLoading } = useSelector(
    (state) => state.dashboard
  );
  const splineGraph = dashboardGraph?.results;
 
  return (
    <React.Fragment>

              
          <Row>
            {dashboardList?.code === 100 ? (
              <>
                {cntry === "all" ? (
                  <Col xl={8} md={7}>
                    <Row className="row-fluid-scroll">
                      <Col xl={4} md={6}>
                        <Card className="card-h-100">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col xs={9}>
                                <span className="text-muted mb-3 lh-1 d-block">
                                  Total AE Orders
                                </span>
                                <h4 className="mb-3">
                                  <span className="counter-value">
                                    <CountUp
                                      start={0}
                                      end={orderCountData?.total_AE_orders}
                                      duration={3}
                                    />
                                  </span>
                                </h4>
                              </Col>
                              <Col xs={3}>
                                <span className="DashbordAuto">
                                  <i className="bx bx-trending-up"></i>
                                </span>
                              </Col>
                            </Row>
                            <div className="text-nowrap">
                              
                              <span className="ms-1 text-muted font-size-13">
                                <Link to="/orders?cn=AE">View All</Link>
                            
                              </span>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={4} md={6}>
                        <Card className="card-h-100">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col xs={9}>
                                <span className="text-muted mb-3 lh-1 d-block">
                                  Total OM Orders
                                </span>
                                <h4 className="mb-3">
                                  <span className="counter-value">
                                    <CountUp
                                      start={0}
                                      end={orderCountData?.total_OM_orders}
                                      duration={3}
                                    />
                                  </span>
                                </h4>
                              </Col>
                              <Col xs={3}>
                                <span className="DashbordAuto">
                                  <i className="bx bx-trending-up"></i>
                                </span>
                              </Col>
                            </Row>
                            <div className="text-nowrap">
                            
                              <span className="ms-1 text-muted font-size-13">
                                <Link to="/orders?cn=OM">View All</Link>
                              </span>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={4} md={6}>
                        <Card className="card-h-100">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col xs={9}>
                                <span className="text-muted mb-3 lh-1 d-block">
                                  Total KW Orders
                                </span>
                                <h4 className="mb-3">
                                  <span className="counter-value">
                                    <CountUp
                                      start={0}
                                      end={orderCountData?.total_KW_orders}
                                      duration={3}
                                    />
                                  </span>
                                </h4>
                              </Col>
                              <Col xs={3}>
                                <span className="DashbordAuto">
                                  <i className="bx bx-trending-up"></i>
                                </span>
                              </Col>
                            </Row>
                            <div className="text-nowrap">
                              
                              <span className="ms-1 text-muted font-size-13">
                                <Link to="/orders?cn=KW">View All</Link>
                              </span>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={4} md={6}>
                        <Card className="card-h-100">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col xs={9}>
                                <span className="text-muted mb-3 lh-1 d-block">
                                  Total QA Orders
                                </span>
                                <h4 className="mb-3">
                                  <span className="counter-value">
                                    <CountUp
                                      start={0}
                                      end={orderCountData?.total_QA_orders}
                                      duration={3}
                                    />
                                  </span>
                                </h4>
                              </Col>
                              <Col xs={3}>
                                <span className="DashbordAuto">
                                  <i className="bx bx-trending-up"></i>
                                </span>
                              </Col>
                            </Row>
                            <div className="text-nowrap">
                             
                              <span className="ms-1 text-muted font-size-13">
                                <Link to="/orders?cn=QA">View All</Link>
                              </span>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={4} md={6}>
                        <Card className="card-h-100">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col xs={9}>
                                <span className="text-muted mb-3 lh-1 d-block">
                                  Total BH Orders
                                </span>
                                <h4 className="mb-3">
                                  <span className="counter-value">
                                    <CountUp
                                      start={0}
                                      end={orderCountData?.total_BH_orders}
                                      duration={3}
                                    />
                                  </span>
                                </h4>
                              </Col>
                              <Col xs={3}>
                                <span className="DashbordAuto">
                                  <i className="bx bx-trending-up"></i>
                                </span>
                              </Col>
                            </Row>
                            <div className="text-nowrap">
                              
                              <span className="ms-1 text-muted font-size-13">
                                <Link to="/orders?cn=BH">View All</Link>
                              </span>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <>
                    <Col xl={8} md={7}>
                      <Row className="row-fluid-scroll">
                        <Col xl={4} md={6}>
                          <Card className="card-h-100">
                            <CardBody>
                              <Row className="align-items-center">
                                <Col xs={9}>
                                  <span className="text-muted mb-3 lh-1 d-block">
                                    Total Orders
                                  </span>
                                  <h4 className="mb-3">
                                    <span className="counter-value">
                                      <CountUp
                                        start={0}
                                        end={orderCountData?.total_orders}
                                        duration={3}
                                      />
                                    </span>
                                  </h4>
                                </Col>
                                <Col xs={3}>
                                  <span className="DashbordAuto">
                                    <i className="bx bx-trending-up"></i>
                                  </span>
                                </Col>
                              </Row>
                              <div className="text-nowrap">
                               
                                <span className="ms-1 text-muted font-size-13">
                                  <a href={`/orders`}>View All</a>
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl={4} md={6}>
                          <Card className="card-h-100">
                            <CardBody>
                              <Row className="align-items-center">
                                <Col xs={9}>
                                  <span className="text-muted mb-3 lh-1 d-block">
                                    Total Split Orders
                                  </span>
                                  <h4 className="mb-3">
                                    <span className="counter-value">
                                      <CountUp
                                        start={0}
                                        end={orderCountData?.total_split_orders}
                                        duration={3}
                                      />
                                    </span>
                                  </h4>
                                </Col>
                                <Col xs={3}>
                                  <span className="DashbordAuto">
                                    <i className="bx bx-trending-up"></i>
                                  </span>
                                </Col>
                              </Row>
                              <div className="text-nowrap">
                                
                                <span className="ms-1 text-muted font-size-13">
                                  <a href={`/splitorders`}>View All</a>
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl={4} md={6}>
                          <Card className="card-h-100">
                            <CardBody>
                              <Row className="align-items-center">
                                <Col xs={9}>
                                  <span className="text-muted mb-3 lh-1 d-block">
                                    Total B2B Orders
                                  </span>
                                  <h4 className="mb-3">
                                    <span className="counter-value">
                                      <CountUp
                                        start={0}
                                        end={orderCountData?.total_b2b_orders}
                                        duration={3}
                                      />
                                    </span>
                                  </h4>
                                </Col>
                                <Col xs={3}>
                                  <span className="DashbordAuto">
                                    <i className="bx bx-trending-up"></i>
                                  </span>
                                </Col>
                              </Row>
                              <div className="text-nowrap">
                               
                                <span className="ms-1 text-muted font-size-13">
                                  <a href={`/orders`}>View All</a>
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl={4} md={6}>
                          <Card className="card-h-100">
                            <CardBody>
                              <Row className="align-items-center">
                                <Col xs={9}>
                                  <span className="text-muted mb-3 lh-1 d-block">
                                    Total Click Orders
                                  </span>
                                  <h4 className="mb-3">
                                    <span className="counter-value">
                                      <CountUp
                                        start={0}
                                        end={orderCountData?.total_click_orders}
                                        duration={3}
                                      />
                                    </span>
                                  </h4>
                                </Col>
                                <Col xs={3}>
                                  <span className="DashbordAuto">
                                    <i className="bx bx-trending-up"></i>
                                  </span>
                                </Col>
                              </Row>
                              <div className="text-nowrap">
                                
                                <span className="ms-1 text-muted font-size-13">
                                  <a href={`/storeorders`}>View All</a>
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl={4} md={6}>
                          <Card className="card-h-100">
                            <CardBody>
                              <Row className="align-items-center">
                                <Col xs={9}>
                                  <span className="text-muted mb-3 lh-1 d-block">
                                    Total Return Orders
                                  </span>
                                  <h4 className="mb-3">
                                    <span className="counter-value">
                                      <CountUp
                                        start={0}
                                        end={
                                          orderCountData?.total_return_orders
                                        }
                                        duration={3}
                                      />
                                    </span>
                                  </h4>
                                </Col>
                                <Col xs={3}>
                                  <span className="DashbordAuto">
                                    <i className="bx bx-trending-up"></i>
                                  </span>
                                </Col>
                              </Row>
                              <div className="text-nowrap">
                                
                                <span className="ms-1 text-muted font-size-13">
                                  <a href={`/returnorders`}>View All</a>
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl={4} md={6}>
                          <Card className="card-h-100">
                            <CardBody>
                              <Row className="align-items-center">
                                <Col xs={9}>
                                  <span className="text-muted mb-3 lh-1 d-block">
                                    Total Bulk Orders
                                  </span>
                                  <h4 className="mb-3">
                                    <span className="counter-value">
                                      <CountUp
                                        start={0}
                                        end={orderCountData?.total_bulk_orders}
                                        duration={3}
                                      />
                                    </span>
                                  </h4>
                                </Col>
                                <Col xs={3}>
                                  <span className="DashbordAuto">
                                    <i className="bx bx-trending-up"></i>
                                  </span>
                                </Col>
                              </Row>
                              <div className="text-nowrap">
                                
                                <span className="ms-1 text-muted font-size-13">
                                  <a href={`/bulkorders`}>View All</a>
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </>
            ) : (
              <>
                {" "}
                <Col xl={8} md={7}>
                  <CardBody>
                    <Skeleton count={5} />
                  </CardBody>
                </Col>
              </>
            )}

            <Col xl={4} md={5}>
              <UncontrolledExample Total={Total} />
            </Col>
          </Row>
          <Row>
            {cntry === "AE" ? (
              <>
                {" "}
                <Col xl={8}>
                  <Card>
                    <CardHeader>
                      <CardTitle> Order Summary </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div dir="ltr">
                        {dashboardGraph?.code === "100" ? (
                          <>
                            <Apaexlinecolumn Apaexline={splineGraph} />
                          </>
                        ) : (
                          <>
                            <CardBody>
                              <Skeleton count={5} />
                            </CardBody>
                          </>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : (
              <>
                <Col xl={8}>
                  <Card>
                    <CardHeader className="d-sm-flex align-items-center justify-content-between">
                      <CardTitle>Orders Summary</CardTitle>
                      <Col xl={4}>
                        <div className="InputBg">
                          <i className="bx bx-calendar"></i>
                          <FormGroup>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                defaultValue={`${startDateGraph} to ${endDateGraph}`}
                                options={{
                                  mode: "range",
                                  dateFormat: "Y-m-d",
                                }}
                              

                                onChange={(
                                  selectedDates,
                                  dateStr,
                                  instance
                                ) => {
                                  const date_split = dateStr.split("to");
                                  const firstDate = date_split[0];
                                  setStartDateGraph(firstDate);
                                  const secondDate = date_split[1];
                                  setEndDateGraph(secondDate);

                         
                                }}
                              />
                            </InputGroup>
                          </FormGroup>
                        </div>
                      </Col>
                    </CardHeader>
                    {dashboardGraph?.code === "100" ? (
                      <>
                        <CardBody>
                          <div dir="ltr">
                            <SplineArea splineGraph={splineGraph} />
                          </div>
                        </CardBody>
                      </>
                    ) : (
                      <>
                        <CardBody>
                          <Skeleton count={5} />
                        </CardBody>
                      </>
                    )}
                  </Card>
                </Col>
              </>
            )}
           

            <Col xl={4}>
              <Card>
                <CardHeader>
                  <CardTitle>Payment Summary</CardTitle>
                </CardHeader>

                {dashboardPieGraph?.code === "100" ? (
                  <>
                    <CardBody>
                      <div dir="ltr">
                        <PieChart chartData={dashboardPieGraphResult} />
                      </div>
                    </CardBody>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Skeleton count={5} />
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>     
    </React.Fragment>
  );
};

export default Dashboard;
