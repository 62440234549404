import React ,{useEffect, useState} from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
  import { useParams } from 'react-router-dom';
  import { Container, Row, Col, Card, FormGroup, CardBody, CardTitle, CardSubtitle, Label, Button,  Spinner } from "reactstrap";
  import { useDispatch, useSelector } from "react-redux";
  import { getDmsExpressDateDetail, updateExpressDateDetail} from "./action/action";
  import { AvForm, AvField , AvCheckboxGroup, AvCheckbox} from "availity-reactstrap-validation";
  import toast, { Toaster } from "react-hot-toast";

const DeliverySettings = () => {
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const dispatch = useDispatch();
  const { id , country} = useParams();

  useEffect(() => {
       
    getDmsExpressDateDetail({
        params: {
          id:id,
          country:country
        },
        dispatch,
      })
     
    
    },[]);
  const { expressDate = [], isExpressLoading } = useSelector(
      (state) => state.setting
    );
   
  const expDate = expressDate?.data;
  const handleValidSubmit = (events, values) => {
     setUpdateDetailsLoad(true);
     
    
     const amount = values?.amount ? values?.amount : "";
     const country = values?.country ? values?.country : "";
     const date = values?.date ? values?.date : "";
     updateExpressDateDetail({
       params: {
        id:id,
        country: country,
        amount: amount,
        dms_express_date:date
      },
      dispatch,
    })
    
   };
   const {updateExpressList = [], isExpressUpdateLoading} = useSelector(
    (state) => state.setting
  );
  
  useEffect(() => {
    if (updateExpressList?.code === 100) {
      setUpdateDetailsLoad(false);
      toast.success(updateExpressList?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
    } else {
      if (updateExpressList?.message) {
        toast.error(updateExpressList?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [updateExpressList]);

  const title = "Orders";
  const description = "Manage Orders";
 // const countryAll = "All";
 
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Delivery settings </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Settings" breadcrumbItem="Delivery Settings" />
            </Col>
          
           
          </Row>

          <Row style={{display:"flex",
      flexDirection:"row",
     justifyContent : "center"
}}>
                    <Col lg={6}>
                        <Card>
                            <CardBody>
                              
                                <AvForm
                                 onValidSubmit={(e, v) => {
                                    //console.log(e, v);
                                    handleValidSubmit(e, v);
                                  }}
                                >
                               
                                 <div className="mb-3">
                                        <AvField
                                            name="amount"
                                            label="Amount "
                                            placeholder=""
                                            type="number"
                                            value= {expDate?.[0]?.amount}
                                            required
                                            
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                    <AvField
                                            name="country"
                                            label="Country "
                                            placeholder=""
                                            type="text"
                                            value= {expDate?.[0]?.country}
                                            required
                                            disabled
                                            
                                        
                                        />
                                   
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="date"
                                            label="Start From"
                                            type="date"
                                            value= {expDate?.[0]?.dms_express_date}
                                            required
                                            
                                          
                                        />
                                    </div>
                                  
                                   
                    



                                    <div className="d-flex flex-wrap gap-2">
                                    <button type="submit" className="btn btn-primary">
                  {updateStsLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "}
                  Save
                </button>{" "}
                                        <Button type="reset" color="secondary" className="">
                                            Cancel
                                        </Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>

                </Row> 
          
           
         
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default DeliverySettings;
