import { gql } from '@apollo/client';
export const UPDATE_CATEGORY= gql`
mutation(
   $input:CategoryUpdateInput!
  ){
    updateCategoryDetails(input: $input)  {
        _id
    name {
      en
      ar
    }
    slug
    icon
    shortDescription {
      en
      ar
    }
    metaTitle {
      en
      ar
    }
    metaDescription {
      en
      ar
    }
    metaKeywords {
      en
      ar
    }
    createdBy
  }
  }`;
