import React ,{useEffect} from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
import Callbacklist from "./components/Callbacklist";





import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
  } from "reactstrap";

const Callback = () => {
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Callback View</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Callback" breadcrumbItem="Callback View" />
            </Col>
       
          </Row>
          
         <Callbacklist/>
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Callback;
