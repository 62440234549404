import React ,{useEffect ,useSelector} from "react";
import { MetaTags } from "react-meta-tags";
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
import OrderList from "./components/OrderList";
import OrderExportReport from "./components/OrderExportReport";
import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
  } from "reactstrap";
import StoreSelection from "./components/StoreSelection/StoreSelection.component";
import { includes } from "lodash";
import {EXPORT_ALLOWED_USER_ROLES} from "../../constants/index"; 
const Orders = () => {
  const  users = JSON.parse(localStorage?.getItem('authUser'));
  const title = "Orders";
  const description = "Manage Orders";
 // const countryAll = "All";

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mange Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Orders" breadcrumbItem="Manage Orders" />             
            </Col>
            <Col xl={6} className="text-end" style={{display: 'contents'}}>
              <StoreSelection onlyExport={EXPORT_ALLOWED_USER_ROLES.includes(users?.role_id)?'0':'1'} />
              {EXPORT_ALLOWED_USER_ROLES.includes(users?.role_id) ?( <OrderExportReport />):'' }
            </Col>
           
                      
          </Row>
          
            <OrderList  />
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Orders;
