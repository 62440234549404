import { useState, useEffect, button } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row,
  Input,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { DesignHomeDetailFormUpdate } from "../action/action";
import { AvForm, AvField } from "availity-reactstrap-validation";

import toast, { Toaster } from "react-hot-toast";

function ManageHomeLeadDetailsForm({ orderData, itemData }) {
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const [OrderStatus, setOrderStatus] = useState();
  const dispatch = useDispatch();
  
  const LsStatus = orderData?.status;
 
  let LsStsArr = [];
  if (LsStatus === "0") {
    LsStsArr = ["New", "Pending", "Planning", "Completed"];
  } else if (LsStatus === "1") {
    LsStsArr = ["New", "Pending", "Planning", "Completed"];
  } else if (LsStatus === "2") {
    LsStsArr = ["New", "Pending", "Planning", "Completed"];
  } else if (LsStatus === "3") {
    LsStsArr = ["New", "Pending", "Planning", "Completed"];
  }

  
  const handleValidSubmit = (events, values) => {
    setUpdateDetailsLoad(true);
    
    const comment = values?.comment ? values?.comment: "";
 
    const status = values?.status ? values?.status : "";
    const remarks = values?.remarks ? values?.remarks : "";
    const date = values?.date ? values?.date : "";

    DesignHomeDetailFormUpdate({
      params: {
           designhome_id:orderData?.designId,
           comments:comment,
           remarks:remarks,
           status:status,
           visiting_date:date
      },
      dispatch,
    });
  };

  const { designHomeUpdate = [], isUpdateLoading } = useSelector(
    (state) => state.bccw
  );


  useEffect(() => {
  
    if (designHomeUpdate?.code === 100) {
      setUpdateDetailsLoad(false);
      toast.success(designHomeUpdate?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
    } else {
      if (designHomeUpdate?.message) {
        toast.error(designHomeUpdate?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [designHomeUpdate]);
  return (
    <>
      {
        <Card className="Return UpdateDetailsForm">
    
          <CardBody>
          <AvForm
              className="needs-validation row gx-3 gy-2 align-items-center"
              onValidSubmit={(e, v) => {
                console.log(e, v);
                handleValidSubmit(e, v);
              }}
            >
            
              <Col xl={12} md={12}>
              <AvField
                  name="comment"
                  label="Customer Comments*"
                  value= {designHomeUpdate?.comments}
                  className="form-control"
                  type="textarea"
                  disabled
                />
              </Col>
          
              <Col xl={12} md={12}>
              <AvField
                  name="remarks"
                  label="Remarks"
                  value=""
                  className="form-control"
                  type="textarea"
                  required
                />
              </Col>
              <Col xl={12} md={12}>
                <AvField
                      type="select"
                      name="status"
                      label="Status*"
                      className="form-select"
                      onChange={(e) => setOrderStatus(e.target.value)}
                      required
                    >
                  {LsStsArr?.map((e, inx) => (
                    <option value={inx} key={inx}>{e}</option>
                  ))}
                       </AvField>
              </Col>
              {OrderStatus === "2" ? (
                <>
                  <Col xl={12} md={12}>
                  <AvField
                    name="date"
                    label="Visiting date"
                    type="date"                      
                    required
                                                                
                    />
                    
                  </Col>
                  
                </>
              ) : (
                <></>
              )}
              {OrderStatus === "3" ? (
                <>
                  <Col xl={12} md={12}>
                  <AvField
                    name="converted"
                    label="Is Converted?"
                    type="checkbox"                      
                    required
                                                                
                    />
                    
                  </Col>
                  
                </>
              ) : (
                <></>
              )}


              <Col xl={12} md={12}>
              <button type="submit" className="btn btn-primary">
                  {updateStsLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "}
                  Submit
                </button>
              </Col>
            </AvForm>
          </CardBody>
        </Card>
        
      }
         <Toaster position="top-right" reverseOrder={false} />
    </>
  );
  
}
export default ManageHomeLeadDetailsForm;