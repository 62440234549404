import axios from "axios";

import { setRefundList, getRefundDetail, getPassword, updateRefund,exportRefund} from "../../../Redux/refund/refundSlice";
import UserAuthKey from "../../../components/Common/UserAuthKey";
import { setUserData } from "../../../Redux/user/userSlice";

const apiUrl = process.env.REACT_APP_OMS_API;

export const fetchRefundList= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/allrefund-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setRefundList(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
    // this loader added because in case order not returned the success message
 
  
  }
};
export const fetchRefundDetail= async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/get-order-details`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getRefundDetail(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
  };

  export const checkPasswordDetail= async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/orders/check-password`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(getPassword(response?.data));
    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
  
     }
    }
   
  };
  export const fetchUpdateRefundDetail= async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/orders/update-refund`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(updateRefund(response?.data));
    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
  
     }
    }
  };

  export const fetchExportRefundDetail= async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/orders/export-refund`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(exportRefund(response?.data));
      window.location.href = response?.data?.data?.[0]?.report_link
    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
  
     }
    }
  
  };

