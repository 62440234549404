import { gql } from '@apollo/client';
export const GET_CATEGORIES_NAME_LIST= gql`
query GET_CATEGORIES_NAME_LIST{
  getCategoriesNameList {
    categoryNameData {
     _id
      name{en ar}
      slug
     
    }
  }

    }`;