import React, { useState, useEffect } from 'react';
import {
  Table,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Modal,
  Spinner,
} from 'reactstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Badge } from 'reactstrap';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { format, compareAsc } from 'date-fns';

function RefundDetails(refundData) {
  return (
    <CardBody>
      <Row className="mb-3">
        <Col>
          <h5>Refund Details</h5>
        </Col>
      </Row>
      <div className="table-responsive   dt-table ">
        <Table className=" table  mb-0 ">
          <thead>
            <tr className="text-center">
              <th>Created</th>

              <th>Refund #</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Reson</th>
              <th>Remarks</th>

              <th>Created By</th>
            </tr>
          </thead>

          <tbody>
            {refundData?.refundData.map((item, itemInx) => {
              let refundStatus = '';
              if (item.status == 'fully_refunded') {
                refundStatus = 'Fully Refunded';
              } else {
                refundStatus = 'Partially Refunded';
              }
              return (
                <>
                  <tr>
                    <td className="table-bordered text-center">
                      {format(new Date(item.Date), 'MMMM d, yyyy H:i:s')}
                    </td>

                    <td className="table-bordered text-center">
                      {item.refundId}
                    </td>

                    <td className="table-bordered text-center">
                      {item.RefundAmount}
                    </td>

                    <td className="table-bordered text-center">
                      {refundStatus}
                    </td>

                    <td className="table-bordered text-center">
                      {item.RefundReason}
                    </td>

                    <td className="table-bordered text-center">
                      {item.Remarks}
                    </td>

                    <td className="table-bordered text-center">{item.name}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>
      </div>
    </CardBody>
  );
}

export default RefundDetails;
