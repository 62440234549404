import ApolloClientWagonService from "../../../../ApolloClientWagonService";
import { OMS_PLACE_ORDER } from '../gql/checkout';
import guestUserManagement from "../../../../utils/Auth/guestUserManagement";
import { setCartDetails } from "../../../../Redux/order/cartSlice";
//import Cookies from 'utils/BrowserDatabase/Cookies';
import { LAST_ORDER_TOKEN } from 'constants';
import { getGAClientId } from '../../../../helpers/GetGAClientId';
import { ORDER_CONVERSION_TYPE, OMS_USER_ID } from 'constants';

/**
 * Place Order
 *
 * @param {*} param0
 * @param {*} dispatch
 */
export const PlaceOrder = async ({ additionalInfo = {} }, dispatch) => {
  // get cart token from cookies
  const guestCartToken = guestUserManagement.getGuestCartToken();

  // modify elements
  const modifiedAdditionalInfo = {
    ...additionalInfo,
    orderType: localStorage.getItem('orderType'),
    omsUserId: JSON.parse(localStorage?.getItem('authUser'))?.user_id,
  };

  //
  try {
    const { data } = await ApolloClientWagonService.mutate({
      mutation: OMS_PLACE_ORDER,
      variables: { cartToken: guestCartToken, additionalInfo: JSON.stringify(modifiedAdditionalInfo), GAclientId: await getGAClientId() },
      fetchPolicy: 'network-only',
    });
    const { placeOrder = {} } = data || {};
    const { token } = placeOrder;
    // reset cart if get the order reference from oms
    if (token) {
      guestUserManagement.deleteGuestCartToken();
    }
    return token || '';
  } catch (error) {
    //GraphQlErrorToast(error);
    //addWebLog(error);
  }
};


