import React, { useEffect, useState } from "react";
import axios from "axios";
import MetaTags from 'react-meta-tags';
import { Breadcrumbs,
  DashBreadcrumb, } from "../../components/Common/Breadcrumb";
import { CallBackDataUpdate,CallBackDataDetail  } from "./action/action";

import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, FormGroup, CardBody, CardTitle, CardSubtitle, Label, Button,  Spinner } from "reactstrap";
import { useParams } from 'react-router-dom';
import { AvForm, AvField } from "availity-reactstrap-validation";

  import toast, { Toaster } from "react-hot-toast";

const Callbackdetail=()=>{
    const dispatch = useDispatch();
    const [updatebulkLoad, setUpdateDetailbulkLoad] = useState(false);
    const { callbackDetail = [], isDetailLoading } = useSelector(
      (state) => state.callback
    );
  const callData = callbackDetail;
  const callDatas = callData?.OrderDetails?.[0];
  const CallStatus = callDatas?.[0]?.status;
  let CallStsArr = [];
  if (CallStatus === "0") {
    CallStsArr = ["Pending","Completed"];
  } else {
    CallStsArr = ["Completed"];
  } 
  const { id } = useParams();
  
    useEffect(() => {
       
      CallBackDataDetail({
          params: {
            callback_id:id
          },
          dispatch,
        })
       
      
      },[]);
      const handleBulkStatus = (events, values) => {
        setUpdateDetailbulkLoad(true);
        const callOrderRemark = values?.remarks ? values?.remarks : "";
        const status = values?.status ? values?.status : "";
        
        CallBackDataUpdate({
          params: {
            callback_id:id,
            remarks:callOrderRemark,
            status:status


            
          },
          dispatch,
        });
      };
      const { callbackUpdate = [], isUpdateLoading } = useSelector(
        (state) => state.callback
      );
      useEffect(() => {
        if (callbackUpdate?.code === 100) {
            setUpdateDetailbulkLoad(false);
          toast.success(callbackUpdate?.message, {
            style: {
              border: "1px solid #fff",
              padding: "20px",
              color: "#ffffff",
              background: "#4CAF50",
            },
            iconTheme: {
              primary: "#FFFAEE",
              secondary: "#4CAF50",
            },
          });
        } else {
          if (callbackUpdate?.message) {
            toast.error(callbackUpdate?.message, {
              style: {
                border: "1px solid #fff",
                padding: "20px",
                color: "#ffffff",
                background: "#f44336",
              },
              iconTheme: {
                primary: "#FFFAEE",
                secondary: "#f44336",
              },
            });
          }
        }
      }, [callbackUpdate]);

    return (
        <React.Fragment>

        <div className="page-content">
          
            <Container fluid >
            <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Callback" breadcrumbItem="Callback Detail" />
            </Col>
       
          </Row>
              
               
                <Row style={{display:"flex",
      flexDirection:"row",
     justifyContent : "center"
}}>
                    <Col lg={6}>
                        <Card>
                            <CardBody>
                              
                                <AvForm
                                 onValidSubmit={(e, v) => {
                                    handleBulkStatus(e, v);
                                  }}
                                >
                                    <div className="mb-3">
                                        <AvField
                                            name="username"
                                            label="User Name  "
                                            placeholder="Type Something"
                                            type="text"
                                            value= {callDatas?.[0]?.name}
                                            disabled
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="number"
                                            label="Mobile Number  "
                                            placeholder="Enter Only number"
                                            type="number"
                                            value={callDatas?.[0]?.mobile}
                                            disabled
                                          
                                        />
                                    </div>
                                    <div className="mb-3">
                                    <AvField
                                    name="requesttype"
                                    label="Request Type"
                                    value={callDatas?.[0]?.request_type}
                                    disabled
                                    className="form-control"
                                    type="textarea"
                                    
                                 
                                    />
                                    </div>
                                  
                                  
                                 
                                   
                                    <div className="mb-3">
                                        <AvField
                                            name="country"
                                            label="Country"
                                            placeholder="Enter the Country"
                                            type="text"
                                            value= {callDatas?.[0]?.country}
                                            disabled
                                          
                                        />
                                    </div>
                                    
                                    <div className="mb-3">
                                    <AvField
                                    name="remarks"
                                    label="Remarks"
                                    value={callDatas?.[0]?.remarks}
                                    className="form-control"
                                    type="textarea"
                                    required
                                   
                                    />
                                    </div>
                                    <div className="mb-3">
                                    <AvField
                      type="select"
                      name="status"
                      label="Status*"
                      className="form-select"
                    
                   
                    >
                  {CallStsArr?.map((item,inx) => {
                    return <option key={inx} value={item === 'Pending' ? '0' : '1'}>{item}</option>;
                  })}
                </AvField>
                </div>



                                    <div className="d-flex flex-wrap gap-2">
                                        <button type="submit" className="btn btn-success">
                                        {updatebulkLoad === true && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}{" "}
                                            Save
                                        </button>{" "}
                                        <button type="reset" className="btn btn-secondary">
                                            Cancel
                                        </button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
        <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
    );
    
  }
  
  export default Callbackdetail;