import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts"
import { useDispatch, useSelector } from "react-redux";
const SpineArea = ({splineGraph}) => {

    const series = [
      {
        name: "Amazon Order",
        data:  splineGraph?.AmazonOrders,
      },
      {
        name: "Noon Order",
        data: splineGraph?.NoonOrders,
      },
      {
        name: "Carrefour Order",
        data:  splineGraph?.CarrefourOrders,
      },
      {
        name: "Dubai Order",
        data: splineGraph?.DubaistoreOrders,
      },
      {
          name: "Ace Order",
          data: splineGraph?.AceOrders,
        },
        {
            name: "Flitit Orders",
            data: splineGraph?.FlititOrders,
          },
    ]
  
    const options = {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
  
      colors: ["#000000", "#34c38f","#005bc1","#f47f7f","#800080","#FFFF00"],
      xaxis: {
        type: "date",

        categories: splineGraph?.date
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd-MM-yy",
        },
      },
    }
  
    return (
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height="350"
      />
    )

  
  
}

export default SpineArea