import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { KB_URL ,AUTH_TOKEN} from './constants';
const token = process.env.REACT_APP_KB_SERVICE_KEY;
const endpoint = process.env.REACT_APP_KB_SERVICE_API;
const httpLink = createHttpLink({
  uri: endpoint,
});
const authLink = setContext((_, { headers }) => {

  // get the authentication token from local storage if it exists

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
  
    },
  };
});

const ApolloClientKnowledgeBaseService = new ApolloClient({
  // ssrMode: true,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
export default ApolloClientKnowledgeBaseService;
