import React from "react";
import OrderNotebyAdmin from "../Orders/components/OrderNotebyAdmin";
import ProjectTrackerDetailForm from "./components/ProjectTrackingDetailForm";

import moment from "moment";
import ProjectDetailsBlock from "./components/CustomerTrackingDetailBlock";

import { useState, useEffect, button } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
} from "reactstrap";

function CustomerTrackingDetails({ orderData }) {

  return (
    <>
      <Card className="card-dh card-h-100">
        <CardBody className="card-body-dh">
        
          <Row>
            <Col xl={9} md={7}>
              <ProjectDetailsBlock orderData={orderData} />

            </Col>
          
            <Col xl={3} md={5}>
              <ProjectTrackerDetailForm orderData={orderData} />
            
            </Col>
          </Row>

          <Row>
            <Col xl={9} md={7}>
              <OrderNotebyAdmin  orderData={orderData}/>
            </Col>
            <Col xl={3} md={5}></Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default CustomerTrackingDetails;
