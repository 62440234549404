import React, { useState, useRef, useEffect,forwardRef, useImperativeHandle,  } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import { useMutation, gql } from "@apollo/client";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { GET_WISHLIST_ADMIN } from "./query/AddWishlist";



const AddWishListDetail = forwardRef((props,ref) => {
  const [isDataLoading, setDataLoading] = useState(false);
 
    const [addWishlistFunction,{errors , data ,isLoading}] = useLazyQuery(GET_WISHLIST_ADMIN);
    const dispatch = useDispatch();
    const { id } = useParams();

    const [isGetWishlist,setIsGetWishlist]=useState(false);


      const editValue = data?.getWishlistAdmin;

  const getWishlistDataAdminFunc=()=>{
    try{
      setDataLoading(true)
      addWishlistFunction({
        variables: {
          userId: id,
        },
        dispatch,
      })}catch{
  
      }
  }

  useEffect(()=>{
    if(isGetWishlist){
      getWishlistDataAdminFunc();
      if(editValue?.products?.length>0){
        setDataLoading(false)
        setIsGetWishlist(false)
      }
      else if(editValue?.products?.length === 0){
        setDataLoading(false)
        setIsGetWishlist(false)
      }
   
    
    
    }
  
  },[isGetWishlist,editValue])
 
  

  useImperativeHandle(ref, () => ({
    log() {
     setIsGetWishlist(true)
    }
  }));

  
    return (
        <>
         <Row>
    <h4>Items in Card</h4>
    {isLoading || isDataLoading ? (
              <Spinner className="ms-2 sploader" color="primary" />
            ) : null}
    {editValue?.products?.length > 0? (<>
        {editValue?.products?.map((products) => {
         
            return(
             <Col xs={4}>
             <Card>
               <CardBody>
                 <div className="ProductDetails" nowrap="nowrap">
                   <span>
                     <img
                       alt=""
                       width="100"
                       height="100"
                       className="img-thumbnail1"
                       src={products?.media_gallery?.[0]?.value}
                     />
                   </span>
                   <p>
                 {products?.name}
                     <br />
                     Sku : {products?._id}
                     <br />
                     AED <b>{products?.selectedPrice?.currency} {products?.selectedPrice?.specialPrice}</b> <del>{products?.defaultPrice?.currency} {products?.defaultPrice?. specialPrice}</del>
                     <br />
                   </p>
                 </div>
               </CardBody>
             </Card>
           </Col>
            )
            })} 
            </>):(<>
            <div>No Record Found</div>
            </>)}
   
   
    
  </Row>
  </>
    ); 
})
export default AddWishListDetail;