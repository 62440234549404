import axios from "axios";
import { getPhotoShootLists, getPhotoShootDetails, getPhotoShootUpdate } from "../../../Redux/photoshoot/photoshootSlice";


const apiUrl = process.env.REACT_APP_OMS_API;

export const fetchPhotoShootList= ({ params, dispatch }) => {
  return axios
    .post(`${apiUrl}v1/orders/allphotoshoot-list`, { ...params })
    .then((response) => {
      dispatch(getPhotoShootLists(response?.data));

    })
    .catch((error) => {
      throw error;
    });
};
export const fetchPhotoShootDetail= ({ params, dispatch }) => {
  return axios
    .post(`${apiUrl}v1/orders/photoshoot-details`, { ...params })
    .then((response) => {
      dispatch(getPhotoShootDetails(response?.data));

    })
    .catch((error) => {
      throw error;
    });
};
export const PhotoShootUpdateDetail= ({ params, dispatch }) => {
  return axios
    .post(`${apiUrl}v1/orders/update-photoshoot`, { ...params })
    .then((response) => {
      dispatch(getPhotoShootUpdate(response?.data));

    })
    .catch((error) => {
      throw error;
    });
};
