import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlSearchParams } from 'use-url-search-params';
import { Card, Button, OverlayTrigger, Form, Tooltip } from 'react-bootstrap';
import { Badge } from 'reactstrap';
import CsLineIcons from '../../../cs-line-icons/CsLineIcons';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import { fetchRefundList } from '../action/action';
import { SORTING_ORDER } from '../../../constants/index';
import RefundExportReport from './ManageRefundExport';
import moment from 'moment';

import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  NavLink,
  Nav,
  NavItem,
} from 'reactstrap';
//import {useIntl} from 'react-intl';
import CountUp from 'react-countup';

const RefundOrderList = () => {
  const location = useLocation();

  const [btnprimary1, setBtnprimary1] = useState(false);

  const dispatch = useDispatch();
  //const { formatMessage: f } = useIntl();
  //const [countryCode, setCountryCode] = useUrlSearchParams({ country: "" });

  const [sortingName, setSortingName] = useState({ id: 'sort.date_asc' });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: '_date',
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: '' });
  const [customActiveTab, setcustomActiveTab] = useState('All');
  const [orderMode, setOrderMode] = useState('All');
  let is_split = 0;
  const { country = [] } = useSelector((state) => state.country);

  let currentCountry = localStorage.getItem('WEBSITE_COUNTRY');

  const splitVal = location?.pathname.split('/');

  useEffect(() => {
    setDataLoading(true);
    fetchRefundList({
      params: {
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
        search_qry: searchValue.search,
        status: customActiveTab,
        orderBy: 'id',
        country: currentCountry,
      },
      dispatch,
    });
    setDataLoading(false);
  }, [
    paginationValue.page,
    limitValue,
    sortingValue.sort,
    searchValue.search,
    customActiveTab,
    orderMode,
  ]);

  const paymentLabel = {
    payfort_fort_cc: 'Payfort',
    payfort: 'Payfort',
    payfort_fort_installments: 'Payfort',
    apple_pay: 'Payfort',
    payfort_omannet: 'Payfort',
    checkout: 'Checkout',
    tap: 'Tap',
    tap_apple_pay: 'Tap',
    tap_careem_pay: 'Tap',
    tap_knet: 'Tap KNET',
    cashew: 'Cashew',
    tamara: 'Tamara',
    spotii: 'Spotii',
    postpay: 'Postpay',
    tabby: 'Tabby',
    Wallet: 'Wallet',
  };

  const limitByValues = [5, 10, 20, 50];
  const { refundList = [], isLoading } = useSelector((state) => state.refund);

  const [isDataLoading, setDataLoading] = useState(false);

  const { listOrders } = refundList;
  const orderData = listOrders?.orderList;

  let LsSts = 'New';

  const sortByValues = [
    { key: 'date', order: SORTING_ORDER.ASC, label: { id: 'sort.date_asc' } },
    { key: 'date', order: SORTING_ORDER.DESC, label: { id: 'sort.date_desc' } },
    {
      key: 'status',
      order: SORTING_ORDER.ASC,
      label: { id: 'sort.status_asc' },
    },
    {
      key: 'status',
      order: SORTING_ORDER.DESC,
      label: { id: 'sort.status_desc' },
    },
  ];

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  let UserRoleID = '';
  let UserCountry = '';

  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      UserRoleID = userObj?.role_id;
      UserCountry = userObj?.country;
    }
  }

  let pageIndex = Number(listOrders?.start);

  return (
    <>
      <Row>
        <Col>
          <RefundExportReport />
        </Col>
        <Col xl={2} md={6} className="mb-3 ">
          <div className="searchBox">
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 bg-foreground">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <span className="search-magnifier-icon searchIcon">
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </div>
        </Col>
      </Row>

      <>
        {' '}
        <div className=" dt-table">
          {isLoading || isDataLoading ? (
            <Spinner className="ms-2 sploader" color="primary" />
          ) : null}
          {orderData?.length > 0 ? (
            <>
              <div className="table-responsive">
                <Table className="OrderListTable table mb-0">
                  <thead>
                    <tr>
                      <th>SI.No</th>
                      <th nowrap="nowrap">Date</th>
                      <th nowrap="nowrap">Reference No</th>
                      <th nowrap="nowrap">Payment Mode</th>
                      <th nowrap="nowrap">Refund Amount</th>
                      <th nowrap="nowrap">Country</th>
                      <th>Status</th>
                      <th nowrap="nowrap">Refund Reason</th>
                      <th nowrap="nowrap">Refund Remarks</th>
                      <th nowrap="nowrap">Created By</th>
                    </tr>
                  </thead>
                  {(orderData || []).map((order, index) => {
                    pageIndex++;
                    let refundStatus = '';
                    if (order.status === 'fully_refunded') {
                      refundStatus = 'Full Refund';
                    } else {
                      refundStatus = 'Partial Refund';
                    }
                    let cdate = order.Date,
                      orderDt = moment(new Date(cdate)).format('Do MMM, YYYY'),
                      orderTime = moment(new Date(cdate)).format('h:mm:ss a');
                    return (
                      <tbody key={index}>
                        <tr>
                          <th scope="row">{pageIndex}</th>
                          <td>
                            {orderDt} <br></br>
                            {orderTime}
                          </td>
                          <td className="ReturnInfo1">
                            {order.OrderId}
                            <span className="highlightInfo">
                              Refund Id : {order?.refundId}
                            </span>
                          </td>
                          <td>{paymentLabel[order?.paymentMode]}</td>
                          <td>
                            {order?.currency} {order?.RefundAmount}
                          </td>
                          <td>{order?.country}</td>
                          <td>{refundStatus}</td>
                          <td>{order?.RefundReason}</td>
                          <td className="ReturnInfo1">
                            <span className="highlightPara">
                              {order?.Remarks}
                            </span>
                          </td>
                          <td>{order?.name}</td>
                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
              </div>
              <div className="dataTblFooter">
                <div>
                  <Dropdown
                    isOpen={btnprimary1}
                    toggle={() => setBtnprimary1(!btnprimary1)}
                  >
                    <DropdownToggle tag="button" className="btn btn-primary">
                      {limitValue.limit} Items{' '}
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {limitByValues.map((limit, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            href="#"
                            onClick={() => handleLimit(limit)}
                          >
                            {limit} Items
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>

                <div className="d-flex justify-content-center">
                  <Pagination
                    id="listPage"
                    value={paginationValue.page}
                    totalPages={listOrders?.recordsTotal}
                    onChange={(e) => handlePagination(e)}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <Table className="OrderListTable table mb-0">
                <thead>
                  <tr>
                    <th>SI.No</th>
                    <th>Date</th>
                    <th>Reference No</th>
                    <th>Refund Amount</th>
                    <th>Status</th>
                    <th>Refund Reason</th>
                    <th>Refund Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={7}>No Records found</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </div>
      </>
    </>
  );
};

export default RefundOrderList;
