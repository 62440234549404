import { gql } from '@apollo/client';
export const GET_DEPARTMENT_NAME_LIST= gql`
query GET_DEPARTMENT_NAME_LIST(
  $input:DepartmentsListInput!
){
    getDepartmentsList(input: $input){

        departmentsData {
      _id
      name
      slug
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }

    }`;