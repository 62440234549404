import React from "react";
import { Table, Card, CardHeader, CardTitle, CardBody } from "reactstrap";

function ReturnNotebyAdmin({ orderData }) {
  const AdminRemarksDetails = orderData?.Remarks;
  
  
if (AdminRemarksDetails?.[0]?.length > 0){
   
  
  return (
    <>
      <Card className="OrderNotebyAdmin">
        <CardHeader>
          <CardTitle>ORDER NOTES BY ADMIN:</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="table-responsive dt-table">
            <Table className=" table mb-0">
              <thead>
                <tr>
                  <th className="text-center">Updated Date</th>
                  <th className="text-center">Updated By</th>
                  <th className="text-center">Return Status</th>
                  <th className="text-center">Payment Mode</th>
                  <th className="text-center">Remarks</th>
                </tr>
              </thead>
              <tbody>
              {AdminRemarksDetails?.[0]?.map((remark,inRem) => {
                  return ( <tr key={inRem} className="table-bordered text-center align-middle">
                     <td>{remark?.UpdatedAt}</td>
                     <td>{remark?.UpdatedBy}</td>
                    <td>
                      <p>
                        <span className="status">{remark?.AdminStatus}</span>
                      </p>
                    </td>
                        <td>{remark?.ApprovedPaymentMode}</td>
                        <td style={{ maxWidth: 200 }}>{remark?.AdminComments}</td>
                                    </tr> )
              })}
             
             
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
else{
  return(<></>)
}
}
export default ReturnNotebyAdmin;
