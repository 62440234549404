import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  refundList: [],
  isLoading: true,
  isPasswordLoading:true,
  isupdaterfeundLoading:true

 
};

const refundSlice = createSlice({
  name: "Refund",
  initialState,
  reducers: {
    setRefundList(state, actions) {
      state.refundList = actions.payload;
      state.isLoading = false;
    },
    getRefundDetail(state, actions) {
        state.refundDetail = actions.payload;
        state.isRefundLoading = false;
      },
    getPassword(state, actions) {
        state.passwordDetail = actions.payload;
        state.isPasswordLoading = false;
      },
    updateRefund(state, actions) {
        state.updateRefundDetail = actions.payload;
        state.isupdaterfeundLoading = false;
      },
      
      exportRefund(state, actions) {
        state.exportRefundDetail = actions.payload;
        state.isExportLoading = false;
      },
       

   
    
    


  },
});

export const { setRefundList, getRefundDetail, getPassword, updateRefund,exportRefund }= refundSlice.actions;

const refundReducer = refundSlice.reducer;

export default refundReducer;
