import React, { useEffect, useState } from 'react';
import "../../Orders/components/OrderShipmentProgress.scss";

const StatusBar = ({ detail, orderValue }) => {
  let itemMode = 1;
  if (orderValue.reference_no && orderValue.reference_no.startsWith("BB-")) {
    itemMode = 2;
  }

  const refundStatuses = ["0", "3", "21", "2", "5", "6", "1"];
  const walletStatuses = ["0", "3", "21", "2", "9", "10", "1"];
  const cashStatuses = ["0", "3", "21", "1"];

  const getAllowedStatuses = (resolutionType) => {
    let statuses = [];
    switch (resolutionType) {
      case 'refund':
        statuses = itemMode === 2 
          ? ["0",  "7", "8", "17","5",  "3", "21", "2", "6", "1"]
          : [...refundStatuses];
        break;
      case 'wallet':
        statuses = itemMode === 2 
          ? ["0",  "7", "8", "17", "10", "3", "21", "2", "1"]
          : [...walletStatuses];
        break;
      case 'cashondelivery':
        statuses = [...cashStatuses];
        break;
      default:
        statuses = [];
    }
    return statuses;
  };

  const allowedStatus = getAllowedStatuses(orderValue.resolution_type);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    const statusList = detail.map((dt) => dt.status);
    setStatuses(statusList);
  }, [detail]);

  return (
    <div className="trackStatus">
      {allowedStatus.map((status, index) => {
        const isActive = statuses.includes(String(status));
        const createdDate = detail.find(dt => dt.status === status)?.created_at;

        // If itemMode is 2 and "LS Canceled" has been reached, skip rendering "Wallet Initiated" and "Wallet Completed" until "Seller Canceled" is reached
        const lsCanceledIndex = statuses.indexOf("2");
        const sellerCanceledIndex = statuses.indexOf("17");
        if (itemMode === 2 && lsCanceledIndex !== -1 && index > lsCanceledIndex && (sellerCanceledIndex === -1 || index <= sellerCanceledIndex)) {
          if (["9", "10"].includes(status)) {
            return null; // Skip rendering these statuses
          }
        }

        return (
          <div className="trackView" key={status}>
            <div className="trackingIcon">
              <span className={isActive ? "Active" : ""}>
                {isActive ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.004" height="12.549" viewBox="0 0 15.004 12.549">
                    <path
                      id="Path_663"
                      data-name="Path 663"
                      d="M13.639.75,4.433,11.491.75,7.195"
                      transform="translate(0.308 0.308)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.004" height="12.549" viewBox="0 0 15.004">
                    <rect width="15.004" height="12.549" fill="none" />
                  </svg>
                )}
              </span>
            </div>
            <div className="trackingStatus">
              <p>
                <strong>
                  {status === "0" && 'Received Cancellation Request'}
                  {status === "1" && 'Cancelation Completed'}
                  {status === "2" && 'LS Cancellation Completed'}
                  {status === "3" && 'LS Cancellation Request Started'}
                  {status === "21" && 'LS/LPO/AWB Cancellation Initialized'}
                  {status === "7" && 'Back To Back Cancellation Process Started'}
                  {status === "8" && 'LPO/AWB Cancellation Initialized'}
                  {status === "17" && 'LPO/AWB Cancellation Completed'}
                 
                  {status === "5" && orderValue.resolution_type === 'refund' && (
                  <>
                    Refund Initiated
                  </>
                  )}                 
                  {status === "6" && orderValue.resolution_type === 'refund' && 'Cash refunded'}
                  {status === "9" && orderValue.resolution_type === 'wallet' && 'Wallet Initiated'}
                  {status === "10" && orderValue.resolution_type === 'wallet' && 'Cash credited to Wallet'}
                </strong>
              </p>
              {isActive && (
                <span>
                  <em>Created on: {createdDate}</em>
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StatusBar;
