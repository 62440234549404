import { gql } from '@apollo/client';
export const GET_DEP_DATA_DETAIL= gql`
query GET_DEP_DATA_DETAIL(
  $input:dataReqInput!
)
{
    getDepartmentsDetails(input: $input) {
    _id
    name
    slug
    icon
    host
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
    }`;