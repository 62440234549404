import { gql } from '@apollo/client';
export const UPDATE_ARTICLES= gql`
mutation(
   $input:ArticlesUpdateInput!
  ){
    updateArticlesDetails(input: $input)  {
    _id
    title {
      en
      ar
    }
    slug
    icon
    categoryId
    categoryName
    articleType
    content {
      en
      ar
    }
    shortDescription {
      en
      ar
    }
    metaTitle {
      en
      ar
    }
    metaDescription {
      en
      ar
    }
    metaKeywords {
      en
      ar
    }
    status
    createdBy
    createdAt
    updatedAt
    position
  }
  }`;
