import React from "react";
import axios from "axios";
// import OrderProgressbar from "../OrderProgressbar";
import ManageRiverHillOrderBlock from "./components/ManageRiverHillOrderBlock";
import RiverOrderItemDetails from "./components/ManageriverHillItemDetail";
import OrderRiverHillInvoice from "./components/ManageRiverHillInvoiceDetail";
import ManageHomeLeadBlock from "./components/ManageHomeLeadBlock";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, button } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
} from "reactstrap";

function HomeOrderDetails({ orderData, itemData }) {
  return (
    <>
      <Card className="card-dh card-h-100">
        <CardBody className="card-body-dh">
          
          <Row>
            <Col xl={9} md={7}>
              <ManageHomeLeadBlock orderData={orderData} />

          
            </Col>
         
            
          </Row>

        

        </CardBody>
      </Card>
    </>
  );
}

export default HomeOrderDetails;
