import { gql } from '@apollo/client';
export const GET_DEPARTMENT_DATA_LIST= gql`
query GET_DEPARTMENT_DATA_LIST(
  $input:DepartmentAllInput!
){
getAllDepartmentsList(input:$input) {
    departmentsData {
      _id
      name
      slug
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    totalRecord
    }}`;