import React from "react";

import { MetaTags } from "react-meta-tags";
import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../components/Common/Breadcrumb";
//../../../components/Common/Breadcrumb
import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
  } from "reactstrap";

import ListReturnComponent from "./components/StoreSelection/ListReturn.component";
const ManageQueueReturn = () => {
  const title = "Queue Return List";
  const description = "Queue Return List";
  return (
    <React.Fragment> 
      <div className="page-content">
        <MetaTags>
          <title>Return Request</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Queue List" breadcrumbItem="Manage Return Request" />
            </Col>
          </Row>
         <ListReturnComponent></ListReturnComponent>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default ManageQueueReturn;
