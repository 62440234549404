import { useState, useEffect, button, useSearchParams } from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../components/Common/Breadcrumb";
import { DesignHomeDetail } from "../bccwOrders/action/action";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";
import OrderDetails from "./components/orderDetails";
import "react-datepicker/dist/react-datepicker.css";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import HomeOrderDetails from "../bccwOrders/DesignYourHomeLeadDetail";
import Flatpickr from "react-flatpickr";
import WalletStatus from "./components/WalletStatus";
import {
  Col,
  Container,
  Row,
  FormGroup,
  InputGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { Link } from "react-router-dom";

const HomeLeadDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_mode = params.get("order_mode");
  
  useEffect(() => {
    DesignHomeDetail({
      params: {
        
        designhome_id: id,
       
      },
      dispatch,
      
    });
  }, []);
  const { designHomeDetail = [], isHomeDesignLoading, } = useSelector(
    (state) => state.bccw
  );


  const orderData = designHomeDetail?.OrderDetails?.[0]?.[0];
  const itemData = designHomeDetail.OrderDetails?.[0]?.orderitems;
 
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Mange Order View</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Manage Orders"
                breadcrumbItem="Order View"
              />
            </Col>
            <Col xl={6}>
              <Row className="align-items-end">
                <Col xl={12} className="textAlignRigh">
                
                </Col>
              </Row>
            </Col>
          </Row>
          <HomeOrderDetails orderData={orderData} itemData={itemData} />
        </Container>
      </div>
    </>
  );
};

export default HomeLeadDetails;
