import React, { useState } from 'react';
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
    Breadcrumbs,
    DashBreadcrumb,
  } from "../../components/Common/Breadcrumb";
//import OrderList from './components/orderList';

import "react-datepicker/dist/react-datepicker.css";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import AllCustomersList from './AllCustomerList';
import Flatpickr from "react-flatpickr"

import {
    Col,
    Container,
    Row,
    FormGroup, 
    InputGroup, 
    Button
  } from "reactstrap";
  import { useHistory, useLocation } from "react-router-dom";

const AllCustomer = () => {
  const [showRoleForm, setshowRoleForm] = useState(false);    
  const handleRoleForm = () => {
        
        setshowRoleForm(true)
      }
  const title = "Roles";
  const description = "Role Details";

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Customers</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col>
              <DashBreadcrumb title="Customers Detail" breadcrumbItem="Customers" />
            </Col>
            <Col className="customerButton">
            <a
                              href={`/AddCustomerForm`}
                              type="button"
                              className="btn btn-primary"
                            >
                                Add New Customers
                            </a>
             </Col>
           
          </Row>
        
    
       
          
          
          <AllCustomersList/>
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllCustomer;
