import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useUrlSearchParams } from "use-url-search-params";
import { Form } from "react-bootstrap";
import { Badge } from "reactstrap";
import CsLineIcons from "../../../cs-line-icons/CsLineIcons";
import { Link } from "react-router-dom";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import { SORTING_CATEGORY_LIST } from "../../../constants";
import ApolloClientKnowledgeBaseService from "../../../ApolloClientKBService";
import moment from "moment";
import { GET_CATEGORIES_DATA_LIST } from "../../Customers/query/GetCategoryList";

import {
  Table,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
} from "reactstrap";
const KBCategoryList = () => {
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [sortingName, setSortingName] = useState({ id: "sort.date_asc" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({
    page: "0",
  });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: "_date",
    customer: SORTING_CATEGORY_LIST.ASC,
  });
  const [isDataLoading, setDataLoading] = useState(false);
  const [kbCatData, setKbCatData] = useState("");
  const [searchTrigger, setSearchTrigger] = useState("");
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
  const [customActiveTab, setcustomActiveTab] = useState("All");
  const limitByValues = [5, 10, 20, 50];
  const sortByValues = [
    {
      key: "date",
      customer: SORTING_CATEGORY_LIST.ASC,
      label: { id: "sort.date_asc" },
    },
    {
      key: "date",
      customer: SORTING_CATEGORY_LIST.DESC,
      label: { id: "sort.date_desc" },
    },
    {
      key: "status",
      customer: SORTING_CATEGORY_LIST.ASC,
      label: { id: "sort.status_asc" },
    },
    {
      key: "status",
      customer: SORTING_CATEGORY_LIST.DESC,
      label: { id: "sort.status_desc" },
    },
  ];

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const handleSorting = (type, customer = SORTING_CATEGORY_LIST.ASC, name) => {
    setSortingValue({ sort: type, customer });
    setSortingName(name);
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };
  const handleTriggerSearch = (search_key) => {
    setSearchTrigger(search_key);
  };
  const kbCategoryData = async () => {
    try {
      setDataLoading(true);
      const { data = {} } = await ApolloClientKnowledgeBaseService.query({
        query: GET_CATEGORIES_DATA_LIST,
        variables: {
          input: {
            statusType: "all",
            args: {
              searchKey: searchTrigger,
              page: paginationValue?.page,
              limit: Number(limitValue?.limit),
              sortBy: "",
              sortDir: "desc",
            },
          },
        },
        fetchPolicy: "no-cache",
      });
      setKbCatData(data);
    } catch (error) {
      // GraphQlErrorToast(error);
    }
  };
  const kbDataList = kbCatData?.getAllCategoriesList?.categoriesData;
  useEffect(() => {
    kbCategoryData();
    if (kbDataList?.length > 0) {
      setDataLoading(false);
    } else if (kbDataList?.length === 0) {
      setDataLoading(false);
    }
  }, [
    paginationValue?.page,
    limitValue?.limit,
    searchTrigger,
    kbDataList?.length,
  ]);

  let userName = "";
  if (localStorage.getItem("authUser")) {
    const userObj = JSON.parse(localStorage.getItem("authUser"));
    if (userObj?.role_id !== undefined) {
      userName = userObj?.user_name;
    }
  }

  let pageIndex = Number(paginationValue.page * limitValue.limit);

  return (
    <>
      <Row className="align-items-end">
        <Col xl={2} md={6} className="mb-3 textAlignRight">
          <div className="searchBox">
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 bg-foreground">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <span
                className="search-magnifier-icon searchIcon"
                onClick={() => {
                  handleTriggerSearch(searchValue.search);
                }}
              >
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </div>
        </Col>
      </Row>

      <div className=" dt-table">
        {isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}

        {kbDataList?.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="OrderListTable table mb-0">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Category Name</th>
                    <th>Created at</th>
                    <th>Last Updated by</th>
                    <th>No.of Articles</th>
                    <th>Updated at</th>
                    <th>Status</th>

                    <th>Action</th>
                  </tr>
                </thead>

                {(kbDataList || []).map((cat, index) => {
                  let status = "";
                  let stsBtn = "";
                  if (cat?.status === 1) {
                    stsBtn = "bg-success";
                    status = "Published";
                  } else if (cat?.status === 2) {
                    stsBtn = "bg-warning";
                    status = "Draft";
                  } else {
                    stsBtn = "bg-primary";
                    status = "Unpublished";
                  }
                  pageIndex++;

                  let date = new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }).format(cat?.createdAt);

                  let cdate = date,
                    orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                    orderTime = moment(new Date(cdate)).format("h:mm:ss a");
                  let updatedDate = new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }).format(cat?.updatedAt);

                  let udate = updatedDate,
                    updatedDt = moment(new Date(udate)).format("Do MMM, YYYY"),
                    updatedTime = moment(new Date(udate)).format("h:mm:ss a");

                  return (
                    <tbody key={index}>
                      <tr>
                        <th scope="row">{pageIndex}</th>
                        <td nowrap="nowrap">{cat?.name?.en}</td>
                        <td nowrap="nowrap">
                          {orderDt}
                          <br />
                          {orderTime}
                        </td>
                        <td>{cat?.updatedBy}</td>
                        <td>{cat?.noOfArticles !== null ? cat?.noOfArticles : 0}</td>
                        <td nowrap="nowrap">
                          {updatedDt}
                          <br />
                          {updatedTime}
                        </td>
                        <td>
                          {" "}
                          <Badge className={` ${stsBtn} me-2`}>{status}</Badge>
                        </td>

                        <td>
                          {" "}
                          <Link
                            to={`/KbUpdateCategoryForm/${cat?._id}`}
                            type="button"
                            className="btn btn-sm btn-outline-warning waves-effect waves-light mr-2"
                          >
                            {" "}
                            <i className="mdi mdi-eye-outline"></i>{" "}
                          </Link>
                          <Link
                            to={`/Articles/?cat=${cat?._id}`}
                            type="button"
                            className="btn btn-sm btn-outline-secondary waves-effect waves-light"
                          >
                            {" "}
                         Articles
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </Table>
            </div>
            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={Math.ceil(
                    kbCatData?.getAllCategoriesList?.totalRecord /
                      limitValue.limit
                  )}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Category Name</th>
                  <th>Created at</th>
                  <th>Last Updated by</th>
                  <th>No.of Articles</th>
                  <th>Status</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
};

export default KBCategoryList;
