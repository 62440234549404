import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  reviewList: [],
  isLoading: true,
 
 
};

const reviewSlice = createSlice({
  name: "Review",
  initialState,
  reducers: {
    getReviewList(state, actions) {
      state.reviewList = actions.payload;
      state.isDataLoading = false;
    },
    setDeliveryFeedBackExport(state, actions) {
      state.deliveryFeedBackExport = actions.payload;
      state.isExportLoading = false;
    },
    setDeliveryReview(state, actions) {
      state.deliveryReview = actions.payload;
      state.isReviewLoading = false;
    },
   
   

  },
});

export const { getReviewList, setDeliveryFeedBackExport,setDeliveryReview }= reviewSlice.actions;

const reviewReducer = reviewSlice.reducer;

export default reviewReducer;
