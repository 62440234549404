import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../components/Common/Breadcrumb";
import { Col, Container, Row, FormGroup, InputGroup } from "reactstrap";
import ProductsFeedBackList from "./components/ProductsFeedBackList";
import ProductFeedbackExportReport from "./components/ProductsFeedBackExport"
const ProductsFeedback = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Products Feedback View</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col >
              <DashBreadcrumb
                title="Products Feedback"
                breadcrumbItem="Products Feedback View"
              />
            </Col>
            <Col className="productExport mb-2">
            <ProductFeedbackExportReport />
            </Col>
          </Row>

          <ProductsFeedBackList />
        </Container>
      </div>
    </React.Fragment>
  );
};
export default ProductsFeedback;
