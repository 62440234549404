import './DeliverySlotSelection.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  calculateShipments,
  setShipmentTimeAction,
} from '../../actions/action';
import moment from 'moment';
import Lottie from 'lottie-react';
import loading from '../../../../../assets/lottie/loading.json';

/**
 *
 * @param {*} props
 * @returns
 */
const DeliverySlotSelection = (props) => {
  const dispatch = useDispatch();
  const { cart = {}, setShowDeliverySlots, showDeliverySlots } = props || {};

  //  calculate shipment
  useEffect(() => {
    setIsLoading(true);
    if (
      cart?.shippingAddress?.latitude ||
      cart?.shipment?.items?.length === 0
    ) {
      calculateShipments(dispatch);
    }

    setIsLoading(false);
  }, [cart?.grandTotal, cart?.shippingAddress?.latitude]);

  const [isLoadingTime, setIsLoadingTime] = useState(null);
  const [availableShipments, setAvailableShipments] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedTime, setSelectedTime] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // identify the unselected shipments
  const missingShipments = availableShipments
    ?.filter((as) => as?.showCalendar && !as.selected?.timeId)
    .map((as) => as?.shipmentId);
  const shipmentHasNoCalendar = availableShipments?.every(
    (as) => as?.showCalendar === false
  );

  // reselect already exist date and time
  let setSelectedTimesReload = {};
  let setSelectedDatesReload = {};
  useEffect(() => {
    availableShipments
      .filter((as) => as?.showCalendar && as.selected?.timeId)
      .map((ss) => {
        const selectedDateId = ss?.selected?.dateId || null;
        const selectedShipmentId = ss?.shipmentId || null;
        const selectedDateInfo = ss?.dates?.find(
          (d) => d?.dateId === selectedDateId
        );

        if (selectedDateInfo?.times) {
          // set date pre selected
          setSelectedDatesReload[selectedShipmentId] = {
            dateId: selectedDateId,
            times: selectedDateInfo?.times,
          };
          setSelectedDate(setSelectedDatesReload);

          // set time pre selected
          setSelectedTimesReload[selectedShipmentId] = {
            ...ss?.selected,
          };
          setSelectedTime(setSelectedTimesReload);
        }
      });
  }, [availableShipments]);

  // fetch all available shipments
  useEffect(() => {
    setSelectedDate({});
    setSelectedTime({});
    const { items = [] } = cart.shipment || [];
    setAvailableShipments(items);
  }, [cart?.grandTotal, cart?.shipment?.items]);

  /**
   *
   * @param {*} shipment
   * @param {*} slots
   */
  const selectedShipmentDate = (shipmentId, dateId, times) => {
    delete selectedTime[shipmentId]; //
    setSelectedDate((prevSelectedDate) => ({
      ...prevSelectedDate,
      [shipmentId]: { times, dateId },
    }));
  };
  /**
   *
   * @param {*} shipmentId
   * @param {*} timeId
   */
  const setShipmentTime = async (shipmentId, timeId) => {
    setIsLoadingTime(timeId);
    await setShipmentTimeAction({ shipmentId, timeId }, dispatch);
    setIsLoadingTime(null);
  };

  /**
   *
   * @param {*} shipmentId
   * @param {*} timeSlot
   */
  const selectedShipmentTime = (shipmentId, timeSlot) => {
    setSelectedTime((prevSelectedTime) => ({
      ...prevSelectedTime,
      [shipmentId]: { ...timeSlot },
    }));
    setShipmentTime(shipmentId, timeSlot?.timeId);
  };

  const componentData = {};
  // do your translations here
  const {
    chooseDeliveryDateAndTime = 'Choose Delivery Date & Time',
    chooseDeliveryDate = 'Choose a delivery date',
    plzSelectADay = 'Please select a convenient delivery day',
    plzSelectATime = 'Please select a convenient delivery time',
    shipmentOf = 'Shipment {number} of {total}',
    pickATime = 'Pick a convenient time',
    pickADeliveryTime = 'please select a convenient delivery time',
    skipThis = ' I want skip this section',
    continueThis = 'Continue',
    expectedDeliveryAt = 'Expected Delivery',
    scheduledBetween = ' Scheduled for {date} - between {time}',
    expressDeliveryTitle = 'Express delivery',
    expressDeliveryExtraTitle = 'extra',
    weWillSelectYou = 'We will select the most convenient date for you',
    letUsKnowWhenWeCan = 'Let us know when we can deliver the following',
    deliveredByTitle = 'Delivered by',
    viewDeliveryDate = 'View Delivery Date',
    furnitureAndLargeItem = 'Furniture and large items delivery',
    sanitaryAndSmallItems = 'Sanitary and small items delivery',
    smallItems = 'Small items delivery',
  } = componentData || {};

  const deliveryTitle = {
    WH: furnitureAndLargeItem,
    ST: sanitaryAndSmallItems,
    CR: smallItems,
  };

  const InnerLoader = () => {
    return (
      <Lottie
        className="lottieLoader"
        animationData={loading}
        loop={true}
        style={{ width: 30, height: 30 }}
      />
    );
  };

  return (
    <>
      <div className={'deliverySlotScheduled'}>
        {isLoading && <InnerLoader />}
        <div className={'deliverySlotScheduledTitle'}>
          <h3>
            {!shipmentHasNoCalendar
              ? viewDeliveryDate
              : chooseDeliveryDateAndTime}
          </h3>
        </div>
        <div className={'deliveryShipmentsContain'}>
          {/* render all available slots */}
          {(availableShipments || [])?.map((shipment, indexShipment) => {
            const {
              provider,
              shipmentId = null,
              items = [],
              dates = [],
              showCalendar = false,
              isSupplier = false,
              selected,
            } = shipment || {};
            const nextDeliveryDate = items?.[0]?.nextDeliveryDate || null; // take first  next Delivery Date

            const isNotSelected = missingShipments.includes(shipmentId); // identify the shipment is selected or not

            return (
              <div
                className={'deliveryShipments'}
                key={`${shipmentId}${provider}${indexShipment}`}
              >
                {availableShipments?.length > 0 && (
                  <h6>
                    {shipmentOf
                      ?.replace('{number}', indexShipment + 1)
                      .replace('{total}', availableShipments?.length)}
                  </h6>
                )}

                <h5>
                  {!isSupplier
                    ? deliveryTitle?.[provider]
                    : `${deliveredByTitle} ${provider?.toLowerCase()}`}
                </h5>
                <p style={{ color: isNotSelected ? '#d12e27' : '' }}>
                  {!showCalendar && nextDeliveryDate
                    ? weWillSelectYou
                    : letUsKnowWhenWeCan}
                </p>

                <ul className={'ShipmentItems'}>
                  {items?.slice(0, 5).map((item, key) => (
                    <li key={item?.image}>
                      <img
                        src={item?.image}
                        width={75}
                        height={75}
                        alt="DanubeHome"
                      />
                    </li>
                  ))}
                  {items?.length > 5 && (
                    <li className={'ShipmentMoreItems'}>
                      {items?.length - 5}+
                    </li>
                  )}
                </ul>

                {/* show calender for pick dates */}
                {showCalendar && (
                  <div className={'deliveryShipmentsSchedule'}>
                    <div className={'chooseDeliveryShipmentsScheduleDate'}>
                      <div className={'ChooseDeliveryDateTitle'}>
                        <div className={'ChooseDeliveryDateTitleInner'}>
                          <h5>{chooseDeliveryDate}</h5>
                          <p>{plzSelectADay}</p>
                        </div>
                      </div>

                      <div className="checkoutSwiper">
                        <ul className={'chooseDeliveryShipmentsDateSlot'}>
                          {dates?.map((cDate, indexDate) => {
                            const {
                              isExpress = false,
                              charge = 0,
                              dayLabel,
                              dateLabel,
                              times = [],
                              dateId,
                            } = cDate || {};
                            const isDateSelected =
                              selectedDate[shipmentId]?.dateId === dateId;
                            //
                            let expressChargeSplit = charge;
                            const anyExpressSelected =
                              availableShipments?.filter(
                                (shipment) => shipment?.selected?.isExpress
                              ).length || 1;
                            const totalShipment =
                              availableShipments?.filter(
                                (shipment) => shipment?.showCalendar
                              )?.length || 1;

                            if (
                              anyExpressSelected &&
                              isDateSelected &&
                              totalShipment === anyExpressSelected
                            ) {
                              expressChargeSplit = charge / totalShipment;
                            }

                            // active the second date default
                            if (
                              dates?.length > 1 &&
                              !selectedDate[shipmentId] &&
                              indexDate === 1
                            ) {
                              selectedShipmentDate(shipmentId, dateId, times);
                            } else if (
                              dates?.length === 1 &&
                              !selectedDate[shipmentId] &&
                              indexDate === 0
                            ) {
                              selectedShipmentDate(shipmentId, dateId, times);
                            }

                            return (
                              <li
                                onClick={() =>
                                  selectedShipmentDate(
                                    shipmentId,
                                    dateId,
                                    times
                                  )
                                }
                              >
                                <p
                                  className={`${isExpress
                                      ? 'ChooseDeliveryDateExpressSlot'
                                      : 'ChooseDeliveryDateAvailableSlot'
                                    } ${isDateSelected ? 'active' : ''}`}
                                >
                                  {dayLabel}
                                  <br />
                                  {dateLabel}
                                </p>
                                {isExpress && expressChargeSplit > 0 && (
                                  <p className={'expressDeliveryFree'}>
                                    {expressDeliveryTitle} <br /> ({'currency'}{' '}
                                    {expressChargeSplit}{' '}
                                    {expressDeliveryExtraTitle})
                                  </p>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      {!selectedTime[shipmentId] && (
                        <div className={'plzSelectATime'}>{plzSelectATime}</div>
                      )}
                    </div>

                    {/* date selector  */}
                    {selectedDate[shipmentId] && (
                      <div className={'PickConvenientTime'}>
                        <div className={'PickConvenientTimeTitle'}>
                          <div className={'PickConvenientTimeTitleInner'}>
                            <h5>{pickATime}</h5>
                            <p>{pickADeliveryTime}</p>
                          </div>
                        </div>
                        <div className="shipmentSwiper">
                          <ul className={'PickConvenientTimeSlot'}>
                            {selectedDate[shipmentId]?.times?.map(
                              (timeSlot, indexTime) => {
                                const {
                                  timeLabel,
                                  timeId,
                                  preference = '',
                                } = timeSlot || {};
                                const isTimeSelected =
                                  selectedTime[shipmentId]?.timeId === timeId;

                                return (
                                  <li
                                    onClick={() =>
                                      selectedShipmentTime(shipmentId, timeSlot)
                                    }
                                    className={`${'tileSlots'} ${isTimeSelected ? 'active' : ''
                                      }`}
                                  >
                                    {isLoadingTime === timeId &&
                                      isTimeSelected && <InnerLoader />}

                                    {timeLabel}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {/* show delivery date if supplier or not have the date collection */}
                {!showCalendar && nextDeliveryDate && (
                  <p className={'expectedDelivery'}>
                    <strong>
                      <em>
                        {expectedDeliveryAt} :{' '}
                        {moment(nextDeliveryDate).format('YYYY-MM-DD')}
                      </em>
                    </strong>
                  </p>
                )}

                {/* show shipment date time once user selected */}
                {selected?.timeLabel && (
                  <p className={'expectedDelivery'}>
                    <strong>
                      <em>
                        {scheduledBetween
                          .replace('{date}', selected?.date)
                          .replace('{time}', selected?.timeLabel)}
                      </em>
                    </strong>
                  </p>
                )}
              </div>
            );
          })}
        </div>
        {/* skip button */}
        <div className={'CheckoutShipmentsAction'}>
          <button
            className={`Button ${'continue'}`}
            disabled={missingShipments.length !== 0 || !!isLoadingTime}
            onClick={() => {
              setShowDeliverySlots(missingShipments.length !== 0);
            }}
          >
            {continueThis}
          </button>
          <button
            disabled={!!isLoadingTime}
            onClick={() => {
              setShowDeliverySlots(false);
            }}
            className={`ButtonSecondary ${'skip'}`}
          >
            {skipThis}
          </button>
        </div>
      </div>
    </>
  );
};

export default DeliverySlotSelection;
