import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2"
import moment from "moment"


const BarChart = ({TotalCount,summaryType}) => {
  const currentYear = new Date().getFullYear();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
 
    window.addEventListener('resize', handleWindowResize);
 
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  
  }, []);
  function getWindowSize() {
   const {innerWidth, innerHeight} = window;
   return {innerWidth, innerHeight};
 }
 
 
 
  if(summaryType === "lastyear"){
    const arrYearFirst = [];
    const previousYear = TotalCount?.previous?.data;
    Object.keys(previousYear).map((key) => {
      if (previousYear?.[key]){
        arrYearFirst.push(previousYear?.[key]);
             
      }
  })
    
   
  //   previousYear?.forEach((el)=>{
  //     console.log(el)
  //     arrYearFirst.push(el);
  // })
  
  const arrYearSecond = []
   const currentYearDate = TotalCount?.current?.data;
   Object.keys(currentYearDate).map((key) => {
    if (currentYearDate?.[key]){
      arrYearSecond.push(currentYearDate?.[key]);
           
    }
})
  

  //  currentYearDate?.forEach((em)=>{
    
  //   arrYearSecond.push(em);
  // })
    // const chartoptions={
    //     responsive: true,
    //     maintainAspectRatio : true
    // }
    
    const data = {
      labels: ["January", "February", "March", "April", "May", "June", "July","August","September","October","November","December"],
     
      datasets: [
        {
          label:currentYear-1,
          backgroundColor: "#a5cded",
          borderColor: "#80b5eb",
          borderWidth: 1,
          hoverBackgroundColor: "#80b5eb",
          hoverBorderColor: "#80b5eb",
          data: arrYearFirst //TotalCount?.Year2021,
        },
        {
          label: currentYear,
          backgroundColor: "#556ee6",
          borderColor:  "#556ee6",
          borderWidth: 1,
          hoverBackgroundColor: "#556ee6",
          hoverBorderColor:  "#556ee6",
          data: arrYearSecond //['2345698.49', '4112904.82', '4008363.28', '3582336.15', '2448549.78', '2725007.79', '3672460.04', '3750040.37', '5237634.12', '5618697.04', '4161897.97','0'] //TotalCount?.Year2022,
        },
      ],
    }
  
 
    if(windowSize.innerWidth <= 720)
    {    
    
    return <Bar width={474} height={300} data={data}  />
   
    }
    else
    {
      return <Bar width={474} height={150} data={data}  />
    }
  }
  else if(summaryType  === "lastmonth"){
    var startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
    var endDate = moment().format('YYYY-MM-DD');
   

    
    let labels=[];
    const arrMonthFirst = [];
    const previousMonth = TotalCount?.previous?.data;
   
 
     Object.keys(previousMonth).map((key,inxDate) => {
     // let [a, b,c] = key.split("-");
     let inVal = inxDate+1;
      let Dates = ('0' + inVal)?.slice(-2)
    
        if (previousMonth?.[key]){
          arrMonthFirst.push(previousMonth?.[key])
          labels.push(Dates)     
        }
    })
      
  const arrMonthSecond = []
   const currentMonthDate = TotalCount?.current?.data;
    Object.keys(currentMonthDate).map((key) => {
        if (currentMonthDate?.[key]){
          arrMonthSecond.push(currentMonthDate?.[key]);
               
        }
    })
   



    
    const data = {
      labels:labels,
     
      datasets: [
        {
          label:currentYear-1,
          backgroundColor: "#a5cded",
          borderColor: "#80b5eb",
          borderWidth: 1,
          hoverBackgroundColor: "#80b5eb",
          hoverBorderColor: "#80b5eb",
          data: arrMonthFirst //TotalCount?.Year2021,
        },
        {
          label: currentYear,
          backgroundColor: "#556ee6",
          borderColor:  "#556ee6",
          borderWidth: 1,
          hoverBackgroundColor: "#556ee6",
          hoverBorderColor:  "#556ee6",
          data: arrMonthSecond //['2345698.49', '4112904.82', '4008363.28', '3582336.15', '2448549.78', '2725007.79', '3672460.04', '3750040.37', '5237634.12', '5618697.04', '4161897.97','0'] //TotalCount?.Year2022,
        },
      ],
      
    }
  
    if(windowSize.innerWidth <= 720)
    {    
    
    return <Bar width={474} height={300} data={data}  />
   
    }
    else
    {
      return <Bar width={474} height={150} data={data}  />
    }
  }
  else if(summaryType  === "lastweek"){
    let labels=[];
    const arrWeekFirst = [];
    const previousWeek = TotalCount?.previous?.data;
    
 
     Object.keys(previousWeek).map((key) => {
        if (previousWeek?.[key]){
          arrWeekFirst.push(previousWeek?.[key]);
                labels.push(key)
        }
    })
      
  const arrWeekSecond = []
   const currentweek = TotalCount?.current?.data;
    Object.keys(currentweek).map((key) => {
        if (currentweek?.[key]){
          arrWeekSecond.push(currentweek?.[key]);
               
        }
    })



    
    const data = {
      labels:['Monday','Tuesday','wednesday','thursday','Friday','Saturday','Sunday'],
     
      datasets: [
        {
          label:currentYear-1,
          backgroundColor: "#a5cded",
          borderColor: "#80b5eb",
          borderWidth: 1,
          hoverBackgroundColor: "#80b5eb",
          hoverBorderColor: "#80b5eb",
          data: arrWeekFirst //TotalCount?.Year2021,
        },
        {
          label: currentYear,
          backgroundColor: "#556ee6",
          borderColor:  "#556ee6",
          borderWidth: 1,
          hoverBackgroundColor: "#556ee6",
          hoverBorderColor:  "#556ee6",
          data: arrWeekSecond //['2345698.49', '4112904.82', '4008363.28', '3582336.15', '2448549.78', '2725007.79', '3672460.04', '3750040.37', '5237634.12', '5618697.04', '4161897.97','0'] //TotalCount?.Year2022,
        },
      ],
    }
  
  
    if(windowSize.innerWidth <= 720)
    {    
    
    return <Bar width={474} height={300} data={data}  />
   
    }
    else
    {
      return <Bar width={474} height={150} data={data}  />
    }
  }

  }
  
 


export default BarChart