import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Col, Row, Progress } from "reactstrap";
function PhotoShootProgressbar({ orderData }) {
  const progressStatus = orderData?.[0].Status;
  console.log("---", progressStatus);
  let progressArr = [];
  let isCancel = 0;
  let bar = 0;
  if (progressStatus === "Cancel") {
    progressArr = [0, 0, 0, 0, 0];
    isCancel = 1;
    bar = 0;
  } else if (progressStatus === "Open") {
    progressArr = [1, 0, 0, 0, 0];
    isCancel = 0;
    bar = 0;
  } else if (progressStatus === 'merchandising') {
    progressArr = [1, 1, 0, 0, 0];
    isCancel = 0;
    bar = 20;
  } else if (progressStatus === 'photoshoot') {
    progressArr = [1, 1, 1, 0, 0];
    isCancel = 0;
    bar = 40;
  } else if (progressStatus === 'content') {
    progressArr = [1, 1, 1, 1, 0];
    isCancel = 0;
    bar = 60;
  } else if (progressStatus === 'website') {
    progressArr = [1, 1, 1, 1, 1];
    isCancel = 0;
    bar = 80;
  }
  else if (progressStatus === 'completed') {
    progressArr = [1, 1, 1, 1, 1];
    isCancel = 0;
    bar = 100;
  }
  return (
    <>
      <div className="ProgressStepBlock">
        {isCancel ? (
          <>
            <Row className="ProgressbarStatus align-items-center">
              <Col xl={8}>
                <div className="position-relative">
                  <Progress
                    value={25}
                    color="danger"
                    style={{ height: "8px" }}
                  ></Progress>
                  <button className="ProgressStep position-absolute ProgressStepTop-0 ProgressStep-0 translate-middle btn btn-sm  rounded-pill btn-danger">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.956"
                      height="14.175"
                      viewBox="0 0 16.956 14.175"
                    >
                      <g
                        id="Group_531"
                        data-name="Group 531"
                        transform="translate(1.058 1.058)"
                      >
                        <g id="Group_519" data-name="Group 519">
                          <path
                            id="Path_663"
                            data-name="Path 663"
                            d="M15.591.75,4.991,13.118.75,8.171"
                            transform="translate(-0.75 -0.75)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                  <button className="ProgressStep position-absolute ProgressStepTop-0 ProgressStep-1 translate-middle btn btn-sm  rounded-pill btn-danger">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.956"
                      height="14.175"
                      viewBox="0 0 16.956 14.175"
                    >
                      <g
                        id="Group_531"
                        data-name="Group 531"
                        transform="translate(1.058 1.058)"
                      >
                        <g id="Group_519" data-name="Group 519">
                          <path
                            id="Path_663"
                            data-name="Path 663"
                            d="M15.591.75,4.991,13.118.75,8.171"
                            transform="translate(-0.75 -0.75)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </Col>
            </Row>
            <Row className="ProgressbarStatus align-items-center">
              <Col xl={8}>
                <div className="position-relative">
                  <span className="status ProgressStep position-absolute ProgressStepTop-0 ProgressStep-0 translate-middle">
                    Open
                  </span>
                  <span className=" status ProgressStep position-absolute ProgressStepTop-0 ProgressStep-1 translate-middle">
                    Canceled
                  </span>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <>
            {" "}
            <Row className="ProgressbarStatus align-items-center">
              <Col xl={8}>
                <div className="position-relative">
                  <Progress
                    value={bar}
                    color="primary"
                    style={{ height: "8px" }}
                  ></Progress>
                  <button
                    className={`ProgressStep position-absolute ProgressStepTop-0 ProgressSteps-0 translate-middle btn btn-sm  rounded-pill ${
                      progressArr[0] === 1 ? "btn-primary" : "btn-secondary"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.956"
                      height="14.175"
                      viewBox="0 0 16.956 14.175"
                    >
                      <g
                        id="Group_531"
                        data-name="Group 531"
                        transform="translate(1.058 1.058)"
                      >
                        <g id="Group_519" data-name="Group 519">
                          <path
                            id="Path_663"
                            data-name="Path 663"
                            d="M15.591.75,4.991,13.118.75,8.171"
                            transform="translate(-0.75 -0.75)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                  <button
                    className={`ProgressStep position-absolute ProgressStepTop-0 ProgressSteps-1 translate-middle btn btn-sm  rounded-pill ${
                      progressArr[1] === 1 ? "btn-primary" : "btn-secondary"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.956"
                      height="14.175"
                      viewBox="0 0 16.956 14.175"
                    >
                      <g
                        id="Group_531"
                        data-name="Group 531"
                        transform="translate(1.058 1.058)"
                      >
                        <g id="Group_519" data-name="Group 519">
                          <path
                            id="Path_663"
                            data-name="Path 663"
                            d="M15.591.75,4.991,13.118.75,8.171"
                            transform="translate(-0.75 -0.75)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                  <button
                    className={`ProgressStep position-absolute ProgressStepTop-0 ProgressSteps-2 translate-middle btn btn-sm rounded-pill ${
                      progressArr[2] === 1 ? "btn-primary" : "btn-secondary"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.956"
                      height="14.175"
                      viewBox="0 0 16.956 14.175"
                    >
                      <g
                        id="Group_531"
                        data-name="Group 531"
                        transform="translate(1.058 1.058)"
                      >
                        <g id="Group_519" data-name="Group 519">
                          <path
                            id="Path_663"
                            data-name="Path 663"
                            d="M15.591.75,4.991,13.118.75,8.171"
                            transform="translate(-0.75 -0.75)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                  <button
                    className={`ProgressStep position-absolute ProgressStepTop-0 ProgressSteps-3 translate-middle btn btn-sm rounded-pill ${
                      progressArr[3] === 1 ? "btn-primary" : "btn-secondary"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.956"
                      height="14.175"
                      viewBox="0 0 16.956 14.175"
                    >
                      <g
                        id="Group_531"
                        data-name="Group 531"
                        transform="translate(1.058 1.058)"
                      >
                        <g id="Group_519" data-name="Group 519">
                          <path
                            id="Path_663"
                            data-name="Path 663"
                            d="M15.591.75,4.991,13.118.75,8.171"
                            transform="translate(-0.75 -0.75)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                  <button
                    className={`ProgressStep position-absolute ProgressStepTop-0 ProgressSteps-4 translate-middle btn btn-sm rounded-pill ${
                      progressArr[4] === 1 ? "btn-primary" : "btn-secondary"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.956"
                      height="14.175"
                      viewBox="0 0 16.956 14.175"
                    >
                      <g
                        id="Group_531"
                        data-name="Group 531"
                        transform="translate(1.058 1.058)"
                      >
                        <g id="Group_519" data-name="Group 519">
                          <path
                            id="Path_663"
                            data-name="Path 663"
                            d="M15.591.75,4.991,13.118.75,8.171"
                            transform="translate(-0.75 -0.75)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                  <button
                    className={`ProgressStep position-absolute ProgressStepTop-0 ProgressSteps-5 translate-middle btn btn-sm rounded-pill ${
                      progressArr[5] === 1 ? "btn-primary" : "btn-secondary"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.956"
                      height="14.175"
                      viewBox="0 0 16.956 14.175"
                    >
                      <g
                        id="Group_531"
                        data-name="Group 531"
                        transform="translate(1.058 1.058)"
                      >
                        <g id="Group_519" data-name="Group 519">
                          <path
                            id="Path_663"
                            data-name="Path 663"
                            d="M15.591.75,4.991,13.118.75,8.171"
                            transform="translate(-0.75 -0.75)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </Col>
            </Row>
            <Row className="ProgressbarStatus align-items-center">
              <Col xl={8}>
                <div className="position-relative">
                  <span className="status ProgressStep position-absolute ProgressStepTop-0 ProgressSteps-0 translate-middle">
                    Transfer Order
                  </span>
                  <span className="status ProgressStep position-absolute ProgressStepTop-0 ProgressSteps-1 translate-middle">
                   Merchandising
                  </span>
                  <span className="status ProgressStep position-absolute ProgressStepTop-0 ProgressSteps-2 translate-middle">
                    Photoshoot/Editing
                  </span>
                  <span className="status ProgressStep position-absolute ProgressStepTop-0 ProgressSteps-3 translate-middle">
                    Content
                  </span>
                  <span className=" status ProgressStep position-absolute ProgressStepTop-0 ProgressSteps-4 translate-middle">
                    Website
                  </span>
                  <span className=" status ProgressStep position-absolute ProgressStepTop-0 ProgressSteps-5 translate-middle">
                    Completed
                  </span>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}
export default PhotoShootProgressbar;
