import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useUrlSearchParams } from "use-url-search-params";
import { Card, Button, OverlayTrigger, Form, Tooltip } from "react-bootstrap";
import { Badge } from "reactstrap";
import Select from "react-select";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import { fetchCallBackDataList } from "../action/action";
import { SORTING_ORDER } from "../../../constants/index";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";

import moment from "moment";

import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  NavLink,
  Nav,
  NavItem,
} from "reactstrap";
//import {useIntl} from 'react-intl';
import CountUp from "react-countup";

const BulkOrderList = () => {
  const location = useLocation();

  const [btnprimary1, setBtnprimary1] = useState(false);

  const dispatch = useDispatch();
  //const { formatMessage: f } = useIntl();
  //const [countryCode, setCountryCode] = useUrlSearchParams({ country: "" });
  const currentCountry = localStorage.getItem("WEBSITE_COUNTRY");

  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState();
  const [sortingName, setSortingName] = useState({ id: "sort.date_asc" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [selectedType, setSelectedType] = useState(null);
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [ActiveFilter,setActiveFilter] = useState(false);
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: "_date",
    order: SORTING_ORDER.ASC,
  });
  const [isTypeFilterUpdate, setIsTypeFilterUpdate] = useState(false);
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
  const [customActiveTab, setcustomActiveTab] = useState("All");
  const [orderMode, setOrderMode] = useState("All");
  let is_split = 0;
  const { country = [] } = useSelector((state) => state.country);
  const [RequestTypeFilter, setRequestTypeFilter] = useUrlSearchParams({ TypeFilter: "All" });
  const splitVal = location?.pathname.split("/");
  const optionGroup = [
    {
      label: "Request Type",
      options: [
        { label: "Place New Order", value: "Place New Order" },
        { label: "Delivery Related Queries", value: "Delivery Related Queries" },
        { label: "Product Enquiries ", value: "Product Enquiries" },
        { label: "Refund / Credit Note ", value: "Refund / Credit Note" },
        { label: "Order Cancellation", value: "Order Cancellation" },
        { label: "Others ", value: "Others" },
      
       
      ],
    },
  ]

  useEffect(() => {
    setDataLoading(true);
    fetchCallBackDataList({
      params: {
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
        search_qry: searchValue.search,
        status: customActiveTab,
        orderBy: "id",
        country:currentCountry,
        request_type: RequestTypeFilter.TypeFilter
      },
      dispatch,
    })
    if(isTypeFilterUpdate === true){
      setIsTypeFilterUpdate(false)
    }
    setDataLoading(false);
  }, [
    paginationValue.page,
    limitValue,
    sortingValue.sort,
    searchValue.search,
    customActiveTab,
    orderMode,
    RequestTypeFilter.TypeFilter
  ]);

  const limitByValues = [5, 10, 20, 50];
  const { callbackList = [], isLoading } = useSelector((state) => state.callback);
  const { statusCounts = [], isStsCntsLoading } = useSelector(
    (state) => state.order
  );

  const [isDataLoading, setDataLoading] = useState(false);

  const { listOrders } = callbackList;
  const orderData = listOrders?.orderList;

  let LsSts = "New";
  
  const stsCounts = statusCounts?.data;

  var statusArr = [];
  // const countsData = { ...statusArr };
  let getValue = (statusArr, StsKey) => {
    let returnSts = 0;
    statusArr.forEach((o) => {
      if (o[StsKey] !== undefined) {
        returnSts = o[StsKey];
      }
    });
    return returnSts;
  };
  const resetState =()=>{
    setRequestTypeFilter({
      TypeFilter: "",
    });
  }

 

  const sortByValues = [
    { key: "date", order: SORTING_ORDER.ASC, label: { id: "sort.date_asc" } },
    { key: "date", order: SORTING_ORDER.DESC, label: { id: "sort.date_desc" } },
    {
      key: "status",
      order: SORTING_ORDER.ASC,
      label: { id: "sort.status_asc" },
    },
    {
      key: "status",
      order: SORTING_ORDER.DESC,
      label: { id: "sort.status_desc" },
    },
  ];
  let isTypeFilter = JSON.parse(localStorage.getItem("TypeFilter"));
  if (RequestTypeFilter?.TypeFilter === "") {
    isTypeFilter = "";
  }
  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };

  const toggleCustom = (tab) => {
    // console.log("ActiveTab----", tab);
    if (customActiveTab !== tab) {
      // console.log("customActiveTab----", tab);
      setcustomActiveTab(tab);
    }
  };
  const onDrawerClose = () => {
    setOpen(false);
  };
  const AdvanceFilterOnClick = (filterStatus) => {
    if(filterStatus === false){
      setActiveFilter(true)
      setPosition("right");
      setOpen(!open);

    }
    else {
      setActiveFilter(false)
    }

  }
  const handleSelectType = (selectedGroup) => {
    setRequestTypeFilter({ TypeFilter: selectedGroup?.value });
    localStorage.setItem("TypeFilter", JSON.stringify(selectedGroup));
    setSelectedType(selectedGroup);

  };

  let pageIndex = Number(listOrders?.start);

  return (
    <>

<Row className=" dh-TabRow textAlignRight "  >
        
        <Col className="MobileView textAlignRight ">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
       
          <a  onClick={() =>{ AdvanceFilterOnClick(ActiveFilter)}} className="filterTxt" >Filter 
         {ActiveFilter === true ?<i className="bx bx-filter-alt"></i> :  <i className="bx bx-filter-alt"></i> }
         
         
         </a>

                
                </Col>
      </Row>
      <ReactDrawer
                    open={open}
                    position={position}
                    onClose={onDrawerClose}
                    className="sideNavWidth"
                  >
      <Card
                    className="card-h-100 OrderDetailsBox"
                    style={{ overflowY: "scroll" }}
                  >
                    <CardHeader>
                      <Row className="mb-3">
                        <Col>
                          <CardTitle>Advance Filter </CardTitle>
                        </Col>
                        <Col className="textAlignRight">
                          {" "}

                          <button  type= "button" className="btn btn-outline-info
                    waves-effect waves-light "
                    
                    onClick={() => {
                     
                      onDrawerClose()
                      resetState()
                    }}
                    
                  >
                    Clear Filter{" "}
                  </button>
                        
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                    <Row >

<Col>
  <div className="mb-3">
    <label
      htmlFor="choices-single-default"
      className="form-label font-size-13 text-muted"
    >
     Callback Type
    </label>
    <Select
      value={isTypeFilter}
      onChange={handleSelectType}
      options={optionGroup}
      defaultValue={isTypeFilter}
      classNamePrefix="select2-selection"
    />
  </div>
</Col>
{}

</Row>{" "}

                 
              </CardBody>
            </Card>
                    </ReactDrawer>

      <div className="table-responsive dt-table">
        {isLoading  || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {orderData?.length > 0 ? (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th  nowrap="nowrap">Date</th>
                  <th nowrap="nowrap">Name</th>
                  <th>Mobile</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Action </th>
                </tr>
              </thead>
              {(orderData || []).map((order, index) => {
                pageIndex++;
                
                //  console.log("order_id----------",order_id);
                let cdate = order.Date,
                  orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                  orderTime = moment(new Date(cdate)).format("h:mm:ss a");

                let stsBtn = "bg-lightgrey";
                if (order.Status === "0") {
                  stsBtn = "bg-dark";
                  LsSts = "Pending";
                } else {
                  stsBtn = "bg-success"
                  LsSts = "Completed";
                }
             
            
                return (
                  <tbody key={order.CallbackId}>
                    <tr>
                      <th scope="row">{pageIndex}</th>
                      <td  nowrap="nowrap">
                        {orderDt} <br></br>
                        {orderTime}
                      </td>
                      <td nowrap="nowrap">{order.FullName}</td>
                      <td  nowrap="nowrap">{order.MobileNumber}</td>
                      <td nowrap="nowrap">{order.Type}</td>
                    
                    
                      <td>
                        <Badge
                          className={` ${stsBtn} me-2`}
                        >
                          {LsSts}
                        </Badge>
                      </td>
                      <td>
                        <a
                          href={`Callbackdetail/${order.CallbackId}`}
                          type="button"
                          className="btn btn-sm btn-outline-warning waves-effect waves-light"
                        >
                          {" "}
                          <i className="mdi mdi-border-color"></i>{" "}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>

            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
              <tr>
                  <th>SI.No</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Email</th>
                  <th>Company Name</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
};
export default BulkOrderList;
