import axios from 'axios';
import { setUserData } from '../../../Redux/user/userSlice';
import {
  setOrderList,
  getOrderDetail,
  getUpdateCoordinate,
  getRemarks,
  getTriggerDmsDate,
  updateOrderDetails,
  excelGenerateFile,
  getStatusCounts,
  setReturnOrderList,
  ReturnOrderDetails,
  ReturnOrderRemarks,
  setBulkOrderList,
  setBulkDetail,
  setBulkStatus,
  setReturnStatusCount,
  generateCheckpayment,
  createpaymentInvoice,
  setAllOrderCount,
  setLastOrderCount,
  setsplitorderCount,
  setNonServiceArealist,
  setNonServiceAreaView,
  setNonServiceAreaUpdate,
  setNonServiceAreaInsert,
  getShipmentDetail,
  setCreateReturn,
  getOrderDetailReturn,
  getOrderLogDetail,
  getTriggerOrders,
  getChangePaymentmethod,
  orderNotificationEmail,
  getAvilableDays,
  getAvilableTime,
  getAvilableSchedule,
  sendSmSDetail,
  OrderDeleteSlice,
  OrderDeleteList,
  OrderDeleteDetail,
  setSellerData,
  setInstallationList
} from '../../../Redux/order/orderSlice';
import UserAuthKey from '../../../components/Common/UserAuthKey';

const apiUrl = process.env.REACT_APP_OMS_API;
const sellerApiUrl = process.env.REACT_APP_SELLER_API;
const sellerAuthKey = process.env.REACT_APP_SELLER_API_KEY;

export const fetchAllOrders = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/allorder-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setOrderList(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const fetchOrderDetail = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/order-details`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(getOrderDetail(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const updateCoordinate = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/update-cordinates`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(getUpdateCoordinate(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const getSellerInfo = async ({ params, dispatch }) => {
  const config = {
    method: 'POST',
    url: `${sellerApiUrl}v1/get-seller-details`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
      authKey: `${sellerAuthKey}`,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setSellerData(response?.data));
  } catch (error) {
    dispatch(setSellerData(null));
  }
};

export const getCanceledRemarks = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/cancel-reason`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(getRemarks(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const getTriggerDMS = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/trigger-dms`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(getTriggerDmsDate(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const updateOrderStatus = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/shipment-update`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(updateOrderDetails(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const excelGenerate = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/export_result`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(excelGenerateFile(response?.data));
    window.location.href = response?.data?.data?.[0]?.report_link;
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const getOrderStatusCounts = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/statusCounts`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(getStatusCounts(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const checkPayment = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/checkpayment`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(generateCheckpayment(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const paymentInvoiceLink = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/create-invoice`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(createpaymentInvoice(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const getAllReturnOrders = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/allreturn-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setReturnOrderList(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const getReturnOrderDetails = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/orders/return-details`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(ReturnOrderDetails(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const getReturnOrderRemarks = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/returnupdate`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(ReturnOrderRemarks(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};


export const bulkOrderDetail = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/allbulk-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setBulkOrderList(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const bulkOrderData = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/bulk-details`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setBulkDetail(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const bulkOrderStatus = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();

  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/update-bulk-status`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setBulkStatus(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const returnStatusCount = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/returnstatusCounts`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setReturnStatusCount(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const allOrderStatusCount = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/orders/total-count`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setAllOrderCount(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const lastOrderStatusCount = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/orders/lastorder-count`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setLastOrderCount(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const fetchSplitStatusCount = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/orders/split-order`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setsplitorderCount(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const NonServiceAreaList = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/nonservice/nonservicearea-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setNonServiceArealist(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const NonServiceAreaView = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/nonservice/nonservicearea-view`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setNonServiceAreaView(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const NonServiceAreaUpdate = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/nonservice/nonservicearea-update`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setNonServiceAreaUpdate(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const NonServiceAreaInsert = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/nonservice/nonservicearea-insert`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setNonServiceAreaInsert(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const fetchShipmentDetail = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/orders/shipment-tracking`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(getShipmentDetail(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const fetchCreateReturnDetail = async ({ formData, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/return/create`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'multipart/form-data',
    },

    data: formData,
  };
  try {
    const response = await axios(config);
    dispatch(setCreateReturn(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const fetchOrderReturnDetail = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/return/details`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(getOrderDetailReturn(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const fetchOrderLogDetail = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/order-log`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(getOrderLogDetail(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const fetchOrderTriggerDetail = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/trigger-orders`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(getTriggerOrders(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const ChangePaymentMethodApi = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/change-payment-method`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(getChangePaymentmethod(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const OrderEmailNotification = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/sent-order-notification`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(orderNotificationEmail(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const getAvilableDaysDetail = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/get-available-days`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getAvilableDays(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const getAvilableTimeDetail = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/get-available-slot`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getAvilableTime(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const getAvilableTimeSchedule = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/delivery-reschdule`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getAvilableSchedule(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};

export const getSendSMSDetail = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/orders/click-collect-sms`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(sendSmSDetail(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const getOrderDeleteDate = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/delete-order`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(OrderDeleteSlice(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const fetchAllDeleteOrders = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/list-delete-order`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(OrderDeleteList(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const fetchAllDeleteOrdersDetail = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/deleted-order-details`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(OrderDeleteDetail(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
export const getRevertOrderData = (data, dispatch) => {
  const bearerToken = UserAuthKey();

  return axios({
    method: 'post',
    url: `${apiUrl}v1/orders/moveback-archive-order`,
    data: data,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (response) => {
      console.log(response, 'response');
      if (response) {
        return response?.data;
      } else {
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchUserView = async (params, dispatch) => {
  console.log(params);
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/user/user-view`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    return response?.data;
  } catch (error) { }
};

export const fetchInstallationOrders = async ({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/orders/installation-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);

    dispatch(setInstallationList(response?.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setUserData(null));
      window.location.replace('/logout');
    } else {
    }
  }
};
