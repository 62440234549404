import { gql } from '@apollo/client';
export const CREATE_DEPARTMENT= gql`
mutation(
   $input:DepartmentsInput!
  ){
    createDepartments(input: $input) {
    _id
    name
    host
    icon
    slug
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
  }`;
