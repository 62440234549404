import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  productsFeedBackList: [],
  isLoading: true,
  isDetailLoading:true,
  isUpdateLoading:true,
  isExportLoading:true
};

const productsSlice = createSlice({
  name: "Products",
  initialState,
  reducers: {
    setProductsFeedBackList(state, actions) {
      state.productsFeedBackList = actions.payload;
      state.isLoading = false;
    },
    setProductsFeedBackDetails(state, actions) {
      state.productsFeedBackDetail = actions.payload;
      state.isDetailLoading = false;
    },
    setProductsFeedBackUpdate(state, actions) {
      state.productsFeedBackUpdate = actions.payload;
      state.isUpdateLoading = false;
    },
    setProductsFeedBackExport(state, actions) {
      state.productsFeedBackExport = actions.payload;
      state.isExportLoading = false;
    },
  },
});

export const { setProductsFeedBackList, setProductsFeedBackDetails, setProductsFeedBackUpdate,setProductsFeedBackExport }= productsSlice.actions;

const productsReducer = productsSlice.reducer;

export default productsReducer;
