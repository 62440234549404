import "./ProductsFeedBackList.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUrlSearchParams } from "use-url-search-params";
import { Card, Form } from "react-bootstrap";
import { Badge } from "reactstrap";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import {
  fetchProductsFeedBackList,
  getFeedbackDetailsByID,
  ProductsFeedBackUpdate,
} from "../action/productsAction";
import { SORTING_ORDER } from "../../../constants/index";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  FormGroup,
} from "reactstrap";

const ProductsFeedBackList = () => {
  const dispatch = useDispatch();
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [sortingName, setSortingName] = useState({ id: "sort.date_asc" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [feedBackData, setfeedBackData] = useState([]);
  const [isFeedbackReload, setFeedbackReload] = useState(false);
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: "_date",
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
  const { country = [] } = useSelector((state) => state.country);
  const sortByValues = [
    { key: "date", order: SORTING_ORDER.ASC, label: { id: "sort.date_asc" } },
    { key: "date", order: SORTING_ORDER.DESC, label: { id: "sort.date_desc" } },
    {
      key: "status",
      order: SORTING_ORDER.ASC,
      label: { id: "sort.status_asc" },
    },
    {
      key: "status",
      order: SORTING_ORDER.DESC,
      label: { id: "sort.status_desc" },
    },
  ];
  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };
  const toggleRightDrawer = () => {
    setPosition("right");
    setOpen(!open);
  };
  const onDrawerClose = () => {
    setOpen(false);
  };

  const getProductFeedBack = async (id) => {
    setfeedBackData([]);
    setDataLoading(true);
    let result = await getFeedbackDetailsByID({
      params: {
        id: id,
      },
    });
    if (result) {
      setfeedBackData(result);
    }
    setDataLoading(false);
  };
  
  useEffect(() => {
    setDataLoading(true);
    fetchProductsFeedBackList({
      params: {
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        sort_by: "",
        order_by: "id",
        search: searchValue.search,
      },
      dispatch,
    });
    setDataLoading(false);
    //setFeedbackReload(false);
  }, [paginationValue.page, limitValue, searchValue.search, isFeedbackReload]);
  const limitByValues = [5, 10, 20, 50];

  const { productsFeedBackList = [], isLoading } = useSelector(
    (state) => state.products
  );

  const [isDataLoading, setDataLoading] = useState(false);
  const productFeedBackData = productsFeedBackList?.feedback?.data;
  let pageIndex = Number(productsFeedBackList?.feedback?.start);

  const handleFeedbackStatus = (event, values) => {
    const isNotify=values?.notifyCustomer[0] || "0";
    setDataLoading(true);
    ProductsFeedBackUpdate({
      params: {
        id: values?.feedBackId,
        remark: values?.remark,
        status: "1",
        isNotify
      },
      dispatch,
    });
  };

  const { productsFeedBackUpdate = [], isUpdateLoading } = useSelector(
    (state) => state.products
  );
  useEffect(() => {
    if (productsFeedBackUpdate?.code === 100) {
      setFeedbackReload(true);
      setDataLoading(false);
      setfeedBackData([]);
      toast.success(productsFeedBackUpdate?.message, {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
      setOpen(false);
    } else {
      setDataLoading(false);
      if (productsFeedBackUpdate?.message) {
        toast.error(productsFeedBackUpdate?.message, {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [productsFeedBackUpdate]);

  return (
    <>
      <Row className=" dh-TabRow textAlignRight ">
        <Col className="MobileView textAlignRight ">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
        </Col>
      </Row>
      <div className="table-responsive dt-table">
        {isLoading || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {productFeedBackData?.length > 0 ? (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th nowrap="nowrap">Date</th>
                  <th>Mobile</th>
                  <th>Sku</th>
                  <th>Reason </th>
                  <th>Feedback </th>
                  <th>Status</th>
                  <th>Country</th>
                  <th>Action </th>
                </tr>
              </thead>
              {(productFeedBackData || []).map((productFeedback, index) => {
                pageIndex++;
                let stsBtn = "bg-lightgrey";
                let LsSts = "Pending";
                if (productFeedback?.Status === "1") {
                  stsBtn = "bg-success";
                  LsSts = "Completed";
                } else {
                  stsBtn = "bg-dark";
                  LsSts = "Pending";
                }

                let cdate = productFeedback.Created_at,
                  orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                  orderTime = moment(new Date(cdate)).format("h:mm:ss a");

                return (
                  <tbody key={productFeedback?.Id}>
                    <tr>
                      <th scope="row">{pageIndex}</th>
                      <td nowrap="nowrap">
                        {orderDt} <br></br>
                        {orderTime}
                      </td>

                      <td nowrap="nowrap">{productFeedback?.Mobile}<br></br>{productFeedback?.Name}</td>
                      <td nowrap="nowrap">{productFeedback?.Sku}</td>
                      <td>{productFeedback?.Reason}</td>
                      <td>
                        <p className="p-Wrap">{productFeedback?.Feedback}</p>
                      </td>
                      <td>
                        <Badge className={` ${stsBtn} me-2`}>{LsSts}</Badge>
                      </td>
                      <td>{productFeedback?.Country}</td>
                      <td>
                        <a
                          href={`productFeedbackDetail/${productFeedback?.Id}`}
                          type="button"
                          className="btn btn-sm btn-outline-warning waves-effect waves-light"
                        >
                          {" "}
                          <i className="mdi mdi-border-color"></i>{" "}
                        </a>{" "}
                        <button
                          className="btnTracking mb-2"
                          onClick={() => {
                            toggleRightDrawer();
                            getProductFeedBack(productFeedback?.Id);
                          }}
                        >
                          {LsSts==='Completed'?<><i className="bx bx-check-double"></i>Replied</>:'Reply'}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={productsFeedBackList?.feedback?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th nowrap="nowrap">Date</th>
                  <th>Mobile</th>
                  <th>Sku</th>
                  <th>Reason </th>
                  <th>Feedback </th>
                  <th>Status</th>
                  <th>Country</th>
                  <th>Action </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>

      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <Card>
          <CardHeader>
            <Row>
              <Col className="textAlignCenter">
                <CardTitle>Product Feedback</CardTitle>
              </Col>
              <Col className="textAlignRight">
                {" "}
                <button
                  type="button"
                  className="btn-close "
                  onClick={() => {
                    onDrawerClose();
                  }}
                  aria-label="Close"
                ></button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col className="mb-3">
                <div className="productFeedbackCustomerMsg">
                  <p className="productFeedbackCustomer">
                    <strong>{feedBackData?.[0]?.name?feedBackData?.[0]?.name:'Customer'}</strong>
                    <span>
                      {moment(new Date(feedBackData?.[0]?.created_at)).format(
                        "Do MMM, YYYY h:mm:ss a"
                      ) || ""}
                    </span>
                  </p>
                  <p>
                    <b>
                      {feedBackData?.[0]?.reason || ""}
                      <br></br>
                      SKU : {feedBackData?.[0]?.sku || ""}
                    </b>
                    <br></br>
                    {feedBackData?.[0]?.feedback || ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleFeedbackStatus(e, v);
                  }}
                >
                  <FormGroup className="mb-3 productFeedback">
                    <AvField
                      name="feedBackId"
                      value={feedBackData?.[0]?.id || 0}
                      type="hidden"
                    />
                    <AvField
                      name="remark"
                      label="Product Feedback Reply"
                      type="textarea"
                      value={feedBackData?.[0]?.remark || ""}
                      required
                    />
                  </FormGroup>
                  <AvCheckboxGroup name="notifyCustomer" >
                    <AvCheckbox className="d-flex gap-2"
                      customInput
                      label="Send notification to Customer"
                      value="1"
                      defaultChecked={true}
                    />
                  </AvCheckboxGroup>
                  {feedBackData?.[0]?.status!=="1"?<>
                  <div className="d-flex mt-2 flex-wrap gap-2">
                    <button type="submit" className="btn btn-secondary">
                      {isDataLoading === true && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{" "}
                      Reply
                    </button>{" "}
                    <button type="reset" className="btn">
                      Cancel
                    </button>
                  </div>
                  </>:''}
                </AvForm>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ReactDrawer>
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};
export default ProductsFeedBackList;
