import React, { useState } from "react";
import { MetaTags } from "react-meta-tags";
import Sms from "./components/Sms/Sms.component";

import {
  Breadcrumbs,
  DashBreadcrumb,
} from "../../../components/Common/Breadcrumb";
//../../../components/Common/Breadcrumb
import { Col, Container, Row, Button, Card, CardBody } from "reactstrap";
const NotificationSettings = () => {
  const test="Test Data";
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Notification settings </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Settings"
                breadcrumbItem="Notification Settings"
              />
            </Col>
          </Row>

          <Card className="card-dh card-h-100">
            <CardBody className="card-body-dh">
              <Sms testData={test} />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default NotificationSettings;
